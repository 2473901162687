import create from "zustand";
import { mountStoreDevtool } from "simple-zustand-devtools";

const useStore = create((set) => ({
  //condicional para saber si es flujo interno o externo
  globalTipoCotizador: {},
  setGlobalTipoCotizador: (data) =>
    set((state) => ({ globalTipoCotizador: data })),

  //catalogos globales
  globalCatalogos: {},
  setGlobalCatalogos: (data) => set((state) => ({ globalCatalogos: data })),

  //datos de cotizador
  globalDatosAsegurado: {},
  setglobalDatosAsegurado: (data) =>
    set((state) => ({ globalDatosAsegurado: data })),

  // usa alarma local o no
  globalAlarmaLocal: {},
  setGlobalAlarmaLocal: (data) => set((state) => ({ globalAlarmaLocal: data })),

  //dato usa valuador o no
  globalValuador: {},
  setGlobalValuador: (data) => set((state) => ({ globalValuador: data })),

  //datos de valuación
  globalDatosValuacion: {},
  setGlobalDatosValuacion: (data) =>
    set((state) => ({ globalDatosValuacion: data })),

  //suma asegurada
  globalSumaAsegurada: {},
  setGlobalSumaAsegurada: (data) =>
    set((state) => ({ globalSumaAsegurada: data })),

  // nuevo costo con coberturas extras
  globalNuevoCosto: {},
  setGlobalNuevoCosto: (data) => set((state) => ({ globalNuevoCosto: data })),

  //token login
  globalTokenLogin: {},
  setGlobalTokenLogin: (data) => set((state) => ({ globalTokenLogin: data })),

  //token redireccion flujo interno
  globalTokenInterno: {},
  setGlobalTokenInterno: (data) =>
    set((state) => ({ globalTokenInterno: data })),

  //global datos perfil usuario interno
  globalUsuarioInterno: {},
  setGlobalUsuarioInterno: (data) =>
    set((state) => ({ globalUsuarioInterno: data })),

  //token de login/registro sin contraseña
  globalTokenCotizacion: {},
  setGlobalTokenCotizacion: (data) =>
    set((state) => ({ globalTokenCotizacion: data })),

  //token cotizador interno sin decodificar
  globalTokenParams: {},
  setGlobalTokenParams: (data) => set((state) => ({ globalTokenParams: data })),

  // global deal id de deal externo
  globalDealId: {},
  setGlobalDealId: (data) => set((state) => ({ globalDealId: data })),

  // global contact id de deal externo
  globalContactId: {},
  setGlobalContactId: (data) => set((state) => ({ globalContactId: data })),

  //info sobre paquetes luego de cotizar
  globalDatosPaquetes: {},
  setGlobalDatosPaquetes: (data) =>
    set((state) => ({ globalDatosPaquetes: data })),

  //paquete elegido
  globalPaqueteElegido: {},
  setGlobalPaqueteElegido: (data) =>
    set((state) => ({ globalPaqueteElegido: data })),

  //paquete con coberturas con factor linea
  globalPaquetesFactorLinea: {},
  setGlobalPaquetesFactorLinea: (data) =>
    set((state) => ({ globalPaquetesFactorLinea: data })),

  // tipo de pago elegido
  globalTipoPago: {},
  setGlobalTipoPago: (data) => set((state) => ({ globalTipoPago: data })),

  // metodo de pago elegido
  globalMetodoPago: {},
  setGlobalMetodoPago: (data) => set((state) => ({ globalMetodoPago: data })),

  //datos para recotizar desde pantalla de paquetes
  globalDatosRecotizacion: {},
  setGlobalDatosRecotizacion: (data) =>
    set((state) => ({ globalDatosRecotizacion: data })),

  //id de la cotización generada desde LP
  globalIdCotizacion: {},
  setGlobalIdCotizacion: (data) =>
    set((state) => ({ globalIdCotizacion: data })),

  //datos para la emisión
  globalDatosEmision: {},
  setGlobalDatosEmision: (data) =>
    set((state) => ({ globalDatosEmision: data })),

  // id al validar data emisión
  globalIdDatosEmitir: {},
  setGlobalIdDatosEmitir: (data) =>
    set((state) => ({ globalIdDatosEmitir: data })),

  //info de coberturas resultantes de recotizaciones
  globalDatosCoberturas: {},
  setGlobalDatosCoberturas: (data) =>
    set((state) => ({ globalDatosCoberturas: data })),

  // coberturas extras seleccionadas
  globalCoberturasExtras: {},
  setGlobalCoberturasExtras: (data) =>
    set((state) => ({ globalCoberturasExtras: data })),

  // coberturas paquete elegido más extras
  globalCoberturasPaqueteElegido: {},
  setGlobalCoberturasPaqueteElegido: (data) =>
    set((state) => ({ globalCoberturasPaqueteElegido: data })),

  // data cotizaciones pendientes
  globalCotizacionesPendientes: {},
  setGlobalCotizacionesPendientes: (data) =>
    set((state) => ({ globalCotizacionesPendientes: data })),

  // numero de poliza
  globalNumeroPoliza: {},
  setGlobalNumeroPoliza: (data) =>
    set((state) => ({ globalNumeroPoliza: data })),

  // numero de poliza
  globalUrlsHogar: {},
  setGlobalUrlsHogar: (data) => set((state) => ({ globalUrlsHogar: data })),

  // folio lead cotizador interno
  globalFolioLeadInterno: {},
  setGlobalFolioLeadInterno: (data) =>
    set((state) => ({ globalFolioLeadInterno: data })),

  // check id lead pasos proceso
  globalIdCheckLead: {},
  setGlobalIdCheckLead: (data) => set((state) => ({ globalIdCheckLead: data })),

  // datos lead hasta penultimo paso
  globalDatosLead: {},
  setGlobalDatosLead: (data) => set((state) => ({ globalDatosLead: data })),

  // codigo postal valuador
  globalCpValuador: {},
  setGlobalCpValuador: (data) => set((state) => ({ globalCpValuador: data })),
}));

if (process.env.NODE_ENV === "development") {
  mountStoreDevtool("Store", useStore);
}

export default useStore;
