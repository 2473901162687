import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import woowLogo from "../assets/logos/logoWOOW.svg";
import TagManager from "react-gtm-module";

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_KEY,
};
TagManager.initialize(tagManagerArgs);

export const FailedPayment = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const breakpoint = 1020;
  const isResponsive = width < breakpoint;

  useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleWindowResize);

    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  return (
    <ThankYouPageContainer>
      <div className="mid">
        <img src={woowLogo} alt="" />
      </div>
      <div>
        <p className="title">¡Ups, tu pago fue rechazado!</p>
        <p className="subtitle">Te recomendamos usar otro método de pago.</p>
      </div>
      <div className="bottom">
        <button>
          <a
            target="_blank"
            rel="noreferrer"
            href={`${process.env.REACT_APP_LOGIN_WOOW}`}
          >
            IR AL INICIO
          </a>
        </button>
      </div>
      {!isResponsive && (
        <div class="contenedor-div">
          <a
            href="https://wa.me/5215585327747"
            target="_blank"
            rel="noreferrer"
          >
            <img
              class="mi-imagen-abajo-derecha"
              src="https://21884627.fs1.hubspotusercontent-na1.net/hubfs/21884627/whatsapp_woow.svg"
              alt="Whatsapp"
              width="60px"
            />
          </a>
        </div>
      )}
    </ThankYouPageContainer>
  );
};

const ThankYouPageContainer = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  background: linear-gradient(312.79deg, #36405f 5.82%, #536291 89.32%);
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white;
  text-align: center;
  gap: 8vw;

  .contenedor-div {
    position: absolute;
  }

  .mi-imagen-abajo-derecha {
    position: fixed;
    bottom: 15px;
    right: 90px;
    z-index: 1000 !important;
  }

  .widget-app-container,
  .Application__WidgetAppContainer-sc-1f2l0a1-0,
  .dvBAhE {
    z-index: 99px !important;
  }

  @media (max-width: 992px) {
    .mi-imagen-abajo-derecha {
      position: fixed;
      bottom: 9rem;
      right: 15px;
      z-index: 1000 !important;
    }
    div#hubspot-messages-iframe-container {
      bottom: 55px !important;
    }

    div#hubspot-messages-iframe-container {
      bottom: 55px !important;
      right: 20px !important;
    }
  }

  .title {
    font-weight: 600;
    font-size: 24px;
    line-height: 5px;
    color: #ffffff;
  }

  .subtitle {
    font-weight: 400;
    font-size: 24px;
    color: #ffffff;
  }

  a {
    text-decoration: none;
    color: #838383;
  }

  .bottom {
    max-width: 600px;
    p {
      font-weight: 500;
      font-size: 1rem;
      line-height: 25px;
    }

    button {
      margin-left: 10px;
      background-color: #f5f5f5;
      color: #838383;
      border-radius: 16px;
      border: none;
      font-family: "Montserrat";
      font-weight: 600;
      padding: 10px 20px;
      letter-spacing: 0.08em;
      font-size: 1rem;
      &:hover {
        cursor: pointer;
      }
    }
  }
`;
