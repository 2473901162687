import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components/macro";
import { HeaderWOOW } from "../components/HeaderWOOW";
import { useNavigate } from "react-router-dom";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import useStore from "../useStore";
import headerImage from "../assets/heros/woow_lp_hogar_pago.webp";
import {
  Radio,
  TextInput,
  LoadingOverlay,
  Loader,
  Space,
  useMantineColorScheme,
} from "@mantine/core";
import visa from "../assets/visa.svg";
import visaBlanco from "../assets/visa_blanco.svg";
import mastercard from "../assets/mastercard.svg";
import american from "../assets/american_express.svg";
import rappiCard from "../assets/RappiCard.svg";
import paypal from "../assets/paypal.svg";
import paypalBlanco from "../assets/paypal_blanco.svg";
import oxxo from "../assets/oxxo.svg";
import { HeroPages } from "../components/HeroPages";
import woowLoadingHogar from "../assets/woow-loading-hogar.json";
import {
  getReferencia,
  getTarjetas,
  emitirPoliza,
  getConfigPaypal,
  createPlanPaypal,
  saveSubscriptionPaypal,
  generateOrder,
  captureOrder,
  oxxoPayment,
  updateDeal,
  serviciosContratados,
  recotizarCoberturas,
  getTarjetasAcdc,
  pagoRappiConekta,
  saveDataPagoRappiConekta,
  tokuPayment,
} from "../services/api";
import Steps from "../components/Steps";
import { showNotification, hideNotification } from "@mantine/notifications";
import Breadcrumbs from "../components/Breadcrumbs";
import Lottie from "react-lottie";
import {
  descuento,
  getPerfilContratante,
  getTipoInmueble,
  stagesHubspot,
} from "../constants/constants";
import TagManager from "react-gtm-module";

import moment from "moment";

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_KEY,
};
TagManager.initialize(tagManagerArgs);

export const Pago = () => {
  let navigate = useNavigate();
  const {
    globalTokenCotizacion,
    globalDatosEmision,
    globalSumaAsegurada,
    globalDatosAsegurado,
    globalPaqueteElegido,
    globalTipoPago,
    globalDealId,
    setGlobalMetodoPago,
    setGlobalNumeroPoliza,
    globalTipoCotizador,
    setGlobalUrlsHogar,
    globalCoberturasExtras,
    globalCoberturasPaqueteElegido,
    globalDatosRecotizacion,
    globalIdCotizacion,
    globalNuevoCosto,
    setGlobalNuevoCosto,
    globalIdDatosEmitir,
  } = useStore();
  const { colorScheme } = useMantineColorScheme();
  const isFirstRender = useRef(true);
  const [width, setWidth] = useState(window.innerWidth);
  const breakpoint = 1020;
  const isResponsive = width < breakpoint;
  const [referencia, setReferencia] = useState("");
  const [tipoPago, setTipoPago] = useState("");
  const [tarjetasDisponibles, setTarjetasDisponibles] = useState([]);
  const [clientId, setClientId] = useState("");
  const [showPaypalButton, setShowPaypalButton] = useState(false);
  const [dataCreatedPlan, setDataCreatedPlan] = useState([]);
  const [dataGeneratedOrder, setDataGeneratedOrder] = useState([]);
  const [orderId, setOrderId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [pagoExitoso, setPagoExitoso] = useState(false);
  const [correoOxxo, setCorreoOxxo] = useState("");
  const [loadingPagoRappiCard, setLoadingPagoRappiCard] = useState(false);
  const [coberturas, setCoberturas] = useState(globalCoberturasExtras);
  const [paqueteElegidoCoberturas, setPaqueteElegidoCoberturas] = useState(
    globalCoberturasPaqueteElegido
  );

  const currentDay = moment(new Date()).format("DD-MM-YYYY");
  const currentYear = moment(new Date()).format("YYYY-MM-DD");
  const nextYear = moment(currentYear).add(1, "years").calendar();
  const nextCurrentYear = moment(nextYear).format("YYYY-MM-DD");

  const dataReferencia = {
    service_id: 37,
    sub_service_id: 72,
  };
  const scriptPaypalMensual = `https://www.paypal.com/sdk/js?client-id=${clientId}&components=buttons&vault=true&intent=subscription`;
  const scriptPaypalAnual = `https://www.paypal.com/sdk/js?client-id=${clientId}&currency=MXN&enable-funding=paypal&disable-funding=card,credit,bancontact`;

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: woowLoadingHogar,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const initialOptions = {
    "client-id": clientId,
    currency: "MXN",
    vault: true,
  };

  useEffect(() => {
    if (isResponsive) {
      window.scrollTo(0, 0);
    }
  }, []);

  useEffect(() => {
    if (!isFirstRender.current) {
      if (paqueteElegidoCoberturas.length) {
        handleRecotizarCoberturasExtras();
      }
    } else {
      isFirstRender.current = false;
    }
  }, [coberturas, paqueteElegidoCoberturas]);

  useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleWindowResize);

    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  useEffect(() => {
    handleGetReferencia();
    handleGetConfigPaypal();
    handleUpdateDeal();
  }, []);

  useEffect(() => {
    getControladorPago(globalTipoPago);
  }, []);

  const getControladorPago = (pago) => {
    switch (pago) {
      case "Anual":
        return handleGetTarjetasAcdc();
      case "Mensual":
        return handleGetTarjetasBanwire();
      default:
        return handleGetTarjetasAcdc();
    }
  };

  useEffect(() => {
    if (referencia !== "") {
      handleCreatePlanPaypal();
    }
  }, [referencia]);

  useEffect(() => {
    if (tipoPago === "Paypal") setShowPaypalButton(true);
  }, [tipoPago]);

  useEffect(() => {
    if (clientId) {
      const script = document.createElement("script");
      script.src =
        globalDatosEmision?.formaPago === 1
          ? scriptPaypalAnual
          : scriptPaypalMensual;
      script.async = true;
      document.body.appendChild(script);
      // return () => {
      //   document.body.removeChild(script);
      // };
    }
  }, [clientId]);

  const handleRecotizarCoberturasExtras = async (item) => {
    setIsLoading(true);
    const bodyCoberturas = paqueteElegidoCoberturas?.map((item) => {
      return {
        [item.codigoInfo]: {
          idCobertura: item?.codigoInfo,
          sumaAsegurada: item?.sumaAsegurada,
          factorLinea: item?.factorLinea || 0,
          factorRenovacion: item?.factorRenovacion || 0,
        },
      };
    });

    const key = globalPaqueteElegido?.nombre;
    const paqueteCoberturas = {};
    paqueteCoberturas[key] = {
      coberturas: Object.assign({}, ...bodyCoberturas),
    };

    const datos = globalDatosRecotizacion[1];

    const datosInmuebles = [datos].map((item) => {
      return {
        codigoPostalContratante: item?.codigoPostalContratante,
        codigoPostalInmueble: item?.codigoPostalInmueble,
        esConstruccionMaciza: item?.esConstruccionMaciza,
        esLagoLaguna: item?.esLagoLaguna,
        esLineaPlaya: item?.esLineaPlaya,
        idPeriodicidadPago: globalTipoPago === "Anual" ? 1 : 4,
        idTipoMoneda: item?.idTipoMoneda,
        multiclausula: item?.multiclausula,
        valorTipo: item?.valorTipo,
      };
    });

    const output = Object.assign({}, ...datosInmuebles);

    const paquete = globalPaqueteElegido?.nombre;

    const dataCoberturas = {
      idCotizacion: globalIdCotizacion.idCotizacion,
      codigo:
        (paquete === "Hogar Esencial" && "0") ||
        (paquete === "Hogar Vital" && "1") ||
        (paquete === "Hogar Plus" && "2"),
      origin: "APP",
      metodoPago: globalTipoPago === "Anual" ? 1 : 4,
      datosConfiguracion: globalDatosRecotizacion[0],
      datosInmueble: output,
      paquetes: paqueteCoberturas,
    };

    recotizarCoberturas(dataCoberturas, globalTokenCotizacion)
      .then((response) => {
        const nuevaSumaAsegurada = response.data.event.paquetes[0]?.primaTotal;
        const nuevoPrimerRecibo =
          response.data.event.paquetes[0]?.primaPrimerRecibo;
        const nuevoReciboSubsecuente =
          response.data.event.paquetes[0]?.primaReciboSubsecuente;
        setGlobalNuevoCosto({
          pagoTotal: nuevaSumaAsegurada,
          primerRecibo: nuevoPrimerRecibo,
          reciboSubsecuente: nuevoReciboSubsecuente,
        });
        setIsLoading(false);
      })
      .catch(function (error) {
        setIsLoading(false);
      });
  };

  const handleUpdateDeal = () => {
    const dataUpdateDeal = {
      amount: globalNuevoCosto?.pagoTotal,
      dealstage: stagesHubspot?.check_out_pendiente,
      producto_interes: "Seguro de Hogar",
      pipeline: stagesHubspot?.pipeline,
      deal_id: globalDealId,
      paquete_interes: globalPaqueteElegido?.nombre,
      plan_de_pagos: globalTipoPago,
    };
    updateDeal(globalTokenCotizacion, dataUpdateDeal)
      .then((response) => {})
      .catch(function (error) {});
  };

  const handleGetReferencia = () => {
    getReferencia(dataReferencia, globalTokenCotizacion)
      .then((response) => {
        setReferencia(response.data?.reference);
      })
      .catch(function (error) {});
  };

  const handleGetTarjetasBanwire = () => {
    setIsLoading(true);
    getTarjetas(globalTokenCotizacion)
      .then((response) => {
        setTarjetasDisponibles(response.data?.cards);
        setIsLoading(false);
      })
      .catch(function (error) {
        setIsLoading(false);
      });
  };

  const handleGetTarjetasAcdc = () => {
    setIsLoading(true);
    const dataObtenerTarjetas = {
      service_id: 37,
      sub_service_id: 72,
    };
    getTarjetasAcdc(globalTokenCotizacion, dataObtenerTarjetas)
      .then((response) => {
        setTarjetasDisponibles(response.data?.cards);
        setIsLoading(false);
      })
      .catch(function (error) {
        setIsLoading(false);
      });
  };

  const handleGetConfigPaypal = () => {
    getConfigPaypal(dataReferencia, globalTokenCotizacion)
      .then((response) => {
        setClientId(response.data?.clientId);
      })
      .catch(function (error) {});
  };

  const handleCreatePlanPaypal = () => {
    const dataPlanPayal = {
      reference: referencia,
      total_pagos: 12,
      currency_code: "MXN",
      interval_unit: "MONTH",
      value:
        globalTipoPago === "Anual"
          ? globalNuevoCosto?.pagoTotal
          : globalNuevoCosto?.primerRecibo,
    };

    createPlanPaypal(dataPlanPayal, globalTokenCotizacion)
      .then((response) => {
        setClientId(response.data?.event.respPlanPaypal?.clientId);
        setDataCreatedPlan(response?.data);
      })
      .catch(function (error) {});
  };

  const handleSaveSubscriptionPaypal = (id) => {
    const dataSubscriptionPaypal = {
      orderID: dataCreatedPlan?.event.respPlanPaypal?.id,
      subscriptionID: id,
      reference: referencia,
      extra_reference: referencia,
    };

    saveSubscriptionPaypal(dataSubscriptionPaypal, globalTokenCotizacion)
      .then((response) => {
        if (response.data?.success === 1) {
          setGlobalMetodoPago("paypal");
          TagManager.dataLayer({
            dataLayer: {
              event: "hogar_pago_paypal",
            },
          });
          handleEmisionPoliza();
        }
      })
      .catch(function (error) {});
  };

  const handleGenerateOrder = () => {
    const dataGenerateOrder = {
      reference: referencia,
      amount: globalNuevoCosto?.pagoTotal,
      metodoPago: tipoPago,
      extra_reference: referencia,
    };
    handleUpdateDealIntentoPago();
    if (tipoPago === "OXXO") {
      showNotification({
        id: "notificacionOxxoPreparando",
        title: "Procesando tu solicitud",
        message:
          "Estamos preparando tu pago, esto puede durar varios minutos. \n Por favor no cierres esta ventana.",
        color: "gray",
        loading: true,
      });
    }

    return generateOrder(dataGenerateOrder, globalTokenCotizacion)
      .then((response) => {
        if (tipoPago === "Paypal") {
          return response?.data.event?.respOrdenPaypal?.id;
        }
        if (tipoPago === "OXXO") {
          setOrderId(response?.data.event?.respOrdenPaypal?.id);
          handleOxxoPayment(response?.data.event?.respOrdenPaypal?.id);
          setGlobalMetodoPago("oxxo");
          TagManager.dataLayer({
            dataLayer: {
              event: "hogar_pago_oxxo",
              data: "",
            },
          });
        }
      })
      .catch(function (error) {});
  };

  const handleCaptureOrder = (id) => {
    const dataCaptureOrder = {
      orderId: id,
    };

    captureOrder(dataCaptureOrder, globalTokenCotizacion)
      .then((response) => {
        if (
          response.data?.event?.respCaptureOrderPaypal.status === "COMPLETED"
        ) {
          handleEmisionPoliza();
          setGlobalMetodoPago("paypal");
        }
      })
      .catch(function (error) {});
  };

  const handleCancelOrder = () => {
    const orderID = dataGeneratedOrder?.id;
    const dataCancelOrder = {
      orderId: orderID,
    };

    captureOrder(dataCancelOrder, globalTokenCotizacion)
      .then((response) => {})
      .catch(function (error) {});
  };

  const handleOxxoPayment = (id) => {
    const dataOxxo = {
      orderId: id,
      email: globalDatosAsegurado.datosDelAsegurado?.email || correoOxxo,
      name:
        globalDatosEmision.datosEmisor?.nombre +
        " " +
        globalDatosEmision.datosEmisor?.aPaterno +
        " " +
        globalDatosEmision.datosEmisor?.aMaterno,
      producto: globalPaqueteElegido?.nombre,
      deal_id: globalDealId || "",
      jsonEmision: globalDatosEmision,
    };

    oxxoPayment(dataOxxo, globalTokenCotizacion)
      .then((response) => {
        window.open(response.data.event.respOxxoPaypal?.url, "_blank");
        hideNotification("notificacionOxxoPreparando");
      })
      .catch(function (error) {});
  };

  const handleCrearServicioContrarado = (poliza) => {
    const dataServicio = {
      nombre: globalDatosEmision.datosEmisor?.nombre,
      ap_pat: globalDatosEmision.datosEmisor?.aPaterno,
      ap_mat: globalDatosEmision.datosEmisor?.aMaterno,
      fecha_inicio_vigencia: currentYear,
      fecha_fin_vigencia: nextCurrentYear,
      id_membresia: "",
      paquete: globalPaqueteElegido?.nombre,
      costo: globalNuevoCosto?.pagoTotal,
      poliza: poliza,
      reference: referencia,
      agent: navigator.userAgent,
    };

    serviciosContratados(dataServicio, globalTokenCotizacion)
      .then((response) => {})
      .catch(function (error) {});
  };

  const handlePagoRappiCard = () => {
    showNotification({
      id: "paqueteElegido",
      title: "Lo estamos redirigiendo",
      message:
        "Por favor, permanezca en la línea mientras lo dirigimos a nuestra plataforma segura de pagos. Su transacción está en proceso.",
      color: "blue",
      autoClose: 3000,
      position: "top-right",
    });
    setLoadingPagoRappiCard(true);
    handleUpdateDealIntentoPago();
    const dataPago = {
      amount: Number.isInteger(globalNuevoCosto?.pagoTotal)
        ? parseFloat(globalNuevoCosto?.pagoTotal).toFixed(2).toString()
        : globalNuevoCosto?.pagoTotal,
      reference: referencia,
    };

    pagoRappiConekta(globalTokenCotizacion, dataPago)
      .then((response) => {
        if (response?.data?.success === 1) {
          handleSaveDataRappiConekta(response?.data?.data);
          setLoadingPagoRappiCard(false);
        } else {
          showNotification({
            id: "errorRapiConekta",
            title: "Error al cargar pago",
            message:
              "No fue posible realizar tu pago en este momento, te agradecemos intentarlo más tarde.",
            color: "red",
            autoClose: 5000,
          });
          setLoadingPagoRappiCard(false);
        }
      })
      .catch(function (error) {
        showNotification({
          id: "errorRapiConekta",
          title: "Error al cargar pago",
          message:
            "No fue posible realizar tu pago en este momento, te agradecemos intentarlo más tarde.",
          color: "red",
          autoClose: 5000,
        });
        setLoadingPagoRappiCard(false);
      });
  };

  const handleSaveDataRappiConekta = (data) => {
    const emision = Object.assign(globalDatosEmision, {
      idDatosEmitir: globalIdDatosEmitir,
    });
    const dataRappiConekta = {
      id_conekta: data?.id,
      link_pago: data?.link_pago,
      cliente_conekta: data?.cliente_conekta,
      monto: parseFloat(globalNuevoCosto?.pagoTotal).toFixed(2),
      producto: globalPaqueteElegido?.nombre,
      data_order: emision,
      data_update_deal: {
        amount: globalNuevoCosto?.pagoTotal,
        dealstage: stagesHubspot?.cierre_ganado,
        producto_contratado: "Seguro de Hogar",
        producto_interes: "Seguro de Hogar",
        metodo_de_pago: "conekta",
        pipeline: stagesHubspot?.pipeline,
        deal_id: globalDealId,
        paquete_interes: globalPaqueteElegido?.nombre,
        plan_de_pagos: globalTipoPago,
      },
      data_update_contact: {
        servicio_solicitado: "Seguro de Hogar",
        v_costo_total_hogar: globalNuevoCosto?.pagoTotal,
        contador_de_actividad: 1,
        v_paquete_contratado_hogar: globalPaqueteElegido?.nombre,
      },
      data_servicios_contratados: {
        nombre: globalDatosEmision.datosEmisor?.nombre,
        ap_pat: globalDatosEmision.datosEmisor?.aPaterno,
        ap_mat: globalDatosEmision.datosEmisor?.aPaterno,
        id_membresia: "",
        paquete: globalPaqueteElegido?.nombre,
        costo: globalNuevoCosto?.pagoTotal,
        reference: referencia,
        agent: navigator.userAgent,
      },
    };

    saveDataPagoRappiConekta(dataRappiConekta, globalTokenCotizacion)
      .then((response) => {
        if (response?.data?.success === 1) {
          window.location.replace(data?.link_pago);
          TagManager.dataLayer({
            dataLayer: {
              event: "hogar_pago_rappicard",
              data: "",
            },
          });
        } else {
          showNotification({
            id: "errorRapiConekta",
            title: "Error al cargar pago",
            message:
              "No fue posible realizar tu pago en este momento, te agradecemos intentarlo más tarde.",
            color: "red",
            autoClose: 5000,
          });
        }
      })
      .catch(function (error) {
        showNotification({
          id: "errorRapiConekta",
          title: "Error al cargar pago",
          message:
            "No fue posible realizar tu pago en este momento, te agradecemos intentarlo más tarde.",
          color: "red",
          autoClose: 5000,
        });
      });
  };

  const handleUpdateDealIntentoPago = () => {
    const dataUpdateDeal = {
      click_boton_pago: Date.now(),
      click_boton_pago_pasarela:
        tipoPago === "RappiCard" ? "conekta" : tipoPago,
      dealstage: stagesHubspot?.check_out_pendiente,
      deal_id: globalDealId,
      pipeline: stagesHubspot?.pipeline,
      producto_interes: "Seguro de Hogar",
      amount: globalNuevoCosto?.pagoTotal,
    };
    updateDeal(globalTokenCotizacion, dataUpdateDeal)
      .then((response) => {})
      .catch(function (error) {});
  };

  const handleEmisionPoliza = () => {
    setIsLoading(true);
    globalDatosEmision.idDatosEmitir = globalIdDatosEmitir;

    showNotification({
      id: "notificacionEmision",
      title: "Procesando tu solicitud",
      message:
        "Estamos emitiendo tu póliza, este proceso puede durar varios minutos. \n Por favor no cierres esta ventana.",
      color: "blue",
      loading: true,
    });

    emitirPoliza(globalDatosEmision, globalTokenCotizacion)
      .then((response) => {
        if (response.data.success === 1) {
          handleCrearServicioContrarado(response.data.event.dataEmail?.poliza);
          setGlobalNumeroPoliza(response.data.event.dataEmail?.poliza);
          setGlobalUrlsHogar(response.data.paths);
          hideNotification("notificacionEmision");
          navigate("/gracias");
          setIsLoading(false);
        } else {
          setIsLoading(false);
          showNotification({
            id: "errorEmision",
            title: "Error al emitir póliza",
            message:
              "No fue posible realizar tu cotización en este momento, te agradecemos intentarlo más tarde.",
            color: "red",
            autoClose: 5000,
          });
        }
      })
      .catch(function (error) {
        setIsLoading(false);
        showNotification({
          id: "errorEmision",
          title: "Error al emitir póliza",
          message:
            "No fue posible realizar tu cotización en este momento, te agradecemos intentarlo más tarde.",
          color: "red",
          autoClose: 5000,
        });
      });
  };

  const handlePagoToku = () => {
    setIsLoading(true);
    handleUpdateDealIntentoPago();
    showNotification({
      id: "notificacionPagoToku",
      title: "Estamos preparando tu pago",
      message:
        "En un momento serás redirigido a una página segura para completar la transacción",
      color: "blue",
      loading: true,
    });
    const data = {
      amount: globalDatosEmision?.costo,
      reference: referencia,
      descripcion_seguro: "Hogar_esencial",
      forma_pago: globalDatosEmision?.formaPago === 1 ? "anual" : "mensual",
      ...(globalDatosEmision?.formaPago !== 1 && {
        primer_pago: globalNuevoCosto?.primerRecibo,
      }),
      ...(globalDatosEmision?.formaPago !== 1 && {
        pagos_subsecuentes: globalNuevoCosto?.reciboSubsecuente,
      }),
      data_order: {
        idCotizacion: globalDatosEmision?.idCotizacion,
        moneda: globalDatosEmision?.moneda,
        costo: globalDatosEmision?.costo,
        paqueteContratado: globalDatosEmision?.paqueteContratado,
        formaPago: globalDatosEmision?.formaPago,
        valorDestructible: globalDatosEmision?.valorDestructible,
        indicadorDePropietarioOArrenda:
          globalDatosEmision?.indicadorDePropietarioOArrenda,
        dataCasaAsegurar: {
          calle: globalDatosEmision?.dataCasaAsegurar?.calle,
          numExterior: globalDatosEmision?.dataCasaAsegurar?.numExterior,
          numInterior: globalDatosEmision?.dataCasaAsegurar?.numInterior,
          colonia: globalDatosEmision?.dataCasaAsegurar?.colonia,
          municipio: globalDatosEmision?.dataCasaAsegurar?.municipio,
          estado: globalDatosEmision?.dataCasaAsegurar?.estado,
          cveColonia: globalDatosEmision?.dataCasaAsegurar?.cveColonia,
          cveMunicipio: globalDatosEmision?.dataCasaAsegurar?.cveMunicipio,
          cveEstado: globalDatosEmision?.dataCasaAsegurar?.cveEstado,
          cpInmueble: globalDatosEmision?.dataCasaAsegurar?.cpInmueble,
          cpContratante: globalDatosEmision?.dataCasaAsegurar?.cpContratante,
          claseInmueble: globalDatosEmision?.dataCasaAsegurar?.claseInmueble,
          metrosConstruidos:
            globalDatosEmision?.dataCasaAsegurar?.metrosConstruidos,
          metrosCuadrados:
            globalDatosEmision?.dataCasaAsegurar?.metrosCuadrados,
          alarmaLocal: globalDatosEmision?.dataCasaAsegurar?.alarmaLocal,
          esLineaPlaya: globalDatosEmision?.dataCasaAsegurar?.esLineaPlaya,
          esLagoLaguna: globalDatosEmision?.dataCasaAsegurar?.esLagoLaguna,
          tipoInmueble: globalDatosEmision?.dataCasaAsegurar?.tipoInmueble,
          edad: globalDatosEmision?.dataCasaAsegurar?.edad,
        },
        mismoDomicilio: globalDatosEmision?.mismoDomicilio,
        dataDomicilioFiscal: {
          calle: globalDatosEmision?.dataDomicilioFiscal?.calle,
          numExterior: globalDatosEmision?.dataDomicilioFiscal?.numExterior,
          numInterior: globalDatosEmision?.dataDomicilioFiscal?.numInterior,
          colonia: globalDatosEmision?.dataDomicilioFiscal?.colonia,
          cveColonia: globalDatosEmision?.dataDomicilioFiscal?.cveColonia,
          municipio: globalDatosEmision?.dataDomicilioFiscal?.municipio,
          cveMunicipio: globalDatosEmision?.dataDomicilioFiscal?.cveMunicipio,
          estado: globalDatosEmision?.dataDomicilioFiscal?.estado,
          cveEstado: globalDatosEmision?.dataDomicilioFiscal?.cveEstado,
          cp: globalDatosEmision?.dataDomicilioFiscal?.cp,
        },
        claveRegimenFiscalSat: globalDatosEmision?.claveRegimenFiscalSat,
        regimenCapital: globalDatosEmision?.regimenCapital,
        razonSocial: globalDatosEmision?.razonSocial,
        datosEmisor: {
          soyTitular: globalDatosEmision?.datosEmisor?.soyTitular,
          nombre: globalDatosEmision?.datosEmisor?.nombre,
          aPaterno: globalDatosEmision?.datosEmisor?.aPaterno,
          aMaterno: globalDatosEmision?.datosEmisor?.aMaterno,
          fechaNacimiento: globalDatosEmision?.datosEmisor?.fechaNacimiento,
          rfc: globalDatosEmision?.datosEmisor?.rfc,
          telefono: globalDatosEmision?.datosEmisor?.telefono,
          cElectronico: globalDatosEmision?.datosEmisor?.cElectronico,
        },
        coberturas: globalDatosEmision?.coberturas,
        coberturasComision: globalDatosEmision?.coberturasComision,
        idDatosEmitir: globalIdDatosEmitir,
      },
      data_update_deal: {
        amount: globalNuevoCosto?.pagoTotal,
        dealstage: stagesHubspot?.check_out_pendiente,
        producto_contratado: "Seguro de Hogar",
        producto_interes: "Seguro de Hogar",
        metodo_de_pago: "toku",
        pipeline: stagesHubspot?.pipeline,
        deal_id: globalDealId,
        paquete_interes: globalPaqueteElegido?.nombre,
        plan_de_pagos: globalTipoPago,
      },
      data_update_contact: {
        servicio_solicitado: "Seguro de Hogar",
        v_costo_total_hogar: globalNuevoCosto?.pagoTotal,
        contador_de_actividad: 1,
        v_paquete_contratado_hogar: globalPaqueteElegido?.nombre,
      },
      data_servicios_contratados: {
        nombre: globalDatosEmision.datosEmisor?.nombre,
        ap_pat: globalDatosEmision.datosEmisor?.aPaterno,
        ap_mat: globalDatosEmision.datosEmisor?.aMaterno,
        id_membresia: "",
        paquete: globalPaqueteElegido?.nombre,
        costo: globalNuevoCosto?.pagoTotal,
        reference: referencia,
        agent: navigator.userAgent,
      },
    };
    tokuPayment(data, globalTokenCotizacion)
      .then((response) => {
        if (response?.data.success === 1) {
          setIsLoading(false);
          window.location.replace(response?.data.event.link_pago);
          TagManager.dataLayer({
            dataLayer: {
              event: "hogar_pago_toku",
              data: "",
            },
          });
        } else {
          setIsLoading(false);
          showNotification({
            id: "errorPagoToku",
            title: "Ocurrió un error",
            message:
              "No fue posible realizar tu pago en este momento, te agradecemos intentarlo más tarde.",
            color: "red",
            autoClose: 5000,
          });
        }
      })
      .catch(function (error) {
        setIsLoading(false);
        showNotification({
          id: "errorPagoToku",
          title: "Ocurrió un error",
          message:
            "No fue posible realizar tu pago en este momento, te agradecemos intentarlo más tarde.",
          color: "red",
          autoClose: 5000,
        });
      });
  };

  return (
    <ComparadorContainer theme={colorScheme}>
      <HeaderWOOW
        subtitle="¡Tenemos esto para ti!"
        conRegresar
        pagina="/emision"
      />
      <HeroPages img={headerImage} subtitle="Estas a un paso de finalizar" />
      <ComparadorBody>
        <ColumnaPrincipalContainer>
          {!isResponsive && globalTipoCotizador === "externo" && (
            <div className="breadcrumbs">
              <Breadcrumbs active={5} />
            </div>
          )}
          <SeccionTop theme={colorScheme}>
            <LoadingOverlay
              visible={isLoading}
              radius="16px"
              loader={
                <Lottie
                  className="lottie"
                  options={defaultOptions}
                  height={120}
                  width={120}
                />
              }
            />
            <span className="title">Selecciona un método de pago:</span>
            <Radio.Group
              orientation="vertical"
              value={tipoPago}
              onChange={setTipoPago}
              required
            >
              <Radio
                value="toku"
                label={
                  <div>
                    <div className="tarjetas-pago">
                      <img
                        src={colorScheme === "dark" ? visaBlanco : visa}
                        alt="visa"
                      />
                      <img src={mastercard} alt="mastercad" />
                      <img src={american} alt="american express" />
                    </div>
                    <Space h={"md"} />
                    {tipoPago === "toku" && (
                      <div className="info-toku">
                        Da click en el botón <span>“Pagar”</span> serás
                        redirigido a una página segura para completar la
                        transacción. Ingresa tus datos, elige el plazo de pago y
                        finaliza la transacción.
                      </div>
                    )}
                  </div>
                }
              />
              {globalDatosEmision?.formaPago === 1 && (
                <Radio
                  value="RappiCard"
                  label={
                    <>
                      <img src={rappiCard} alt="rappicard" />
                    </>
                  }
                />
              )}
              <Radio
                value="Paypal"
                label={
                  <>
                    <img
                      src={colorScheme === "dark" ? paypalBlanco : paypal}
                      alt="paypal"
                    />
                  </>
                }
              />
              {globalNuevoCosto?.pagoTotal > 10000 ||
              globalNuevoCosto?.primerRecibo > 10000 ? (
                <div />
              ) : (
                <Radio
                  value="OXXO"
                  label={
                    <>
                      <img src={oxxo} alt="oxxo" />
                    </>
                  }
                />
              )}
            </Radio.Group>
          </SeccionTop>
          {tipoPago === "RappiCard" && (
            <SeccionTop theme={colorScheme}>
              <div className="button-center">
                <ButtonRappi onClick={() => handlePagoRappiCard()}>
                  {loadingPagoRappiCard ? (
                    <Loader color="gray" size="sm" />
                  ) : (
                    "Pagar con RappiCard"
                  )}
                </ButtonRappi>
              </div>
            </SeccionTop>
          )}
          {tipoPago === "OXXO" && (
            <SeccionTop theme={colorScheme}>
              <MantineTextInputStyled
                theme={colorScheme}
                label="Correo electrónico"
                value={
                  globalDatosEmision.datosEmisor?.cElectronico ||
                  globalDatosAsegurado.datosDelAsegurado?.email ||
                  correoOxxo ||
                  ""
                }
                required
                placeholder="Correo"
                variant="filled"
                hideControls
                onChange={(e) => {
                  setCorreoOxxo(e.currentTarget.value);
                }}
              />
              <div className="button-center">
                <ButtonOxxo onClick={() => handleGenerateOrder()}>
                  Pagar con OXXO
                </ButtonOxxo>
              </div>
            </SeccionTop>
          )}
          {tipoPago === "Paypal" && (
            <SeccionTop theme={colorScheme}>
              <LoadingOverlay
                visible={isLoading}
                radius="16px"
                loader={
                  <Lottie
                    className="lottie"
                    options={defaultOptions}
                    height={120}
                    width={120}
                  />
                }
              />
              {globalDatosEmision?.formaPago !== 1 ? (
                // pago mensual
                <div
                  style={{
                    colorScheme: "none",
                  }}
                >
                  <PayPalScriptProvider options={clientId && initialOptions}>
                    <PayPalButtons
                      style={{
                        layout: "horizontal",
                        tagline: colorScheme === "dark" ? false : true,
                        label: colorScheme === "dark" ? "pay" : "paypal",
                      }}
                      createSubscription={(data, actions) => {
                        handleUpdateDealIntentoPago();
                        let rest = actions.subscription.create({
                          plan_id: dataCreatedPlan?.event.respPlanPaypal.id,
                        });
                        return rest;
                      }}
                      onApprove={(data, actions) => {
                        handleSaveSubscriptionPaypal(data.subscriptionID);
                      }}
                      onError={() => {
                        showNotification({
                          title: "Error en el cobro",
                          message:
                            "Hubo un problema con tu transacción, por favor intentalo de nuevo más tarde",
                          color: "red",
                          autoClose: 5000,
                        });
                      }}
                    />
                  </PayPalScriptProvider>
                </div>
              ) : (
                // pago anual
                <div
                  style={{
                    colorScheme: "none",
                  }}
                >
                  <PayPalScriptProvider options={clientId && initialOptions}>
                    <PayPalButtons
                      style={{
                        layout: "horizontal",
                        tagline: colorScheme === "dark" ? false : true,
                        label: colorScheme === "dark" ? "pay" : "paypal",
                      }}
                      createOrder={() => handleGenerateOrder()}
                      onApprove={(data, actions) => {
                        handleCaptureOrder(data.orderID);
                      }}
                      onCancel={() => handleCancelOrder()}
                      onError={(err) => {
                        console.error(err);
                        showNotification({
                          title: "Error en el cobro",
                          message:
                            "Hubo un problema con tu transacción, por favor intentalo de nuevo más tarde",
                          color: "red",
                          autoClose: 5000,
                        });
                      }}
                    />
                  </PayPalScriptProvider>
                </div>
              )}
            </SeccionTop>
          )}
        </ColumnaPrincipalContainer>
        <ColumnaSecundariaContainer theme={colorScheme}>
          {!isResponsive && <Steps active={4} />}
          <div className="sticky">
            <SeccionTopLateral theme={colorScheme}>
              <LoadingOverlay
                visible={isLoading}
                radius="16px"
                loader={
                  <Lottie
                    className="lottie"
                    options={defaultOptions}
                    height={120}
                    width={120}
                  />
                }
              />
              <div className="bodyLateral">
                <p className="big-title">Resumen</p>
                <p className="subtitle">{globalPaqueteElegido?.nombre}</p>
                <div class="pago-total">
                  <>
                    <p className="subtitle">Tipo de inmueble</p>
                    <p className="subtitle2">
                      {getTipoInmueble(
                        globalDatosAsegurado.datosValuacion?.tipoInmueble ||
                          globalDatosEmision.datosEmision?.tipoImueble
                      ) || "Sin valuación"}
                    </p>
                  </>
                </div>
                <div class="pago-total">
                  <p className="subtitle">Suma asegurada</p>
                  <p className="subtitle2">
                    $
                    {globalSumaAsegurada.toLocaleString("en-US", {
                      maximumFractionDigits: 2,
                    })}
                  </p>
                </div>
                <div class="pago-total">
                  <p className="subtitle">Perfil del contratante</p>
                  <p className="subtitle2">
                    {getPerfilContratante(
                      globalDatosAsegurado.datosDelAsegurado?.perfilContratante
                    )}
                  </p>
                </div>
                <div class="pago-total">
                  <p className="subtitle">Pago total</p>
                  <p className="subtitle2">
                    MX$
                    {globalNuevoCosto?.pagoTotal?.toLocaleString("en-US", {
                      maximumFractionDigits: 2,
                    })}
                  </p>
                </div>
                {globalTipoPago === "Mensual" && (
                  <>
                    <div class="pago-total">
                      <p className="subtitle">Primer pago</p>
                      <p className="subtitle2">
                        MX$
                        {globalNuevoCosto?.primerRecibo.toLocaleString(
                          "en-US",
                          {
                            maximumFractionDigits: 2,
                          }
                        )}
                      </p>
                    </div>
                    <div class="pago-total">
                      <p className="subtitle">11 pagos subsecuentes de:</p>
                      <p className="subtitle2">
                        MX$
                        {globalNuevoCosto?.reciboSubsecuente.toLocaleString(
                          "en-US",
                          {
                            maximumFractionDigits: 2,
                          }
                        )}
                      </p>
                    </div>
                  </>
                )}
                {descuento && (
                  <>
                    <div className="descuento">10% DESC. INCLUIDO</div>
                  </>
                )}
                <br />
                {tipoPago === "toku" && (
                  <ButtonPrincipal onClick={() => handlePagoToku()}>
                    PAGAR
                  </ButtonPrincipal>
                )}
              </div>
            </SeccionTopLateral>
            <p className="texto-abajo">
              <a
                href="https://woowtodobien.com/terminos-y-condiciones"
                target="_blank"
                rel="noreferrer"
              >
                Términos y Condiciones
              </a>
              <span>y</span>
              <a
                href="https://woowtodobien.com/aviso-de-privacidad"
                target="_blank"
                rel="noreferrer"
              >
                Aviso de Privacidad
              </a>
            </p>
          </div>
        </ColumnaSecundariaContainer>
      </ComparadorBody>
      {!isResponsive && (
        <div class="contenedor-div">
          <a
            href="https://wa.me/5215585327747"
            target="_blank"
            rel="noreferrer"
          >
            <img
              class="mi-imagen-abajo-derecha"
              src="https://21884627.fs1.hubspotusercontent-na1.net/hubfs/21884627/whatsapp_woow.svg"
              alt="Whatsapp"
              width="60px"
            />
          </a>
        </div>
      )}
    </ComparadorContainer>
  );
};

const ComparadorContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: ${(theme) => {
    return theme.theme === "dark" ? "#1A1B1E" : "#f5f5f5";
  }};
  position: relative;
  height: auto;
  padding-bottom: 300px;

  .contenedor-div {
    position: absolute;
  }

  .mi-imagen-abajo-derecha {
    position: fixed;
    bottom: 15px;
    right: 90px;
    z-index: 1000 !important;
  }

  .widget-app-container,
  .Application__WidgetAppContainer-sc-1f2l0a1-0,
  .dvBAhE {
    z-index: 99px !important;
  }

  @media (max-width: 992px) {
    .mi-imagen-abajo-derecha {
      position: fixed;
      bottom: 9rem;
      right: 15px;
      z-index: 1000 !important;
    }
    div#hubspot-messages-iframe-container {
      bottom: 55px !important;
    }

    div#hubspot-messages-iframe-container {
      bottom: 55px !important;
      right: 20px !important;
    }
  }

  .header-image {
    width: 1300px;
  }

  .nav {
    width: 100%;
    max-width: 1150px;
    font-size: 0.9rem;

    .actual {
      color: #037cbf;
      font-weight: 700;
    }
  }
`;

const ComparadorBody = styled.div`
  display: flex;
  width: 100%;
  max-width: 1370px;
  @media (max-width: 1020px) {
    flex-direction: column;
    margin-top: 120px;
  }
`;

const ColumnaPrincipalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 70%;
  max-width: 1250px;

  @media (max-width: 1020px) {
    width: 100%;
  }

  .button-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .otras {
    color: #4e4e4e;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 24px;
    padding: 40px 0px;
    margin: auto;
  }
`;

const ColumnaSecundariaContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 30%;

  @media (max-width: 1020px) {
    width: 100%;
  }

  .sticky {
    position: sticky;
    top: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .texto-abajo {
    font-weight: 600;
    font-size: 12px;
    line-height: 21px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #4e4e4e;
    a {
      color: #4e4e4e;
      text-decoration-line: underline;
    }
    span {
      margin: 0 5px;
    }
  }
`;
const SeccionTopLateral = styled.div`
  position: relative;
  margin: 20px;
  background-color: ${(theme) => {
    return theme.theme === "dark" ? "#343434" : "#fff";
  }};
  border-radius: 16px;
  box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.1);
  padding: 27px 15px 15px;
  box-sizing: border-box;
  font-size: 1.1rem;
  text-align: center;
  width: 92%;
  text-align: left;

  .descuento {
    color: #f86374;
    text-align: right;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px; /* 150% */
  }

  .big-title {
    font-weight: 700;
    font-size: 18px;
    line-height: 27px;
    color: ${(theme) => {
      return theme.theme === "dark" ? "#fff" : "#4e4e4e";
    }};
  }

  .subtitle {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: ${(theme) => {
      return theme.theme === "dark" ? "#fff" : "#4e4e4e";
    }};
  }

  .subtitle2 {
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: ${(theme) => {
      return theme.theme === "dark" ? "#fff" : "#4e4e4e";
    }};
  }

  .resumen {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    text-align: right;
    color: ${(theme) => {
      return theme.theme === "dark" ? "#fff" : "#4e4e4e";
    }};
    text-align: left;
  }

  .pago-total {
    display: flex;
    justify-content: space-between;
  }
`;

const SeccionTop = styled.div`
  position: relative;
  margin: 20px;
  background-color: ${(theme) => {
    return theme.theme === "dark" ? "#343434" : "#fff";
  }};
  box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  padding: 50px;
  box-sizing: border-box;
  font-size: 1.1rem;
  width: 92%;
  @media (max-width: 1020px) {
    padding: 20px;
  }

  .title {
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: ${(theme) => {
      return theme.theme === "dark" ? "#fff" : "#4e4e4e";
    }};
  }

  .big-title {
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: ${(theme) => {
      return theme.theme === "dark" ? "#fff" : "#4e4e4e";
    }};
  }

  .subtitle {
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: ${(theme) => {
      return theme.theme === "dark" ? "#fff" : "#4e4e4e";
    }};
  }

  .description {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: ${(theme) => {
      return theme.theme === "dark" ? "#fff" : "#4e4e4e";
    }};
  }

  .description-der {
    font-size: 14px;
    line-height: 21px;
    color: ${(theme) => {
      return theme.theme === "dark" ? "#fff" : "#4e4e4e";
    }};
    text-align: right;
    margin: 5px 0;
  }

  .resumen {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    text-align: right;
    color: ${(theme) => {
      return theme.theme === "dark" ? "#fff" : "#4e4e4e";
    }};
    text-align: left;
  }

  .container {
    display: flex;
    @media (max-width: 1020px) {
      justify-content: space-between;
    }
  }

  .informacion {
    display: flex;
    flex-direction: column;
    :first-child {
      width: 35%;
    }
  }

  .container-pago {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  .informacion-tarjeta {
    display: flex;
    gap: 30px;
    align-items: center;
    input:checked {
      background-color: #536291;
      border: 1px solid #536291;
    }
    @media (max-width: 1020px) {
      gap: 12px;
    }
  }

  .informacion-pago {
    display: flex;
    flex-direction: column;
  }

  .mantine-RadioGroup-root {
    margin-top: 30px;
  }

  .mantine-RadioGroup-icon {
    color: #536291 !important;
    width: 14px;
    height: 15px;
    top: calc(50% - 8px);
    left: calc(50% - 7px);
  }

  .mantine-RadioGroup-radio {
    &:checked {
      background-color: #fff;
      border: 2px solid #536291;
    }
  }

  .mantine-Group-root {
    gap: 50px;
  }

  .mantine-SegmentedControl-root {
    border-radius: 30px;
    gap: 10px;
    width: 650px;
    background-color: #e7f6fb;
    height: 40px;
  }

  .mantine-SegmentedControl-label {
    color: #037cbf;
    font-size: 1.1rem;
    font-family: "Montserrat";
    font-weight: 400;
    margin-top: 5px;
  }

  .mantine-SegmentedControl-labelActive {
    color: white;
  }

  .mantine-SegmentedControl-active {
    border-radius: 20px;

    height: 40px;
    background-color: #037cbf !important;
    color: white;
  }

  .info-toku {
    font-family: "Montserrat";
    font-weight: 400;
    font-size: 13px;
    line-height: 25px;
    color: ${(theme) => {
      return theme.theme === "dark" ? "#fff" : "#000";
    }};
    span {
      font-weight: 600;
    }
  }

  .tarjetas-pago {
    display: flex;
    img {
      margin-right: 20px;
    }
  }
`;

const MantineTextInputStyled = styled(TextInput)`
  width: 100%;
  .mantine-Input-input {
    background-color: ${(theme) => {
      return theme.theme === "dark" ? "#4a4a4a" : "#f5f5f5";
    }};
    &::placeholder {
      color: #808083;
    }
  }
  .mantine-TextInput-filledVariant {
    height: 48px;
    border-radius: 16px;
    background-color: #f5f5f5;
  }
  label {
    padding-left: 12px;
  }
`;

const ButtonOxxo = styled.div`
  cursor: pointer;
  margin-top: 20px;
  width: 40%;
  background-color: #e12617;
  padding: 12px 12px;
  border-radius: 16px;
  color: #fff;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.08em;
  border: none;
  margin-right: 30px;
  position: relative;
  text-align: center;

  &:hover {
    background-color: #b92217;
  }
  &:active {
    background-color: #a51e15;
  }
`;

const ButtonPrincipal = styled.button`
  font-family: "Montserrat", sans-serif;
  padding: 15px 0;
  border-radius: 16px;
  border: none;
  width: 100%;
  background: linear-gradient(312.79deg, #36405f 5.82%, #536291 89.32%);
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: #ffffff;
  &:hover {
    cursor: pointer;
  }
`;

const ButtonAgregarTarjeta = styled.div`
  padding: 15px 0;
  border-radius: 16px;
  width: 35%;
  background: #536291;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: #fff;
  @media (max-width: 1020px) {
    width: 100%;
  }
  &:hover {
    cursor: pointer;
  }
`;

export const ButtonRappi = styled.div`
  cursor: pointer;
  width: 96%;
  display: flex;
  justify-content: center;
  background-color: #000;
  padding: 12px 12px;
  border-radius: 16px;
  color: #fff;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.08em;
  border: none;
  margin-right: 30px;
  position: relative;
  text-align: center;
`;
