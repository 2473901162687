import LocalizedStrings from "react-localization";

export const errores = new LocalizedStrings({
  es: {
    campoVacio: "Debes llenar este campo",
    longitudTelefono: "El teléfono debe tener 10 caracteres",
    longitudCodigoPostal: "El código postal debe tener 5 caracteres",
    emailValido: "Dirección de correo no válido",
    cpValido: "Código postal no válido",
    terminosCondiciones: "Debes aceptar los términos y condiciones",
    paqueteElegido: "Debes elegir un paquete",
    longitudAnioConstruccion: "El año debe tener 4 caracteres",
    edadInmueble: "La edad del inmueble debe ser igual o menor a 50 años",
    longitudRfc: "El RFC debe tener 13 caracteres",
    longitudMetros: "Debes escribir al menos 2 caracteres",
    edadInmuebleActual: "El año no puede ser mayor al actual",
    fechaValida: "Fecha de nacimiento inválida",
    fechaMayorActual: "La fecha no puede ser mayor a la actual"
  },
});
