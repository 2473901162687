import React from "react";
import "./index.css";
import App from './App';
import { createRoot } from "react-dom/client";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

Sentry.init({
  dsn: `${process.env.REACT_APP_SENTRY_URL}`,
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);