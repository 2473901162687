import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Section } from "@woow/base-woow-react";
import esencial from "../../assets/esencial.svg";
import vital from "../../assets/vital.svg";
import plus from "../../assets/plus.svg";
import tick from "../../assets/tick.svg";
import { Tooltip, useMantineColorScheme } from "@mantine/core";

export const Paquetes = () => {
  const { colorScheme } = useMantineColorScheme();
  const [width, setWidth] = useState(window.innerWidth);
  const breakpoint = 1020;
  const isResponsive = width < breakpoint;
  const [opened, setOpened] = useState(false);

  useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleWindowResize);

    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  return (
    <Div
      style={{ margin: isResponsive ? "1150px 0 0" : "600px 0 20px" }}
      theme={colorScheme}
    >
      <div className="title">Conoce nuestros paquetes</div>

      <div className="paquetesPerks">
        <div className="header">
          <h3>TODOS NUESTROS PAQUETES INCLUYEN:</h3>
        </div>
        <div className="body">
          <div className="row">
            <div className="item">
              <img src={tick} alt="" />
              <span>Asistencia al Hogar</span>
              <MantineStyledTooltipAsistencias
                withinPortal={false}
                events={{ touch: true }}
                label={
                  <>
                    <ul>
                      <span className="bold">Asistencia al Hogar</span>
                      <br />
                      Incluye los servicios para reparaciones <br />
                      eléctricas, cerrajería, vidriería y plomería. <br />
                      Además de contar con asesoría legal <br />
                      telefónica.Para estos servicios aplica un límite <br />
                      por evento de $1,000 incluyendo materiales y
                      <br />
                      mano de obra. Aplica un horario de 24 horas,
                      <br />
                      durante los 365 días del año a nivel nacional.
                    </ul>
                  </>
                }
              >
                <div className="question">?</div>
              </MantineStyledTooltipAsistencias>
            </div>
            <hr className="hrMobile" />
            <div className="item">
              <img src={tick} alt="" />
              <span>Asistencia Salud</span>
              <MantineStyledTooltipAsistencias
                withinPortal={false}
                events={{ touch: true }}
                label={
                  <>
                    <ul>
                      <span className="bold">Asistencia Salud</span>
                      <br />
                      Incluye los servicios de consulta médica a domicilio con
                      costo
                      <br />
                      preferencial, asistencia médica telefónica, servicio de
                      <br />
                      ambulancia (a través de Médica Móvil los 365 días 24
                      horas)
                      <br />y servicio funerario para el asegurado y los
                      familiares que
                      <br />
                      habiten en el hogar. El servicio de ambulancia el primer
                      evento
                      <br />
                      anual es sin costo, para eventos posteriores aplica un
                      costo
                      <br />
                      preferencial que corre por cuenta del Asegurado.
                    </ul>
                  </>
                }
              >
                <div className="question">?</div>
              </MantineStyledTooltipAsistencias>
            </div>
            <hr className="hrMobile" />
            <div className="item">
              <img src={tick} alt="" />
              <span>Asistencia Mascotas</span>
              <MantineStyledTooltipAsistencias
                withinPortal={false}
                events={{ touch: true }}
                label={
                  <>
                    <ul>
                      <span className="bold">Asistencia Mascotas</span>
                      <br />
                      Protegemos a los miembros de tu familia con asistencia
                      <br />
                      veterinaria telefónica y apoyo en caso de extravío.
                    </ul>
                  </>
                }
              >
                <div className="question">?</div>
              </MantineStyledTooltipAsistencias>
            </div>
            <hr className="hrMobile" />
          </div>
          <hr />
          <div className="row">
            <div className="item">
              <img src={tick} alt="" />
              <span>Asesoría Legal telefónica</span>
              <MantineStyledTooltipAsistencias
                withinPortal={false}
                events={{ touch: true }}
                label={
                  <>
                    <ul>
                      <span className="bold">Asesoría Legal telefónica</span>
                      <li>
                        Asesoría Legal Telefónica para cualquier consulta
                        telefónica
                        <br />
                        relacionada con asuntos penales y civiles.
                      </li>
                      <li>Asesoría legal en caso de Robo a domicilio</li>
                      Estos servicios son ilimitados y sin costo para el
                      Asegurado.
                      <br />
                      Aplica todos los días del año, las 24 horas, a nivel
                      nacional.
                    </ul>
                  </>
                }
              >
                <div className="question">?</div>
              </MantineStyledTooltipAsistencias>
            </div>
            <hr className="hrMobile" />
            <div className="item">
              <img src={tick} alt="" />
              <span>Asistencia Funeraria telefónica</span>
              <MantineStyledTooltipAsistencias
                withinPortal={false}
                events={{ touch: true }}
                label={
                  <>
                    <ul>
                      <span className="bold">
                        Asistencia Funeraria telefónica
                      </span>
                      <br />
                      El Asegurado o sus beneficiarios recibirán asistencia y
                      <br />
                      asesoría especializada por parte de un Ejecutivo de
                      Asistencia
                      <br />
                      vía telefónica durante todo el servicio funerario.
                    </ul>
                  </>
                }
              >
                <div className="question">?</div>
              </MantineStyledTooltipAsistencias>
            </div>
            <hr className="hrMobile" />
            <div className="item">
              <img src={tick} alt="" />
              <span>Cupones de Descuento</span>
            </div>
          </div>
          <hr />
          <div className="row">
            <div className="item">
              <img src={tick} alt="" />
              <span>
                Servicios para reparaciones eléctricas, cerrajería, vidriería y
                plomería
              </span>
            </div>
            <hr className="hrMobile" />
            <div className="item">
              <img src={tick} alt="" />
              <span>
                Servicio de ambulancia. Primer evento <br />
                anual sin costo
              </span>
            </div>
            <hr className="hrMobile" />
            <div className="item">
              <img src={tick} alt="" />
              <span>Cobertura en todo el país</span>
            </div>
            <hr className="hrMobile" />
          </div>
        </div>
      </div>
      <Section
        bgColor={colorScheme === "dark" ? "transparent" : "#fff"}
        margin="100px 0 0"
      >
        <div className="responsive">
          <PackageDiv theme={colorScheme}>
            <PackageHeader>
              <img src={esencial} alt="hogar esencial" />
              <Title color="#fff" fontSize="18px">
                HOGAR ESENCIAL
              </Title>
            </PackageHeader>
            <PackageBody theme={colorScheme}>
              <div className="list">
                <div className="paquete-description">
                  Es una protección básica, este paquete es perfecto, porque
                  reúne las coberturas esenciales para tu hogar que son:
                </div>
                <span>
                  <img src={tick} alt="tick" /> Edificio y/o contenidos
                </span>
                <span>
                  <img src={tick} alt="tick" /> Responsabilidad civil
                </span>
                <span>
                  <img src={tick} alt="tick" /> Remoción de escombros
                </span>
                <span>
                  <img src={tick} alt="tick" /> Servicios de asistencia
                </span>
              </div>
              <ButtonPaquete
                onClick={() => {
                  document.getElementById("cotizador").scrollIntoView();
                }}
              >
                ¡LO QUIERO!
              </ButtonPaquete>
            </PackageBody>
          </PackageDiv>
          <PackageDiv theme={colorScheme}>
            <PackageHeader>
              <img src={vital} alt="Hogar vital" />
              <Title color="#fff" fontSize="18px">
                HOGAR VITAL
              </Title>
            </PackageHeader>
            <PackageBody theme={colorScheme}>
              <div className="list">
                <div className="paquete-description">
                  Este paquete brinda mayor protección para tu hogar, incluye
                  los beneficios del Paquete Esencial, más las siguientes
                  coberturas:
                </div>
                <span>
                  <img src={tick} alt="tick" /> Equipo electrónico y
                  electrodomésticos{" "}
                </span>
                <span>
                  <img src={tick} alt="tick" /> Cristales{" "}
                </span>
                <span>
                  <img src={tick} alt="tick" /> Gastos extraordinarios{" "}
                </span>
              </div>
              <ButtonPaquete
                onClick={() => {
                  document.getElementById("cotizador").scrollIntoView();
                }}
              >
                ¡LO QUIERO!
              </ButtonPaquete>
            </PackageBody>
          </PackageDiv>
          <PackageDiv theme={colorScheme}>
            <PackageHeader>
              <img src={plus} alt="Hogar plus" />
              <Title color="#fff" fontSize="18px">
                HOGAR PLUS
              </Title>
            </PackageHeader>
            <PackageBody theme={colorScheme}>
              <div className="list">
                <div className="paquete-description">
                  Incluye las coberturas de los paquetes Esencial y Vital,
                  adicional la cobertura Robo de contenidos (te encuentres
                  dentro o fuera de tu hogar), más las siguientes coberturas:
                </div>
                <span>
                  <img src={tick} alt="tick" /> Menaje de Casa
                  <MantineStyledTooltip
                    theme={colorScheme}
                    withinPortal={false}
                    events={{ touch: true }}
                    label={
                      <>
                        <ul>
                          <span className="bold">Menaje de casa</span>
                          Protegemos tus bienes más preciados en caso <br /> de
                          robo o asalto con violencia dentro de tu hogar, <br />
                          como son:
                          <li>
                            Orfebrería (adornos, utensilios de metales
                            preciosos).
                          </li>
                          <li>Joyería.</li>
                          <li> Relojes.</li>
                          <li>Colecciones</li>
                          <li>Dinero en efectivo</li>
                        </ul>
                      </>
                    }
                  >
                    <div className="question">?</div>
                  </MantineStyledTooltip>
                </span>
                <span>
                  <img src={tick} alt="tick" /> Robo de Bienes a la Intemperie
                  <MantineStyledTooltip
                    withinPortal={false}
                    events={{ touch: true }}
                    label={
                      <>
                        <ul>
                          <span className="bold">
                            Robo de Bienes a la Intemperie
                          </span>
                          Asegura los riesgos a los que está expuesto los bienes
                          <br />
                          del inmueble, por Riesgos Hidrometeorológicos
                          <li>Albercas.</li>
                          <li>Decoraciones.</li>
                          <li>Instalaciones y/o canchas deportivas.</li>
                          <li>Luminarias.</li>
                          <li>Muros.</li>
                          <li>Palapas</li>
                          <li>Sistemas de riego.</li>
                          <li>Paneles solares.</li>
                        </ul>
                      </>
                    }
                  >
                    <div className="question">?</div>
                  </MantineStyledTooltip>
                </span>
                <span>
                  <img src={tick} alt="tick" /> Artículos en tránsito
                  <MantineStyledTooltip
                    withinPortal={false}
                    events={{ touch: true }}
                    label={
                      <>
                        <ul>
                          <span className="bold">Artículos en tránsito</span>
                          Protegemos tus bienes ante robo o asalto con <br />
                          violencia fuera de casa, ente ellos:
                          <li>Bolsas</li>
                          <li>Carteras</li>
                          <li> Joyerías</li>
                          <li>Relojes</li>
                          <li>
                            {" "}
                            Aparatos electrónicos, fotográficos o de video
                          </li>
                          <li>Artículos deportivos</li>
                        </ul>
                      </>
                    }
                  >
                    <div className="question">?</div>
                  </MantineStyledTooltip>
                </span>
              </div>
              <ButtonPaquete
                onClick={() => {
                  document.getElementById("cotizador").scrollIntoView();
                }}
              >
                ¡LO QUIERO!
              </ButtonPaquete>
            </PackageBody>
          </PackageDiv>
        </div>
      </Section>
      <div className="extra">
        *Además puedes agregar protección extra como
        <b>
          {" "}
          Riesgos Hidrometeorológicos Edificio y Contenido, Terremoto, Erupción
          Volcánica Edificio y Contenidos y Servicios Funerarios para Mascotas
        </b>
      </div>
    </Div>
  );
};

const Div = styled.div`
  margin: 59px 0;

  .mantine-Tooltip-tooltip {
    background: #d9d9d9;
    color: #4e4e4e;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 15px;
    .bold {
      font-weight: 600;
      font-size: 12px;
    }
    ul {
      padding-inline-start: 30px;
    }
    @media (max-width: 1020px) {
      width: 100%;
      white-space: normal;
      ul {
        padding-inline-start: 10px;
      }
    }
  }

  .responsive {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 100px;
    margin-bottom: 50px;
    @media (max-width: 1020px) {
      flex-direction: column;
      margin-left: 24px;
      margin-right: 24px;
    }
  }

  .title {
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    color: ${(theme) => {
      return theme.theme === "dark" ? "#fff" : "#4e4e4e";
    }};
    margin-bottom: 55px;
  }

  .extra {
    margin: 0 auto;
    max-width: 1350px;
    width: 90%;
    padding: 15px;
    text-align: center;
    color: ${(theme) => {
      return theme.theme === "dark" ? "#fff" : "#4e4e4e";
    }};
  }

  .paquetesPerks {
    position: relative;
    margin: 0 auto;
    max-width: 1350px;
    width: 90%;
    padding: 15px;
    background: ${(theme) => {
      return theme.theme === "dark" ? "#242424" : "#fff";
    }};
    box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.08);
    border-radius: 16px;
    margin-bottom: 40px;
    @media (max-width: 1020px) {
      width: 80%;
    }
    hr {
      border: 1px solid #f5f5f5;
      width: 100%;

      @media (max-width: 500px) {
        display: none;
      }
    }

    .hrMobile {
      display: initial;
      @media (min-width: 500px) {
        display: none;
      }
    }

    .header {
      position: absolute;
      left: 0px;
      top: 0px;
      height: 60px;
      width: 100%;
      background: linear-gradient(312.79deg, #36405f 5.82%, #536291 89.32%);
      border-radius: 16px 16px 0 0;

      h3 {
        text-align: center;
        font-weight: 500;
        letter-spacing: 0.09rem;
        font-size: 16px;
        color: white;
        margin-top: 20px;
        @media (max-width: 500px) {
          font-size: 13px;
        }
      }
    }
  }

  .body {
    margin-top: 50px;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;

    hr {
      border: 1px solid #f5f5f5;
      width: 100%;
    }

    .row {
      display: flex;
      @media (max-width: 1020px) {
        flex-direction: column;
      }

      .item {
        height: 50px;
        flex: 1;
        display: flex;
        align-items: center;
        gap: 10px;
        font-weight: 600;
        font-size: 16px;
        line-height: 18px;
        letter-spacing: 0.08em;
        color: ${(theme) => {
          return theme.theme === "dark" ? "#fff" : "#4e4e4e";
        }};
        @media (max-width: 500px) {
          margin: 5px 0px;
        }

        .question {
          width: 12px;
          height: 12px;
          color: ${(theme) => {
            return theme.theme === "dark" ? "#000" : "#fff";
          }};
          background: #c1c1c1;
          text-align: center;
          border-radius: 20px;
          font-weight: 600;
          font-size: 10px;
          line-height: 12px;
          margin-left: 10px;
          :hover {
            cursor: pointer;
          }
        }
      }
    }
  }
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
`;

const ButtonPaquete = styled.div`
  padding: 16px 0;
  width: 100%;
  background: linear-gradient(312.79deg, #36405f 5.82%, #536291 89.32%);
  border-radius: 16px;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: #ffffff;
  &:hover {
    cursor: pointer;
  }
`;

const PackageDiv = styled.div`
  box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.15);
  border-radius: 16px;
  height: 560px;
  width: 380px;
  background: ${(theme) => {
    return theme.theme === "dark" ? "#343434" : "#fff";
  }};
  @media (max-width: 1020px) {
    width: 100%;
    margin-bottom: 50px;
  }
`;

const PackageHeader = styled.div`
  background: radial-gradient(
      98.82% 3135.89% at 100% 76.67%,
      #808083 0%,
      rgba(229, 230, 231, 0) 100%
    ),
    #536291;
  background-blend-mode: multiply, normal;
  border-radius: 16px 16px 0px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 22px 0;

  img {
    width: 90px;
    height: 49px;
    margin-bottom: 10px;
  }
`;

const PackageBody = styled.div`
  padding: 32px 41px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  height: 380px;
  span {
    font-size: 0.875rem;
  }
  & .list {
    display: flex;
    flex-direction: column;
    .paquete-description {
      font-weight: 400;
      font-size: 16px;
      line-height: 25px;
      color: ${(theme) => {
        return theme.theme === "dark" ? "#fff" : "#4e4e4e";
      }};
      padding-bottom: 20px;
    }
    & span {
      display: flex;
      padding-bottom: 10px;
      font-weight: 400;
      font-size: 16px;
      line-height: 25px;
      color: ${(theme) => {
        return theme.theme === "dark" ? "#fff" : "#4e4e4e";
      }};
      display: flex;
      align-items: center;

      .question {
        width: 12px;
        height: 12px;
        color: ${(theme) => {
          return theme.theme === "dark" ? "#000" : "#fff";
        }};
        background: #c1c1c1;
        text-align: center;
        border-radius: 20px;
        font-weight: 600;
        font-size: 10px;
        line-height: 12px;
        margin-left: 10px;
        :hover {
          cursor: pointer;
        }
      }

      & img {
        padding-right: 7px;
      }
    }
  }
`;

const MantineStyledTooltip = styled(Tooltip)`
  .mantine-Tooltip-body {
    color: #000;
    background-color: #f5f5f5;
    font-size: 10px;
    line-height: 14px;
    font-weight: 400;
    width: 300px;
    padding: 10px;
    .bold {
      font-weight: 600;
    }
  }
`;

const MantineStyledTooltipAsistencias = styled(Tooltip)`
  .mantine-Tooltip-body {
    color: #000;
    background-color: #f5f5f5;
    font-size: 10px;
    line-height: 14px;
    font-weight: 400;
    width: 400px;
    padding: 10px;
    .bold {
      font-weight: 600;
      font-size: 12px;
    }
  }
`;
