import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import {
  Tooltip,
  Select,
  LoadingOverlay,
  useMantineColorScheme,
} from "@mantine/core";
import { HeaderWOOW } from "../components/HeaderWOOW";
import esencial from "../assets/esencial.svg";
import vital from "../assets/vital.svg";
import plus from "../assets/plus.svg";
import tick from "../assets/tick.svg";
import chevronDown from "../assets/chevron.svg";
import useStore from "../useStore";
import { useNavigate } from "react-router-dom";
import Lottie from "react-lottie";
import woowLoadingHogar from "../assets/woow-loading-hogar.json";
import {
  descuento,
  getPerfilContratante,
  getTipoInmueble,
  tipoPagoDoble,
  tipoPagoIndividual,
  uuid,
  verticalColor,
} from "../constants/constants";
import { HeroPages } from "../components/HeroPages";
import headerImage from "../assets/heros/woow_lp_hogar_coberturas-adicionales.webp";
import { errores } from "../constants/strings";
import { recotizarCoberturas } from "../services/api";
import Steps from "../components/Steps";
import Breadcrumbs from "../components/Breadcrumbs";
import TagManager from "react-gtm-module";
import { showNotification } from "@mantine/notifications";
import { useColorScheme } from "@mantine/hooks";

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_KEY,
};
TagManager.initialize(tagManagerArgs);

export const Cotizacion = () => {
  const {
    globalDatosAsegurado,
    globalDatosPaquetes,
    setGlobalDatosCoberturas,
    globalIdCotizacion,
    globalDatosRecotizacion,
    setGlobalPaqueteElegido,
    globalPaqueteElegido,
    globalSumaAsegurada,
    setGlobalTipoPago,
    setGlobalSumaAsegurada,
    setglobalDatosAsegurado,
    globalTipoCotizador,
    globalTokenCotizacion,
    globalPaquetesFactorLinea,
    setGlobalDatosPaquetes,
    setGlobalPaquetesFactorLinea,
    setGlobalDatosRecotizacion,
    setGlobalTipoCotizador,
    setGlobalIdCotizacion,
    globalDealId,
    globalTokenParams,
  } = useStore();
  const { colorScheme } = useMantineColorScheme();
  const [width, setWidth] = useState(window.innerWidth);
  const breakpoint = 1020;
  const isResponsive = width < breakpoint;
  let navigate = useNavigate();
  const [periodicidad, setPeriodicidad] = useState("");
  const [eleccion, setEleccion] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [paqueteElegidoError, setPaqueteElegidoError] = useState(false);
  const paqueteEsencial = globalPaqueteElegido?.nombre === "Hogar Esencial";
  const paqueteVital = globalPaqueteElegido?.nombre === "Hogar Vital";
  const paquetePlus = globalPaqueteElegido?.nombre === "Hogar Plus";
  const dataPaquete = sessionStorage.getItem("paqueteElegido");
  const dataCotizacion = sessionStorage.getItem("cotizacion");
  const loadedDataPaquete =
    dataPaquete !== "undefined" ? JSON.parse(dataPaquete) : {};
  const loadedDataCotizacion =
    dataCotizacion !== "undefined" ? JSON.parse(dataCotizacion) : {};
  const [datosGuardadosPaquete, setDatosGuardadosPaquete] =
    useState(loadedDataPaquete);
  const [datosGuardadosCotizacion, setDatosGuardadosCotizacion] =
    useState(loadedDataCotizacion);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: woowLoadingHogar,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  useEffect(() => {
    if (isResponsive) {
      window.scrollTo(0, 0);
    }
  }, []);

  useEffect(() => {
    if (sessionStorage.getItem("datosCoberturas")) {
      sessionStorage.removeItem("datosCoberturas");
    }

    if (sessionStorage.getItem("coberturasExtra")) {
      sessionStorage.removeItem("coberturasExtra");
    }

    if (sessionStorage.getItem("globalDatosEmision")) {
      sessionStorage.removeItem("globalDatosEmision");
    }
  }, []);

  useEffect(() => {
    if (datosGuardadosPaquete) {
      setGlobalPaqueteElegido(datosGuardadosPaquete);
      if (datosGuardadosPaquete?.nombre === "Hogar Esencial") {
        setEleccion("0");
      } else if (datosGuardadosPaquete?.nombre === "Hogar Vital") {
        setEleccion("1");
      } else if (datosGuardadosPaquete?.nombre === "Hogar Plus") {
        setEleccion("2");
      }
    }
  }, [datosGuardadosPaquete]);

  useEffect(() => {
    if (datosGuardadosCotizacion) {
      setGlobalSumaAsegurada(
        datosGuardadosCotizacion?.cotizacionPrincipal?.valorInmueble
      );
      setglobalDatosAsegurado({
        datosDelAsegurado: datosGuardadosCotizacion?.cotizacionPrincipal,
      });
      setGlobalDatosPaquetes(datosGuardadosCotizacion?.datosPaquetes);
      setGlobalPaquetesFactorLinea(
        datosGuardadosCotizacion?.paquetesFactorLinea
      );
      setGlobalDatosRecotizacion(datosGuardadosCotizacion?.datosRecotizacion);
      setGlobalTipoCotizador(datosGuardadosCotizacion?.tipoCotizador);
      const idCotizacion = datosGuardadosCotizacion?.idCotizacion;
      setGlobalIdCotizacion({ idCotizacion });
    }
  }, [datosGuardadosCotizacion]);

  const onlyAnualPayment =
    globalDatosPaquetes[0]?.primaTotal <= 4000 ||
    globalDatosPaquetes[1]?.primaTotal <= 4000 ||
    globalDatosPaquetes[2]?.primaTotal <= 4000;

  useEffect(() => {
    if (onlyAnualPayment) {
      setPeriodicidad("Anual");
    } else setPeriodicidad("Mensual");
  }, [onlyAnualPayment]);

  const paquetesFactorLinea = Object.keys(globalPaquetesFactorLinea).map(
    function (key) {
      return globalPaquetesFactorLinea[key];
    }
  );

  const getPaqueteFactorLinea = (nombre) => {
    switch (nombre) {
      case "Hogar Esencial":
        return paquetesFactorLinea[0]["coberturas"];
      case "Hogar Vital":
        return paquetesFactorLinea[1]["coberturas"];
      case "Hogar Plus":
        return paquetesFactorLinea[2]["coberturas"];
      default:
        return paquetesFactorLinea[0]["coberturas"];
    }
  };

  const handleCoberturas = async () => {
    setGlobalTipoPago(periodicidad);
    setIsLoading(true);

    // const coberturas = globalDatosRecotizacion[2];
    const coberturas = getPaqueteFactorLinea(globalPaqueteElegido?.nombre);

    const filtered = coberturas.filter(
      (item) =>
        item.idCobertura === "0000001325" ||
        item.idCobertura === "0000001324" ||
        item.idCobertura === "0000000866" ||
        item.idCobertura === "0000000869" ||
        item.idCobertura === "0000001476"
    );

    const body = filtered.map((item) => {
      return {
        [item.idCobertura]: {
          idCobertura: item?.idCobertura,
          sumaAsegurada: item?.sumaAsegurada,
          factorLinea: item?.factorLinea || 0,
          factorRenovacion: item?.factorRenovacion || 0,
        },
      };
    });

    const esencialFiltered = {
      "Hogar Esencial": {
        coberturas: Object.assign({}, ...body),
      },
    };

    const vitalFiltered = {
      "Hogar Vital": {
        coberturas: Object.assign({}, ...body),
      },
    };

    const plusFiltered = {
      "Hogar Plus": {
        coberturas: Object.assign({}, ...body),
      },
    };

    const datos = globalDatosRecotizacion[1];
    const datosConfig = globalDatosRecotizacion[0];

    const datosInmuebles = [datos].map((item) => {
      return {
        codigoPostalContratante: item?.codigoPostalContratante,
        codigoPostalInmueble: item?.codigoPostalInmueble,
        esConstruccionMaciza: item?.esConstruccionMaciza,
        esLagoLaguna: item?.esLagoLaguna,
        esLineaPlaya: item?.esLineaPlaya,
        idPeriodicidadPago: periodicidad === "Anual" ? 1 : 4,
        idTipoMoneda: item?.idTipoMoneda,
        multiclausula: item?.multiclausula,
        valorTipo: item?.valorTipo,
      };
    });

    const medidaSeguridad =
      Number(globalDatosAsegurado.datosDelAsegurado.medidasDeSeguridad) / 100;

    const datosConfiguracion = [datosConfig].map((item) => {
      return {
        cesionComision: item?.cesionComision,
        descuentoMedidasSeguridad: medidaSeguridad,
        fechaEfectiva: item?.fechaEfectiva,
        operacionBase: item?.operacionBase,
      };
    });

    const output = Object.assign({}, ...datosInmuebles);
    const outputConfig = Object.assign({}, ...datosConfiguracion);

    const dataCoberturas = {
      idCotizacion: globalIdCotizacion.idCotizacion,
      codigo: eleccion || "",
      origin: globalTipoCotizador === "interno" ? "APP" : "LP",
      metodoPago: periodicidad === "Anual" ? 1 : 4,
      datosConfiguracion: outputConfig,
      datosInmueble: output,
      paquetes:
        (eleccion === "0" && esencialFiltered) ||
        (eleccion === "1" && vitalFiltered) ||
        (eleccion === "2" && plusFiltered),
    };

    const token =
      globalTipoCotizador === "interno" ? globalTokenCotizacion : uuid;

    recotizarCoberturas(dataCoberturas, token)
      .then((response) => {
        if (response.data.success === 1) {
          const coberturas = response.data.event.paquetes[0];
          setGlobalDatosCoberturas([coberturas]);

          const datosCoberturas = {
            datosCoberturas: [coberturas],
            tipoPago: periodicidad,
          };

          sessionStorage.setItem(
            "datosCoberturas",
            JSON.stringify(datosCoberturas)
          );

          setIsLoading(false);

          TagManager.dataLayer({
            dataLayer: {
              event: "hogar_seleccion_coberturas",
              data: "",
            },
          });

          navigate("/coberturas");
        } else {
          setPaqueteElegidoError(true);
          setIsLoading(false);
        }
      })
      .catch(function (error) {
        setIsLoading(false);
      });
  };

  const getInfoPaquetesMensual = (eleccion) => {
    switch (eleccion) {
      case "0":
        return globalDatosPaquetes[0]?.primaTotal.toLocaleString("en-US", {
          maximumFractionDigits: 2,
        });
      case "1":
        return globalDatosPaquetes[1]?.primaTotal.toLocaleString("en-US", {
          maximumFractionDigits: 2,
        });
      case "2":
        return globalDatosPaquetes[2]?.primaTotal.toLocaleString("en-US", {
          maximumFractionDigits: 2,
        });
      default:
        return globalDatosPaquetes[0]?.primaTotal.toLocaleString("en-US", {
          maximumFractionDigits: 2,
        });
    }
  };
  const getInfoPaquetesMensualDescuento = (eleccion) => {
    switch (eleccion) {
      case "0":
        return globalDatosPaquetes[0]?.primaTotalAnterior.toLocaleString(
          "en-US",
          {
            maximumFractionDigits: 2,
          }
        );
      case "1":
        return globalDatosPaquetes[1]?.primaTotalAnterior.toLocaleString(
          "en-US",
          {
            maximumFractionDigits: 2,
          }
        );
      case "2":
        return globalDatosPaquetes[2]?.primaTotalAnterior.toLocaleString(
          "en-US",
          {
            maximumFractionDigits: 2,
          }
        );
      default:
        return globalDatosPaquetes[0]?.primaTotalAnterior.toLocaleString(
          "en-US",
          {
            maximumFractionDigits: 2,
          }
        );
    }
  };

  const getInfoPaquetesAnual = (eleccion) => {
    switch (eleccion) {
      case "0":
        return globalDatosPaquetes[0]?.primaAnual.toLocaleString("en-US", {
          maximumFractionDigits: 2,
        });
      case "1":
        return globalDatosPaquetes[1]?.primaAnual.toLocaleString("en-US", {
          maximumFractionDigits: 2,
        });
      case "2":
        return globalDatosPaquetes[2]?.primaAnual.toLocaleString("en-US", {
          maximumFractionDigits: 2,
        });
      default:
        return globalDatosPaquetes[0]?.primaAnual.toLocaleString("en-US", {
          maximumFractionDigits: 2,
        });
    }
  };

  const getInfoPaquetesAnualDescuento = (eleccion) => {
    switch (eleccion) {
      case "0":
        return globalDatosPaquetes[0]?.primaAnualAnterior.toLocaleString(
          "en-US",
          {
            maximumFractionDigits: 2,
          }
        );
      case "1":
        return globalDatosPaquetes[1]?.primaAnualAnterior.toLocaleString(
          "en-US",
          {
            maximumFractionDigits: 2,
          }
        );
      case "2":
        return globalDatosPaquetes[2]?.primaAnualAnterior.toLocaleString(
          "en-US",
          {
            maximumFractionDigits: 2,
          }
        );
      default:
        return globalDatosPaquetes[0]?.primaAnualAnterior.toLocaleString(
          "en-US",
          {
            maximumFractionDigits: 2,
          }
        );
    }
  };

  const getInfoPrimerRecibo = (eleccion) => {
    switch (eleccion) {
      case "0":
        return globalDatosPaquetes[0]?.primaPrimerRecibo.toLocaleString(
          "en-US",
          { maximumFractionDigits: 2 }
        );
      case "1":
        return globalDatosPaquetes[1]?.primaPrimerRecibo.toLocaleString(
          "en-US",
          { maximumFractionDigits: 2 }
        );
      case "2":
        return globalDatosPaquetes[2]?.primaPrimerRecibo.toLocaleString(
          "en-US",
          { maximumFractionDigits: 2 }
        );
      default:
        return globalDatosPaquetes[0]?.primaPrimerRecibo.toLocaleString(
          "en-US",
          { maximumFractionDigits: 2 }
        );
    }
  };
  const getInfoPrimerReciboDescuento = (eleccion) => {
    switch (eleccion) {
      case "0":
        return globalDatosPaquetes[0]?.primaPrimerReciboAnterior.toLocaleString(
          "en-US",
          { maximumFractionDigits: 2 }
        );
      case "1":
        return globalDatosPaquetes[1]?.primaPrimerReciboAnterior.toLocaleString(
          "en-US",
          { maximumFractionDigits: 2 }
        );
      case "2":
        return globalDatosPaquetes[2]?.primaPrimerReciboAnterior.toLocaleString(
          "en-US",
          { maximumFractionDigits: 2 }
        );
      default:
        return globalDatosPaquetes[0]?.primaPrimerReciboAnterior.toLocaleString(
          "en-US",
          { maximumFractionDigits: 2 }
        );
    }
  };

  const getInfoPagoSubsecuente = (eleccion) => {
    switch (eleccion) {
      case "0":
        return globalDatosPaquetes[0]?.primaReciboSubsecuente.toLocaleString(
          "en-US",
          { maximumFractionDigits: 2 }
        );
      case "1":
        return globalDatosPaquetes[1]?.primaReciboSubsecuente.toLocaleString(
          "en-US",
          { maximumFractionDigits: 2 }
        );
      case "2":
        return globalDatosPaquetes[2]?.primaReciboSubsecuente.toLocaleString(
          "en-US",
          { maximumFractionDigits: 2 }
        );
      default:
        return globalDatosPaquetes[0]?.primaReciboSubsecuente.toLocaleString(
          "en-US",
          { maximumFractionDigits: 2 }
        );
    }
  };
  const getInfoPagoSubsecuenteDescuento = (eleccion) => {
    switch (eleccion) {
      case "0":
        return globalDatosPaquetes[0]?.primaReciboSubsecuenteAnterior.toLocaleString(
          "en-US",
          { maximumFractionDigits: 2 }
        );
      case "1":
        return globalDatosPaquetes[1]?.primaReciboSubsecuenteAnterior.toLocaleString(
          "en-US",
          { maximumFractionDigits: 2 }
        );
      case "2":
        return globalDatosPaquetes[2]?.primaReciboSubsecuenteAnterior.toLocaleString(
          "en-US",
          { maximumFractionDigits: 2 }
        );
      default:
        return globalDatosPaquetes[0]?.primaReciboSubsecuenteAnterior.toLocaleString(
          "en-US",
          { maximumFractionDigits: 2 }
        );
    }
  };

  return (
    <ComparadorContainer theme={colorScheme}>
      <HeaderWOOW subtitle="¡Tenemos esto para ti!" />
      <HeroPages img={headerImage} subtitle="¡Tenemos esto para ti" />
      <ComparadorBody>
        <ColumnaPrincipalContainer theme={colorScheme}>
          {!isResponsive && globalTipoCotizador === "externo" && (
            <div className="breadcrumbs">
              <Breadcrumbs active={2} />
            </div>
          )}
          <div className="paquetes">
            <PackageDiv>
              <PackageHeader>
                <img src={esencial} alt="hogar esencial" />
                <Title color="#fff" fontSize="18px">
                  HOGAR ESENCIAL
                </Title>
              </PackageHeader>
              <PackageBody theme={colorScheme}>
                <div className="list">
                  <div className="paquete-description">
                    Es una protección básica, este paquete es perfecto, porque
                    reúne las coberturas esenciales para tu hogar que son:
                  </div>
                  <span>
                    <img src={tick} alt="tick" /> Edificio y/o contenidos
                  </span>
                  <span>
                    <img src={tick} alt="tick" /> Responsabilidad civil
                  </span>
                  <span>
                    <img src={tick} alt="tick" /> Remoción de escombros
                  </span>
                  <span>
                    <img src={tick} alt="tick" /> Servicios de asistencia*
                  </span>
                </div>
                <div className="bottom-package">
                  {globalDatosPaquetes[0]?.elegible === false ? (
                    <div className="iva-label">
                      El paquete Hogar Esencial no se puede seleccionar en este
                      momento
                    </div>
                  ) : (
                    <>
                      <div className="total-paquete">
                        <>
                          {periodicidad !== "Anual" ? (
                            <>
                              {descuento && (
                                <div className="descuento">
                                  10% DESC. INCLUIDO
                                </div>
                              )}
                              <>
                                <span>MX$</span>

                                {globalDatosPaquetes[0]?.primaReciboSubsecuente.toLocaleString(
                                  "en-US",
                                  { maximumFractionDigits: 2 }
                                )}
                              </>
                            </>
                          ) : (
                            <>
                              {descuento && (
                                <div className="descuento">
                                  10% DESC. INCLUIDO
                                </div>
                              )}
                              <>
                                <span>MX$</span>
                                {globalDatosPaquetes[0]?.primaAnual.toLocaleString(
                                  "en-US",
                                  { maximumFractionDigits: 2 }
                                )}
                              </>
                            </>
                          )}
                        </>
                      </div>
                      <div className="tipo-pago">
                        PAGO {periodicidad !== "Anual" ? "MENSUAL" : "ANUAL"}
                      </div>
                      {periodicidad !== "Anual" ? (
                        <div className="iva-label">
                          O MX$
                          {periodicidad !== "Anual" ? (
                            <>
                              {globalDatosPaquetes[0]?.primaAnual.toLocaleString(
                                "en-US",
                                { maximumFractionDigits: 2 }
                              )}
                            </>
                          ) : (
                            <>
                              {globalDatosPaquetes[0]?.primaAnual.toLocaleString(
                                "en-US",
                                { maximumFractionDigits: 2 }
                              )}
                            </>
                          )}{" "}
                          PAGO ANUAL
                        </div>
                      ) : (
                        <div className="iva-label">IVA INCLUIDO</div>
                      )}
                      <ButtonBlue
                        style={{
                          background: paqueteEsencial
                            ? colorScheme === "dark"
                              ? "transparent"
                              : "#E5E5E5"
                            : verticalColor,
                          color: paqueteEsencial
                            ? colorScheme === "dark"
                              ? "#536291"
                              : "#838383"
                            : "#fff",
                        }}
                        disabled={paqueteEsencial}
                        onClick={() => {
                          setEleccion("0");
                          setGlobalPaqueteElegido(globalDatosPaquetes[0]);
                          sessionStorage.setItem(
                            "paqueteElegido",
                            JSON.stringify(globalDatosPaquetes[0])
                          );
                          showNotification({
                            id: "paqueteElegido",
                            title: "Paquete seleccionado",
                            message: "Se agregó paquete a tu resumen",
                            color: "blue",
                            autoClose: 3000,
                            position: "top-right",
                          });
                        }}
                      >
                        ¡LO QUIERO!
                      </ButtonBlue>
                    </>
                  )}
                </div>
              </PackageBody>
            </PackageDiv>
            <PackageDiv>
              <PackageHeader>
                <img src={vital} alt="hogar vital" />
                <Title color="#fff" fontSize="18px">
                  HOGAR VITAL
                </Title>
              </PackageHeader>
              <PackageBody theme={colorScheme}>
                <div className="list">
                  <div className="paquete-description">
                    Este paquete brinda mayor protección para tu hogar, incluye
                    los beneficios del Paquete Esencial, más las siguientes
                    coberturas:
                  </div>
                  <span>
                    <img src={tick} alt="tick" /> Equipo electrónico y
                    electrodomésticos{" "}
                  </span>
                  <span>
                    <img src={tick} alt="tick" /> Cristales{" "}
                  </span>
                  <span>
                    <img src={tick} alt="tick" /> Gastos extraordinarios{" "}
                  </span>
                </div>
                <div className="bottom-package">
                  {globalDatosPaquetes[1]?.elegible === false ? (
                    <div className="iva-label">
                      El paquete Hogar Vital no se puede seleccionar en este
                      momento
                    </div>
                  ) : (
                    <>
                      <div className="total-paquete">
                        <>
                          {periodicidad !== "Anual" ? (
                            <>
                              {descuento && (
                                <div className="descuento">
                                  10% DESC. INCLUIDO
                                </div>
                              )}
                              <>
                                <span>MX$</span>
                                {globalDatosPaquetes[1]?.primaReciboSubsecuente.toLocaleString(
                                  "en-US",
                                  { maximumFractionDigits: 2 }
                                )}
                              </>
                            </>
                          ) : (
                            <>
                              {descuento && (
                                <div className="descuento">
                                  10% DESC. INCLUIDO
                                </div>
                              )}
                              <>
                                <span>MX$</span>
                                {globalDatosPaquetes[1]?.primaAnual.toLocaleString(
                                  "en-US",
                                  { maximumFractionDigits: 2 }
                                )}
                              </>
                            </>
                          )}
                        </>
                      </div>
                      <div className="tipo-pago">
                        PAGO {periodicidad !== "Anual" ? "MENSUAL" : "ANUAL"}
                      </div>
                      {periodicidad !== "Anual" ? (
                        <div className="iva-label">
                          O MX$
                          {periodicidad !== "Anual" ? (
                            <>
                              {globalDatosPaquetes[1]?.primaAnual.toLocaleString(
                                "en-US",
                                { maximumFractionDigits: 2 }
                              )}
                            </>
                          ) : (
                            <>
                              {globalDatosPaquetes[1]?.primaAnual.toLocaleString(
                                "en-US",
                                { maximumFractionDigits: 2 }
                              )}
                            </>
                          )}{" "}
                          PAGO ANUAL
                        </div>
                      ) : (
                        <div className="iva-label">IVA INCLUIDO</div>
                      )}
                      <ButtonBlue
                        style={{
                          background: paqueteVital ? "#E5E5E5" : verticalColor,
                          color: paqueteVital ? "#838383" : "#fff",
                        }}
                        disabled={paqueteVital}
                        width="100%"
                        onClick={() => {
                          setEleccion("1");
                          setGlobalPaqueteElegido(globalDatosPaquetes[1]);
                          sessionStorage.setItem(
                            "paqueteElegido",
                            JSON.stringify(globalDatosPaquetes[1])
                          );
                          showNotification({
                            id: "paqueteElegido",
                            title: "Paquete seleccionado",
                            message: "Se agregó paquete a tu resumen",
                            color: "blue",
                            autoClose: 3000,
                            position: "top-right",
                          });
                        }}
                      >
                        ¡LO QUIERO!
                      </ButtonBlue>
                    </>
                  )}
                </div>
              </PackageBody>
            </PackageDiv>
            <PackageDiv>
              <PackageHeader>
                <img src={plus} alt="hogar plus" />
                <Title color="#fff" fontSize="18px">
                  HOGAR PLUS
                </Title>
              </PackageHeader>
              <PackageBody theme={colorScheme}>
                <div className="list">
                  <div className="paquete-description">
                  Incluye las coberturas de los paquetes Esencial y Vital,
                  adicional la cobertura Robo de contenidos (te encuentres
                  dentro o fuera de tu hogar), más las siguientes coberturas:
                  </div>
                  <span>
                    <img src={tick} alt="tick" /> Menaje de Casa
                    <MantineStyledTooltip
                      theme={colorScheme}
                      withinPortal={false}
                      events={{ touch: true }}
                      label={
                        <>
                          <ul>
                            <span className="bold">Menaje de casa</span>
                            Protegemos tus bienes más preciados en caso <br />{" "}
                            de robo o asalto con violencia dentro de tu hogar,{" "}
                            <br />
                            como son:
                            <li>
                              Orfebrería (adornos, utensilios de metales
                              preciosos).
                            </li>
                            <li>Joyería.</li>
                            <li> Relojes.</li>
                            <li>Colecciones</li>
                            <li>Dinero en efectivo</li>
                          </ul>
                        </>
                      }
                    >
                      <div className="question">?</div>
                    </MantineStyledTooltip>
                  </span>
                  <span>
                    <img src={tick} alt="tick" /> Robo de Bienes a la Intemperie
                  </span>
                  <span>
                    <img src={tick} alt="tick" /> Artículos en tránsito
                    <MantineStyledTooltip
                      withinPortal={false}
                      events={{ touch: true }}
                      label={
                        <>
                          <ul>
                            <span className="bold">Artículos en tránsito</span>
                            Protegemos tus bienes ante robo o asalto con <br />
                            violencia fuera de casa, ente ellos:
                            <li>Bolsas.</li>
                            <li>Carteras.</li>
                            <li> Joyerías.</li>
                            <li>Relojes.</li>
                            <li>
                              {" "}
                              Aparatos electrónicos, fotográficos o de video.
                            </li>
                            <li>Artículos deportivos</li>
                          </ul>
                        </>
                      }
                    >
                      <div className="question">?</div>
                    </MantineStyledTooltip>
                  </span>
                </div>
                <div className="bottom-package">
                  {globalDatosPaquetes[2]?.elegible === false ? (
                    <div className="iva-label">
                      El paquete Hogar Plus no se puede seleccionar en este
                      momento
                    </div>
                  ) : (
                    <>
                      <div className="total-paquete">
                        {periodicidad !== "Anual" ? (
                          <>
                            {descuento && (
                              <div className="descuento">
                                10% DESC. INCLUIDO
                              </div>
                            )}
                            <>
                              <span>MX$</span>
                              {globalDatosPaquetes[2]?.primaReciboSubsecuente.toLocaleString(
                                "en-US",
                                { maximumFractionDigits: 2 }
                              )}
                            </>
                          </>
                        ) : (
                          <>
                            {descuento && (
                              <div className="descuento">
                                10% DESC. INCLUIDO
                              </div>
                            )}
                            <>
                              <span>MX$</span>
                              {globalDatosPaquetes[2]?.primaAnual.toLocaleString(
                                "en-US",
                                { maximumFractionDigits: 2 }
                              )}
                            </>
                          </>
                        )}
                      </div>
                      <div className="tipo-pago">
                        PAGO {periodicidad !== "Anual" ? "MENSUAL" : "ANUAL"}
                      </div>
                      {periodicidad !== "Anual" ? (
                        <div className="iva-label">
                          O MX$
                          {periodicidad !== "Anual" ? (
                            <>
                              {globalDatosPaquetes[2]?.primaAnual.toLocaleString(
                                "en-US",
                                { maximumFractionDigits: 2 }
                              )}
                            </>
                          ) : (
                            <>
                              {globalDatosPaquetes[2]?.primaAnual.toLocaleString(
                                "en-US",
                                { maximumFractionDigits: 2 }
                              )}
                            </>
                          )}{" "}
                          PAGO ANUAL
                        </div>
                      ) : (
                        <div className="iva-label">IVA INCLUIDO</div>
                      )}
                      <ButtonBlue
                        style={{
                          background: paquetePlus ? "#E5E5E5" : verticalColor,
                          color: paquetePlus ? "#838383" : "#fff",
                        }}
                        disabled={paquetePlus}
                        width="100%"
                        onClick={() => {
                          setEleccion("2");
                          setGlobalPaqueteElegido(globalDatosPaquetes[2]);
                          sessionStorage.setItem(
                            "paqueteElegido",
                            JSON.stringify(globalDatosPaquetes[2])
                          );
                          showNotification({
                            id: "paqueteElegido",
                            title: "Paquete seleccionado",
                            message: "Se agregó paquete a tu resumen",
                            color: "blue",
                            autoClose: 3000,
                            position: "top-right",
                          });
                        }}
                      >
                        ¡LO QUIERO!
                      </ButtonBlue>
                    </>
                  )}
                </div>
              </PackageBody>
            </PackageDiv>
          </div>
        </ColumnaPrincipalContainer>
        <ColumnaSecundariaContainer>
          {!isResponsive && (
            <Steps active={globalTipoCotizador === "externo" ? 1 : 2} />
          )}
          <div className="sticky">
            <SeccionTopLateral theme={colorScheme}>
              <LoadingOverlay
                visible={isLoading}
                radius="16px"
                loader={
                  <Lottie
                    className="lottie"
                    options={defaultOptions}
                    height={120}
                    width={120}
                  />
                }
              />
              <div className="bodyLateral">
                <p className="big-title">Resumen</p>
                <>
                  {eleccion && (
                    <p className="subtitle">
                      {eleccion === "0" && "Plan Hogar esencial"}
                      {eleccion === "1" && "Plan Hogar vital"}
                      {eleccion === "2" && "Plan Hogar plus"}
                    </p>
                  )}
                  <div class="pago-total">
                    <>
                      <p className="subtitle">Tipo de inmueble</p>
                      <p className="subtitle2">
                        {getTipoInmueble(
                          globalDatosAsegurado.datosValuacion?.tipoInmueble
                        ) || "Sin valuación"}
                      </p>
                    </>
                  </div>
                  <div class="pago-total">
                    <p className="subtitle">Suma asegurada</p>
                    <p className="subtitle2">
                      $
                      {globalSumaAsegurada.toLocaleString("en-US", {
                        maximumFractionDigits: 2,
                      }) ||
                        globalDatosAsegurado.datosDelAsegurado?.valorInmueble.toLocaleString(
                          "en-US",
                          {
                            maximumFractionDigits: 2,
                          }
                        )}
                    </p>
                  </div>
                  <div class="pago-total">
                    <p className="subtitle">Perfil del contratante</p>
                    <p className="subtitle2">
                      {getPerfilContratante(
                        globalDatosAsegurado.datosDelAsegurado
                          ?.perfilContratante
                      )}
                    </p>
                  </div>
                </>
                {onlyAnualPayment ? (
                  <MantineSelectStyled
                    rightSection={
                      <img src={chevronDown} alt="flecha abajo" width="20" />
                    }
                    rightSectionWidth={50}
                    styles={{ rightSection: { pointerEvents: "none" } }}
                    defaultValue="Anual"
                    required
                    data={tipoPagoIndividual}
                    placeholder="Tipo de pago"
                    variant="filled"
                    onChange={(value) => setPeriodicidad(value)}
                  />
                ) : (
                  <MantineSelectStyled
                    rightSection={
                      <img src={chevronDown} alt="flecha abajo" width="20" />
                    }
                    rightSectionWidth={50}
                    styles={{ rightSection: { pointerEvents: "none" } }}
                    defaultValue="Mensual"
                    required
                    data={tipoPagoDoble}
                    placeholder="Tipo de pago"
                    variant="filled"
                    onChange={(value) => setPeriodicidad(value)}
                  />
                )}
                <br />
                {eleccion && (
                  <>
                    <div class="pago-total">
                      <p className="subtitle">Pago total</p>
                      {periodicidad === "Anual" ? (
                        <p className="subtitle2">
                          MX${getInfoPaquetesAnual(eleccion)}
                        </p>
                      ) : (
                        <p className="subtitle2">
                          MX${getInfoPaquetesMensual(eleccion)}
                        </p>
                      )}
                    </div>
                    {periodicidad === "Mensual" && (
                      <>
                        <div class="pago-total">
                          <p className="subtitle">Primer pago</p>
                          <p className="subtitle2">
                            MX${getInfoPrimerRecibo(eleccion)}
                          </p>
                        </div>
                        <div class="pago-total">
                          <p className="subtitle">11 pagos subsecuentes de:</p>
                          <p className="subtitle2">
                            MX${getInfoPagoSubsecuente(eleccion)}
                          </p>
                        </div>
                      </>
                    )}
                    {descuento && (
                      <>
                        <div className="descuento">10% DESC. INCLUIDO</div>
                        <br />
                      </>
                    )}
                  </>
                )}
                {paqueteElegidoError && (
                  <span className="error">{errores.paqueteElegido}</span>
                )}
                <ButtonPrincipal
                  onClick={() => {
                    handleCoberturas();
                  }}
                >
                  COMPRAR
                </ButtonPrincipal>
                <br />
                <ButtonSecundario
                  theme={colorScheme}
                  onClick={() =>
                    globalTipoCotizador === "externo"
                      ? navigate("/")
                      : navigate(
                          `/interno?token=${globalTokenParams}&deal_id=${globalDealId}`
                        )
                  }
                >
                  REGRESAR
                </ButtonSecundario>
              </div>
            </SeccionTopLateral>
            <p className="texto-abajo">
              <a
                href="https://woowtodobien.com/terminos-y-condiciones"
                target="_blank"
                rel="noreferrer"
              >
                Términos y Condiciones
              </a>
              <span>y</span>
              <a
                href="https://woowtodobien.com/aviso-de-privacidad"
                target="_blank"
                rel="noreferrer"
              >
                Aviso de Privacidad
              </a>
            </p>
          </div>
        </ColumnaSecundariaContainer>
      </ComparadorBody>
      {!isResponsive && (
        <div class="contenedor-div">
          <a
            href="https://wa.me/5215585327747"
            target="_blank"
            rel="noreferrer"
          >
            <img
              class="mi-imagen-abajo-derecha"
              src="https://21884627.fs1.hubspotusercontent-na1.net/hubfs/21884627/whatsapp_woow.svg"
              alt="Whatsapp"
              width="60px"
            />
          </a>
        </div>
      )}
    </ComparadorContainer>
  );
};

const ComparadorContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: ${(theme) => {
    return theme.theme === "dark" ? "#1A1B1E" : "#f5f5f5";
  }};
  position: relative;
  height: 1000px;
  min-height: 100vh;

  .contenedor-div {
    position: absolute;
  }

  .mi-imagen-abajo-derecha {
    position: fixed;
    bottom: 15px;
    right: 90px;
    z-index: 1000 !important;
  }

  .widget-app-container,
  .Application__WidgetAppContainer-sc-1f2l0a1-0,
  .dvBAhE {
    z-index: 99px !important;
  }

  @media (max-width: 992px) {
    .mi-imagen-abajo-derecha {
      position: fixed;
      bottom: 9rem;
      right: 15px;
      z-index: 1000 !important;
    }
    div#hubspot-messages-iframe-container {
      bottom: 55px !important;
    }

    div#hubspot-messages-iframe-container {
      bottom: 55px !important;
      right: 20px !important;
    }
  }

  @media (max-width: 1020px) {
    padding: 120px 24px 24px;
  }

  .header-image {
    width: 1400px;
    @media (max-width: 1020px) {
      display: none;
    }
  }

  .nav {
    width: 100%;
    max-width: 1150px;
    font-size: 0.9rem;

    .actual {
      color: #037cbf;
      font-weight: 700;
    }
  }

  .error {
    color: red;
    font-size: 14px;
    margin-left: 10px;
  }

  .mantine-Tooltip-tooltip {
    background: #d9d9d9;
    color: #4e4e4e;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 15px;
    .bold {
      font-weight: 600;
      font-size: 12px;
    }
    ul {
      padding-inline-start: 30px;
    }
    @media (max-width: 1020px) {
      width: 100%;
      white-space: normal;
      ul {
        padding-inline-start: 10px;
      }
    }
  }
`;

const ComparadorBody = styled.div`
  display: flex;
  width: 100%;
  max-width: 1370px;
  @media (max-width: 1020px) {
    flex-direction: column;
  }
`;

const ColumnaPrincipalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 70%;
  max-width: 1250px;

  @media (max-width: 1020px) {
    width: 100%;
  }

  .breadcrumbs {
    width: 100%;
    display: flex;
    align-self: flex-start;
    margin-bottom: 24px;
  }

  .paquetes {
    display: flex;
    width: 100%;
    justify-content: space-between;
    @media (max-width: 1020px) {
      flex-direction: column;
      gap: 30px;
    }
  }

  .otras {
    color: #4e4e4e;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 24px;
    padding: 40px 0px;
    margin: auto;
  }
`;

const ColumnaSecundariaContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 30%;

  @media (max-width: 1020px) {
    width: 100%;
  }

  .sticky {
    position: sticky;
    top: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .texto-abajo {
    font-weight: 600;
    font-size: 12px;
    line-height: 21px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #4e4e4e;
    a {
      color: #4e4e4e;
      text-decoration-line: underline;
    }
    span {
      margin: 0 5px;
    }
  }
`;
const SeccionTopLateral = styled.div`
  position: relative;
  margin: 20px;
  background-color: ${(theme) => {
    return theme.theme === "dark" ? "#343434" : "#fff";
  }};
  box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  padding: 27px 15px 15px;
  box-sizing: border-box;
  font-size: 1.1rem;
  text-align: center;
  width: 76%;
  text-align: left;

  @media (max-width: 1020px) {
    width: 100%;
  }

  .descuento {
    color: #f86374;
    text-align: right;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px; /* 150% */
  }

  .big-title {
    font-weight: 700;
    font-size: 18px;
    line-height: 27px;
    color: ${(theme) => {
      return theme.theme === "dark" ? "#fff" : "#4e4e4e";
    }};
  }

  .subtitle {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: ${(theme) => {
      return theme.theme === "dark" ? "#fff" : "#4e4e4e";
    }};
  }

  .subtitle2 {
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: ${(theme) => {
      return theme.theme === "dark" ? "#fff" : "#4e4e4e";
    }};
  }

  .resumen {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    text-align: right;
    color: ${(theme) => {
      return theme.theme === "dark" ? "#fff" : "#4e4e4e";
    }};
    text-align: left;
  }

  .pago-total {
    display: flex;
    justify-content: space-between;
  }
`;

const Title = styled.span`
  padding: 0;
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  text-align: left;
`;

const ButtonPrincipal = styled.div`
  padding: 15px 0;
  border-radius: 16px;
  width: 100%;
  background: linear-gradient(312.79deg, #36405f 5.82%, #536291 89.32%);
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: #ffffff;
  &:hover {
    cursor: pointer;
  }
`;

const ButtonSecundario = styled.div`
  padding: 15px 0;
  border-radius: 16px;
  width: 100%;
  background: ${(theme) => {
    return theme.theme === "dark" ? "transparent" : "#e5e5e5";
  }};
  font-weight: ${(theme) => {
    return theme.theme === "dark" ? 600 : 400;
  }};
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  border: ${(theme) => {
    return theme.theme === "dark" ? "2px solid#536291" : "transparent";
  }};
  color: ${(theme) => {
    return theme.theme === "dark" ? "#536291" : "#838383";
  }};
  &:hover {
    cursor: pointer;
  }
`;

const ButtonBlue = styled.button`
  font-family: "Montserrat";
  letter-spacing: 0.08em;
  width: 100%;
  padding: 15px 0;
  background: linear-gradient(312.79deg, #36405f 5.82%, #536291 89.32%);
  background-blend-mode: color-burn, normal;
  border-radius: 16px;
  border: 1px transparent;
  color: #fff;
  font-weight: 400;
  text-align: center;
  &:hover {
    cursor: pointer;
  }
`;

const PackageDiv = styled.div`
  box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.15);
  border-radius: 16px;
  height: 620px;
  width: 30%;
  @media (max-width: 1020px) {
    width: 100%;
  }
`;

const PackageHeader = styled.div`
  background: radial-gradient(
      98.82% 3135.89% at 100% 76.67%,
      #808083 0%,
      rgba(229, 230, 231, 0) 100%
    ),
    #536291;
  background-blend-mode: multiply, normal;
  border-radius: 16px 16px 0px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 22px 0;

  img {
    width: 90px;
    height: 49px;
    margin-bottom: 10px;
  }
`;

const PackageBody = styled.div`
  padding: 9px 26px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  height: 460px;
  background: ${(theme) => {
    return theme.theme === "dark" ? "#343434" : "#fff";
  }};

  .bottom-package {
    width: 100%;
    text-align: center;
  }

  .total-paquete {
    font-weight: 600;
    font-size: 24px;
    line-height: 17px;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    color: #536291;

    .descuento {
      color: #f86374;
      text-align: center;
      font-size: 14px;
      font-weight: 400;
      line-height: 21px; /* 150% */
      margin-bottom: 10px;
    }
  }
  .tipo-pago {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    color: ${(theme) => {
      return theme.theme === "dark" ? "#fff" : "#4e4e4e";
    }};
  }
  .iva-label {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    color: ${(theme) => {
      return theme.theme === "dark" ? "#fff" : "#4e4e4e";
    }};
    margin-bottom: 20px;
  }

  span {
    font-size: 0.875rem;
  }
  & .list {
    display: flex;
    flex-direction: column;
    .paquete-description {
      font-weight: 400;
      font-size: 14px;
      line-height: 25px;
      color: ${(theme) => {
        return theme.theme === "dark" ? "#fff" : "#4e4e4e";
      }};
      padding-bottom: 20px;
    }
    & span {
      display: flex;
      padding-bottom: 10px;
      align-items: center;

      .question {
        width: 12px;
        height: 12px;
        color: #fff;
        background: ${(theme) => {
          return theme.theme === "dark" ? "#000" : "#c1c1c1";
        }};
        text-align: center;
        border-radius: 20px;
        font-weight: 600;
        font-size: 10px;
        line-height: 12px;
        margin-left: 10px;
        :hover {
          cursor: pointer;
        }
      }

      & img {
        padding-right: 7px;
      }
    }
  }
`;

const MantineSelectStyled = styled(Select)`
  .mantine-Select-filledVariant {
    height: 50px;
    border-radius: 16px;
    background-color: #f5f5f5;
  }
  width: ${(props) => props.width || "auto"};

  .mantine-Select-root {
  }
`;

const MantineStyledTooltip = styled(Tooltip)`
  .mantine-Tooltip-body {
    color: #000;
    background-color: #f5f5f5;
    font-size: 10px;
    line-height: 14px;
    font-weight: 400;
    width: 300px;
    padding: 10px;
    .bold {
      font-weight: 600;
    }
  }
`;
