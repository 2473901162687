import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Modal, LoadingOverlay, useMantineColorScheme } from "@mantine/core";
import Lottie from "react-lottie";
import woowLoadingHogar from "../../assets/woow-loading-hogar.json";
import { getPerfilContratante } from "../../constants/constants";
import { useNavigate } from "react-router-dom";
import useStore from "../../useStore";
import deleteIcon from "../../assets/delete.svg";
import { eliminarCotizacionesPendientes } from "../../services/api";

const CotizacionPendiente = ({
  openModal,
  onCloseModal,
  dataCotizacionesPendientes,
  sinCotizacionesPendientes,
  catalogos
}) => {
  const { colorScheme } = useMantineColorScheme();
  const {
    setGlobalCatalogos,
    setGlobalPaquetesFactorLinea,
    setGlobalSumaAsegurada,
    setGlobalAlarmaLocal,
    setGlobalIdCotizacion,
    setGlobalDatosRecotizacion,
    setGlobalPaqueteElegido,
    setglobalDatosAsegurado,
    globalUsuarioInterno,
    setGlobalNuevoCosto,
    setGlobalCoberturasExtras,
    setGlobalCoberturasPaqueteElegido,
    setGlobalTipoPago,
    setGlobalDatosEmision,
    setGlobalIdDatosEmitir,
    setGlobalDatosPaquetes,
    setGlobalDatosCoberturas,
    globalTokenCotizacion,
  } = useStore();
  let navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingDeleteCotizaciones, setIsLoadingDeleteCotizaciones] =
    useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const breakpoint = 1020;
  const isResponsive = width < breakpoint;

  useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleWindowResize);

    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: woowLoadingHogar,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const getPaqueteContratado = (item, nombre) => {
    switch (nombre) {
      case "Hogar Esencial":
        return item?.respCotizacionJson.calculo.paquetes[0];
      case "Hogar Vital":
        return item?.respCotizacionJson.calculo.paquetes[1];
      case "Hogar Plus":
        return item?.respCotizacionJson.calculo.paquetes[2];
      default:
        return item?.respCotizacionJson.calculo.paquetes[0];
    }
  };

  const getCostoTotal = (lastItem, item, nombre) => {
    switch (nombre) {
      case "Hogar Esencial":
        return lastItem?.metodoPago === 1
          ? item?.respCotizacion[0]?.primaAnual
          : item?.respCotizacion[0]?.primaTotal;
      case "Hogar Vital":
        return lastItem?.metodoPago === 1
          ? item?.respCotizacion[1]?.primaAnual
          : item?.respCotizacion[1]?.primaTotal;
      case "Hogar Plus":
        return lastItem?.metodoPago === 1
          ? item?.respCotizacion[2]?.primaAnual
          : item?.respCotizacion[2]?.primaTotal;
      default:
        return item?.respCotizacion[0]?.primaAnual;
    }
  };

  const handleEmision = (
    item,
    lastItem,
    lastItemCoberturasExtra,
    firstItemCoberturasExtra,
    coberturasLenght
  ) => {
    if (item?.recotizaciones?.length === 0) {
      const datosDelAsegurado = {
        codigoPostal: item.cotizacion?.codigoPostal,
        email: globalUsuarioInterno?.email,
        esLaguna: item.cotizacion?.esLagoLaguna?.toString(),
        esPlaya: item.cotizacion?.esLineaPlaya?.toString(),
        medidasDeSeguridad: item.cotizacion?.montoDescMedidaSeg,
        nombre: globalUsuarioInterno?.nombre,
        perfilContratante: item.cotizacion?.perfilContratante?.toString(),
        telefono: globalUsuarioInterno?.celular,
        valorInmueble: item.cotizacion?.valorInmueble,
      };
      setglobalDatosAsegurado({ datosDelAsegurado });
      setGlobalSumaAsegurada(item.cotizacion?.valorInmueble);

      const paqueteEsencial = item?.respCotizacionJson.calculo.paquetes[0];
      const paqueteVital = item?.respCotizacionJson.calculo.paquetes[1];
      const paquetePlus = item?.respCotizacionJson.calculo.paquetes[2];
      setGlobalDatosPaquetes([paqueteEsencial, paqueteVital, paquetePlus]);
      setGlobalPaquetesFactorLinea(item.respCotizacionJson.neo?.paquetes);
      const idCotizacion = item.cotizacion?.id;
      setGlobalIdCotizacion({ idCotizacion });

      const datosConfiguracion =
        item.respCotizacionJson.contratoCotizacion.datosConfiguracion;
      const paquetes = item.respCotizacionJson.neo.paquetes[2].coberturas;

      const datosInmueble = {
        multiclausula:
          item.respCotizacionJson.contratoCotizacion.datosInmueble
            .multiclausula,
        codigoPostalInmueble:
          item.respCotizacionJson.contratoCotizacion.datosInmueble
            .codigoPostalInmueble,
        codigoPostalContratante:
          item.respCotizacionJson.contratoCotizacion.datosInmueble
            .codigoPostalContratante,
        esConstruccionMaciza:
          item.respCotizacionJson.contratoCotizacion.datosInmueble
            .esConstruccionMaciza,
        idTipoMoneda:
          item.respCotizacionJson.contratoCotizacion.datosInmueble.idTipoMoneda,
        esLineaPlaya: item.cotizacion?.esLineaPlaya?.toString(),
        esLagoLaguna: item.cotizacion?.esLagoLaguna?.toString(),
        idPeriodicidadPago:
          item.respCotizacionJson.contratoCotizacion.datosInmueble
            .idPeriodicidadPago,
        valorTipo:
          item.respCotizacionJson.contratoCotizacion.datosInmueble.valorTipo,
      };
      setGlobalDatosRecotizacion([datosConfiguracion, datosInmueble, paquetes]);
      const dataLocalStorage = {
        cotizacionPrincipal: datosDelAsegurado,
        sumaAsegurada: datosDelAsegurado.valorInmueble,
        datosRecotizacion: [datosConfiguracion, datosInmueble, paquetes],
        idCotizacion: idCotizacion,
        paquetesFactorLinea: item?.respCotizacionJson.neo.paquetes,
        datosPaquetes: [paqueteEsencial, paqueteVital, paquetePlus],
        tipoCotizador: "interno",
      };
      sessionStorage.setItem("cotizacion", JSON.stringify(dataLocalStorage));
      navigate("/cotizar");
      onCloseModal();
    }
    if (
      coberturasLenght === 1 ||
      firstItemCoberturasExtra?.length === lastItemCoberturasExtra
    ) {
      const datosDelAsegurado = {
        codigoPostal: item.cotizacion?.codigoPostal,
        email: globalUsuarioInterno?.email,
        esLaguna: item.cotizacion?.esLagoLaguna?.toString(),
        esPlaya: item.cotizacion?.esLineaPlaya?.toString(),
        medidasDeSeguridad: item.cotizacion?.montoDescMedidaSeg,
        nombre: globalUsuarioInterno?.nombre,
        perfilContratante: item.cotizacion?.perfilContratante?.toString(),
        telefono: globalUsuarioInterno?.celular,
        valorInmueble: item.cotizacion?.valorInmueble,
      };

      const pagoTotal = getCostoTotal(lastItem, item, lastItem?.nombre);

      const nuevoCosto = { pagoTotal };

      setGlobalTipoPago(lastItem?.metodoPago === 1 ? "Anual" : "Menual");

      setglobalDatosAsegurado({ datosDelAsegurado });
      setGlobalNuevoCosto(nuevoCosto);
      setGlobalPaquetesFactorLinea(item.respCotizacionJson.neo?.paquetes);
      setGlobalSumaAsegurada(item.cotizacion?.valorInmueble);
      setGlobalAlarmaLocal(
        item.cotizacion?.montoDescMedidaSeg === "7.50" ? 1 : 0
      );
      const idCotizacion = item.cotizacion?.id;
      setGlobalIdCotizacion({ idCotizacion });

      const datosConfiguracion =
        item.respCotizacionJson.contratoCotizacion.datosConfiguracion;
      const paquetes = item.respCotizacionJson.neo.paquetes[2].coberturas;

      const datosInmueble = {
        multiclausula:
          item.respCotizacionJson.contratoCotizacion.datosInmueble
            .multiclausula,
        codigoPostalInmueble:
          item.respCotizacionJson.contratoCotizacion.datosInmueble
            .codigoPostalInmueble,
        codigoPostalContratante:
          item.respCotizacionJson.contratoCotizacion.datosInmueble
            .codigoPostalContratante,
        esConstruccionMaciza:
          item.respCotizacionJson.contratoCotizacion.datosInmueble
            .esConstruccionMaciza,
        idTipoMoneda:
          item.respCotizacionJson.contratoCotizacion.datosInmueble.idTipoMoneda,
        esLineaPlaya: item.cotizacion?.esLineaPlaya?.toString(),
        esLagoLaguna: item.cotizacion?.esLagoLaguna?.toString(),
        idPeriodicidadPago:
          item.respCotizacionJson.contratoCotizacion.datosInmueble
            .idPeriodicidadPago,
        valorTipo:
          item.respCotizacionJson.contratoCotizacion.datosInmueble.valorTipo,
      };
      const paqueteEsencial = item?.respCotizacionJson.calculo?.paquetes[0];
      const paqueteVital = item?.respCotizacionJson.calculo?.paquetes[1];
      const paquetePlus = item?.respCotizacionJson.calculo?.paquetes[2];
      setGlobalDatosPaquetes([paqueteEsencial, paqueteVital, paquetePlus]);
      setGlobalDatosRecotizacion([datosConfiguracion, datosInmueble, paquetes]);
      setGlobalPaqueteElegido(getPaqueteContratado(item, lastItem?.nombre));
      const datosCoberturasPaquete = {
        coberturas: firstItemCoberturasExtra,
      };
      setGlobalDatosCoberturas([datosCoberturasPaquete]);
      navigate("/emision");
      onCloseModal();
    } else {
      setGlobalPaqueteElegido(getPaqueteContratado(item, lastItem?.nombre));
      const datosCoberturasPaquete = {
        coberturas: firstItemCoberturasExtra,
      };
      setGlobalDatosCoberturas([datosCoberturasPaquete]);
      const filteredCoberturasExtras = lastItemCoberturasExtra.filter(
        (item) =>
          item.idCobertura === "0000001325" ||
          item.idCobertura === "0000001324" ||
          item.idCobertura === "0000000866" ||
          item.idCobertura === "0000000869" ||
          item.idCobertura === "0000001476"
      );
      setGlobalCoberturasExtras(filteredCoberturasExtras);

      if (lastItem?.nombre === "Hogar Esencial") {
        setGlobalCoberturasPaqueteElegido(
          item?.respCotizacionJson.calculo?.paquetes[0]?.coberturas
        );
      } else if (lastItem?.nombre === "Hogar Vital") {
        setGlobalCoberturasPaqueteElegido(
          item?.respCotizacionJson.calculo?.paquetes[1]?.coberturas
        );
      } else if (lastItem?.nombre === "Hogar Plus") {
        setGlobalCoberturasPaqueteElegido(
          item?.respCotizacionJson.calculo?.paquetes[2]?.coberturas
        );
      }

      const pagoTotal =
        lastItem?.metodoPago === 1
          ? lastItem?.primaAnual
          : lastItem?.primaTotal;
      const primerRecibo = lastItem?.primaPrimerRecibo;
      const reciboSubsecuente = lastItem?.primaReciboSubsecuente;
      const nuevoCosto = { pagoTotal, primerRecibo, reciboSubsecuente };

      setGlobalNuevoCosto(nuevoCosto);
      const datosDelAsegurado = {
        codigoPostal: item.cotizacion?.codigoPostal,
        email: globalUsuarioInterno?.email,
        esLaguna: item.cotizacion?.esLagoLaguna?.toString(),
        esPlaya: item.cotizacion?.esLineaPlaya?.toString(),
        medidasDeSeguridad: item.cotizacion?.montoDescMedidaSeg,
        nombre: globalUsuarioInterno?.nombre,
        perfilContratante: item.cotizacion?.perfilContratante?.toString(),
        telefono: globalUsuarioInterno?.celular,
        valorInmueble: item.cotizacion?.valorInmueble,
      };
      setglobalDatosAsegurado({ datosDelAsegurado });
      setglobalDatosAsegurado({ datosDelAsegurado });
      setGlobalPaquetesFactorLinea(item.respCotizacionJson.neo?.paquetes);
      setGlobalSumaAsegurada(item.cotizacion?.valorInmueble);
      setGlobalAlarmaLocal(
        item.cotizacion?.montoDescMedidaSeg === "7.50" ? 1 : 0
      );
      const idCotizacion = item.cotizacion?.id;
      setGlobalIdCotizacion({ idCotizacion });
      const datosConfiguracion =
        item.respCotizacionJson.contratoCotizacion.datosConfiguracion;
      const paquetes = item.respCotizacionJson.neo.paquetes[2].coberturas;

      const datosInmueble = {
        multiclausula:
          item.respCotizacionJson.contratoCotizacion.datosInmueble
            .multiclausula,
        codigoPostalInmueble:
          item.respCotizacionJson.contratoCotizacion.datosInmueble
            .codigoPostalInmueble,
        codigoPostalContratante:
          item.respCotizacionJson.contratoCotizacion.datosInmueble
            .codigoPostalContratante,
        esConstruccionMaciza:
          item.respCotizacionJson.contratoCotizacion.datosInmueble
            .esConstruccionMaciza,
        idTipoMoneda:
          item.respCotizacionJson.contratoCotizacion.datosInmueble.idTipoMoneda,
        esLineaPlaya: item.cotizacion?.esLineaPlaya?.toString(),
        esLagoLaguna: item.cotizacion?.esLagoLaguna?.toString(),
        idPeriodicidadPago:
          item.respCotizacionJson.contratoCotizacion.datosInmueble
            .idPeriodicidadPago,
        valorTipo:
          item.respCotizacionJson.contratoCotizacion.datosInmueble.valorTipo,
      };
      const paqueteEsencial = item?.respCotizacionJson.calculo?.paquetes[0];
      const paqueteVital = item?.respCotizacionJson.calculo?.paquetes[1];
      const paquetePlus = item?.respCotizacionJson.calculo?.paquetes[2];
      setGlobalDatosPaquetes([paqueteEsencial, paqueteVital, paquetePlus]);
      setGlobalDatosRecotizacion([datosConfiguracion, datosInmueble, paquetes]);
      setGlobalTipoPago(lastItem?.metodoPago === 1 ? "Anual" : "Menual");
      navigate("/emision");
      onCloseModal();
    }
    setGlobalCatalogos(catalogos);
    sessionStorage.setItem(
      "globalCatalogos",
      JSON.stringify(catalogos)
    );
  };

  const handlePago = (
    item,
    lastItem,
    lastItemCoberturasExtra,
    firstItemCoberturasExtra,
    coberturasLenght
  ) => {
    if (
      coberturasLenght === 1 ||
      firstItemCoberturasExtra?.length === lastItemCoberturasExtra
    ) {
      const datosDelAsegurado = {
        codigoPostal: item.cotizacion?.codigoPostal,
        email: globalUsuarioInterno?.email,
        esLaguna: item.cotizacion?.esLagoLaguna?.toString(),
        esPlaya: item.cotizacion?.esLineaPlaya?.toString(),
        medidasDeSeguridad: item.cotizacion?.montoDescMedidaSeg,
        nombre: globalUsuarioInterno?.nombre,
        perfilContratante: item.cotizacion?.perfilContratante?.toString(),
        telefono: globalUsuarioInterno?.celular,
        valorInmueble: item.cotizacion?.valorInmueble,
      };

      const pagoTotal = getCostoTotal(lastItem, item, lastItem?.nombre);

      const nuevoCosto = { pagoTotal };

      setGlobalTipoPago(lastItem?.metodoPago === 1 ? "Anual" : "Menual");

      setglobalDatosAsegurado({ datosDelAsegurado });
      setGlobalNuevoCosto(nuevoCosto);
      setGlobalPaquetesFactorLinea(item.respCotizacionJson.neo?.paquetes);
      setGlobalSumaAsegurada(item.cotizacion?.valorInmueble);
      setGlobalAlarmaLocal(
        item.cotizacion?.montoDescMedidaSeg === "7.50" ? 1 : 0
      );
      const idCotizacion = item.cotizacion?.id;
      setGlobalIdCotizacion({ idCotizacion });

      const datosConfiguracion =
        item.respCotizacionJson.contratoCotizacion.datosConfiguracion;
      const paquetes = item.respCotizacionJson.neo.paquetes[2].coberturas;

      const datosInmueble = {
        multiclausula:
          item.respCotizacionJson.contratoCotizacion.datosInmueble
            .multiclausula,
        codigoPostalInmueble:
          item.respCotizacionJson.contratoCotizacion.datosInmueble
            .codigoPostalInmueble,
        codigoPostalContratante:
          item.respCotizacionJson.contratoCotizacion.datosInmueble
            .codigoPostalContratante,
        esConstruccionMaciza:
          item.respCotizacionJson.contratoCotizacion.datosInmueble
            .esConstruccionMaciza,
        idTipoMoneda:
          item.respCotizacionJson.contratoCotizacion.datosInmueble.idTipoMoneda,
        esLineaPlaya: item.cotizacion?.esLineaPlaya?.toString(),
        esLagoLaguna: item.cotizacion?.esLagoLaguna?.toString(),
        idPeriodicidadPago:
          item.respCotizacionJson.contratoCotizacion.datosInmueble
            .idPeriodicidadPago,
        valorTipo:
          item.respCotizacionJson.contratoCotizacion.datosInmueble.valorTipo,
      };
      setGlobalDatosRecotizacion([datosConfiguracion, datosInmueble, paquetes]);
      setGlobalPaqueteElegido(getPaqueteContratado(item, lastItem?.nombre));
      const datosCoberturasPaquete = {
        coberturas: firstItemCoberturasExtra,
      };
      setGlobalDatosCoberturas([datosCoberturasPaquete]);

      // mapeo coberturas emision

      const paquetesFactorLinea = item.respCotizacionJson.neo?.paquetes;
      const getPaqueteFactorLinea = (nombre) => {
        switch (nombre) {
          case "Hogar Esencial":
            return paquetesFactorLinea[0]["coberturas"];
          case "Hogar Vital":
            return paquetesFactorLinea[1]["coberturas"];
          case "Hogar Plus":
            return paquetesFactorLinea[2]["coberturas"];
          default:
            return paquetesFactorLinea[0]["coberturas"];
        }
      };

      const paqueteElegidoFactorLinea = Object.keys(
        getPaqueteFactorLinea(lastItem?.nombre)
      ).map(function (key) {
        return getPaqueteFactorLinea(lastItem?.nombre)[key];
      });

      const paqueteContratado = getPaqueteContratado(item, lastItem?.nombre);

      const coberturasPaquete = paqueteContratado?.coberturas;

      /* paso 1 meter nuevo valor de factorLinea*/
      const newArrCoberturas = coberturasPaquete.map((v) => ({
        ...v,
        factorLinea: [],
      }));

      newArrCoberturas.forEach(
        (node) =>
          (node.factorLinea = node.factorLinea.concat(
            paqueteElegidoFactorLinea
              .filter((item) => item.idCobertura === node.codigoInfo)
              .map((item) => item.factorLinea)
          ))
      );

      const filteredResponsabilidad = newArrCoberturas.filter(
        (item) =>
          item?.codigoInfo === "0000000878" || item?.codigoInfo === "0000000870"
      );

      const bodyResponsabilidad = filteredResponsabilidad.map((item) => {
        if (item.codigoInfo === "0000000870") {
          return {
            codigoCobertura: item?.codigoInfo,
            elementosOpcionales: [
              {
                nombreElemento: "cuota",
                valorAlfanumerico: "",
                valorNumerico: item?.cuota,
              },
              {
                nombreElemento: "recargoTipoConstructivo",
                valorAlfanumerico: "",
                valorNumerico: item?.recargoTipoConstructivo || 0,
              },
              {
                nombreElemento: "sumaAsegurada",
                valorAlfanumerico: "",
                valorNumerico: item?.sumaAsegurada,
              },
              {
                nombreElemento: "factorLinea",
                valorAlfanumerico: "",
                valorNumerico: item?.factorLinea[0],
              },
              {
                nombreElemento: "factorRenovacion",
                valorAlfanumerico: "",
                valorNumerico: item?.factorRenovacion || 0,
              },
              {
                nombreElemento: "opcionCalculo",
                valorAlfanumerico: "M",
                valorNumerico: 0,
              },
            ],
          };
        } else {
          return {
            codigoCobertura: item?.codigoInfo,
            elementosOpcionales: [
              {
                nombreElemento: "cuota",
                valorAlfanumerico: "",
                valorNumerico: item?.cuota,
              },
              {
                nombreElemento: "sumaAsegurada",
                valorAlfanumerico: "",
                valorNumerico: item?.sumaAsegurada,
              },
              {
                nombreElemento: "factorLinea",
                valorAlfanumerico: "",
                valorNumerico: item?.factorLinea[0],
              },
              {
                nombreElemento: "factorRenovacion",
                valorAlfanumerico: "",
                valorNumerico: item?.factorRenovacion || 0,
              },
              {
                nombreElemento: "opcionCalculo",
                valorAlfanumerico: "M",
                valorNumerico: 0,
              },
            ],
          };
        }
      });

      const filteredAsistencias = newArrCoberturas.filter(
        (item) =>
          item?.codigoInfo === "0000001477" || item?.codigoInfo === "0000001476"
      );

      const bodyAsistencias = filteredAsistencias.map((item) => {
        return {
          codigoCobertura: item?.codigoInfo,
          elementosOpcionales: [
            {
              nombreElemento: "cuota",
              valorAlfanumerico: "",
              valorNumerico: 0,
            },
            {
              nombreElemento: "prima",
              valorAlfanumerico: "",
              valorNumerico: item?.primaNeta,
            },
            {
              nombreElemento: "sumaAsegurada",
              valorAlfanumerico: "",
              valorNumerico: item?.sumaAsegurada,
            },

            {
              nombreElemento: "opcionCalculo",
              valorAlfanumerico: "M",
              valorNumerico: 0,
            },
          ],
        };
      });

      const filteredCoberturasGenerales = newArrCoberturas.filter((item) => {
        if (
          item.codigoInfo !== "0000000870" &&
          item.codigoInfo !== "0000000878" &&
          item.codigoInfo !== "0000001477" &&
          item.codigoInfo !== "0000001476"
        ) {
          return true;
        } else {
          return false;
        }
      });

      const bodyCoberturasComision = newArrCoberturas.map((item) => {
        return {
          codigoCobertura: item?.codigoInfo,
          porcentajeComision: 0.125,
        };
      });

      const medidaDeSeguridad = Number(item.cotizacion?.montoDescMedidaSeg);

      const bodyCoberturas = filteredCoberturasGenerales.map((item) => {
        if (item.codigoInfo === "0000001441") {
          return {
            codigoCobertura: item?.codigoInfo,
            elementosOpcionales: [
              {
                nombreElemento: "cuota",
                valorAlfanumerico: "",
                valorNumerico: item?.cuota,
              },
              {
                nombreElemento: "sumaAsegurada",
                valorAlfanumerico: "",
                valorNumerico: item?.sumaAsegurada,
              },
              {
                nombreElemento: "unidadDeducible",
                valorAlfanumerico: item?.unidadDeducible,
                valorNumerico: 0,
              },
              {
                nombreElemento: "factorDeducible",
                valorAlfanumerico: "",
                valorNumerico: item?.factorDeducible || 0,
              },
              {
                nombreElemento: "aplicaIndemnizacionReposicion",
                valorAlfanumerico: "",
                valorNumerico: item?.aplicaIndemnizacionReposicion || 0,
              },
              {
                nombreElemento: "recargoIndemnizacionValorReposicion",
                valorAlfanumerico: "",
                valorNumerico: item?.recargoIndemnizacionValorReposicion || 0,
              },
              {
                nombreElemento: "valorDeducible",
                valorAlfanumerico: "",
                valorNumerico: item?.valorDeducible || 0,
              },
              {
                nombreElemento: "factorLinea",
                valorAlfanumerico: "",
                valorNumerico: item?.factorLinea[0],
              },
              {
                nombreElemento: "factorRenovacion",
                valorAlfanumerico: "",
                valorNumerico: item?.factorRenovacion || 0,
              },
              {
                nombreElemento: "opcionCalculo",
                valorAlfanumerico: "M",
                valorNumerico: 0,
              },
            ],
          };
        } else if (item.codigoInfo === "0000001440") {
          return {
            codigoCobertura: item?.codigoInfo,
            elementosOpcionales: [
              {
                nombreElemento: "cuota",
                valorAlfanumerico: "",
                valorNumerico: item?.cuota,
              },
              {
                nombreElemento: "sumaAsegurada",
                valorAlfanumerico: "",
                valorNumerico: item?.sumaAsegurada,
              },
              {
                nombreElemento: "unidadDeducible",
                valorAlfanumerico: item?.unidadDeducible,
                valorNumerico: 0,
              },
              {
                nombreElemento: "valorDeducible",
                valorAlfanumerico: "",
                valorNumerico: item?.valorDeducible || 0,
              },
              {
                nombreElemento: "factorLinea",
                valorAlfanumerico: "",
                valorNumerico: item?.factorLinea[0],
              },
              {
                nombreElemento: "factorRenovacion",
                valorAlfanumerico: "",
                valorNumerico: item?.factorRenovacion || 0,
              },
              {
                nombreElemento: "opcionCalculo",
                valorAlfanumerico: "M",
                valorNumerico: 0,
              },
            ],
          };
        } else if (item.codigoInfo === "0000000877") {
          return {
            codigoCobertura: item?.codigoInfo,
            elementosOpcionales: [
              {
                nombreElemento: "cuota",
                valorAlfanumerico: "",
                valorNumerico: item?.cuota,
              },
              {
                nombreElemento: "sumaAsegurada",
                valorAlfanumerico: "",
                valorNumerico: item?.sumaAsegurada,
              },
              {
                nombreElemento: "aplicaIndemnizacionReposicion",
                valorAlfanumerico: "",
                valorNumerico: 0,
              },
              {
                nombreElemento: "aplicaRoboBienesIntemperie",
                valorAlfanumerico: "",
                valorNumerico: 0,
              },
              {
                nombreElemento: "descuentoMedidasSeguridad",
                valorAlfanumerico: "",
                valorNumerico: 0,
              },
              {
                nombreElemento: "recargoIndemnizacionValorReposicion",
                valorAlfanumerico: "",
                valorNumerico: 0,
              },
              {
                nombreElemento: "recargoBienesIntemperie",
                valorAlfanumerico: "",
                valorNumerico: 0,
              },
              {
                nombreElemento: "unidadDeducible",
                valorAlfanumerico: item?.unidadDeducible,
                valorNumerico: 0,
              },
              {
                nombreElemento: "valorDeducible",
                valorAlfanumerico: "",
                valorNumerico: item?.valorDeducible || 0,
              },
              {
                nombreElemento: "factorLinea",
                valorAlfanumerico: "",
                valorNumerico: item?.factorLinea[0],
              },
              {
                nombreElemento: "factorRenovacion",
                valorAlfanumerico: "",
                valorNumerico: item?.factorRenovacion || 0,
              },
              {
                nombreElemento: "opcionCalculo",
                valorAlfanumerico: "M",
                valorNumerico: 0,
              },
            ],
          };
        } else if (item.codigoInfo === "0000000874") {
          return {
            codigoCobertura: item?.codigoInfo,
            elementosOpcionales: [
              {
                nombreElemento: "cuota",
                valorAlfanumerico: "",
                valorNumerico: item?.cuota,
              },
              {
                nombreElemento: "sumaAsegurada",
                valorAlfanumerico: "",
                valorNumerico: item?.sumaAsegurada,
              },
              {
                nombreElemento: "aplicaIndemnizacionReposicion",
                valorAlfanumerico: "",
                valorNumerico: 0,
              },
              {
                nombreElemento: "aplicaRoboBienesIntemperie",
                valorAlfanumerico: "",
                valorNumerico: 0,
              },
              {
                nombreElemento: "descuentoMedidasSeguridad",
                valorAlfanumerico: "",
                valorNumerico: medidaDeSeguridad,
              },
              {
                nombreElemento: "recargoIndemnizacionValorReposicion",
                valorAlfanumerico: "",
                valorNumerico: 0,
              },
              {
                nombreElemento: "recargoBienesIntemperie",
                valorAlfanumerico: "",
                valorNumerico: 0,
              },
              {
                nombreElemento: "unidadDeducible",
                valorAlfanumerico: item?.unidadDeducible,
                valorNumerico: 0,
              },
              {
                nombreElemento: "valorDeducible",
                valorAlfanumerico: "",
                valorNumerico: item?.valorDeducible || 0,
              },
              {
                nombreElemento: "factorLinea",
                valorAlfanumerico: "",
                valorNumerico: item?.factorLinea[0],
              },
              {
                nombreElemento: "factorRenovacion",
                valorAlfanumerico: "",
                valorNumerico: item?.factorRenovacion || 0,
              },
              {
                nombreElemento: "opcionCalculo",
                valorAlfanumerico: "M",
                valorNumerico: 0,
              },
            ],
          };
        } else if (item.codigoInfo === "0000000871") {
          return {
            codigoCobertura: item?.codigoInfo,
            elementosOpcionales: [
              {
                nombreElemento: "cuota",
                valorAlfanumerico: "",
                valorNumerico: item?.cuota,
              },
              {
                nombreElemento: "sumaAsegurada",
                valorAlfanumerico: "",
                valorNumerico: item?.sumaAsegurada,
              },
              {
                nombreElemento: "recargoTipoConstructivo",
                valorAlfanumerico: "",
                valorNumerico: item?.recargoTipoConstructivo || 0,
              },
              {
                nombreElemento: "factorLinea",
                valorAlfanumerico: "",
                valorNumerico: item?.factorLinea[0],
              },
              {
                nombreElemento: "factorRenovacion",
                valorAlfanumerico: "",
                valorNumerico: item?.factorRenovacion || 0,
              },
              {
                nombreElemento: "opcionCalculo",
                valorAlfanumerico: "M",
                valorNumerico: 0,
              },
            ],
          };
        } else if (
          item.codigoInfo === "0000001325" ||
          item.codigoInfo === "0000001324" ||
          item.codigoInfo === "0000000866" ||
          item.codigoInfo === "0000000869"
        ) {
          return {
            codigoCobertura: item?.codigoInfo,
            elementosOpcionales: [
              {
                nombreElemento: "cuota",
                valorAlfanumerico: "",
                valorNumerico: item?.cuota,
              },
              {
                nombreElemento: "porcentajeCoaseguro",
                valorAlfanumerico: "",
                valorNumerico: item?.porcentajeCoaseguro,
              },
              {
                nombreElemento: "sumaAsegurada",
                valorAlfanumerico: "",
                valorNumerico: item?.sumaAsegurada,
              },
              {
                nombreElemento: "unidadDeducible",
                valorAlfanumerico: item?.unidadDeducible,
                valorNumerico: 0,
              },
              {
                nombreElemento: "valorDeducible",
                valorAlfanumerico: "",
                valorNumerico: item?.valorDeducible,
              },
              {
                nombreElemento: "coaseguroTarifa",
                valorAlfanumerico: "",
                valorNumerico: item?.coaseguroTarifa,
              },
              {
                nombreElemento: "factorLinea",
                valorAlfanumerico: "",
                valorNumerico: item?.factorLinea[0],
              },
              {
                nombreElemento: "factorRenovacion",
                valorAlfanumerico: "",
                valorNumerico: item?.factorRenovacion || 0,
              },
              {
                nombreElemento: "opcionCalculo",
                valorAlfanumerico: "M",
                valorNumerico: 0,
              },
            ],
          };
        } else {
          return {
            codigoCobertura: item?.codigoInfo,
            elementosOpcionales: [
              {
                nombreElemento: "cuota",
                valorAlfanumerico: "",
                valorNumerico: item?.cuota,
              },
              {
                nombreElemento: "sumaAsegurada",
                valorAlfanumerico: "",
                valorNumerico: item?.sumaAsegurada,
              },
              {
                nombreElemento: "recargoTipoConstructivo",
                valorAlfanumerico: "",
                valorNumerico: item?.recargoTipoConstructivo || 0,
              },
              {
                nombreElemento: "unidadDeducible",
                valorAlfanumerico: "",
                valorNumerico: item?.unidadDeducible || 0,
              },
              {
                nombreElemento: "valorDeducible",
                valorAlfanumerico: "",
                valorNumerico: item?.valorDeducible || 0,
              },
              {
                nombreElemento: "factorLinea",
                valorAlfanumerico: "",
                valorNumerico: item?.factorLinea[0],
              },
              {
                nombreElemento: "factorRenovacion",
                valorAlfanumerico: "",
                valorNumerico: item?.factorRenovacion || 0,
              },
              {
                nombreElemento: "opcionCalculo",
                valorAlfanumerico: "M",
                valorNumerico: 0,
              },
            ],
          };
        }
      });

      /// data emisión completa sin coberturas extras
      const data = item?.dataGral[0];
      const dataEmision = {
        idCotizacion: data?.idCotizacion?.toString(),
        moneda: 1,
        costo: Number(data?.costo),
        paqueteContratado: data?.paqueteContratado,
        formaPago: data?.formaPago,
        valorDestructible: Number(data?.valorDestructible),
        indicadorDePropietarioOArrenda: data?.indicadorDePropietarioOArrenda,
        dataCasaAsegurar: {
          calle: data?.calle,
          numExterior: data?.numExterior,
          numInterior: data?.numInterior || "",
          colonia: data?.coloniaa,
          municipio: data?.municipio,
          estado: data?.estado,
          cveColonia: data?.cveColonia,
          cveMunicipio: data?.cveMunicipio,
          cveEstado: data?.cveEstado,
          cpInmueble: data?.cpInmueble,
          cpContratante: data?.cpContratante,
          claseInmueble: data?.claseInmueble,
          metrosConstruidos: data?.metrosConstruidos,
          metrosCuadrados: data?.metrosCuadrados,
          alarmaLocal: data?.alarmaLocal,
          esLineaPlaya: data?.esLineaPlaya,
          esLagoLaguna: data?.esLagoLaguna,
          tipoInmueble: data?.tipoInmueble,
          edad: data?.edad,
        },
        mismoDomicilio: data?.mismoDomicilio,
        dataDomicilioFiscal: {
          calle: data?.calle,
          numExterior: data?.numExterior,
          numInterior: data?.numInterior || "",
          colonia: data?.colonia,
          cveColonia: data?.cveColonia,
          municipio: data?.municipio,
          cveMunicipio: data?.cveMunicipio,
          estado: data?.estado,
          cveEstado: data?.cveEstado,
          cp: data?.cpContratante,
        },
        claveRegimenFiscalSat: data?.claveRegimenFiscalSat,
        razonSocial: data?.razonSocial,
        datosEmisor: {
          soyTitular: data?.soyTitular,
          nombre: data?.nombre,
          aPaterno: data?.aPaterno,
          aMaterno: data?.aMaterno,
          fechaNacimiento: globalUsuarioInterno?.fecha_nacimiento,
          rfc: data?.rfc,
          telefono: data?.telefono,
          cElectronico: data?.cElectronico,
        },
        coberturas: [
          ...bodyCoberturas,
          ...bodyAsistencias,
          ...bodyResponsabilidad,
        ],
        coberturasComision: bodyCoberturasComision,
      };
      setGlobalDatosEmision(dataEmision);
      const emision = {
        idDatosEmitir: data?.idEmitir,
        dataEmision: dataEmision,
        regimenFiscalDescripcion: "",
      };
      sessionStorage.setItem("datosEmision", JSON.stringify(emision));
    } else {
      const datosCoberturasPaquete = {
        coberturas: firstItemCoberturasExtra,
      };
      setGlobalDatosCoberturas([datosCoberturasPaquete]);
      const filteredCoberturasExtras = lastItemCoberturasExtra.filter(
        (item) =>
          item.idCobertura === "0000001325" ||
          item.idCobertura === "0000001324" ||
          item.idCobertura === "0000000866" ||
          item.idCobertura === "0000000869" ||
          item.idCobertura === "0000001476"
      );
      setGlobalCoberturasExtras(filteredCoberturasExtras);

      if (lastItem?.nombre === "Hogar Esencial") {
        setGlobalCoberturasPaqueteElegido(
          item?.respCotizacionJson.calculo?.paquetes[0]?.coberturas
        );
      } else if (lastItem?.nombre === "Hogar Vital") {
        setGlobalCoberturasPaqueteElegido(
          item?.respCotizacionJson.calculo?.paquetes[1]?.coberturas
        );
      } else if (lastItem?.nombre === "Hogar Plus") {
        setGlobalCoberturasPaqueteElegido(
          item?.respCotizacionJson.calculo?.paquetes[2]?.coberturas
        );
      }

      // mapeo coberturas emision

      const getPaqueteElegido = (nombre) => {
        switch (nombre) {
          case "Hogar Esencial":
            return item?.respCotizacionJson.calculo?.paquetes[0]?.coberturas;
          case "Hogar Vital":
            return item?.respCotizacionJson.calculo?.paquetes[1]?.coberturas;
          case "Hogar Plus":
            return item?.respCotizacionJson.calculo?.paquetes[2]?.coberturas;
          default:
            return item?.respCotizacionJson.calculo?.paquetes[0]?.coberturas;
        }
      };

      const paquetesFactorLinea = item.respCotizacionJson.neo?.paquetes;
      const getPaqueteFactorLinea = (nombre) => {
        switch (nombre) {
          case "Hogar Esencial":
            return paquetesFactorLinea[0]["coberturas"];
          case "Hogar Vital":
            return paquetesFactorLinea[1]["coberturas"];
          case "Hogar Plus":
            return paquetesFactorLinea[2]["coberturas"];
          default:
            return paquetesFactorLinea[0]["coberturas"];
        }
      };

      const paqueteElegidoFactorLinea = Object.keys(
        getPaqueteFactorLinea(lastItem?.nombre)
      ).map(function (key) {
        return getPaqueteFactorLinea(lastItem?.nombre)[key];
      });

      const coberturasPaquete = getPaqueteElegido(lastItem?.nombre);

      /* paso 1 meter nuevo valor de factorLinea*/
      const newArrCoberturas = coberturasPaquete.map((v) => ({
        ...v,
        factorLinea: [],
      }));

      newArrCoberturas.forEach(
        (node) =>
          (node.factorLinea = node.factorLinea.concat(
            paqueteElegidoFactorLinea
              .filter((item) => item.idCobertura === node.codigoInfo)
              .map((item) => item.factorLinea)
          ))
      );

      const filteredResponsabilidad = newArrCoberturas.filter(
        (item) =>
          item?.codigoInfo === "0000000878" || item?.codigoInfo === "0000000870"
      );

      const bodyResponsabilidad = filteredResponsabilidad.map((item) => {
        if (item.codigoInfo === "0000000870") {
          return {
            codigoCobertura: item?.codigoInfo,
            elementosOpcionales: [
              {
                nombreElemento: "cuota",
                valorAlfanumerico: "",
                valorNumerico: item?.cuota,
              },
              {
                nombreElemento: "recargoTipoConstructivo",
                valorAlfanumerico: "",
                valorNumerico: item?.recargoTipoConstructivo || 0,
              },
              {
                nombreElemento: "sumaAsegurada",
                valorAlfanumerico: "",
                valorNumerico: item?.sumaAsegurada,
              },
              {
                nombreElemento: "factorLinea",
                valorAlfanumerico: "",
                valorNumerico: item?.factorLinea[0],
              },
              {
                nombreElemento: "factorRenovacion",
                valorAlfanumerico: "",
                valorNumerico: item?.factorRenovacion || 0,
              },
              {
                nombreElemento: "opcionCalculo",
                valorAlfanumerico: "M",
                valorNumerico: 0,
              },
            ],
          };
        } else {
          return {
            codigoCobertura: item?.codigoInfo,
            elementosOpcionales: [
              {
                nombreElemento: "cuota",
                valorAlfanumerico: "",
                valorNumerico: item?.cuota,
              },
              {
                nombreElemento: "sumaAsegurada",
                valorAlfanumerico: "",
                valorNumerico: item?.sumaAsegurada,
              },
              {
                nombreElemento: "factorLinea",
                valorAlfanumerico: "",
                valorNumerico: item?.factorLinea[0],
              },
              {
                nombreElemento: "factorRenovacion",
                valorAlfanumerico: "",
                valorNumerico: item?.factorRenovacion || 0,
              },
              {
                nombreElemento: "opcionCalculo",
                valorAlfanumerico: "M",
                valorNumerico: 0,
              },
            ],
          };
        }
      });

      const filteredAsistencias = newArrCoberturas.filter(
        (item) =>
          item?.codigoInfo === "0000001477" || item?.codigoInfo === "0000001476"
      );

      const bodyAsistencias = filteredAsistencias.map((item) => {
        return {
          codigoCobertura: item?.codigoInfo,
          elementosOpcionales: [
            {
              nombreElemento: "cuota",
              valorAlfanumerico: "",
              valorNumerico: 0,
            },
            {
              nombreElemento: "prima",
              valorAlfanumerico: "",
              valorNumerico: item?.primaNeta,
            },
            {
              nombreElemento: "sumaAsegurada",
              valorAlfanumerico: "",
              valorNumerico: item?.sumaAsegurada,
            },

            {
              nombreElemento: "opcionCalculo",
              valorAlfanumerico: "M",
              valorNumerico: 0,
            },
          ],
        };
      });

      const filteredCoberturasGenerales = newArrCoberturas.filter((item) => {
        if (
          item.codigoInfo !== "0000000870" &&
          item.codigoInfo !== "0000000878" &&
          item.codigoInfo !== "0000001477" &&
          item.codigoInfo !== "0000001476"
        ) {
          return true;
        } else {
          return false;
        }
      });

      const bodyCoberturasComision = newArrCoberturas.map((item) => {
        return {
          codigoCobertura: item?.codigoInfo,
          porcentajeComision: 0.125,
        };
      });

      const medidaDeSeguridad = Number(item.cotizacion?.montoDescMedidaSeg);

      const bodyCoberturas = filteredCoberturasGenerales.map((item) => {
        if (item.codigoInfo === "0000001441") {
          return {
            codigoCobertura: item?.codigoInfo,
            elementosOpcionales: [
              {
                nombreElemento: "cuota",
                valorAlfanumerico: "",
                valorNumerico: item?.cuota,
              },
              {
                nombreElemento: "sumaAsegurada",
                valorAlfanumerico: "",
                valorNumerico: item?.sumaAsegurada,
              },
              {
                nombreElemento: "unidadDeducible",
                valorAlfanumerico: item?.unidadDeducible,
                valorNumerico: 0,
              },
              {
                nombreElemento: "factorDeducible",
                valorAlfanumerico: "",
                valorNumerico: item?.factorDeducible || 0,
              },
              {
                nombreElemento: "aplicaIndemnizacionReposicion",
                valorAlfanumerico: "",
                valorNumerico: item?.aplicaIndemnizacionReposicion || 0,
              },
              {
                nombreElemento: "recargoIndemnizacionValorReposicion",
                valorAlfanumerico: "",
                valorNumerico: item?.recargoIndemnizacionValorReposicion || 0,
              },
              {
                nombreElemento: "valorDeducible",
                valorAlfanumerico: "",
                valorNumerico: item?.valorDeducible || 0,
              },
              {
                nombreElemento: "factorLinea",
                valorAlfanumerico: "",
                valorNumerico: item?.factorLinea[0],
              },
              {
                nombreElemento: "factorRenovacion",
                valorAlfanumerico: "",
                valorNumerico: item?.factorRenovacion || 0,
              },
              {
                nombreElemento: "opcionCalculo",
                valorAlfanumerico: "M",
                valorNumerico: 0,
              },
            ],
          };
        } else if (item.codigoInfo === "0000001440") {
          return {
            codigoCobertura: item?.codigoInfo,
            elementosOpcionales: [
              {
                nombreElemento: "cuota",
                valorAlfanumerico: "",
                valorNumerico: item?.cuota,
              },
              {
                nombreElemento: "sumaAsegurada",
                valorAlfanumerico: "",
                valorNumerico: item?.sumaAsegurada,
              },
              {
                nombreElemento: "unidadDeducible",
                valorAlfanumerico: item?.unidadDeducible,
                valorNumerico: 0,
              },
              {
                nombreElemento: "valorDeducible",
                valorAlfanumerico: "",
                valorNumerico: item?.valorDeducible || 0,
              },
              {
                nombreElemento: "factorLinea",
                valorAlfanumerico: "",
                valorNumerico: item?.factorLinea[0],
              },
              {
                nombreElemento: "factorRenovacion",
                valorAlfanumerico: "",
                valorNumerico: item?.factorRenovacion || 0,
              },
              {
                nombreElemento: "opcionCalculo",
                valorAlfanumerico: "M",
                valorNumerico: 0,
              },
            ],
          };
        } else if (item.codigoInfo === "0000000877") {
          return {
            codigoCobertura: item?.codigoInfo,
            elementosOpcionales: [
              {
                nombreElemento: "cuota",
                valorAlfanumerico: "",
                valorNumerico: item?.cuota,
              },
              {
                nombreElemento: "sumaAsegurada",
                valorAlfanumerico: "",
                valorNumerico: item?.sumaAsegurada,
              },
              {
                nombreElemento: "aplicaIndemnizacionReposicion",
                valorAlfanumerico: "",
                valorNumerico: 0,
              },
              {
                nombreElemento: "aplicaRoboBienesIntemperie",
                valorAlfanumerico: "",
                valorNumerico: 0,
              },
              {
                nombreElemento: "descuentoMedidasSeguridad",
                valorAlfanumerico: "",
                valorNumerico: 0,
              },
              {
                nombreElemento: "recargoIndemnizacionValorReposicion",
                valorAlfanumerico: "",
                valorNumerico: 0,
              },
              {
                nombreElemento: "recargoBienesIntemperie",
                valorAlfanumerico: "",
                valorNumerico: 0,
              },
              {
                nombreElemento: "unidadDeducible",
                valorAlfanumerico: item?.unidadDeducible,
                valorNumerico: 0,
              },
              {
                nombreElemento: "valorDeducible",
                valorAlfanumerico: "",
                valorNumerico: item?.valorDeducible || 0,
              },
              {
                nombreElemento: "factorLinea",
                valorAlfanumerico: "",
                valorNumerico: item?.factorLinea[0],
              },
              {
                nombreElemento: "factorRenovacion",
                valorAlfanumerico: "",
                valorNumerico: item?.factorRenovacion || 0,
              },
              {
                nombreElemento: "opcionCalculo",
                valorAlfanumerico: "M",
                valorNumerico: 0,
              },
            ],
          };
        } else if (item.codigoInfo === "0000000874") {
          return {
            codigoCobertura: item?.codigoInfo,
            elementosOpcionales: [
              {
                nombreElemento: "cuota",
                valorAlfanumerico: "",
                valorNumerico: item?.cuota,
              },
              {
                nombreElemento: "sumaAsegurada",
                valorAlfanumerico: "",
                valorNumerico: item?.sumaAsegurada,
              },
              {
                nombreElemento: "aplicaIndemnizacionReposicion",
                valorAlfanumerico: "",
                valorNumerico: 0,
              },
              {
                nombreElemento: "aplicaRoboBienesIntemperie",
                valorAlfanumerico: "",
                valorNumerico: 0,
              },
              {
                nombreElemento: "descuentoMedidasSeguridad",
                valorAlfanumerico: "",
                valorNumerico: medidaDeSeguridad,
              },
              {
                nombreElemento: "recargoIndemnizacionValorReposicion",
                valorAlfanumerico: "",
                valorNumerico: 0,
              },
              {
                nombreElemento: "recargoBienesIntemperie",
                valorAlfanumerico: "",
                valorNumerico: 0,
              },
              {
                nombreElemento: "unidadDeducible",
                valorAlfanumerico: item?.unidadDeducible,
                valorNumerico: 0,
              },
              {
                nombreElemento: "valorDeducible",
                valorAlfanumerico: "",
                valorNumerico: item?.valorDeducible || 0,
              },
              {
                nombreElemento: "factorLinea",
                valorAlfanumerico: "",
                valorNumerico: item?.factorLinea[0],
              },
              {
                nombreElemento: "factorRenovacion",
                valorAlfanumerico: "",
                valorNumerico: item?.factorRenovacion || 0,
              },
              {
                nombreElemento: "opcionCalculo",
                valorAlfanumerico: "M",
                valorNumerico: 0,
              },
            ],
          };
        } else if (item.codigoInfo === "0000000871") {
          return {
            codigoCobertura: item?.codigoInfo,
            elementosOpcionales: [
              {
                nombreElemento: "cuota",
                valorAlfanumerico: "",
                valorNumerico: item?.cuota,
              },
              {
                nombreElemento: "sumaAsegurada",
                valorAlfanumerico: "",
                valorNumerico: item?.sumaAsegurada,
              },
              {
                nombreElemento: "recargoTipoConstructivo",
                valorAlfanumerico: "",
                valorNumerico: item?.recargoTipoConstructivo || 0,
              },
              {
                nombreElemento: "factorLinea",
                valorAlfanumerico: "",
                valorNumerico: item?.factorLinea[0],
              },
              {
                nombreElemento: "factorRenovacion",
                valorAlfanumerico: "",
                valorNumerico: item?.factorRenovacion || 0,
              },
              {
                nombreElemento: "opcionCalculo",
                valorAlfanumerico: "M",
                valorNumerico: 0,
              },
            ],
          };
        } else if (
          item.codigoInfo === "0000001325" ||
          item.codigoInfo === "0000001324" ||
          item.codigoInfo === "0000000866" ||
          item.codigoInfo === "0000000869"
        ) {
          return {
            codigoCobertura: item?.codigoInfo,
            elementosOpcionales: [
              {
                nombreElemento: "cuota",
                valorAlfanumerico: "",
                valorNumerico: item?.cuota,
              },
              {
                nombreElemento: "porcentajeCoaseguro",
                valorAlfanumerico: "",
                valorNumerico: item?.porcentajeCoaseguro,
              },
              {
                nombreElemento: "sumaAsegurada",
                valorAlfanumerico: "",
                valorNumerico: item?.sumaAsegurada,
              },
              {
                nombreElemento: "unidadDeducible",
                valorAlfanumerico: item?.unidadDeducible,
                valorNumerico: 0,
              },
              {
                nombreElemento: "valorDeducible",
                valorAlfanumerico: "",
                valorNumerico: item?.valorDeducible,
              },
              {
                nombreElemento: "coaseguroTarifa",
                valorAlfanumerico: "",
                valorNumerico: item?.coaseguroTarifa,
              },
              {
                nombreElemento: "factorLinea",
                valorAlfanumerico: "",
                valorNumerico: item?.factorLinea[0],
              },
              {
                nombreElemento: "factorRenovacion",
                valorAlfanumerico: "",
                valorNumerico: item?.factorRenovacion || 0,
              },
              {
                nombreElemento: "opcionCalculo",
                valorAlfanumerico: "M",
                valorNumerico: 0,
              },
            ],
          };
        } else {
          return {
            codigoCobertura: item?.codigoInfo,
            elementosOpcionales: [
              {
                nombreElemento: "cuota",
                valorAlfanumerico: "",
                valorNumerico: item?.cuota,
              },
              {
                nombreElemento: "sumaAsegurada",
                valorAlfanumerico: "",
                valorNumerico: item?.sumaAsegurada,
              },
              {
                nombreElemento: "recargoTipoConstructivo",
                valorAlfanumerico: "",
                valorNumerico: item?.recargoTipoConstructivo || 0,
              },
              {
                nombreElemento: "unidadDeducible",
                valorAlfanumerico: "",
                valorNumerico: item?.unidadDeducible || 0,
              },
              {
                nombreElemento: "valorDeducible",
                valorAlfanumerico: "",
                valorNumerico: item?.valorDeducible || 0,
              },
              {
                nombreElemento: "factorLinea",
                valorAlfanumerico: "",
                valorNumerico: item?.factorLinea[0],
              },
              {
                nombreElemento: "factorRenovacion",
                valorAlfanumerico: "",
                valorNumerico: item?.factorRenovacion || 0,
              },
              {
                nombreElemento: "opcionCalculo",
                valorAlfanumerico: "M",
                valorNumerico: 0,
              },
            ],
          };
        }
      });

      /// data emisión completa con coberturas extras
      const data = item?.dataGral[0];
      const dataEmision = {
        idCotizacion: data?.idCotizacion?.toString(),
        moneda: 1,
        costo: Number(data?.costo),
        paqueteContratado: data?.paqueteContratado,
        formaPago: data?.formaPago,
        valorDestructible: Number(data?.valorDestructible),
        indicadorDePropietarioOArrenda: data?.indicadorDePropietarioOArrenda,
        dataCasaAsegurar: {
          calle: data?.calle,
          numExterior: data?.numExterior,
          numInterior: data?.numInterior || "",
          colonia: data?.coloniaa,
          municipio: data?.municipio,
          estado: data?.estado,
          cveColonia: data?.cveColonia,
          cveMunicipio: data?.cveMunicipio,
          cveEstado: data?.cveEstado,
          cpInmueble: data?.cpInmueble,
          cpContratante: data?.cpContratante,
          claseInmueble: data?.claseInmueble,
          metrosConstruidos: data?.metrosConstruidos,
          metrosCuadrados: data?.metrosCuadrados,
          alarmaLocal: data?.alarmaLocal,
          esLineaPlaya: data?.esLineaPlaya,
          esLagoLaguna: data?.esLagoLaguna,
          tipoInmueble: data?.tipoInmueble,
          edad: data?.edad,
        },
        mismoDomicilio: data?.mismoDomicilio,
        dataDomicilioFiscal: {
          calle: data?.calle,
          numExterior: data?.numExterior,
          numInterior: data?.numInterior || "",
          colonia: data?.colonia,
          cveColonia: data?.cveColonia,
          municipio: data?.municipio,
          cveMunicipio: data?.cveMunicipio,
          estado: data?.estado,
          cveEstado: data?.cveEstado,
          cp: data?.cpContratante,
        },
        claveRegimenFiscalSat: data?.claveRegimenFiscalSat,
        razonSocial: data?.razonSocial,
        datosEmisor: {
          soyTitular: data?.soyTitular,
          nombre: data?.nombre,
          aPaterno: data?.aPaterno,
          aMaterno: data?.aMaterno,
          fechaNacimiento: globalUsuarioInterno?.fecha_nacimiento,
          rfc: data?.rfc,
          telefono: data?.telefono,
          cElectronico: data?.cElectronico,
        },
        coberturas: [
          ...bodyCoberturas,
          ...bodyAsistencias,
          ...bodyResponsabilidad,
        ],
        coberturasComision: bodyCoberturasComision,
      };
      setGlobalDatosEmision(dataEmision);

      const pagoTotal =
        lastItem?.metodoPago === 1
          ? lastItem?.primaAnual
          : lastItem?.primaTotal;
      const primerRecibo = lastItem?.primaPrimerRecibo;
      const reciboSubsecuente = lastItem?.primaReciboSubsecuente;
      const nuevoCosto = { pagoTotal, primerRecibo, reciboSubsecuente };

      setGlobalNuevoCosto(nuevoCosto);
      const datosDelAsegurado = {
        codigoPostal: item.cotizacion?.codigoPostal,
        email: globalUsuarioInterno?.email,
        esLaguna: item.cotizacion?.esLagoLaguna?.toString(),
        esPlaya: item.cotizacion?.esLineaPlaya?.toString(),
        medidasDeSeguridad: item.cotizacion?.montoDescMedidaSeg,
        nombre: globalUsuarioInterno?.nombre,
        perfilContratante: item.cotizacion?.perfilContratante?.toString(),
        telefono: globalUsuarioInterno?.celular,
        valorInmueble: item.cotizacion?.valorInmueble,
      };
      setglobalDatosAsegurado({ datosDelAsegurado });
      setglobalDatosAsegurado({ datosDelAsegurado });
      setGlobalPaquetesFactorLinea(item.respCotizacionJson.neo?.paquetes);
      setGlobalSumaAsegurada(item.cotizacion?.valorInmueble);
      setGlobalAlarmaLocal(
        item.cotizacion?.montoDescMedidaSeg === "7.50" ? 1 : 0
      );
      const idCotizacion = item.cotizacion?.id;
      setGlobalIdCotizacion({ idCotizacion });
      const datosConfiguracion =
        item.respCotizacionJson.contratoCotizacion.datosConfiguracion;
      const paquetes = item.respCotizacionJson.neo.paquetes[2].coberturas;

      const datosInmueble = {
        multiclausula:
          item.respCotizacionJson.contratoCotizacion.datosInmueble
            .multiclausula,
        codigoPostalInmueble:
          item.respCotizacionJson.contratoCotizacion.datosInmueble
            .codigoPostalInmueble,
        codigoPostalContratante:
          item.respCotizacionJson.contratoCotizacion.datosInmueble
            .codigoPostalContratante,
        esConstruccionMaciza:
          item.respCotizacionJson.contratoCotizacion.datosInmueble
            .esConstruccionMaciza,
        idTipoMoneda:
          item.respCotizacionJson.contratoCotizacion.datosInmueble.idTipoMoneda,
        esLineaPlaya: item.cotizacion?.esLineaPlaya?.toString(),
        esLagoLaguna: item.cotizacion?.esLagoLaguna?.toString(),
        idPeriodicidadPago:
          item.respCotizacionJson.contratoCotizacion.datosInmueble
            .idPeriodicidadPago,
        valorTipo:
          item.respCotizacionJson.contratoCotizacion.datosInmueble.valorTipo,
      };
      setGlobalDatosRecotizacion([datosConfiguracion, datosInmueble, paquetes]);
      setGlobalTipoPago(lastItem?.metodoPago === 1 ? "Anual" : "Menual");
    }

    setGlobalIdDatosEmitir(item.dataGral[0]?.idEmitir);

    navigate("/pago");
    onCloseModal();
  };

  const handleDeleteCotizaciones = () => {
    setIsLoadingDeleteCotizaciones(true);
    const idsCotizaciones = dataCotizacionesPendientes.map(
      (item) => item.cotizacion.id
    );
    const dataCotizaciones = {
      idsCotizacion: idsCotizaciones,
    };
    eliminarCotizacionesPendientes(dataCotizaciones, globalTokenCotizacion)
      .then((response) => {
        if (response) {
          if (response?.data?.success === 1) {
            setTimeout(() => {
              setIsLoadingDeleteCotizaciones(false);
              onCloseModal();
              sinCotizacionesPendientes();
            }, 3000);
          }
        }
      })
      .catch(function (error) {
        setTimeout(() => {
          setIsLoadingDeleteCotizaciones(false);
        }, 3000);
      });
  };

  const cotizacionesPendientesPago =
    dataCotizacionesPendientes > 0 &&
    dataCotizacionesPendientes?.filter(() =>
      dataCotizacionesPendientes?.some(({ dataGral }) => dataGral?.length > 0)
    );

  const cotizacionesPendientesEmision =
    dataCotizacionesPendientes > 0 &&
    dataCotizacionesPendientes?.filter(() =>
      dataCotizacionesPendientes?.some(({ dataGral }) => dataGral?.length === 0)
    );

  return (
    <>
      <Container>
        <MantineStyledModal
          theme={colorScheme}
          size={isResponsive ? "100%" : "60%"}
          withCloseButton
          opened={openModal}
          onClose={onCloseModal}
        >
          <LoadingOverlay
            visible={isLoadingDeleteCotizaciones}
            radius="35px"
            loader={
              <Lottie
                className="lottie"
                options={defaultOptions}
                height={50}
                width={50}
              />
            }
          />
          <Div theme={colorScheme}>
            <div className="title">¡Tienes procesos sin concluir!</div>
            <div className="subtitle">
              La última vez que visitaste <b>WOOW</b> dejaste cotizaciones
              pendientes por concluir, reanúdalas con un clic.
            </div>
            <PendientesDiv theme={colorScheme}>
              {cotizacionesPendientesEmision && (
                <div className="header">
                  Continuar con tu proceso de emisión de póliza
                </div>
              )}
              {dataCotizacionesPendientes?.length &&
                dataCotizacionesPendientes.map((item, i) => {
                  const lastItemCoberturasExtras =
                    item?.recotizacionCoberturasExtra[
                      item.recotizacionCoberturasExtra?.length - 1
                    ];
                  const firstItemCobertuasExtras =
                    item?.recotizacionCoberturasExtra[0];
                  const lastItem =
                    item?.recotizaciones[item.recotizaciones?.length - 1];
                  const cotizacionesLength = item?.recotizaciones?.length;
                  const getPaqueteContratado = (nombre) => {
                    switch (nombre) {
                      case "Hogar Esencial":
                        return lastItem?.metodoPago === 1
                          ? item?.respCotizacion[0]?.primaAnual
                          : item?.respCotizacion[0]?.primaTotal;
                      case "Hogar Vital":
                        return lastItem?.metodoPago === 1
                          ? item?.respCotizacion[1]?.primaAnual
                          : item?.respCotizacion[1]?.primaTotal;
                      case "Hogar Plus":
                        return lastItem?.metodoPago === 1
                          ? item?.respCotizacion[2]?.primaAnual
                          : item?.respCotizacion[2]?.primaTotal;
                      default:
                        return item?.respCotizacion[0]?.primaAnual;
                    }
                  };
                  return (
                    <>
                      {item?.dataGral?.length === 0 && (
                        <>
                          <div className="body">
                            <div className="column">
                              <div className="title-column">
                                {lastItem?.nombre}
                              </div>
                              <div className="subtitle-column">
                                Tipo de inmueble: Departamento
                              </div>
                              <div className="subtitle-column">
                                Suma asegurada: $
                                {item.cotizacion?.valorInmueble?.toLocaleString(
                                  "en-US",
                                  { maximumFractionDigits: 2 }
                                )}
                              </div>
                              <div className="subtitle-column">
                                Perfil del contratante:{" "}
                                {getPerfilContratante(
                                  item.cotizacion?.perfilContratante?.toString()
                                )}
                              </div>
                            </div>
                            <div className="column">
                              <div className="amount">
                                {item?.recotizaciones?.length > 0 ? (
                                  <>
                                    <span>MX</span>${" "}
                                    {cotizacionesLength === 1 ? (
                                      <>
                                        {lastItem?.metodoPago === 1 ? (
                                          <>
                                            {getPaqueteContratado(
                                              lastItem?.nombre
                                            )?.toLocaleString("en-US", {
                                              maximumFractionDigits: 2,
                                            })}
                                          </>
                                        ) : (
                                          <>
                                            {getPaqueteContratado(
                                              lastItem?.nombre
                                            )?.toLocaleString("en-US", {
                                              maximumFractionDigits: 2,
                                            })}
                                          </>
                                        )}
                                      </>
                                    ) : (
                                      <>
                                        {lastItem?.metodoPago === 1
                                          ? lastItem?.primaAnual?.toLocaleString(
                                              "en-US",
                                              {
                                                maximumFractionDigits: 2,
                                              }
                                            )
                                          : lastItem?.primaTotal?.toLocaleString(
                                              "en-US",
                                              {
                                                maximumFractionDigits: 2,
                                              }
                                            )}
                                      </>
                                    )}
                                  </>
                                ) : null}
                              </div>
                              {item?.recotizaciones?.length > 0 ? (
                                <div className="title-column-2">
                                  {lastItem?.metodoPago === 1
                                    ? "Pago total anual"
                                    : "Pago total a meses"}
                                </div>
                              ) : null}
                            </div>
                          </div>
                          <LoadingDiv>
                            <LoadingOverlay
                              visible={isLoading}
                              radius="35px"
                              loader={
                                <Lottie
                                  className="lottie"
                                  options={defaultOptions}
                                  height={50}
                                  width={50}
                                />
                              }
                            />
                            <ButtonContinuar
                              onClick={() => {
                                handleEmision(
                                  item,
                                  lastItem,
                                  lastItemCoberturasExtras,
                                  firstItemCobertuasExtras,
                                  cotizacionesLength
                                );
                              }}
                            >
                              CONTINUAR
                            </ButtonContinuar>
                            <ButtonSecondary
                              theme={colorScheme}
                              onClick={() => {
                                onCloseModal();
                              }}
                            >
                              COMENZAR NUEVO PROCESO
                            </ButtonSecondary>
                          </LoadingDiv>
                        </>
                      )}
                    </>
                  );
                })}
              {cotizacionesPendientesPago && (
                <div className="header">Pendientes de pago</div>
              )}
              {dataCotizacionesPendientes?.length &&
                dataCotizacionesPendientes.map((item, i) => {
                  const lastItemCoberturasExtras =
                    item?.recotizacionCoberturasExtra[
                      item.recotizacionCoberturasExtra?.length - 1
                    ];
                  const firstItemCobertuasExtras =
                    item?.recotizacionCoberturasExtra[0];
                  const lastItem =
                    item?.recotizaciones[item.recotizaciones?.length - 1];
                  const cotizacionesLength = item?.recotizaciones.length;
                  const getPaqueteContratado = (nombre) => {
                    switch (nombre) {
                      case "Hogar Esencial":
                        return lastItem?.metodoPago === 1
                          ? item?.respCotizacion[0]?.primaAnual
                          : item?.respCotizacion[0]?.primaTotal;
                      case "Hogar Vital":
                        return lastItem?.metodoPago === 1
                          ? item?.respCotizacion[1]?.primaAnual
                          : item?.respCotizacion[1]?.primaTotal;
                      case "Hogar Plus":
                        return lastItem?.metodoPago === 1
                          ? item?.respCotizacion[2]?.primaAnual
                          : item?.respCotizacion[2]?.primaTotal;
                      default:
                        return item?.respCotizacion[0]?.primaAnual;
                    }
                  };
                  return (
                    <>
                      {item?.dataGral?.length !== 0 && (
                        <>
                          <div className="body">
                            <div className="column">
                              <div className="title-column">
                                {lastItem?.nombre}
                              </div>
                              <div className="subtitle-column">
                                Tipo de inmueble: Departamento
                              </div>
                              <div className="subtitle-column">
                                Suma asegurada: $
                                {item.cotizacion?.valorInmueble?.toLocaleString(
                                  "en-US",
                                  { maximumFractionDigits: 2 }
                                )}
                              </div>
                              <div className="subtitle-column">
                                Perfil del contratante:{" "}
                                {getPerfilContratante(
                                  item.cotizacion?.perfilContratante?.toString()
                                )}
                              </div>
                            </div>
                            <div className="column">
                              <div className="amount">
                                <span>MX</span>${" "}
                                {cotizacionesLength === 1 ? (
                                  <>
                                    {lastItem?.metodoPago === 1 ? (
                                      <>
                                        {getPaqueteContratado(
                                          lastItem?.nombre
                                        )?.toLocaleString("en-US", {
                                          maximumFractionDigits: 2,
                                        })}
                                      </>
                                    ) : (
                                      <>
                                        {getPaqueteContratado(
                                          lastItem?.nombre
                                        )?.toLocaleString("en-US", {
                                          maximumFractionDigits: 2,
                                        })}
                                      </>
                                    )}
                                  </>
                                ) : (
                                  <>
                                    {lastItem?.metodoPago === 1
                                      ? lastItem?.primaAnual?.toLocaleString(
                                          "en-US",
                                          {
                                            maximumFractionDigits: 2,
                                          }
                                        )
                                      : lastItem?.primaTotal?.toLocaleString(
                                          "en-US",
                                          {
                                            maximumFractionDigits: 2,
                                          }
                                        )}
                                  </>
                                )}
                              </div>
                              <div className="title-column-2">
                                {lastItem?.metodoPago === 1
                                  ? "Pago total anual"
                                  : "Pago total a meses"}
                              </div>
                            </div>
                          </div>
                          <LoadingDiv>
                            <LoadingOverlay
                              visible={isLoading}
                              radius="35px"
                              loader={
                                <Lottie
                                  className="lottie"
                                  options={defaultOptions}
                                  height={50}
                                  width={50}
                                />
                              }
                            />
                            <ButtonContinuar
                              color="#fff"
                              onClick={() => {
                                handlePago(
                                  item,
                                  lastItem,
                                  lastItemCoberturasExtras,
                                  firstItemCobertuasExtras,
                                  cotizacionesLength
                                );
                              }}
                            >
                              CONTINUAR
                            </ButtonContinuar>
                            <ButtonSecondary
                              theme={colorScheme}
                              onClick={() => {
                                onCloseModal();
                              }}
                            >
                              COMENZAR NUEVO PROCESO
                            </ButtonSecondary>
                          </LoadingDiv>
                        </>
                      )}
                    </>
                  );
                })}
            </PendientesDiv>
          </Div>
          <FixedDiv theme={colorScheme}>
            <ButtonDelete
              theme={colorScheme}
              onClick={() => {
                handleDeleteCotizaciones();
              }}
            >
              ELIMINAR HISTORIAL{" "}
              <img src={deleteIcon} alt="eliminar cotizaciones" />
            </ButtonDelete>
          </FixedDiv>
        </MantineStyledModal>
      </Container>
    </>
  );
};

const Container = styled.div``;

const ButtonContinuar = styled.div`
  background: linear-gradient(312.79deg, #36405f 5.82%, #536291 89.32%);
  border-radius: 16px;
  width: 100%;
  padding: 15px 0;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: #ffffff;
  margin-bottom: 20px;
  &:hover {
    cursor: pointer;
  }
`;

const ButtonSecondary = styled.div`
  background: ${(theme) => {
    return theme.theme === "dark" ? "transparent" : "#f5f5f5";
  }};
  border-radius: 16px;
  width: 100%;
  padding: 15px 0;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  border: ${(theme) => {
    return theme.theme === "dark" ? "2px solid#536291" : "transparent";
  }};
  color: ${(theme) => {
    return theme.theme === "dark" ? "#536291" : "#838383";
  }};
  @media (max-width: 1020px) {
    flex-direction: column;
    margin-bottom: 40px;
  }
  &:hover {
    cursor: pointer;
  }
`;

const ButtonDelete = styled.div`
  background: ${(theme) => {
    return theme.theme === "dark" ? "transparent" : "#f5f5f5";
  }};
  border-radius: 16px;
  width: 95%;
  padding: 15px 0;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: ${(theme) => {
    return theme.theme === "dark" ? "#536291" : "#36405f";
  }};
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border: ${(theme) => {
    return theme.theme === "dark" ? "2px solid#536291" : "2px solid #36405f";
  }};
  &:hover {
    cursor: pointer;
  }
`;

const MantineStyledModal = styled(Modal)`
  .mantine-Modal-modal {
    padding: 0;
    border-radius: 18px 18px 0px 0px;
  }
  .mantine-Modal-header {
    margin-right: 0;
    padding: 16px 24px;
    border-radius: 16px 16px 0px 0px;
    background: linear-gradient(312.79deg, #36405f 5.82%, #536291 89.32%);
  }
  .mantine-Modal-close {
    svg {
      color: #fff !important;
      width: 20px;
      height: 20px;
      &:hover {
        background-color: none !important;
      }
    }
  }
`;

const Div = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 10px 70px;
  position: relative;
  .flex-inputs {
    width: 100%;
    display: flex;
    gap: 24px;
    margin-bottom: 24px;
    @media (max-width: 1020px) {
      flex-direction: column;
    }
  }
  .title {
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;
    text-align: center;
    color: ${(theme) => {
      return theme.theme === "dark" ? "#fff" : "#4e4e4e";
    }};
    padding-bottom: 24px;
  }
  .subtitle {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: ${(theme) => {
      return theme.theme === "dark" ? "#fff" : "#4e4e4e";
    }};
    padding-bottom: 48px;
    @media (max-width: 1020px) {
      padding-bottom: 28px;
    }
  }
  .mantine-Checkbox-label {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #4e4e4e;
  }
  .mantine-Anchor-root {
    color: #fff;
    line-height: 24px;
  }

  .login {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-decoration-line: underline;
    color: #536291;
    &:hover {
      cursor: pointer;
    }
  }

  .container {
    width: 100%;
  }

  .error {
    color: red;
    font-size: 14px;
    margin-left: 10px;
  }

  & button {
    margin-top: 20px;
  }
`;

const LoadingDiv = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 20px;
  @media (max-width: 1020px) {
    margin-top: 40px;
    margin-bottom: 30px;
  }
`;

const PendientesDiv = styled.div`
  width: 100%;
  height: 400px;
  overflow: auto;
  @media (max-width: 1020px) {
    height: 300px;
  }
  .header {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 51.3px;
    background: linear-gradient(312.79deg, #36405f 5.82%, #536291 89.32%);
    border-radius: 16px 16px 0px 0px;
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    @media (max-width: 1020px) {
      text-align: center;
      height: 60px;
    }
  }
  .body {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px 100px;
    @media (max-width: 1020px) {
      padding: 16px 10px;
      flex-direction: column;
      gap: 30px;
    }
    background: ${(theme) => {
      return theme.theme === "dark"
        ? "transparent"
        : "rgba(245, 245, 245, 0.5)";
    }};
    .column {
      display: flex;
      flex-direction: column;
      .title-column {
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        color: ${(theme) => {
          return theme.theme === "dark" ? "#fff" : "#4e4e4e";
        }};
      }
      .title-column-2 {
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        color: ${(theme) => {
          return theme.theme === "dark" ? "#fff" : "#4e4e4e";
        }};
        @media (max-width: 1020px) {
          text-align: center;
        }
      }
      .subtitle-column {
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: ${(theme) => {
          return theme.theme === "dark" ? "#fff" : "#4e4e4e";
        }};
      }

      .amount {
        width: 100%;
        font-weight: 600;
        font-size: 24px;
        line-height: 17px;
        text-align: center;
        color: #536291;
        span {
          font-size: 10px;
        }
      }
    }
  }
`;

const FixedDiv = styled.div`
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 0;
  background: ${(theme) => {
    return theme.theme === "dark" ? "transparent" : "#fff";
  }};
`;

export default CotizacionPendiente;
