import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import woowLogo from "../assets/logos/logoWOOW.svg";
import appStore from "../assets/descargas/descarga_appstore.svg";
import googlePlay from "../assets/descargas/descarga_googleplay.svg";
import { stagesHubspot } from "../constants/constants";
import { updateContact, updateDeal } from "../services/api";
import useStore from "../useStore";
import moment from "moment";
import TagManager from "react-gtm-module";

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_KEY,
};
TagManager.initialize(tagManagerArgs);

export const ThankYouPage = () => {
  const {
    globalTokenCotizacion,
    globalPaqueteElegido,
    globalTipoPago,
    globalDealId,
    globalMetodoPago,
    globalNumeroPoliza,
    globalUrlsHogar,
  } = useStore();

  const currentYear = moment(new Date()).format("YYYY-MM-DD");
  const nextYear = moment(currentYear).add(1, "years").calendar();
  const nextCurrentYear = moment(nextYear).format("YYYY-MM-DD");
  const [width, setWidth] = useState(window.innerWidth);
  const breakpoint = 1020;
  const isResponsive = width < breakpoint;

  useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleWindowResize);

    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  useEffect(() => {
    handleUpdateDeal();
    TagManager.dataLayer({
      dataLayer: {
        event: "hogar_thankyou_page",
        data: "",
      },
    });
  }, []);

  const handleUpdateDeal = () => {
    const dataUpdateDeal = {
      amount: globalPaqueteElegido?.primaTotal,
      dealstage: stagesHubspot?.cierre_ganado,
      producto_contratado: "Seguro de Hogar",
      producto_interes: "Seguro de Hogar",
      metodo_de_pago: globalMetodoPago,
      numero_de_caso: globalNumeroPoliza,
      pipeline: stagesHubspot?.pipeline,
      deal_id: globalDealId,
      paquete_interes: globalPaqueteElegido?.nombre,
      plan_de_pagos: globalTipoPago,
      closedate: currentYear,
    };
    updateDeal(globalTokenCotizacion, dataUpdateDeal)
      .then((response) => {
        if (response) {
          handleUpdateContact();
        }
      })
      .catch(function (error) {});
  };

  const handleUpdateContact = () => {
    const dataContact = {
      servicio_solicitado: "Seguro de Hogar",
      v_numero_de_poliza_hogar: globalNumeroPoliza,
      v_fecha_de_fin_de_vigencia_hogar: nextCurrentYear,
      v_costo_total_hogar: globalPaqueteElegido?.primaTotal,
      v_fecha_de_inicio_de_vigencia_hogar: currentYear,
      contador_de_actividad: 1,
      v_paquete_contratado_hogar: globalPaqueteElegido?.nombre,
      v_url_caratula_de_la_poliza_hogar: globalUrlsHogar.poliza,
    };
    updateContact(globalTokenCotizacion, dataContact)
      .then((response) => {})
      .catch(function (error) {});
  };

  return (
    <ThankYouPageContainer>
      <div>
        <p className="title">¡Toodo bien!</p>
        <p className="subtitle">
          {globalMetodoPago !== "oxxo"
            ? "¡Ya tienes tu Seguro de Hogar!"
            : "¡Ya casi tienes tu Seguro de Hogar!"}
        </p>
      </div>
      <div className="mid">
        <img src={woowLogo} alt="" />
      </div>
      <div className="bottom">
        <p>
          {globalMetodoPago !== "oxxo"
            ? "Gracias por confiar en nosotros. Para revisar los detalles de tu compra, te invitamos a descargar nuestra aplicación y acceder a la sección Mi WOOW"
            : "Hemos enviado a tu correo electrónico toda la información relacionada a tu pago en OXXO."}
        </p>
        {globalMetodoPago !== "oxxo" && (
          <>
            <div className="descargas">
              <a href="https://apps.apple.com/mx/app/woow-seguros-y-servicios/id1539521309?l=es">
                <img src={appStore} alt="descarga app store" />
              </a>
              <a href="https://play.google.com/store/apps/details?id=com.xs.protect.woow">
                <img src={googlePlay} alt="descarga google play" />
              </a>
            </div>
            <p>
              Si lo prefieres también puedes consultar tu correo electrónico.
              ¿Dudas? llámanos al 55 5481 6032 o escríbenos a
              ayuda@woowtodobien.com
            </p>
            <div>
              <button style={{ marginBottom: isResponsive ? 10 : 0}}>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={`${process.env.REACT_APP_LOGIN_WOOW}`}
                >
                  IR A MI WOOW
                </a>
              </button>
              <a
                href={globalUrlsHogar.poliza || "https://www.google.com"}
                target="_blank"
                rel="noreferrer"
              >
                <button target="_blank">DESCARGAR PÓLIZA</button>
              </a>
            </div>
          </>
        )}
      </div>
      {!isResponsive && (
        <div class="contenedor-div">
          <a
            href="https://wa.me/5215585327747"
            target="_blank"
            rel="noreferrer"
          >
            <img
              class="mi-imagen-abajo-derecha"
              src="https://21884627.fs1.hubspotusercontent-na1.net/hubfs/21884627/whatsapp_woow.svg"
              alt="Whatsapp"
              width="60px"
            />
          </a>
        </div>
      )}
    </ThankYouPageContainer>
  );
};

const ThankYouPageContainer = styled.div`
  width: 100%;
  min-height: 120vh;
  display: flex;
  background: linear-gradient(312.79deg, #36405f 5.82%, #536291 89.32%);
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white;
  text-align: center;
  gap: 6vw;

  .descargas {
    display: flex;
    gap: 40px;
    justify-content: center;
    @media (max-width: 1000px) {
      gap: 10px;
    }
    img {
      width: 170px;
      @media (max-width: 1000px) {
        width: 139px;
      }
    }
  }

  .contenedor-div {
    position: absolute;
  }

  .mi-imagen-abajo-derecha {
    position: fixed;
    bottom: 15px;
    right: 90px;
    z-index: 1000 !important;
  }

  .widget-app-container,
  .Application__WidgetAppContainer-sc-1f2l0a1-0,
  .dvBAhE {
    z-index: 99px !important;
  }

  @media (max-width: 992px) {
    .mi-imagen-abajo-derecha {
      position: fixed;
      bottom: 9rem;
      right: 15px;
      z-index: 1000 !important;
    }
    div#hubspot-messages-iframe-container {
      bottom: 55px !important;
    }

    div#hubspot-messages-iframe-container {
      bottom: 55px !important;
      right: 20px !important;
    }
  }

  .title {
    font-weight: 600;
    font-size: 36px;
    line-height: 5px;
    color: #ffffff;
  }

  .subtitle {
    font-weight: 400;
    font-size: 24px;
    color: #ffffff;
  }

  a {
    text-decoration: none;
    color: #838383;
  }

  .bottom {
    max-width: 700px;
    p {
      font-weight: 500;
      font-size: 1rem;
      line-height: 25px;
    }

    button {
      margin-left: 10px;
      background-color: #f5f5f5;
      color: #838383;
      border-radius: 16px;
      border: none;
      font-family: "Montserrat";
      font-weight: 600;
      padding: 10px 20px;
      letter-spacing: 0.08em;
      font-size: 1rem;
      &:hover {
        cursor: pointer;
      }
    }
  }
`;
