import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components/macro";
import { HeaderWOOW } from "../components/HeaderWOOW";
import headerImage from "../assets/heros/woow_lp_hogar_emisor.webp";
import useStore from "../useStore";
import { useNavigate } from "react-router-dom";
import {
  Accordion,
  TextInput,
  Select,
  Checkbox,
  Tooltip,
  LoadingOverlay,
  Switch,
  useMantineColorScheme,
} from "@mantine/core";
import chevronDown from "../assets/chevron.svg";
import Lottie from "react-lottie";
import woowLoadingHogar from "../assets/woow-loading-hogar.json";
import {
  descuento,
  getPaqueteContratado,
  getPerfilContratante,
  getTipoInmueble,
} from "../constants/constants";
import { HeroPages } from "../components/HeroPages";
import "dayjs/locale/es-mx";
import {
  asociateDeal,
  direccionCodigoPostal,
  getRegimenFiscal,
  recotizarCoberturas,
  validarEmitir,
} from "../services/api";
import Steps from "../components/Steps";
import Breadcrumbs from "../components/Breadcrumbs";
import TagManager from "react-gtm-module";
import { errores } from "../constants/strings";
import { showNotification } from "@mantine/notifications";
import InputMask from "react-input-mask";
import moment from "moment";

export const Emisor = () => {
  const { colorScheme } = useMantineColorScheme();
  let navigate = useNavigate();
  const {
    globalDatosAsegurado,
    globalIdCotizacion,
    globalTokenCotizacion,
    globalSumaAsegurada,
    globalPaqueteElegido,
    globalCatalogos,
    globalDatosValuacion,
    setGlobalDatosEmision,
    globalTipoPago,
    globalContactId,
    globalDealId,
    globalTipoCotizador,
    globalUsuarioInterno,
    globalCoberturasExtras,
    globalDatosRecotizacion,
    globalCoberturasPaqueteElegido,
    globalNuevoCosto,
    setGlobalNuevoCosto,
    setGlobalIdDatosEmitir,
    globalPaquetesFactorLinea,
    globalAlarmaLocal,
    setGlobalCoberturasExtras,
    setGlobalCoberturasPaqueteElegido,
    setGlobalDatosCoberturas,
    setGlobalPaqueteElegido,
    setGlobalSumaAsegurada,
    setglobalDatosAsegurado,
    setGlobalDatosRecotizacion,
    setGlobalTipoCotizador,
    setGlobalDatosPaquetes,
    setGlobalIdCotizacion,
    setGlobalPaquetesFactorLinea,
  } = useStore();
  const [coberturas, setCoberturas] = useState(globalCoberturasExtras);
  const [paqueteElegidoCoberturas, setPaqueteElegidoCoberturas] = useState(
    globalCoberturasPaqueteElegido
  );
  const [datosEmision, setDatosEmision] = useState(null);
  const [datosEmisor, setDatosEmisor] = useState(null);
  const [datosRegimenFiscal, setDatosRegimenFiscal] = useState(null);
  const [colonia, setColonia] = useState([]);
  const [estado, setEstado] = useState([]);
  const [municipio, setMunicipio] = useState([]);
  const [coloniaRegimen, setColoniaRegimen] = useState([]);
  const [estadoRegimen, setEstadoRegimen] = useState([]);
  const [municipioRegimen, setMunicipioRegimen] = useState([]);
  const [perfilContratante, setPerfilContratante] = useState([]);
  const [claseInmueble, setClaseInmueble] = useState([]);
  const [tipoInmueble, setTipoInmueble] = useState([]);
  const [medidasSeguridad, setMedidasSeguridad] = useState([]);
  const [regimenFiscalData, setRegimenFiscalData] = useState([]);
  const [isTitular, setIsTitular] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [checkedDatosRegimen, setCheckedDatosRegimen] = useState(true);
  const currentYear = new Date().getFullYear();
  const isFirstRender = useRef(true);
  const [width, setWidth] = useState(window.innerWidth);
  const breakpoint = 1020;
  const isResponsive = width < breakpoint;
  // states de errores
  const [calleError, setCalleError] = useState(false);
  const [numExteriorError, setNumExteriorError] = useState(false);
  const [coloniaError, setColoniaError] = useState(false);
  const [estadoError, setEstadoError] = useState(false);
  const [municipioError, setMunicipioError] = useState(false);
  const [codigoPostalInmuebleError, setCodigoPostalInmuebleError] =
    useState(false);
  const [codigoPostalContratanteError, setCodigoPostalContratanteError] =
    useState(false);
  const [claseInmuebleError, setClaseInmuebleError] = useState(false);
  const [metrosConstruidosError, setMetrosConstruidosError] = useState(false);
  const [metrosConstruidosLengthError, setMetrosConstruidosLengthError] =
    useState(false);
  const [metrosCuadradosError, setMetrosCuadradosError] = useState(false);
  const [metrosCuadradosLengthError, setMetrosCuadradosLengthError] =
    useState(false);
  const [tipoInmuebleError, setTipoInmuebleError] = useState(false);
  const [anioConstruccionError, setAnioConstruccionError] = useState(false);
  const [anioConstruccionLongitudError, setAnioConstruccionLongitudError] =
    useState(false);
  const [anioConstruccionActualError, setAnioConstruccionActualError] =
    useState(false);
  const [claveFiscalError, setClaveFiscalError] = useState(false);
  const [nombreError, setNombreError] = useState(false);
  const [aPaternoError, setAPaternoError] = useState(false);
  const [aMaternoError, setAMaternoError] = useState(false);
  const [fechaNacimientoError, setFechaNacimientoError] = useState(false);
  const [fechaInvalidaError, setFechaInvalidaError] = useState(false);
  const [rfcError, setRfcError] = useState(false);
  const [rfcLongitudError, setRfcLongitudError] = useState(false);
  const [telefonoError, setTelefonoError] = useState(false);
  const [telefonoLongitudError, setTelefonoLongitudError] = useState(false);
  const [correoError, setCorreoError] = useState(false);
  const [calleFiscalError, setCalleFiscalError] = useState(false);
  const [numExteriorFiscalError, setNumExteriorFiscalError] = useState(false);
  const [coloniaFiscalError, setColoniaFiscalError] = useState(false);
  const [municipioFiscalError, setMunicipioFiscalError] = useState(false);
  const [estadoFiscalError, setEstadoFiscalError] = useState(false);
  const [codigoPostalFiscalError, setCodigoPostalFiscalError] = useState(false);
  // states session storage
  const dataCatalogos = sessionStorage.getItem("globalCatalogos");
  const dataDatosEmision = sessionStorage.getItem("datosEmision");
  const dataPaquete = sessionStorage.getItem("paqueteElegido");
  const dataCotizacion = sessionStorage.getItem("cotizacion");
  const dataDatosCoberturas = sessionStorage.getItem("datosCoberturas");
  const dataCoberturasPaqueteElegido = sessionStorage.getItem(
    "coberturasPaqueteElegido"
  );
  const dataCoberturasExtra = sessionStorage.getItem("coberturasExtra");
  const loadedDataEmision =
    dataDatosEmision !== "undefined" ? JSON.parse(dataDatosEmision) : {};
  const loadedDataCatalogos =
    dataCatalogos !== "undefined" ? JSON.parse(dataCatalogos) : {};
  const loadedDataPaquete =
    dataPaquete !== "undefined" ? JSON.parse(dataPaquete) : {};
  const loadedDataCotizacion =
    dataCotizacion !== "undefined" ? JSON.parse(dataCotizacion) : {};
  const loadedDataCoberturas =
    dataDatosCoberturas !== "undefined" ? JSON.parse(dataDatosCoberturas) : {};
  const loadedDataCoberturasPaqueteElegido =
    dataCoberturasPaqueteElegido !== "undefined"
      ? JSON.parse(dataCoberturasPaqueteElegido)
      : {};
  const loadedDataCoberturasExtra =
    dataCoberturasExtra !== "undefined" ? JSON.parse(dataCoberturasExtra) : {};
  const [datosGuardadosEmision, setDatosGuardadosEmision] =
    useState(loadedDataEmision);
  const [datosGuardadosCatalogos, setDatosGuardadosCatalogos] =
    useState(loadedDataCatalogos);
  const [datosGuardadosPaquete, setDatosGuardadosPaquete] =
    useState(loadedDataPaquete);
  const [datosGuardadosCotizacion, setDatosGuardadosCotizacion] =
    useState(loadedDataCotizacion);
  const [datosGuardadosCoberturas, setDatosGuardadosCoberturas] =
    useState(loadedDataCoberturas);
  const [
    datosGuardadosCoberturasPaqueteElegido,
    setDatosGuardadosCoberturasPaqueteElegido,
  ] = useState(loadedDataCoberturasPaqueteElegido);
  const [datosGuardadosCoberturasExtra, setDatosGuardadosCoberturasExtra] =
    useState(loadedDataCoberturasExtra);
  const fechaRegex =
    /^(0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])[- /.](19|20)\d\d$/;
  const today = moment().format("YYYY-MM-DD");
  const nacimiento = moment(datosEmisor?.fechaNacimiento, "DD-MM-YYYY").format(
    "YYYY-MM-DD"
  );
  const fechaIgualOMenor = moment(nacimiento).isSameOrBefore(today);

  useEffect(() => {
    if (isResponsive) {
      window.scrollTo(0, 0);
    }
  }, []);

  useEffect(() => {
    if (datosGuardadosEmision?.dataEmision?.dataCasaAsegurar) {
      setDatosEmision({
        ...datosEmision,
        calle:
          datosGuardadosEmision?.dataEmision.dataCasaAsegurar?.calle ||
          datosEmision?.calle,
        numExterior:
          datosGuardadosEmision?.dataEmision.dataCasaAsegurar?.numExterior ||
          datosEmision?.numExterior,
        numInterior:
          datosGuardadosEmision?.dataEmision.dataCasaAsegurar?.numInterior ||
          datosEmision?.numInterior,
        colonia:
          datosGuardadosEmision?.dataEmision?.dataCasaAsegurar?.colonia?.toString() ||
          datosEmision?.colonia,
        cveColonia:
          datosGuardadosEmision?.dataEmision?.dataCasaAsegurar?.cveColonia?.toString() ||
          datosEmision?.cveColonia,
        municipio:
          datosGuardadosEmision?.dataEmision.dataCasaAsegurar?.municipio ||
          datosEmision?.municipio,
        cveMunicipio:
          datosGuardadosEmision?.dataEmision.dataCasaAsegurar?.cveMunicipio ||
          datosEmision?.cveMunicipio,
        estado:
          datosGuardadosEmision?.dataEmision.dataCasaAsegurar?.estado ||
          datosEmision?.estado,
        cveEstado:
          datosGuardadosEmision?.dataEmision.dataCasaAsegurar?.cveEstado ||
          datosEmision?.cveEstado,
        cpInmueble:
          datosGuardadosEmision?.dataEmision.dataCasaAsegurar?.cpInmueble ||
          datosEmision?.cpInmueble,
        cpContratante:
          datosGuardadosEmision?.dataEmision.dataCasaAsegurar?.cpContratante ||
          datosEmision?.cpContratante,
        perfilContratante:
          datosGuardadosEmision?.dataEmision.dataCasaAsegurar
            ?.perfilContratante ||
          globalDatosAsegurado?.datosDelAsegurado?.perfilContratante,
        tipoInmueble:
          datosGuardadosEmision?.dataEmision.dataCasaAsegurar?.tipoInmueble ||
          globalDatosValuacion?.datosValuacion?.tipoInmueble,
        claseInmueble:
          datosGuardadosEmision?.dataEmision.dataCasaAsegurar?.claseInmueble ||
          globalDatosValuacion?.datosValuacion?.claseInmueble,
        metrosCuadrados:
          datosGuardadosEmision?.dataEmision.dataCasaAsegurar
            ?.metrosCuadrados ||
          globalDatosValuacion?.datosValuacion?.metrosTerreno ||
          datosEmision?.metrosCuadrados,
        metrosConstruidos:
          datosGuardadosEmision?.dataEmision.dataCasaAsegurar
            ?.metrosConstruidos ||
          globalDatosValuacion?.datosValuacion?.metrosConstruidos ||
          datosEmision?.metrosConstruidos,
        edad:
          currentYear -
            datosGuardadosEmision?.dataEmision.dataCasaAsegurar?.edad ||
          currentYear - globalDatosValuacion?.datosValuacion?.edad ||
          currentYear - datosEmision?.edad,
        alarmaLocal:
          datosGuardadosEmision?.dataEmision.dataCasaAsegurar?.alarmaLocal ||
          globalDatosAsegurado?.datosDelAsegurado?.medidasDeSeguridad?.toString() ||
          datosEmision?.medidasDeSeguridad,
      });
      setDatosEmisor({
        ...datosEmisor,
        nombre:
          datosGuardadosEmision?.dataEmision.datosEmisor?.nombre ||
          datosEmisor?.nombre,
        aPaterno:
          datosGuardadosEmision?.dataEmision.datosEmisor?.aPaterno ||
          datosEmisor?.aPaterno,
        aMaterno:
          datosGuardadosEmision?.dataEmision.datosEmisor?.aMaterno ||
          datosEmisor?.aMaterno,
        fechaNacimiento:
          moment(
            datosGuardadosEmision?.dataEmision.datosEmisor?.fechaNacimiento
          ).format("DD/MM/YYYY") ||
          moment(datosEmisor?.fechaNacimiento).format("DD/MM/YYYY"),
        rfc:
          datosGuardadosEmision?.dataEmision.datosEmisor?.rfc ||
          datosEmisor?.rfc,
        telefono:
          datosGuardadosEmision?.dataEmision.datosEmisor?.telefono ||
          datosEmisor?.telefono,
        cElectronico:
          datosGuardadosEmision?.dataEmision.datosEmisor?.cElectronico ||
          datosEmisor?.cElectronico,
      });
      setDatosRegimenFiscal({
        ...datosRegimenFiscal,
        regimenFiscal:
          datosGuardadosEmision?.dataEmision?.claveRegimenFiscalSat ||
          datosRegimenFiscal?.regimenFiscal,
        descripcion:
          datosGuardadosEmision?.regimenFiscalDescripcion ||
          datosRegimenFiscal?.descripcion,
        codigoPostal:
          datosGuardadosEmision?.dataEmision.dataDomicilioFiscal?.codigoPostal,
        calle: datosGuardadosEmision?.dataEmision.dataDomicilioFiscal?.calle,
        numExterior:
          datosGuardadosEmision?.dataEmision.dataDomicilioFiscal?.numExterior,
        numInterior:
          datosGuardadosEmision?.dataEmision.dataDomicilioFiscal?.numInterior,
        colonia:
          datosGuardadosEmision?.dataEmision.dataDomicilioFiscal?.colonia ||
          datosEmision?.colonia,
        cveColonia:
          datosGuardadosEmision?.dataEmision.dataDomicilioFiscal?.cveColonia ||
          datosEmision?.cveColonia,
        municipio:
          datosGuardadosEmision?.dataEmision.dataDomicilioFiscal?.municipio ||
          datosEmision?.municipio,
        cveMunicipio:
          datosGuardadosEmision?.dataEmision.dataDomicilioFiscal
            ?.cveMunicipio || datosEmision?.cveMunicipio,
        estado:
          datosGuardadosEmision?.dataEmision.dataDomicilioFiscal?.estado ||
          datosEmision?.estado,
        cveEstado:
          datosGuardadosEmision.dataEmision.dataDomicilioFiscal?.cveEstado ||
          datosEmision?.cveEstado,
      });
      setIsTitular(
        datosGuardadosEmision?.dataEmision.datosEmisor?.soyTitular === 1
          ? true
          : false
      );
    }
  }, [datosGuardadosEmision]);

  useEffect(() => {
    if (datosGuardadosCoberturasExtra) {
      setGlobalCoberturasExtras(datosGuardadosCoberturasExtra);
      setCoberturas(datosGuardadosCoberturasExtra);
    }
  }, [datosGuardadosCoberturasExtra]);

  useEffect(() => {
    if (datosGuardadosCoberturasPaqueteElegido) {
      setGlobalCoberturasPaqueteElegido(datosGuardadosCoberturasPaqueteElegido);
    }
  }, [datosGuardadosCoberturasPaqueteElegido]);

  useEffect(() => {
    if (datosGuardadosCoberturas) {
      setGlobalDatosCoberturas(datosGuardadosCoberturas?.datosCoberturas);
      // setGlobalTipoPago(datosGuardadosCoberturas?.tipoPago);
    }
  }, [datosGuardadosCoberturas]);

  useEffect(() => {
    if (datosGuardadosPaquete) {
      setGlobalPaqueteElegido(datosGuardadosPaquete);
    }
  }, [datosGuardadosPaquete]);

  useEffect(() => {
    if (datosGuardadosCotizacion) {
      setGlobalSumaAsegurada(
        datosGuardadosCotizacion?.cotizacionPrincipal?.valorInmueble
      );
      setglobalDatosAsegurado({
        datosDelAsegurado: datosGuardadosCotizacion?.cotizacionPrincipal,
      });
      setGlobalDatosPaquetes(datosGuardadosCotizacion?.datosPaquetes);
      setGlobalPaquetesFactorLinea(
        datosGuardadosCotizacion?.paquetesFactorLinea
      );
      setGlobalDatosRecotizacion(datosGuardadosCotizacion?.datosRecotizacion);
      setGlobalTipoCotizador(datosGuardadosCotizacion?.tipoCotizador);
      const idCotizacion = datosGuardadosCotizacion?.idCotizacion;
      setGlobalIdCotizacion({ idCotizacion });
    }
  }, [datosGuardadosCotizacion]);

  useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleWindowResize);

    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: woowLoadingHogar,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  useEffect(() => {
    if (!isFirstRender.current) {
      if (paqueteElegidoCoberturas.length) {
        handleRecotizarCoberturasExtras();
      }
    } else {
      isFirstRender.current = false;
    }
  }, [paqueteElegidoCoberturas]);

  useEffect(() => {
    if (isTitular === true) {
      if (globalTipoCotizador === "interno") {
        setDatosEmisor({
          ...datosEmisor,
          nombre: globalUsuarioInterno?.nombre,
          aPaterno: globalUsuarioInterno?.apellido_paterno,
          aMaterno: globalUsuarioInterno?.apellido_materno,
          telefono: globalUsuarioInterno?.celular,
          cElectronico: globalUsuarioInterno?.email,
        });
      } else {
        setDatosEmisor({
          ...datosEmisor,
          nombre: globalDatosAsegurado?.datosDelAsegurado?.nombre,
          telefono: globalDatosAsegurado?.datosDelAsegurado?.telefono,
          cElectronico: globalDatosAsegurado?.datosDelAsegurado?.email,
          ...(datosGuardadosEmision?.dataEmision?.dataCasaAsegurar && {
            aPaterno:
              datosGuardadosEmision?.dataEmision.datosEmisor?.aPaterno ||
              datosEmisor?.aPaterno,
          }),
          ...(datosGuardadosEmision?.dataEmision?.dataCasaAsegurar && {
            aMaterno:
              datosGuardadosEmision?.dataEmision.datosEmisor?.aMaterno ||
              datosEmisor?.aMaterno,
          }),
          ...(datosGuardadosEmision?.dataEmision?.dataCasaAsegurar && {
            rfc:
              datosGuardadosEmision?.dataEmision.datosEmisor?.rfc ||
              datosEmisor?.rfc,
          }),
          ...(datosGuardadosEmision?.dataEmision?.dataCasaAsegurar && {
            fechaNacimiento:
              moment(
                datosGuardadosEmision?.dataEmision.datosEmisor?.fechaNacimiento
              ).format("DD/MM/YYYY") ||
              moment(datosEmisor?.fechaNacimiento).format("DD/MM/YYYY"),
          }),
        });
      }
    } else {
      if (globalTipoCotizador === "interno") {
        setDatosEmisor({
          ...datosEmisor,
          nombre: "",
          aPaterno: "",
          aMaterno: "",
          telefono: "",
          cElectronico: "",
        });
      } else {
        setDatosEmisor({
          ...datosEmisor,
          nombre: "",
          telefono: "",
          cElectronico: "",
        });
      }
    }
  }, [isTitular]);

  useEffect(() => {
    if (datosEmision?.calle) {
      setCalleError(false);
    }
    if (datosEmision?.numExterior) {
      setNumExteriorError(false);
    }
    if (datosEmision?.colonia) {
      setColoniaError(false);
    }
    if (datosEmision?.municipio) {
      setMunicipioError(false);
    }
    if (datosEmision?.estado) {
      setEstadoError(false);
    }
    if (datosEmision?.cpContratante) {
      setCodigoPostalContratanteError(false);
    }
    if (datosEmision?.tipoInmueble) {
      setTipoInmuebleError(false);
    }
    if (datosEmision?.claseInmueble) {
      setClaseInmuebleError(false);
    }
    if (datosEmision?.metrosCuadrados) {
      setMetrosCuadradosError(false);
    }
    if (datosEmision?.metrosCuadrados?.length >= 2) {
      setMetrosCuadradosLengthError(false);
    }
    if (datosEmision?.metrosConstruidos) {
      setMetrosConstruidosError(false);
    }
    if (datosEmision?.metrosConstruidos?.length >= 2) {
      setMetrosConstruidosLengthError(false);
    }
    if (datosEmision?.edad || globalDatosValuacion?.datosValuacion?.edad) {
      setAnioConstruccionError(false);
    }
    if (
      datosEmision?.edad?.length === 4 ||
      globalDatosValuacion?.datosValuacion?.edad?.length === 4
    ) {
      setAnioConstruccionLongitudError(false);
    }
    if (
      datosEmision?.edad <= currentYear &&
      globalDatosValuacion?.datosValuacion?.edad <= currentYear
    ) {
      setAnioConstruccionActualError(false);
    }
    if (datosRegimenFiscal?.codigoPostal) {
      setCodigoPostalFiscalError(false);
    }
    if (datosRegimenFiscal?.calle) {
      setCalleFiscalError(false);
    }
    if (datosRegimenFiscal?.numExteriorError) {
      setNumExteriorFiscalError(false);
    }
    if (datosRegimenFiscal?.colonia) {
      setColoniaFiscalError(false);
    }
    if (datosRegimenFiscal?.municipio) {
      setMunicipioFiscalError(false);
    }
    if (datosRegimenFiscal?.estado) {
      setEstadoFiscalError(false);
    }
    if (datosEmisor?.nombre) {
      setNombreError(false);
    }
    if (datosEmisor?.aPaterno) {
      setAPaternoError(false);
    }
    if (datosEmisor?.aMaterno) {
      setAMaternoError(false);
    }
    if (datosEmisor?.fechaNacimiento !== undefined) {
      setFechaNacimientoError(false);
    }
    if (datosEmisor?.fechaNacimiento !== "") {
      setFechaNacimientoError(false);
    }
    if (fechaRegex.test(datosEmisor?.fechaNacimiento)) {
      setFechaInvalidaError(false);
    }
    if (fechaIgualOMenor === true) {
      setFechaInvalidaError(false);
    }
    if (datosEmisor?.rfc) {
      setRfcError(false);
    }
    if (datosEmisor?.rfc?.length === 13) {
      setRfcLongitudError(false);
    }
    if (datosEmisor?.telefono) {
      setTelefonoError(false);
    }
    if (datosEmisor?.telefono?.length === 10) {
      setTelefonoLongitudError(false);
    }
    if (datosEmisor?.cElectronico) {
      setCorreoError(false);
    }
    if (datosRegimenFiscal?.regimenFiscal) {
      setClaveFiscalError(false);
    }
  }, [datosEmision, datosRegimenFiscal, datosEmisor, globalDatosValuacion]);

  useEffect(() => {
    if (globalTokenCotizacion) {
      handleCodigoPostal();
      handleGetRegimenFiscal();
      if (globalTipoCotizador === "externo") {
        handleAsociateDeal();
      }
    }
  }, [globalTokenCotizacion]);

  useEffect(() => {
    if (
      datosRegimenFiscal?.codigoPostal &&
      datosRegimenFiscal?.codigoPostal.length === 5
    ) {
      handleCodigoPostalRegimenFiscal();
    }
  }, [datosRegimenFiscal?.codigoPostal]);

  useEffect(() => {
    const clase = globalCatalogos[0];
    const arrayClases = clase?.map((item) => {
      return { label: item.label, value: item.value };
    });
    setClaseInmueble(arrayClases);

    const tipo = globalCatalogos[1];
    const arrayTipos = tipo?.map((item) => {
      return { label: item.label, value: item.value };
    });
    setTipoInmueble(arrayTipos);

    const medida = globalCatalogos[2];
    const arrayMedidas = medida?.map((item) => {
      return { label: item.label, value: item.value };
    });
    setMedidasSeguridad(arrayMedidas);

    const perfiles = globalCatalogos[3];
    const arrayPerfiles = perfiles?.map((item) => {
      return { label: item.label, value: item.value };
    });
    setPerfilContratante(arrayPerfiles);
  }, []);

  const handleAsociateDeal = () => {
    const dataDeal = {
      contact_id: globalContactId,
      deal_id: globalDealId,
    };
    asociateDeal(globalTokenCotizacion, dataDeal)
      .then((response) => {})
      .catch(function (error) {});
  };

  const handleGetRegimenFiscal = () => {
    const tipoPersona = "F";
    getRegimenFiscal(globalTokenCotizacion, tipoPersona)
      .then((response) => {
        const regimenFiscal = response.data?.event;

        let a6Idx = regimenFiscal.findIndex((obj) => obj.clave === "616");
        let newData = JSON.parse(JSON.stringify(regimenFiscal));
        newData.splice(0, 0, regimenFiscal[a6Idx]);
        newData.splice(8, 1);

        const arrayRegimenFiscal = newData.map((item) => {
          return {
            label: item.descripcion,
            value: `${item.descripcion}/${item.clave}`,
          };
        });

        setRegimenFiscalData(arrayRegimenFiscal);
      })
      .catch(function (error) {});
  };

  const handleCodigoPostal = async () => {
    const dataCp = {
      cp:
        datosEmision?.cpInmueble ||
        globalDatosAsegurado?.datosDelAsegurado?.codigoPostal,
      origin: "APP",
    };

    direccionCodigoPostal(dataCp, globalTokenCotizacion)
      .then((response) => {
        const colonias =
          response.data.event.codigoPostal.consultarAnidadosCRMResponse
            .catalogos[1].listaElementos;
        const isArrayColonias = Array.isArray(colonias) === false;
        if (!isArrayColonias) {
          const arrayColonias = colonias.map((item) => {
            return {
              label: item.descripcionValor,
              value: `${item.descripcionValor}/${item.idValor}`,
            };
          });
          setColonia(arrayColonias);
        } else {
          const oneOptionColonia = [colonias];
          const arrayColonias = oneOptionColonia.map((item) => {
            return {
              label: item.descripcionValor,
              value: `${item.descripcionValor}/${item.idValor}`,
            };
          });
          setColonia(arrayColonias);
        }

        const municipios = [
          response.data.event.codigoPostal.consultarAnidadosCRMResponse
            .catalogos[2].listaElementos,
        ];
        const arrayMunicipios = municipios.map((item) => {
          return {
            label: item.descripcionValor,
            value: `${item.descripcionValor}/${item.idValor}`,
          };
        });
        setMunicipio(arrayMunicipios);

        const estados = [
          response.data.event.codigoPostal.consultarAnidadosCRMResponse
            .catalogos[3].listaElementos,
        ];
        const arrayEstados = estados.map((item) => {
          return {
            label: item.descripcionValor,
            value: `${item.descripcionValor}/${item.idValor}`,
          };
        });
        setEstado(arrayEstados);
      })
      .catch(function (error) {});
  };

  const handleCodigoPostalRegimenFiscal = async () => {
    const dataCp = {
      cp: datosRegimenFiscal?.codigoPostal,
      origin: "APP",
    };

    direccionCodigoPostal(dataCp, globalTokenCotizacion)
      .then((response) => {
        const colonias =
          response?.data.event.codigoPostal.consultarAnidadosCRMResponse
            .catalogos[1].listaElementos;
        const arrayColonias = colonias.map((item) => {
          return {
            label: item.descripcionValor,
            value: `${item.descripcionValor}/${item.idValor}`,
          };
        });
        setColoniaRegimen(arrayColonias);

        const municipios = [
          response?.data.event.codigoPostal.consultarAnidadosCRMResponse
            .catalogos[2].listaElementos,
        ];
        const arrayMunicipios = municipios.map((item) => {
          return {
            label: item.descripcionValor,
            value: `${item.descripcionValor}/${item.idValor}`,
          };
        });
        setMunicipioRegimen(arrayMunicipios);

        const estados = [
          response?.data.event.codigoPostal.consultarAnidadosCRMResponse
            .catalogos[3].listaElementos,
        ];
        const arrayEstados = estados.map((item) => {
          return {
            label: item.descripcionValor,
            value: `${item.descripcionValor}/${item.idValor}`,
          };
        });
        setEstadoRegimen(arrayEstados);
      })
      .catch(function (error) {});
  };

  const handleRecotizarCoberturasExtras = async (item) => {
    setIsLoading(true);
    const bodyCoberturas = paqueteElegidoCoberturas?.map((item) => {
      return {
        [item?.codigoInfo]: {
          idCobertura: item?.codigoInfo,
          sumaAsegurada: item?.sumaAsegurada,
          factorLinea: item?.factorLinea || 0,
          factorRenovacion: item?.factorRenovacion || 0,
        },
      };
    });

    const key = globalPaqueteElegido?.nombre;
    const paqueteCoberturas = {};
    paqueteCoberturas[key] = {
      coberturas: Object?.assign({}, ...bodyCoberturas),
    };

    const datos = globalDatosRecotizacion[1];

    const datosInmuebles = [datos].map((item) => {
      return {
        codigoPostalContratante: item.codigoPostalContratante,
        codigoPostalInmueble: item.codigoPostalInmueble,
        esConstruccionMaciza: item.esConstruccionMaciza,
        esLagoLaguna: item.esLagoLaguna,
        esLineaPlaya: item.esLineaPlaya,
        idPeriodicidadPago: globalTipoPago === "Anual" ? 1 : 4,
        idTipoMoneda: item.idTipoMoneda,
        multiclausula: item.multiclausula,
        valorTipo: item.valorTipo,
      };
    });

    const output = Object.assign({}, ...datosInmuebles);

    const paquete = globalPaqueteElegido?.nombre;

    const dataCoberturas = {
      idCotizacion: globalIdCotizacion.idCotizacion,
      codigo:
        (paquete === "Hogar Esencial" && "0") ||
        (paquete === "Hogar Vital" && "1") ||
        (paquete === "Hogar Plus" && "2"),
      origin: "APP",
      metodoPago: globalTipoPago === "Anual" ? 1 : 4,
      datosConfiguracion: globalDatosRecotizacion[0],
      datosInmueble: output,
      paquetes: paqueteCoberturas,
    };

    recotizarCoberturas(dataCoberturas, globalTokenCotizacion)
      .then((response) => {
        const nuevaSumaAsegurada = response.data.event.paquetes[0].primaTotal;
        const nuevoPrimerRecibo =
          response.data.event.paquetes[0].primaPrimerRecibo;
        const nuevoReciboSubsecuente =
          response.data.event.paquetes[0].primaReciboSubsecuente;
        setGlobalNuevoCosto({
          pagoTotal: nuevaSumaAsegurada,
          primerRecibo: nuevoPrimerRecibo,
          reciboSubsecuente: nuevoReciboSubsecuente,
        });
        setIsLoading(false);
      })
      .catch(function (error) {
        setIsLoading(false);
      });
  };

  const handleDeleteItem = (codigo) => {
    setCoberturas((previousCoberturas) =>
      previousCoberturas.filter((item) => item.codigoInfo !== codigo)
    );

    setPaqueteElegidoCoberturas((previousElegidas) =>
      previousElegidas.filter((item) => item.codigoInfo !== codigo)
    );
  };

  const paquetesFactorLinea = Object.keys(globalPaquetesFactorLinea).map(
    function (key) {
      return globalPaquetesFactorLinea[key];
    }
  );

  const getPaqueteFactorLinea = (nombre) => {
    switch (nombre) {
      case "Hogar Esencial":
        return paquetesFactorLinea[0]["coberturas"];
      case "Hogar Vital":
        return paquetesFactorLinea[1]["coberturas"];
      case "Hogar Plus":
        return paquetesFactorLinea[2]["coberturas"];
      default:
        return {};
    }
  };

  const paqueteElegidoFactorLinea = Object.keys(
    getPaqueteFactorLinea(globalPaqueteElegido?.nombre)
  ).map(function (key) {
    return getPaqueteFactorLinea(globalPaqueteElegido?.nombre)[key];
  });

  const coberturasPaquete =
    globalCoberturasPaqueteElegido?.length > 0
      ? globalCoberturasPaqueteElegido
      : globalPaqueteElegido?.coberturas;

  /* paso 1 meter nuevo valor de factorLinea*/
  const newArrCoberturas = coberturasPaquete?.map((v) => ({
    ...v,
    factorLinea: [],
  }));

  newArrCoberturas?.forEach(
    (node) =>
      (node.factorLinea = node.factorLinea.concat(
        paqueteElegidoFactorLinea
          .filter((item) => item.idCobertura === node.codigoInfo)
          .map((item) => item.factorLinea)
      ))
  );

  const filteredResponsabilidad = newArrCoberturas?.filter(
    (item) =>
      item?.codigoInfo === "0000000878" || item?.codigoInfo === "0000000870"
  );

  const bodyResponsabilidad = filteredResponsabilidad?.map((item) => {
    if (item.codigoInfo === "0000000870") {
      return {
        codigoCobertura: item?.codigoInfo,
        elementosOpcionales: [
          {
            nombreElemento: "cuota",
            valorAlfanumerico: "",
            valorNumerico: item?.cuota,
          },
          {
            nombreElemento: "recargoTipoConstructivo",
            valorAlfanumerico: "",
            valorNumerico: item?.recargoTipoConstructivo || 0,
          },
          {
            nombreElemento: "sumaAsegurada",
            valorAlfanumerico: "",
            valorNumerico: item?.sumaAsegurada,
          },
          {
            nombreElemento: "factorLinea",
            valorAlfanumerico: "",
            valorNumerico: item?.factorLinea[0],
          },
          {
            nombreElemento: "factorRenovacion",
            valorAlfanumerico: "",
            valorNumerico: item?.factorRenovacion || 0,
          },
          {
            nombreElemento: "opcionCalculo",
            valorAlfanumerico: "M",
            valorNumerico: 0,
          },
        ],
      };
    } else {
      return {
        codigoCobertura: item?.codigoInfo,
        elementosOpcionales: [
          {
            nombreElemento: "cuota",
            valorAlfanumerico: "",
            valorNumerico: item?.cuota,
          },
          {
            nombreElemento: "sumaAsegurada",
            valorAlfanumerico: "",
            valorNumerico: item?.sumaAsegurada,
          },
          {
            nombreElemento: "factorLinea",
            valorAlfanumerico: "",
            valorNumerico: item?.factorLinea[0],
          },
          {
            nombreElemento: "factorRenovacion",
            valorAlfanumerico: "",
            valorNumerico: item?.factorRenovacion || 0,
          },
          {
            nombreElemento: "opcionCalculo",
            valorAlfanumerico: "M",
            valorNumerico: 0,
          },
        ],
      };
    }
  });

  const filteredAsistencias = newArrCoberturas?.filter(
    (item) =>
      item?.codigoInfo === "0000001477" || item?.codigoInfo === "0000001476"
  );

  const bodyAsistencias = filteredAsistencias?.map((item) => {
    return {
      codigoCobertura: item?.codigoInfo,
      elementosOpcionales: [
        {
          nombreElemento: "cuota",
          valorAlfanumerico: "",
          valorNumerico: 0,
        },
        {
          nombreElemento: "prima",
          valorAlfanumerico: "",
          valorNumerico: item?.primaNeta,
        },
        {
          nombreElemento: "sumaAsegurada",
          valorAlfanumerico: "",
          valorNumerico: item?.sumaAsegurada,
        },

        {
          nombreElemento: "opcionCalculo",
          valorAlfanumerico: "M",
          valorNumerico: 0,
        },
      ],
    };
  });

  const filteredCoberturasGenerales = newArrCoberturas?.filter((item) => {
    if (
      item.codigoInfo !== "0000000870" &&
      item.codigoInfo !== "0000000878" &&
      item.codigoInfo !== "0000001477" &&
      item.codigoInfo !== "0000001476"
    ) {
      return true;
    } else {
      return false;
    }
  });

  const bodyCoberturasComision = newArrCoberturas?.map((item) => {
    return {
      codigoCobertura: item?.codigoInfo,
      porcentajeComision: 0.125,
    };
  });

  const medidaDeSeguridad =
    Number(globalDatosAsegurado.datosDelAsegurado?.medidasDeSeguridad) ||
    Number(datosEmision?.medidasDeSeguridad);

  const bodyCoberturas = filteredCoberturasGenerales?.map((item) => {
    if (item.codigoInfo === "0000001441") {
      return {
        codigoCobertura: item?.codigoInfo,
        elementosOpcionales: [
          {
            nombreElemento: "cuota",
            valorAlfanumerico: "",
            valorNumerico: item?.cuota,
          },
          {
            nombreElemento: "sumaAsegurada",
            valorAlfanumerico: "",
            valorNumerico: item?.sumaAsegurada,
          },
          {
            nombreElemento: "unidadDeducible",
            valorAlfanumerico: item?.unidadDeducible,
            valorNumerico: 0,
          },
          {
            nombreElemento: "factorDeducible",
            valorAlfanumerico: "",
            valorNumerico: item?.factorDeducible || 0,
          },
          {
            nombreElemento: "aplicaIndemnizacionReposicion",
            valorAlfanumerico: "",
            valorNumerico: item?.aplicaIndemnizacionReposicion || 0,
          },
          {
            nombreElemento: "recargoIndemnizacionValorReposicion",
            valorAlfanumerico: "",
            valorNumerico: item?.recargoIndemnizacionValorReposicion || 0,
          },
          {
            nombreElemento: "valorDeducible",
            valorAlfanumerico: "",
            valorNumerico: item?.valorDeducible || 0,
          },
          {
            nombreElemento: "factorLinea",
            valorAlfanumerico: "",
            valorNumerico: item?.factorLinea[0],
          },
          {
            nombreElemento: "factorRenovacion",
            valorAlfanumerico: "",
            valorNumerico: item?.factorRenovacion || 0,
          },
          {
            nombreElemento: "opcionCalculo",
            valorAlfanumerico: "M",
            valorNumerico: 0,
          },
        ],
      };
    } else if (item.codigoInfo === "0000001440") {
      return {
        codigoCobertura: item?.codigoInfo,
        elementosOpcionales: [
          {
            nombreElemento: "cuota",
            valorAlfanumerico: "",
            valorNumerico: item?.cuota,
          },
          {
            nombreElemento: "sumaAsegurada",
            valorAlfanumerico: "",
            valorNumerico: item?.sumaAsegurada,
          },
          {
            nombreElemento: "unidadDeducible",
            valorAlfanumerico: item?.unidadDeducible,
            valorNumerico: 0,
          },
          {
            nombreElemento: "valorDeducible",
            valorAlfanumerico: "",
            valorNumerico: item?.valorDeducible || 0,
          },
          {
            nombreElemento: "factorLinea",
            valorAlfanumerico: "",
            valorNumerico: item?.factorLinea[0],
          },
          {
            nombreElemento: "factorRenovacion",
            valorAlfanumerico: "",
            valorNumerico: item?.factorRenovacion || 0,
          },
          {
            nombreElemento: "opcionCalculo",
            valorAlfanumerico: "M",
            valorNumerico: 0,
          },
        ],
      };
    } else if (item.codigoInfo === "0000000877") {
      return {
        codigoCobertura: item?.codigoInfo,
        elementosOpcionales: [
          {
            nombreElemento: "cuota",
            valorAlfanumerico: "",
            valorNumerico: item?.cuota,
          },
          {
            nombreElemento: "sumaAsegurada",
            valorAlfanumerico: "",
            valorNumerico: item?.sumaAsegurada,
          },
          {
            nombreElemento: "aplicaIndemnizacionReposicion",
            valorAlfanumerico: "",
            valorNumerico: 0,
          },
          {
            nombreElemento: "aplicaRoboBienesIntemperie",
            valorAlfanumerico: "",
            valorNumerico: 0,
          },
          {
            nombreElemento: "descuentoMedidasSeguridad",
            valorAlfanumerico: "",
            valorNumerico: 0,
          },
          {
            nombreElemento: "recargoIndemnizacionValorReposicion",
            valorAlfanumerico: "",
            valorNumerico: 0,
          },
          {
            nombreElemento: "recargoBienesIntemperie",
            valorAlfanumerico: "",
            valorNumerico: 0,
          },
          {
            nombreElemento: "unidadDeducible",
            valorAlfanumerico: item?.unidadDeducible,
            valorNumerico: 0,
          },
          {
            nombreElemento: "valorDeducible",
            valorAlfanumerico: "",
            valorNumerico: item?.valorDeducible || 0,
          },
          {
            nombreElemento: "factorLinea",
            valorAlfanumerico: "",
            valorNumerico: item?.factorLinea[0],
          },
          {
            nombreElemento: "factorRenovacion",
            valorAlfanumerico: "",
            valorNumerico: item?.factorRenovacion || 0,
          },
          {
            nombreElemento: "opcionCalculo",
            valorAlfanumerico: "M",
            valorNumerico: 0,
          },
        ],
      };
    } else if (item.codigoInfo === "0000000874") {
      return {
        codigoCobertura: item?.codigoInfo,
        elementosOpcionales: [
          {
            nombreElemento: "cuota",
            valorAlfanumerico: "",
            valorNumerico: item?.cuota,
          },
          {
            nombreElemento: "sumaAsegurada",
            valorAlfanumerico: "",
            valorNumerico: item?.sumaAsegurada,
          },
          {
            nombreElemento: "aplicaIndemnizacionReposicion",
            valorAlfanumerico: "",
            valorNumerico: 0,
          },
          {
            nombreElemento: "aplicaRoboBienesIntemperie",
            valorAlfanumerico: "",
            valorNumerico: 0,
          },
          {
            nombreElemento: "descuentoMedidasSeguridad",
            valorAlfanumerico: "",
            valorNumerico: medidaDeSeguridad,
          },
          {
            nombreElemento: "recargoIndemnizacionValorReposicion",
            valorAlfanumerico: "",
            valorNumerico: 0,
          },
          {
            nombreElemento: "recargoBienesIntemperie",
            valorAlfanumerico: "",
            valorNumerico: 0,
          },
          {
            nombreElemento: "unidadDeducible",
            valorAlfanumerico: item?.unidadDeducible,
            valorNumerico: 0,
          },
          {
            nombreElemento: "valorDeducible",
            valorAlfanumerico: "",
            valorNumerico: item?.valorDeducible || 0,
          },
          {
            nombreElemento: "factorLinea",
            valorAlfanumerico: "",
            valorNumerico: item?.factorLinea[0],
          },
          {
            nombreElemento: "factorRenovacion",
            valorAlfanumerico: "",
            valorNumerico: item?.factorRenovacion || 0,
          },
          {
            nombreElemento: "opcionCalculo",
            valorAlfanumerico: "M",
            valorNumerico: 0,
          },
        ],
      };
    } else if (item.codigoInfo === "0000000871") {
      return {
        codigoCobertura: item?.codigoInfo,
        elementosOpcionales: [
          {
            nombreElemento: "cuota",
            valorAlfanumerico: "",
            valorNumerico: item?.cuota,
          },
          {
            nombreElemento: "sumaAsegurada",
            valorAlfanumerico: "",
            valorNumerico: item?.sumaAsegurada,
          },
          {
            nombreElemento: "recargoTipoConstructivo",
            valorAlfanumerico: "",
            valorNumerico: item?.recargoTipoConstructivo || 0,
          },
          {
            nombreElemento: "factorLinea",
            valorAlfanumerico: "",
            valorNumerico: item?.factorLinea[0],
          },
          {
            nombreElemento: "factorRenovacion",
            valorAlfanumerico: "",
            valorNumerico: item?.factorRenovacion || 0,
          },
          {
            nombreElemento: "opcionCalculo",
            valorAlfanumerico: "M",
            valorNumerico: 0,
          },
        ],
      };
    } else if (
      item.codigoInfo === "0000001325" ||
      item.codigoInfo === "0000001324" ||
      item.codigoInfo === "0000000866" ||
      item.codigoInfo === "0000000869"
    ) {
      return {
        codigoCobertura: item?.codigoInfo,
        elementosOpcionales: [
          {
            nombreElemento: "cuota",
            valorAlfanumerico: "",
            valorNumerico: item?.cuota,
          },
          {
            nombreElemento: "porcentajeCoaseguro",
            valorAlfanumerico: "",
            valorNumerico: item?.porcentajeCoaseguro,
          },
          {
            nombreElemento: "sumaAsegurada",
            valorAlfanumerico: "",
            valorNumerico: item?.sumaAsegurada,
          },
          {
            nombreElemento: "unidadDeducible",
            valorAlfanumerico: item?.unidadDeducible,
            valorNumerico: 0,
          },
          {
            nombreElemento: "valorDeducible",
            valorAlfanumerico: "",
            valorNumerico: item?.valorDeducible,
          },
          {
            nombreElemento: "coaseguroTarifa",
            valorAlfanumerico: "",
            valorNumerico: item?.coaseguroTarifa,
          },
          {
            nombreElemento: "factorLinea",
            valorAlfanumerico: "",
            valorNumerico: item?.factorLinea[0],
          },
          {
            nombreElemento: "factorRenovacion",
            valorAlfanumerico: "",
            valorNumerico: item?.factorRenovacion || 0,
          },
          {
            nombreElemento: "opcionCalculo",
            valorAlfanumerico: "M",
            valorNumerico: 0,
          },
        ],
      };
    } else {
      return {
        codigoCobertura: item?.codigoInfo,
        elementosOpcionales: [
          {
            nombreElemento: "cuota",
            valorAlfanumerico: "",
            valorNumerico: item?.cuota,
          },
          {
            nombreElemento: "sumaAsegurada",
            valorAlfanumerico: "",
            valorNumerico: item?.sumaAsegurada,
          },
          {
            nombreElemento: "recargoTipoConstructivo",
            valorAlfanumerico: "",
            valorNumerico: item?.recargoTipoConstructivo || 0,
          },
          {
            nombreElemento: "unidadDeducible",
            valorAlfanumerico: "",
            valorNumerico: item?.unidadDeducible || 0,
          },
          {
            nombreElemento: "valorDeducible",
            valorAlfanumerico: "",
            valorNumerico: item?.valorDeducible || 0,
          },
          {
            nombreElemento: "factorLinea",
            valorAlfanumerico: "",
            valorNumerico: item?.factorLinea[0],
          },
          {
            nombreElemento: "factorRenovacion",
            valorAlfanumerico: "",
            valorNumerico: item?.factorRenovacion || 0,
          },
          {
            nombreElemento: "opcionCalculo",
            valorAlfanumerico: "M",
            valorNumerico: 0,
          },
        ],
      };
    }
  });

  const edadInmueble =
    currentYear - datosEmision?.edad ||
    currentYear - globalDatosValuacion.datosValuacion?.edad;

  const dataEmision = {
    idCotizacion: globalIdCotizacion?.idCotizacion,
    moneda: 1,
    costo:
      globalTipoPago === "Mensual"
        ? globalNuevoCosto?.primerRecibo
        : globalNuevoCosto?.pagoTotal,
    paqueteContratado: getPaqueteContratado(globalPaqueteElegido?.nombre),
    formaPago: globalTipoPago === "Mensual" ? 4 : 1,
    valorDestructible:
      globalDatosAsegurado.datosDelAsegurado?.valorInmueble ||
      globalSumaAsegurada ||
      "",
    indicadorDePropietarioOArrenda:
      globalDatosAsegurado.datosDelAsegurado?.perfilContratante ||
      datosEmision?.perfilContratante,
    dataCasaAsegurar: {
      calle: datosEmision?.calle || globalDatosValuacion.datosValuacion?.calle,
      numExterior:
        datosEmision?.numExterior ||
        globalDatosValuacion.datosValuacion?.numExterior,
      numInterior:
        datosEmision?.numInterior ||
        globalDatosValuacion.datosValuacion?.numInterior ||
        "",
      colonia:
        datosEmision?.colonia || globalDatosValuacion.datosValuacion?.colonia,
      municipio:
        datosEmision?.municipio ||
        globalDatosValuacion.datosValuacion?.municipio,
      estado:
        datosEmision?.estado || globalDatosValuacion.datosValuacion?.estado,
      cveColonia:
        datosEmision?.cveColonia ||
        globalDatosValuacion.datosValuacion?.cveColonia,
      cveMunicipio:
        datosEmision?.cveMunicipio ||
        globalDatosValuacion.datosValuacion?.cveMunicipio,
      cveEstado:
        datosEmision?.cveEstado ||
        globalDatosValuacion.datosValuacion?.cveEstado,
      cpInmueble:
        datosEmision?.cpInmueble ||
        globalDatosAsegurado.datosDelAsegurado?.codigoPostal,
      cpContratante: datosEmision?.cpContratante,
      claseInmueble:
        globalDatosValuacion.datosValuacion?.claseInmueble ||
        datosEmision?.claseInmueble,
      metrosConstruidos:
        datosEmision?.metrosConstruidos ||
        globalDatosValuacion.datosValuacion?.metrosConstruidos,
      metrosCuadrados:
        datosEmision?.metrosCuadrados ||
        globalDatosValuacion.datosValuacion?.metrosTerreno,
      alarmaLocal: globalAlarmaLocal,
      esLineaPlaya: globalDatosAsegurado.datosDelAsegurado?.esPlaya,
      esLagoLaguna: globalDatosAsegurado.datosDelAsegurado?.esLaguna,
      tipoInmueble:
        globalDatosValuacion.datosValuacion?.tipoInmueble ||
        datosEmision?.tipoInmueble,
      edad: edadInmueble,
    },
    mismoDomicilio: checkedDatosRegimen ? "1" : "0",
    dataDomicilioFiscal: {
      calle: checkedDatosRegimen
        ? datosEmision?.calle || globalDatosValuacion.datosValuacion?.calle
        : datosRegimenFiscal?.calle,
      numExterior: checkedDatosRegimen
        ? datosEmision?.numExterior ||
          globalDatosValuacion.datosValuacion?.numExterior
        : datosRegimenFiscal?.numExterior,
      numInterior: checkedDatosRegimen
        ? datosEmision?.numInterior ||
          globalDatosValuacion.datosValuacion?.numInterior ||
          ""
        : datosRegimenFiscal?.numInterior || "",
      colonia: checkedDatosRegimen
        ? datosEmision?.colonia || globalDatosValuacion.datosValuacion?.colonia
        : datosRegimenFiscal?.colonia,
      cveColonia: checkedDatosRegimen
        ? datosEmision?.cveColonia ||
          globalDatosValuacion.datosValuacion?.cveColonia
        : datosRegimenFiscal?.cveColonia,
      municipio: checkedDatosRegimen
        ? datosEmision?.municipio ||
          globalDatosValuacion.datosValuacion?.municipio
        : datosRegimenFiscal?.municipio,
      cveMunicipio: checkedDatosRegimen
        ? datosEmision?.cveMunicipio ||
          globalDatosValuacion.datosValuacion?.cveMunicipio
        : datosRegimenFiscal?.cveMunicipio,
      estado: checkedDatosRegimen
        ? datosEmision?.estado || globalDatosValuacion.datosValuacion?.estado
        : datosRegimenFiscal?.estado,
      cveEstado: checkedDatosRegimen
        ? datosEmision?.cveEstado ||
          globalDatosValuacion.datosValuacion?.cveEstado
        : datosRegimenFiscal?.cveEstado,
      cp: checkedDatosRegimen
        ? datosEmision?.cpInmueble ||
          globalDatosAsegurado.datosDelAsegurado?.codigoPostal
        : datosRegimenFiscal?.codigoPostal,
    },
    claveRegimenFiscalSat: datosRegimenFiscal?.regimenFiscal,
    regimenCapital: datosRegimenFiscal?.descripcion,
    razonSocial:
      datosEmisor?.nombre ||
      globalDatosAsegurado.datosDelAsegurado?.nombre ||
      globalUsuarioInterno?.nombre +
        " " +
        datosEmisor?.aPaterno +
        " " +
        datosEmisor?.aMaterno,
    datosEmisor: {
      soyTitular: isTitular ? 1 : 0,
      nombre: datosEmisor?.nombre,
      aPaterno: datosEmisor?.aPaterno,
      aMaterno: datosEmisor?.aMaterno,
      fechaNacimiento: moment(
        datosEmisor?.fechaNacimiento,
        "DD-MM-YYYY"
      ).format("YYYY-MM-DD"),
      rfc: datosEmisor?.rfc,
      telefono: datosEmisor?.telefono,
      cElectronico: datosEmisor?.cElectronico,
    },
    coberturas:
      bodyCoberturas && bodyAsistencias && bodyResponsabilidad
        ? [...bodyCoberturas, ...bodyAsistencias, ...bodyResponsabilidad]
        : [],
    coberturasComision: bodyCoberturasComision,
  };

  const handleValidarEmisionPoliza = () => {
    if (
      dataEmision.dataCasaAsegurar.calle &&
      dataEmision.dataCasaAsegurar.numExterior &&
      dataEmision.dataCasaAsegurar.colonia &&
      dataEmision.dataCasaAsegurar.estado &&
      dataEmision.dataCasaAsegurar.municipio &&
      dataEmision.dataCasaAsegurar.cpInmueble &&
      dataEmision.dataCasaAsegurar.cpContratante &&
      dataEmision.dataCasaAsegurar.claseInmueble &&
      (globalDatosValuacion?.datosValuacion?.metrosConstruidos
        ? globalDatosValuacion?.datosValuacion?.metrosConstruidos
        : dataEmision.dataCasaAsegurar.metrosConstruidos) &&
      (datosEmision.metrosConstruidos?.length >= 2 ||
        globalDatosValuacion?.datosValuacion?.metrosConstruidos) &&
      (globalDatosValuacion?.datosValuacion?.metrosTerreno
        ? globalDatosValuacion?.datosValuacion?.metrosTerreno
        : dataEmision.dataCasaAsegurar.metrosCuadrados) &&
      (datosEmision.metrosCuadrados?.length >= 2 ||
        globalDatosValuacion?.datosValuacion?.metrosTerreno?.length >= 2) &&
      dataEmision?.dataCasaAsegurar.tipoInmueble &&
      (globalDatosValuacion?.datosValuacion?.edad
        ? globalDatosValuacion?.datosValuacion?.edad
        : datosEmision?.edad) &&
      (datosEmision?.edad?.toString()?.length === 4 ||
        globalDatosValuacion?.datosValuacion?.edad?.toString()?.length === 4) &&
      (datosEmision?.edad <= currentYear ||
        globalDatosValuacion?.datosValuacion?.edad <= currentYear) &&
      dataEmision.claveRegimenFiscalSat &&
      dataEmision.datosEmisor.nombre &&
      dataEmision.datosEmisor.aPaterno &&
      dataEmision.datosEmisor.aMaterno &&
      dataEmision.datosEmisor.fechaNacimiento &&
      datosEmisor?.fechaNacimiento !== undefined &&
      datosEmisor?.fechaNacimiento !== "" &&
      fechaRegex.test(datosEmisor?.fechaNacimiento) &&
      fechaIgualOMenor === true &&
      dataEmision.datosEmisor.rfc &&
      dataEmision?.datosEmisor.rfc?.length === 13 &&
      dataEmision.datosEmisor.telefono &&
      dataEmision.datosEmisor.telefono?.length === 10 &&
      dataEmision.datosEmisor.cElectronico &&
      dataEmision.dataDomicilioFiscal.calle &&
      dataEmision.dataDomicilioFiscal.numExterior &&
      dataEmision.dataDomicilioFiscal.colonia &&
      dataEmision.dataDomicilioFiscal.municipio &&
      dataEmision.dataDomicilioFiscal.estado &&
      dataEmision.dataDomicilioFiscal.cp
    ) {
      setIsLoading(true);
      validarEmitir(dataEmision, globalTokenCotizacion)
        .then((response) => {
          if (response && response.data.success === 1) {
            setGlobalIdDatosEmitir(response.data?.idDatosEmitir);
            setGlobalDatosEmision(dataEmision);
            const emision = {
              idDatosEmitir: response.data?.idDatosEmitir,
              dataEmision: dataEmision,
              regimenFiscalDescripcion: datosRegimenFiscal?.descripcion,
            };
            sessionStorage.setItem("datosEmision", JSON.stringify(emision));
            TagManager.dataLayer({
              dataLayer: {
                event: "hogar_formularios_contratacion",
              },
            });
            setIsLoading(false);
            navigate("/pago");
          } else {
            const errores = response?.data?.errores?.map((item, index) => {
              let key = Object?.keys(item)[0];

              return <div key={key}>{item[key]} </div>;
            });
            showNotification({
              id: "errorEmision",
              title: "Error al procesar los datos",
              message:
                errores ||
                response?.data?.message ||
                "¡Ups! No podemos continuar en este momento. Por favor, revisa la información proporcionada, asegúrate de que todos los campos estén completos y que los datos sean correctos.",
              color: "red",
              autoClose: 5000,
            });
            setIsLoading(false);
          }
        })
        .catch(function (error) {
          showNotification({
            id: "errorEmision",
            title: "Error al procesar los datos",
            message:
              "¡Ups! No podemos continuar en este momento. Por favor, revisa la información proporcionada, asegúrate de que todos los campos estén completos y que los datos sean correctos.",
            color: "red",
            autoClose: 5000,
          });
          setIsLoading(false);
        });
    } else {
      if (!dataEmision.dataCasaAsegurar.calle) {
        setCalleError(true);
      }
      if (!dataEmision.dataCasaAsegurar.numExterior) {
        setNumExteriorError(true);
      }
      if (!dataEmision.dataCasaAsegurar.colonia) {
        setColoniaError(true);
      }
      if (!dataEmision.dataCasaAsegurar.estado) {
        setEstadoError(true);
      }
      if (!dataEmision.dataCasaAsegurar.municipio) {
        setMunicipioError(true);
      }
      if (!dataEmision.dataCasaAsegurar.cpInmueble) {
        setCodigoPostalInmuebleError(true);
      }
      if (!dataEmision.dataCasaAsegurar.cpContratante) {
        setCodigoPostalContratanteError(true);
      }
      if (!dataEmision.dataCasaAsegurar.claseInmueble) {
        setClaseInmuebleError(true);
      }
      if (!dataEmision.dataCasaAsegurar.metrosConstruidos) {
        setMetrosConstruidosError(true);
      }
      if (datosEmision?.metrosConstruidos?.length < 2) {
        setMetrosConstruidosLengthError(true);
      }
      if (!dataEmision.dataCasaAsegurar.metrosCuadrados) {
        setMetrosCuadradosError(true);
      }
      if (datosEmision?.metrosCuadrados?.length < 2) {
        setMetrosCuadradosLengthError(true);
      }
      if (!dataEmision.dataCasaAsegurar.tipoInmueble) {
        setTipoInmuebleError(true);
      }
      if (!datosEmision?.edad || !globalDatosValuacion?.datosValuacion?.edad) {
        setAnioConstruccionError(true);
      }
      if (
        datosEmision?.edad?.toString()?.length !== 4 &&
        globalDatosValuacion?.datosValuacion?.edad?.toString()?.length !== 4
      ) {
        setAnioConstruccionLongitudError(true);
      }
      if (
        datosEmision?.edad > currentYear &&
        globalDatosValuacion?.datosValuacion?.edad > currentYear
      ) {
        setAnioConstruccionActualError(true);
      }
      if (!dataEmision.claveRegimenFiscalSat) {
        setClaveFiscalError(true);
      }
      if (!dataEmision.datosEmisor.nombre) {
        setNombreError(true);
      }
      if (!dataEmision.datosEmisor.aPaterno) {
        setAPaternoError(true);
      }
      if (!dataEmision.datosEmisor.aMaterno) {
        setAMaternoError(true);
      }
      if (datosEmisor?.fechaNacimiento === undefined) {
        setFechaNacimientoError(true);
      }
      if (datosEmisor?.fechaNacimiento === "") {
        setFechaNacimientoError(true);
      }
      if (!fechaRegex?.test(datosEmisor?.fechaNacimiento)) {
        setFechaInvalidaError(true);
      }
      if (fechaIgualOMenor === false) {
        setFechaInvalidaError(true);
      }
      if (!dataEmision.datosEmisor.rfc) {
        setRfcError(true);
      }
      if (dataEmision?.datosEmisor?.rfc?.length !== 13) {
        setRfcLongitudError(true);
      }
      if (!dataEmision.datosEmisor.telefono) {
        setTelefonoError(true);
      }
      if (dataEmision?.datosEmisor?.telefono?.length !== 10) {
        setTelefonoLongitudError(true);
      }
      if (!dataEmision.datosEmisor.cElectronico) {
        setCorreoError(true);
      }
      showNotification({
        id: "errorFaltaDatos",
        title: "No fue posible procesar los datos",
        message: "Es posible que la información esté incompleta",
        color: "red",
        autoClose: 5000,
      });
      setIsLoading(false);
    }
  };

  const datosValuacionExist = JSON.stringify(globalDatosValuacion) !== "{}";

  return (
    <ComparadorContainer theme={colorScheme}>
      <HeaderWOOW
        subtitle="¡Tenemos esto para ti!"
        conRegresar
        pagina="/coberturas"
      />
      <HeroPages img={headerImage} subtitle="Bríndanos los siguientes datos" />
      <ComparadorBody>
        <ColumnaPrincipalContainer theme={colorScheme}>
          {!isResponsive && globalTipoCotizador === "externo" && (
            <div className="breadcrumbs">
              <Breadcrumbs active={4} />
            </div>
          )}
          <MantineStyledAccordion
            theme={colorScheme}
            defaultValue="casaAsegurar"
          >
            <Accordion.Item value="casaAsegurar">
              <Accordion.Control>
                Domicilio de la casa a asegurar
              </Accordion.Control>
              <Accordion.Panel>
                <div className="flex-inputs">
                  <div className="input-con-error">
                    <MantineTextInputStyled
                      theme={colorScheme}
                      pattern="^[a-zA-Z][a-zA-Z0-9-_\.]{1,20}$"
                      label="Calle"
                      value={
                        datosEmision?.calle ||
                        globalDatosValuacion.datosValuacion?.calle ||
                        ""
                      }
                      required
                      disabled={globalDatosValuacion.datosValuacion?.calle}
                      placeholder="Calle"
                      variant="filled"
                      hideControls
                      onChange={(e) => {
                        setDatosEmision({
                          ...datosEmision,
                          calle: e.currentTarget.value,
                        });
                      }}
                    />
                    {calleError && (
                      <span className="error">{errores.campoVacio}</span>
                    )}
                  </div>
                  <div className="input-con-error">
                    <MantineTextInputStyled
                      theme={colorScheme}
                      label="Número exterior"
                      value={
                        datosEmision?.numExterior ||
                        globalDatosValuacion.datosValuacion?.numExterior ||
                        ""
                      }
                      required
                      maxLength={20}
                      disabled={
                        globalDatosValuacion.datosValuacion?.numExterior
                      }
                      placeholder="Número exterior"
                      variant="filled"
                      hideControls
                      onChange={(e) => {
                        setDatosEmision({
                          ...datosEmision,
                          numExterior: e.target.value,
                        });
                      }}
                    />
                    {numExteriorError && (
                      <span className="error">{errores.campoVacio}</span>
                    )}
                  </div>
                  <div className="input-con-error">
                    <MantineTextInputStyled
                      theme={colorScheme}
                      label="Número interior"
                      maxLength={20}
                      value={
                        datosEmision?.numInterior ||
                        globalDatosValuacion.datosValuacion?.numInterior ||
                        ""
                      }
                      placeholder="Número interior"
                      variant="filled"
                      hideControls
                      onChange={(e) => {
                        setDatosEmision({
                          ...datosEmision,
                          numInterior: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="flex-inputs">
                  <div className="input-con-error">
                    <MantineSelectStyled
                      theme={colorScheme}
                      label="Colonia"
                      rightSection={
                        <img src={chevronDown} alt="flecha abajo" width="20" />
                      }
                      value={
                        !datosValuacionExist
                          ? `${datosEmision?.colonia}/${datosEmision?.cveColonia}`
                          : `${globalDatosValuacion?.datosValuacion?.colonia}/${globalDatosValuacion?.datosValuacion?.cveColonia}`
                      }
                      disabled={datosValuacionExist}
                      rightSectionWidth={50}
                      styles={{ rightSection: { pointerEvents: "none" } }}
                      data={colonia || []}
                      required
                      placeholder="Colonia"
                      variant="filled"
                      hideControls
                      onChange={(value) => {
                        setDatosEmision({
                          ...datosEmision,
                          colonia: value.split("/").slice(-2)[0],
                          cveColonia: value.split("/").slice(-1)[0],
                        });
                      }}
                    />
                    {coloniaError && (
                      <span className="error">{errores.campoVacio}</span>
                    )}
                  </div>
                  <div className="input-con-error">
                    <MantineSelectStyled
                      theme={colorScheme}
                      label="Municipio"
                      rightSection={
                        <img src={chevronDown} alt="flecha abajo" width="20" />
                      }
                      rightSectionWidth={50}
                      value={
                        !datosValuacionExist
                          ? `${datosEmision?.municipio}/${datosEmision?.cveMunicipio}`
                          : `${globalDatosValuacion?.datosValuacion?.municipio}/${globalDatosValuacion?.datosValuacion?.cveMunicipio}`
                      }
                      disabled={datosValuacionExist}
                      styles={{ rightSection: { pointerEvents: "none" } }}
                      data={municipio || []}
                      required
                      placeholder="Municipio"
                      variant="filled"
                      hideControls
                      onChange={(value) => {
                        setDatosEmision({
                          ...datosEmision,
                          municipio: value.split("/").slice(-2)[0],
                          cveMunicipio: value.split("/").slice(-1)[0],
                        });
                      }}
                    />
                    {municipioError && (
                      <span className="error">{errores.campoVacio}</span>
                    )}
                  </div>
                  <div className="input-con-error">
                    <MantineSelectStyled
                      theme={colorScheme}
                      label="Estado"
                      rightSection={
                        <img src={chevronDown} alt="flecha abajo" width="20" />
                      }
                      rightSectionWidth={50}
                      value={
                        !datosValuacionExist
                          ? `${datosEmision?.estado}/${datosEmision?.cveEstado}`
                          : `${globalDatosValuacion?.datosValuacion?.estado}/${globalDatosValuacion?.datosValuacion?.cveEstado}`
                      }
                      disabled={datosValuacionExist}
                      styles={{ rightSection: { pointerEvents: "none" } }}
                      data={estado || []}
                      required
                      placeholder="Estado"
                      variant="filled"
                      hideControls
                      onChange={(value) => {
                        setDatosEmision({
                          ...datosEmision,
                          estado: value.split("/").slice(-2)[0],
                          cveEstado: value.split("/").slice(-1)[0],
                        });
                      }}
                    />
                    {estadoError && (
                      <span className="error">{errores.campoVacio}</span>
                    )}
                  </div>
                </div>
                <div className="flex-inputs">
                  <div className="input-con-error">
                    <MantineTextInputStyled
                      theme={colorScheme}
                      label="Código Postal de Inmueble"
                      value={
                        datosEmision?.cpInmueble ||
                        globalDatosAsegurado.datosDelAsegurado?.codigoPostal ||
                        ""
                      }
                      disabled={
                        globalDatosAsegurado.datosDelAsegurado?.codigoPostal
                      }
                      required
                      max="99999"
                      minLength="5"
                      maxLength="5"
                      pattern="[0-9]+"
                      placeholder="Código Postal de Inmueble"
                      variant="filled"
                      hideControls
                      onChange={(value) => {
                        setDatosEmision({
                          ...datosEmision,
                          cpInmueble: value,
                        });
                      }}
                    />
                  </div>
                  <div className="input-con-error">
                    <MantineTextInputStyled
                      theme={colorScheme}
                      label="Código Postal del contratante"
                      value={datosEmision?.cpContratante || ""}
                      required
                      minLength={1}
                      maxLength={5}
                      pattern="[0-9]+"
                      placeholder="Código Postal del Contratante"
                      variant="filled"
                      hideControls
                      onChange={(e) => {
                        setDatosEmision({
                          ...datosEmision,
                          cpContratante: e.currentTarget.value.replace(
                            /[^0-9]/g,
                            ""
                          ),
                        });
                      }}
                    />
                    {codigoPostalContratanteError && (
                      <span className="error">{errores.campoVacio}</span>
                    )}
                  </div>
                  <div className="input-con-error">
                    <MantineSelectStyled
                      theme={colorScheme}
                      rightSection={
                        <img src={chevronDown} alt="flecha abajo" width="20" />
                      }
                      rightSectionWidth={50}
                      styles={{ rightSection: { pointerEvents: "none" } }}
                      label="Perfil del contratante"
                      required
                      searchable
                      data={perfilContratante || []}
                      value={
                        globalDatosAsegurado?.datosDelAsegurado
                          ?.perfilContratante ||
                        datosEmision?.perfilContratante ||
                        ""
                      }
                      disabled={
                        globalDatosAsegurado.datosDelAsegurado
                          ?.perfilContratante || datosEmision?.perfilContratante
                      }
                      placeholder="Perfil del contratante"
                      variant="filled"
                      hideControls
                      onChange={(value) => {
                        setDatosEmision({
                          ...datosEmision,
                          perfilContratante: value,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="flex-inputs">
                  <div className="input-con-error">
                    <MantineSelectStyled
                      theme={colorScheme}
                      searchable
                      rightSection={
                        <img src={chevronDown} alt="flecha abajo" width="20" />
                      }
                      rightSectionWidth={50}
                      styles={{ rightSection: { pointerEvents: "none" } }}
                      label="Tipo de inmueble"
                      data={tipoInmueble || []}
                      value={
                        !datosValuacionExist
                          ? datosEmision?.tipoInmueble
                          : globalDatosValuacion?.datosValuacion?.tipoInmueble
                      }
                      disabled={datosValuacionExist}
                      required
                      placeholder="Tipo de inmueble"
                      variant="filled"
                      hideControls
                      onChange={(value) => {
                        setDatosEmision({
                          ...datosEmision,
                          tipoInmueble: value,
                        });
                      }}
                    />
                    {tipoInmuebleError && (
                      <span className="error">{errores.campoVacio}</span>
                    )}
                  </div>
                  <div className="input-con-error">
                    <MantineSelectStyled
                      theme={colorScheme}
                      searchable
                      rightSection={
                        <img src={chevronDown} alt="flecha abajo" width="20" />
                      }
                      rightSectionWidth={50}
                      styles={{ rightSection: { pointerEvents: "none" } }}
                      label="Clase de inmueble"
                      data={claseInmueble || []}
                      value={
                        !datosValuacionExist
                          ? datosEmision?.claseInmueble
                          : globalDatosValuacion?.datosValuacion?.claseInmueble
                      }
                      disabled={datosValuacionExist}
                      required
                      placeholder="Clase de inmueble"
                      variant="filled"
                      hideControls
                      onChange={(value) => {
                        setDatosEmision({
                          ...datosEmision,
                          claseInmueble: value,
                        });
                      }}
                    />
                    {claseInmuebleError && (
                      <span className="error">{errores.campoVacio}</span>
                    )}
                  </div>
                  <div className="input-con-error">
                    <MantineTextInputStyled
                      theme={colorScheme}
                      label="Metros cuadrados del terreno"
                      value={
                        datosEmision?.metrosCuadrados ||
                        globalDatosValuacion?.datosValuacion?.metrosTerreno ||
                        ""
                      }
                      required
                      defaultValue={
                        globalDatosValuacion.datosValuacion?.metrosTerreno || ""
                      }
                      disabled={
                        globalDatosValuacion.datosValuacion?.metrosTerreno
                      }
                      minLength={1}
                      maxLength={10}
                      pattern="[0-9]+"
                      placeholder="Metros cuadrados del terreno"
                      variant="filled"
                      hideControls
                      onChange={(e) => {
                        setDatosEmision({
                          ...datosEmision,
                          metrosCuadrados: e.currentTarget.value?.replace(
                            /[^0-9]/g,
                            ""
                          ),
                        });
                      }}
                    />
                    {metrosCuadradosError && (
                      <span className="error">{errores.campoVacio}</span>
                    )}
                    {datosEmision?.metrosCuadrados > 0 &&
                      metrosCuadradosLengthError && (
                        <span className="error">{errores.longitudMetros}</span>
                      )}
                  </div>
                </div>
                <div className="flex-inputs">
                  <div className="input-con-error">
                    <MantineTextInputStyled
                      theme={colorScheme}
                      label="Metros construidos"
                      value={
                        datosEmision?.metrosConstruidos ||
                        globalDatosValuacion?.datosValuacion
                          ?.metrosConstruidos ||
                        ""
                      }
                      required
                      disabled={
                        globalDatosValuacion?.datosValuacion?.metrosConstruidos
                      }
                      defaultValue={
                        globalDatosValuacion?.datosValuacion
                          ?.metrosConstruidos || ""
                      }
                      max="10"
                      minLength={1}
                      maxLength={10}
                      pattern="[0-9]+"
                      placeholder="Metros construidos"
                      variant="filled"
                      hideControls
                      onChange={(e) => {
                        setDatosEmision({
                          ...datosEmision,
                          metrosConstruidos: e.currentTarget.value?.replace(
                            /[^0-9]/g,
                            ""
                          ),
                        });
                      }}
                    />
                    {metrosConstruidosError && (
                      <span className="error">{errores.campoVacio}</span>
                    )}
                    {datosEmision?.metrosConstruidos?.length > 0 &&
                      metrosConstruidosLengthError && (
                        <span className="error">{errores.longitudMetros}</span>
                      )}
                  </div>
                  <div className="input-con-error">
                    <MantineTextInputStyled
                      theme={colorScheme}
                      label="Año de construcción"
                      value={
                        !globalDatosValuacion?.datosValuacion?.edad
                          ? datosEmision?.edad
                          : globalDatosValuacion?.datosValuacion?.edad
                      }
                      pattern="[0-9]+"
                      max="4"
                      minLength={1}
                      maxLength={4}
                      required
                      disabled={globalDatosValuacion.datosValuacion?.edad}
                      placeholder="Ej. 1950"
                      variant="filled"
                      hideControls
                      onChange={(e) => {
                        setDatosEmision({
                          ...datosEmision,
                          edad: e.currentTarget.value?.replace(/[^0-9]/g, ""),
                        });
                      }}
                    />
                    {anioConstruccionError && (
                      <span className="error">{errores.campoVacio}</span>
                    )}
                    {datosEmision?.edad?.length > 0 &&
                      anioConstruccionLongitudError && (
                        <span className="error">
                          {errores.longitudAnioConstruccion}
                        </span>
                      )}
                    {datosEmision?.edad?.length === 4 &&
                      anioConstruccionActualError && (
                        <span className="error">
                          {errores.edadInmuebleActual}
                        </span>
                      )}
                  </div>
                  <div className="input-con-error">
                    <MantineSelectStyled
                      theme={colorScheme}
                      searchable
                      label="Medida de seguridad"
                      rightSection={
                        <img src={chevronDown} alt="flecha abajo" width="20" />
                      }
                      rightSectionWidth={50}
                      styles={{ rightSection: { pointerEvents: "none" } }}
                      data={medidasSeguridad || []}
                      value={
                        globalDatosAsegurado?.datosDelAsegurado?.medidasDeSeguridad?.toString() ||
                        datosEmision?.medidasDeSeguridad
                      }
                      disabled={globalDatosAsegurado?.datosDelAsegurado?.medidasDeSeguridad?.toString()}
                      required
                      placeholder="Cuentas con alguna medida de seguridad"
                      variant="filled"
                      hideControls
                      onChange={(value) => {
                        setDatosEmision({
                          ...datosEmision,
                          alarmaLocal: value,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="switch-datos-regimen">
                  <span>
                    El domicilio de la casa asegurar es el mismo que el
                    domicilio fiscal{" "}
                  </span>
                  <MantineStyledSwitch
                    size="md"
                    label=""
                    onLabel="Si"
                    offLabel="No"
                    checked={checkedDatosRegimen}
                    onChange={(event) =>
                      setCheckedDatosRegimen(event.currentTarget.checked)
                    }
                  />
                </div>
              </Accordion.Panel>
            </Accordion.Item>
          </MantineStyledAccordion>
          {!checkedDatosRegimen && (
            <MantineStyledAccordion theme={colorScheme}>
              <Accordion.Item value="regimenFiscal">
                <Accordion.Control>Datos del Régimen Fiscal</Accordion.Control>
                <Accordion.Panel>
                  <span className="tooltip-regimen">
                    ¿Dónde lo puedo consultar?
                    <MantineStyledTooltip
                      withinPortal={false}
                      events={{ touch: true }}
                      label={
                        <>
                          <ul>
                            <span className="bold">
                              Desde el portal del SAT
                            </span>
                            <li>
                              {" "}
                              Si cuentas con tu contraseña para el
                              <br />
                              portal SAT o tu firma electrónica (e.firma)
                            </li>
                            <span className="bold">
                              - Desde tu celular
                              <br />
                            </span>
                            <span className="bold">- En oficinas del SAT</span>
                          </ul>
                        </>
                      }
                    >
                      <div className="question">?</div>
                    </MantineStyledTooltip>
                  </span>
                  <div className="flex-inputs" style={{ marginTop: "10px" }}>
                    <div className="input-con-error">
                      <MantineTextInputStyled
                        theme={colorScheme}
                        label="Código Postal"
                        value={datosRegimenFiscal?.codigoPostal || ""}
                        required
                        max="99999"
                        minLength="5"
                        maxLength="5"
                        pattern="[0-9]+"
                        placeholder="Código Postal"
                        variant="filled"
                        hideControls
                        onChange={(e) => {
                          setDatosRegimenFiscal({
                            ...datosRegimenFiscal,
                            codigoPostal: e.currentTarget.value,
                          });
                        }}
                      />
                      {codigoPostalFiscalError && (
                        <span className="error">{errores.campoVacio}</span>
                      )}
                    </div>
                    <div className="input-con-error">
                      <MantineTextInputStyled
                        theme={colorScheme}
                        label="Calle"
                        value={datosRegimenFiscal?.calle || ""}
                        defaultValue={
                          globalDatosValuacion.datosValuacion?.calle || ""
                        }
                        required
                        placeholder="Calle"
                        variant="filled"
                        hideControls
                        onChange={(e) => {
                          setDatosRegimenFiscal({
                            ...datosRegimenFiscal,
                            calle: e.currentTarget.value,
                          });
                        }}
                      />
                      {calleFiscalError && (
                        <span className="error">{errores.campoVacio}</span>
                      )}
                    </div>
                    <div className="input-con-error">
                      <MantineTextInputStyled
                        theme={colorScheme}
                        label="Número exterior"
                        value={
                          datosRegimenFiscal?.numExterior ||
                          globalDatosValuacion.datosValuacion?.numExterior ||
                          ""
                        }
                        required
                        placeholder="Número exterior"
                        variant="filled"
                        hideControls
                        onChange={(e) => {
                          setDatosRegimenFiscal({
                            ...datosRegimenFiscal,
                            numExterior: e.target.value,
                          });
                        }}
                      />
                      {numExteriorFiscalError && (
                        <span className="error">{errores.campoVacio}</span>
                      )}
                    </div>
                  </div>
                  <div className="flex-inputs">
                    <div className="input-con-error">
                      <MantineTextInputStyled
                        theme={colorScheme}
                        label="Número interior"
                        value={
                          datosRegimenFiscal?.numInterior ||
                          globalDatosValuacion.datosValuacion?.numInterior ||
                          ""
                        }
                        placeholder="Número interior"
                        variant="filled"
                        hideControls
                        onChange={(e) => {
                          setDatosRegimenFiscal({
                            ...datosRegimenFiscal,
                            numInterior: e.target.value,
                          });
                        }}
                      />
                    </div>
                    <div className="input-con-error">
                      <MantineSelectStyled
                        theme={colorScheme}
                        label="Colonia"
                        rightSection={
                          <img
                            src={chevronDown}
                            alt="flecha abajo"
                            width="20"
                          />
                        }
                        rightSectionWidth={50}
                        styles={{ rightSection: { pointerEvents: "none" } }}
                        data={coloniaRegimen || []}
                        required
                        placeholder="Colonia"
                        variant="filled"
                        hideControls
                        onChange={(value) => {
                          setDatosRegimenFiscal({
                            ...datosRegimenFiscal,
                            colonia: value.split("/").slice(-2)[0],
                            cveColonia: value.split("/").slice(-1)[0],
                          });
                        }}
                      />
                      {coloniaFiscalError && (
                        <span className="error">{errores.campoVacio}</span>
                      )}
                    </div>
                    <div className="input-con-error">
                      <MantineSelectStyled
                        theme={colorScheme}
                        label="Municipio"
                        rightSection={
                          <img
                            src={chevronDown}
                            alt="flecha abajo"
                            width="20"
                          />
                        }
                        rightSectionWidth={50}
                        styles={{ rightSection: { pointerEvents: "none" } }}
                        data={municipioRegimen || []}
                        required
                        placeholder="Municipio"
                        variant="filled"
                        hideControls
                        onChange={(value) => {
                          setDatosRegimenFiscal({
                            ...datosRegimenFiscal,
                            municipio: value.split("/").slice(-2)[0],
                            cveMunicipio: value.split("/").slice(-1)[0],
                          });
                        }}
                      />
                      {municipioFiscalError && (
                        <span className="error">{errores.campoVacio}</span>
                      )}
                    </div>
                  </div>
                  <div className="flex-inputs-one">
                    <div className="input-con-error">
                      <MantineSelectStyled
                        theme={colorScheme}
                        label="Estado"
                        rightSection={
                          <img
                            src={chevronDown}
                            alt="flecha abajo"
                            width="20"
                          />
                        }
                        rightSectionWidth={50}
                        styles={{ rightSection: { pointerEvents: "none" } }}
                        data={estadoRegimen || []}
                        required
                        placeholder="Estado"
                        variant="filled"
                        hideControls
                        onChange={(value) => {
                          setDatosRegimenFiscal({
                            ...datosRegimenFiscal,
                            estado: value.split("/").slice(-2)[0],
                            cveEstado: value.split("/").slice(-1)[0],
                          });
                        }}
                      />
                      {estadoFiscalError && (
                        <span className="error">{errores.campoVacio}</span>
                      )}
                    </div>
                  </div>
                </Accordion.Panel>
              </Accordion.Item>
            </MantineStyledAccordion>
          )}
          <MantineStyledAccordion
            theme={colorScheme}
            defaultValue="datosContratante"
          >
            <Accordion.Item value="datosContratante">
              <Accordion.Control>Datos del contratante</Accordion.Control>
              <Accordion.Panel>
                <div className="checkBoxs">
                  <Checkbox
                    required
                    onChange={() => {
                      setIsTitular((value) => !value);
                    }}
                    label={
                      <>
                        <span>Yo soy el titular</span>
                      </>
                    }
                  />
                </div>
                <div className="flex-inputs">
                  <div className="input-con-error">
                    <MantineTextInputStyled
                      theme={colorScheme}
                      label="Nombre"
                      value={datosEmisor?.nombre || ""}
                      required
                      maxLength={50}
                      placeholder="Nombre"
                      variant="filled"
                      hideControls
                      onChange={(e) => {
                        setDatosEmisor({
                          ...datosEmisor,
                          nombre: e.currentTarget.value?.replace(
                            /[^a-zA-Z\u00C0-\u017F\s-]/g,
                            ""
                          ),
                        });
                      }}
                    />
                    {nombreError && (
                      <span className="error">{errores.campoVacio}</span>
                    )}
                  </div>
                  <div className="input-con-error">
                    <MantineTextInputStyled
                      theme={colorScheme}
                      label="Apellido paterno"
                      value={datosEmisor?.aPaterno || ""}
                      required
                      maxLength={50}
                      placeholder="Apellido paterno"
                      variant="filled"
                      hideControls
                      onChange={(e) => {
                        setDatosEmisor({
                          ...datosEmisor,
                          aPaterno: e.currentTarget.value?.replace(
                            /[^a-zA-Z\u00C0-\u017F\s-]/g,
                            ""
                          ),
                        });
                      }}
                    />
                    {aPaternoError && (
                      <span className="error">{errores.campoVacio}</span>
                    )}
                  </div>
                  <div className="input-con-error">
                    <MantineTextInputStyled
                      theme={colorScheme}
                      label="Apellido materno"
                      value={datosEmisor?.aMaterno || ""}
                      required
                      maxLength={50}
                      placeholder="Apellido materno"
                      variant="filled"
                      hideControls
                      onChange={(e) => {
                        setDatosEmisor({
                          ...datosEmisor,
                          aMaterno: e.currentTarget.value?.replace(
                            /[^a-zA-Z\u00C0-\u017F\s-]/g,
                            ""
                          ),
                        });
                      }}
                    />
                    {aMaternoError && (
                      <span className="error">{errores.campoVacio}</span>
                    )}
                  </div>
                </div>
                <div className="flex-inputs">
                  <div className="input-con-error input-fecha">
                    <div className="label-fecha">
                      Fecha de nacimiento <span>*</span>
                    </div>
                    <InputMask
                      mask="99/99/9999"
                      placeholder="dd/mm/aaaa"
                      value={datosEmisor?.fechaNacimiento || ""}
                      onChange={(e) => {
                        setDatosEmisor({
                          ...datosEmisor,
                          fechaNacimiento: e.target.value,
                        });
                      }}
                    />
                    {fechaNacimientoError && (
                      <span className="error">{errores.campoVacio}</span>
                    )}
                    {datosEmisor?.fechaNacimiento !== "" &&
                      fechaInvalidaError && (
                        <span className="error">{errores.fechaValida}</span>
                      )}
                  </div>
                  <div className="input-con-error">
                    <MantineTextInputStyled
                      theme={colorScheme}
                      label="RFC con homoclave"
                      value={datosEmisor?.rfc || ""}
                      max="13"
                      minLength={1}
                      maxLength={13}
                      required
                      placeholder="RFC con homoclave"
                      variant="filled"
                      hideControls
                      onChange={(e) => {
                        setDatosEmisor({
                          ...datosEmisor,
                          rfc: e.currentTarget.value,
                        });
                      }}
                    />
                    {rfcError && (
                      <span className="error">{errores.campoVacio}</span>
                    )}
                    {datosEmisor?.rfc?.length > 0 && rfcLongitudError && (
                      <span className="error">{errores.longitudRfc}</span>
                    )}
                  </div>
                  <div className="input-con-error">
                    <MantineTextInputStyled
                      theme={colorScheme}
                      max="9999999999"
                      minLength="10"
                      maxLength="10"
                      pattern="[0-9]+"
                      label="Teléfono"
                      value={datosEmisor?.telefono || ""}
                      required
                      placeholder="Teléfono a 10 dígitos"
                      variant="filled"
                      hideControls
                      onChange={(e) => {
                        setDatosEmisor({
                          ...datosEmisor,
                          telefono: e.currentTarget.value.replace(
                            /[^0-9]/g,
                            ""
                          ),
                        });
                      }}
                    />
                    {telefonoError && (
                      <span className="error">{errores.campoVacio}</span>
                    )}
                    {datosEmisor?.telefono?.length > 0 &&
                      telefonoLongitudError && (
                        <span className="error">
                          {errores.longitudTelefono}
                        </span>
                      )}
                  </div>
                </div>
                <div className="flex-inputs-two">
                  <div className="input-con-error">
                    <MantineTextInputStyled
                      theme={colorScheme}
                      type="email"
                      label="Correo electrónico"
                      value={datosEmisor?.cElectronico || ""}
                      required
                      placeholder="Correo"
                      variant="filled"
                      hideControls
                      onChange={(e) => {
                        setDatosEmisor({
                          ...datosEmisor,
                          cElectronico: e.currentTarget.value,
                        });
                      }}
                    />
                    {correoError && (
                      <span className="error">{errores.campoVacio}</span>
                    )}
                  </div>
                  <div className="input-con-error">
                    <MantineSelectStyled
                      theme={colorScheme}
                      searchable
                      label="Régimen fiscal"
                      rightSection={
                        <img src={chevronDown} alt="flecha abajo" width="20" />
                      }
                      rightSectionWidth={50}
                      defaultValue="F"
                      value={
                        `${datosRegimenFiscal?.descripcion}/${datosRegimenFiscal?.regimenFiscal}` ||
                        ""
                      }
                      styles={{ rightSection: { pointerEvents: "none" } }}
                      data={regimenFiscalData || []}
                      required
                      placeholder="Régimen fiscal"
                      variant="filled"
                      hideControls
                      onChange={(value) => {
                        setDatosRegimenFiscal({
                          ...datosRegimenFiscal,
                          descripcion: value.split("/").slice(-2)[0],
                          regimenFiscal: value.split("/").slice(-1)[0],
                        });
                      }}
                    />
                    {claveFiscalError && (
                      <span className="error">{errores.campoVacio}</span>
                    )}
                  </div>
                </div>
              </Accordion.Panel>
            </Accordion.Item>
          </MantineStyledAccordion>
        </ColumnaPrincipalContainer>
        <ColumnaSecundariaContainer>
          {!isResponsive && <Steps active={3} />}
          <div className="sticky">
            <SeccionTopLateral theme={colorScheme}>
              <LoadingOverlay
                visible={isLoading}
                radius="16px"
                loader={
                  <Lottie
                    className="lottie"
                    options={defaultOptions}
                    height={120}
                    width={120}
                  />
                }
              />
              <div className="bodyLateral">
                <p className="big-title">Resumen</p>
                <p className="subtitle">{globalPaqueteElegido?.nombre}</p>
                <div class="pago-total">
                  <>
                    <p className="subtitle">Tipo de inmueble</p>
                    <p className="subtitle2">
                      {getTipoInmueble(
                        globalDatosValuacion.datosValuacion?.tipoInmueble ||
                          globalDatosAsegurado.datosDelAsegurado
                            ?.tipoInmueble ||
                          datosEmision?.tipoImueble
                      )}
                    </p>
                  </>
                </div>
                <div class="pago-total">
                  <p className="subtitle">Suma asegurada</p>
                  <p className="subtitle2">
                    $
                    {globalSumaAsegurada.toLocaleString("en-US", {
                      maximumFractionDigits: 2,
                    })}
                  </p>
                </div>
                <div class="pago-total">
                  <p className="subtitle">Perfil del contratante</p>
                  <p className="subtitle2">
                    {getPerfilContratante(
                      globalDatosAsegurado.datosDelAsegurado?.perfilContratante
                    )}
                  </p>
                </div>
                {coberturas.length > 0 &&
                  coberturas?.map((item, index) => (
                    <>
                      <div class="pago-total">
                        <p className="resumen">{item.nombre}</p>
                        <p className="subtitle2">
                          MX$
                          {item.prima.toLocaleString("en-US", {
                            maximumFractionDigits: 2,
                          })}
                          <ButtonQuitar
                            onClick={() => {
                              handleDeleteItem(item.codigoInfo);
                            }}
                          >
                            QUITAR
                          </ButtonQuitar>
                        </p>
                      </div>
                    </>
                  ))}
                <div class="pago-total">
                  <p className="subtitle">Pago total</p>
                  <p className="subtitle2">
                    MX$
                    {globalNuevoCosto?.pagoTotal?.toLocaleString("en-US", {
                      maximumFractionDigits: 2,
                    })}
                  </p>
                </div>
                {globalTipoPago === "Mensual" && (
                  <>
                    <div class="pago-total">
                      <p className="subtitle">Primer pago</p>
                      <p className="subtitle2">
                        MX$
                        {globalNuevoCosto?.primerRecibo.toLocaleString(
                          "en-US",
                          {
                            maximumFractionDigits: 2,
                          }
                        )}
                      </p>
                    </div>
                    <div class="pago-total">
                      <p className="subtitle">11 pagos subsecuentes de:</p>
                      <p className="subtitle2">
                        MX$
                        {globalNuevoCosto?.reciboSubsecuente.toLocaleString(
                          "en-US",
                          {
                            maximumFractionDigits: 2,
                          }
                        )}
                      </p>
                    </div>
                  </>
                )}
                {descuento && (
                  <>
                    <div className="descuento">10% DESC. INCLUIDO</div>
                  </>
                )}
                <br />
                <ButtonPrincipal
                  onClick={() => {
                    handleValidarEmisionPoliza();
                  }}
                >
                  CONTINUAR
                </ButtonPrincipal>
                <br />
                <ButtonSecundario
                  theme={colorScheme}
                  onClick={() => navigate("/coberturas")}
                >
                  REGRESAR
                </ButtonSecundario>
              </div>
            </SeccionTopLateral>
            <p className="texto-abajo">
              <a
                href="https://woowtodobien.com/terminos-y-condiciones"
                target="_blank"
                rel="noreferrer"
              >
                Términos y Condiciones
              </a>
              <span>y</span>
              <a
                href="https://woowtodobien.com/aviso-de-privacidad"
                target="_blank"
                rel="noreferrer"
              >
                Aviso de Privacidad
              </a>
            </p>
          </div>
        </ColumnaSecundariaContainer>
      </ComparadorBody>
      {!isResponsive && (
        <div class="contenedor-div">
          <a
            href="https://wa.me/5215585327747"
            target="_blank"
            rel="noreferrer"
          >
            <img
              class="mi-imagen-abajo-derecha"
              src="https://21884627.fs1.hubspotusercontent-na1.net/hubfs/21884627/whatsapp_woow.svg"
              alt="Whatsapp"
              width="60px"
            />
          </a>
        </div>
      )}
    </ComparadorContainer>
  );
};

const ComparadorContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: ${(theme) => {
    return theme.theme === "dark" ? "#1A1B1E" : "#f5f5f5";
  }};
  position: relative;
  height: 1250px;
  min-height: 120vh;

  .contenedor-div {
    position: absolute;
  }

  .mi-imagen-abajo-derecha {
    position: fixed;
    bottom: 15px;
    right: 90px;
    z-index: 1000 !important;
  }

  .widget-app-container,
  .Application__WidgetAppContainer-sc-1f2l0a1-0,
  .dvBAhE {
    z-index: 99px !important;
  }

  @media (max-width: 992px) {
    .mi-imagen-abajo-derecha {
      position: fixed;
      bottom: 9rem;
      right: 15px;
      z-index: 1000 !important;
    }
    div#hubspot-messages-iframe-container {
      bottom: 55px !important;
    }

    div#hubspot-messages-iframe-container {
      bottom: 55px !important;
      right: 20px !important;
    }
  }

  .header-image {
    width: 1300px;
  }

  .nav {
    width: 100%;
    max-width: 1150px;
    font-size: 0.9rem;

    .actual {
      color: #037cbf;
      font-weight: 700;
    }
  }
`;

const ButtonPrincipal = styled.div`
  padding: 15px 0;
  border-radius: 16px;
  width: 100%;
  background: linear-gradient(312.79deg, #36405f 5.82%, #536291 89.32%);
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: #ffffff;
  &:hover {
    cursor: pointer;
  }
`;

const ButtonQuitar = styled.div`
  width: auto;
  background-color: transparent;
  color: #babcbe;
  padding: 5px;
  font-weight: 400;
  font-size: 12px;
  line-height: 21px;
  &:hover {
    cursor: pointer;
  }
`;

const ButtonSecundario = styled.div`
  padding: 15px 0;
  border-radius: 16px;
  width: 100%;
  background: ${(theme) => {
    return theme.theme === "dark" ? "transparent" : "#e5e5e5";
  }};
  font-weight: ${(theme) => {
    return theme.theme === "dark" ? 600 : 400;
  }};
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  border: ${(theme) => {
    return theme.theme === "dark" ? "2px solid#536291" : "transparent";
  }};
  color: ${(theme) => {
    return theme.theme === "dark" ? "#536291" : "#838383";
  }};
  &:hover {
    cursor: pointer;
  }
`;

const ComparadorBody = styled.div`
  display: flex;
  width: 100%;
  max-width: 1370px;
  @media (max-width: 1020px) {
    flex-direction: column;
    padding-top: 120px;
  }
`;

const ColumnaPrincipalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 70%;
  max-width: 1250px;
  @media (max-width: 1020px) {
    align-items: center;
    width: 100%;
  }

  .breadcrumbs {
    width: 100%;
    display: flex;
    align-self: flex-start;
    margin-bottom: 27px;
  }

  .title-accordion {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: #4e4e4e;
  }

  .switch-datos-regimen {
    margin: 24px 0 14px;
    span {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: ${(theme) => {
        return theme.theme === "dark" ? "#fff" : "#000";
      }};
    }
  }

  .otras {
    color: #4e4e4e;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 24px;
    padding: 40px 0px;
    margin: auto;
  }

  input:checked {
    background-color: #536291;
    border: 1px solid #536291;
  }

  .mantine-Checkbox-label {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
  }
`;

const ColumnaSecundariaContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 30%;
  @media (max-width: 1020px) {
    width: 100%;
    align-items: center;
  }

  .sticky {
    position: sticky;
    top: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .texto-abajo {
    font-weight: 600;
    font-size: 12px;
    line-height: 21px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #4e4e4e;
    a {
      color: #4e4e4e;
      text-decoration-line: underline;
    }
    span {
      margin: 0 5px;
    }
  }
`;
const SeccionTopLateral = styled.div`
  position: relative;
  margin: 20px;
  background-color: ${(theme) => {
    return theme.theme === "dark" ? "#343434" : "#fff";
  }};
  border-radius: 16px;
  box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.1);
  padding: 27px 15px 15px;
  box-sizing: border-box;
  font-size: 1.1rem;
  text-align: center;
  width: 92%;
  text-align: left;

  .descuento {
    color: #f86374;
    text-align: right;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px; /* 150% */
  }

  .big-title {
    font-weight: 700;
    font-size: 18px;
    line-height: 27px;
    color: ${(theme) => {
      return theme.theme === "dark" ? "#fff" : "#4e4e4e";
    }};
  }

  .subtitle {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: ${(theme) => {
      return theme.theme === "dark" ? "#fff" : "#4e4e4e";
    }};
  }

  .subtitle2 {
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: ${(theme) => {
      return theme.theme === "dark" ? "#fff" : "#4e4e4e";
    }};
    text-align: right;
  }

  .resumen {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    text-align: right;
    color: ${(theme) => {
      return theme.theme === "dark" ? "#fff" : "#4e4e4e";
    }};
    text-align: left;
  }

  .pago-total {
    display: flex;
    justify-content: space-between;
  }
`;

const MantineStyledAccordion = styled(Accordion)`
  width: 100%;
  background-color: ${(theme) => {
    return theme.theme === "dark" ? "#343434" : "#fff";
  }};
  box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  margin-bottom: 24px;
  .mantine-Accordion-label {
    font-family: "Montserrat";
    font-weight: 600;
    font-size: 16px;
    line-height: 27px;
    color: ${(theme) => {
      return theme.theme === "dark" ? "#fff" : "#4e4e4e";
    }};
  }
  .mantine-Accordion-item {
    border-bottom: none;
  }
  .flex-inputs {
    display: flex;
    gap: 24px;
    margin-bottom: 24px;
    align-items: center;
    @media (max-width: 1020px) {
      flex-direction: column;
    }
    .input-fecha {
      .label-fecha {
        color: rgb(131, 131, 131);
        font-weight: 400;
        font-size: 14px;
        span {
          color: red;
        }
      }
      & input {
        height: 36px;
        line-height: 34px;
        appearance: none;
        resize: none;
        box-sizing: border-box;
        font-size: 14px;
        width: 100%;
        color: ${(theme) => {
          return theme.theme === "dark" ? "#fff" : "#000";
        }};
        display: block;
        text-align: left;
        min-height: 36px;
        padding-left: 12px;
        padding-right: 12px;
        border-radius: 4px;
        border: 1px solid transparent;
        border-color: ${(theme) => {
          return theme.theme === "dark" ? "#424242" : "transparent";
        }};
        background-color: ${(theme) => {
          return theme.theme === "dark" ? "#4a4a4a" : "#f5f5f5";
        }};
        &::placeholder {
          color: #808083;
        }
      }
    }
    .input-con-error {
      width: 100%;
      .error {
        color: red;
        font-size: 13px;
        margin-left: 5px;
      }
    }
  }

  .flex-inputs-one {
    display: flex;
    width: 31%;
    gap: 24px;
    margin-bottom: 24px;
    @media (max-width: 1020px) {
      flex-direction: column;
      width: 100%;
    }
    .input-con-error {
      width: 100%;
      .error {
        color: red;
        font-size: 14px;
        margin-left: 10px;
      }
    }
  }

  .flex-inputs-two {
    display: flex;
    width: 65%;
    gap: 24px;
    margin-bottom: 24px;
    @media (max-width: 1020px) {
      flex-direction: column;
      width: 100%;
    }
    .input-con-error {
      width: 100%;
      .error {
        color: red;
        font-size: 14px;
        margin-left: 10px;
      }
    }
  }

  .tooltip-regimen {
    font-family: "Montserrat";
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
    color: ${(theme) => {
      return theme.theme === "dark" ? "#fff" : "#000";
    }};
    display: flex;
    gap: 10px;
  }

  .question {
    width: 20px;
    height: 20px;
    background: #f5f5f5;
    text-align: center;
    border-radius: 20px;
    font-weight: 600;
    :hover {
      cursor: pointer;
    }
  }
`;

const MantineStyledSwitch = styled(Switch)`
  margin-top: 5px;
  label {
    padding-left: 0;
    font-family: "Montserrat";
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #fff;
    background: linear-gradient(312.79deg, #36405f 5.82%, #536291 89.32%);
    border-color: #36405f !important;
  }
  .mantine-Switch-input {
    :checked {
      background-color: #36405f;
      border-color: #36405f;
    }
    ::after {
      font-size: 12px;
      font-weight: 400;
    }
  }
`;

const MantineStyledTooltip = styled(Tooltip)`
  .mantine-Tooltip-body {
    color: #000;
    background-color: #f5f5f5;
    font-size: 10px;
    line-height: 14px;
    font-weight: 400;
    width: 250px;
    padding: 10px;
    .bold {
      font-weight: 600;
    }
  }
`;

const MantineTextInputStyled = styled(TextInput)`
  width: 100%;
  .mantine-Input-input {
    background-color: ${(theme) => {
      return theme.theme === "dark" ? "#4a4a4a" : "#f5f5f5";
    }};
    &::placeholder {
      color: #808083;
    }
  }
  .mantine-TextInput-filledVariant {
    height: 48px;
    border-radius: 16px;
    background-color: #f5f5f5;
    &:disabled {
      color: ${(theme) => {
        return theme.theme === "dark" ? "#8a8787" : "#000";
      }};
      opacity: 1;
    }
  }
  .mantine-TextInput-disabled {
    &:disabled {
      color: ${(theme) => {
        return theme.theme === "dark" ? "#8a8787" : "#000";
      }};
    }
  }
  .mantine-TextInput-label {
    color: rgb(131, 131, 131);
    font-weight: 400;
  }
  label {
    padding-left: 12px;
  }
`;

const MantineSelectStyled = styled(Select)`
  width: 100%;
  .mantine-Input-input {
    background-color: ${(theme) => {
      return theme.theme === "dark" ? "#4a4a4a" : "#f5f5f5";
    }};
    &::placeholder {
      color: #808083;
    }
  }
  .mantine-Select-filledVariant {
    height: 50px;
    border-radius: 16px;
    background-color: #f5f5f5;
    &:disabled {
      color: ${(theme) => {
        return theme.theme === "dark" ? "#8a8787" : "#000";
      }};
      opacity: 1;
    }
  }
  .mantine-Select-disabled {
    &:disabled {
      color: ${(theme) => {
        return theme.theme === "dark" ? "#8a8787" : "#000";
      }};
      opacity: 1;
    }
  }
  .mantine-Select-label {
    color: rgb(131, 131, 131);
    font-weight: 400;
  }

  label {
    padding-left: 12px;
  }
`;
