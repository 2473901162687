import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import img1 from "../../assets/heros/woow2/buenfin2024/woow-buenfin-bf-hero_desktop-3_casa-landing.jpg";
import imgResponsive1 from "../../assets/heros/woow2/buenfin2024/woow-buenfin-bf-hero_mobile-3_casa-landing.jpg";
import pleca from "../../assets/heros/woow2/buenfin2024/Hogar-Desktop-Pleca-Respaldo-Descarga.png";
import plecaResponsive from "../../assets/heros/woow2/buenfin2024/Hogar-Mobile-Pleca-Respaldo-Descarga.jpg";

export const HeroSlider = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const breakpoint = 1020;
  const isResponsive = width < breakpoint;

  useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleWindowResize);

    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  return (
    <Container>
      <Swiper
        modules={[Navigation, Pagination, Scrollbar, A11y]}
        slidesPerView={1}
        //navigation
        loop={false}
        centeredSlides={true}
        //pagination={{ clickable: true }}
      >
        <SwiperSlide>
          {isResponsive ? (
            <>
              <img src={imgResponsive1} alt="hero 1 responsive" />
              <img src={plecaResponsive} alt="pleca responsive" />
            </>
          ) : (
            <>
              <img src={img1} alt="hero 1 desktop" />
              <img src={pleca} alt="pleca" />
            </>
          )}
        </SwiperSlide>
      </Swiper>
    </Container>
  );
};

const Container = styled.div`
  .swiper-button-prev {
    color: #fff;
  }

  .swiper-button-next {
    color: #fff;
  }
  img {
    width: 100%;
  }
`;
