import { useState } from "react"
import toodobien from "../../assets/landing/toodo_bien_dark.svg"
import Section from "./Section"
import styled from "styled-components"
import { useMantineColorScheme } from "@mantine/core"

const Preguntas = ({ content, bgColor, svgColor, color }) => {
  const { colorScheme } = useMantineColorScheme()
  const [preguntaSeleccionada, setPreguntaSeleccionada] = useState(null)

  const toggle = i => {
    if (preguntaSeleccionada === i) {
      return setPreguntaSeleccionada(null)
    }
    setPreguntaSeleccionada(i)
  }

  return (
    <Section bgColor={bgColor || "#FFF"}>
      <PreguntasContainer theme={colorScheme} color={color} svgColor={svgColor}>
        <h2>¿Aún tienes dudas? Consulta las preguntas frecuentes 👇</h2>
        <div className="section-8-preguntas">
          <div className="preguntas">
            {content.map((item, i) => (
              <div key={"pregunta " + i} className="item">
                <div
                  className={
                    preguntaSeleccionada === i ? "title active" : "title"
                  }
                  onClick={() => {
                    toggle(i)
                  }}
                >
                  <h2>{item.pregunta}</h2>
                </div>
                <div
                  className={
                    preguntaSeleccionada === i ? "content show" : "content"
                  }
                >
                  {item.respuesta}
                </div>
              </div>
            ))}
          </div>
        </div>
        <br />
        <br />
        <br />
        <img
          src={toodobien}
          className="svg"
          width="280"
          height="50"
          style={{ margin: "10px auto" }}
          alt="toodo bien"
        />
        <br />
      </PreguntasContainer>
    </Section>
  )
}

export const PreguntasContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  font-family: Montserrat;

  filter: drop-shadow(0px 8px 32px rgba(0, 0, 0, 0.08));

  .svg {
    //filter: ${({ svgColor }) => svgColor && svgColor};
  }

  h2 {
    color: ${theme => {
      return theme.theme === "dark" ? "#fff" : "#4e4e4e"
    }};
    padding-top: 50px;
    font-weight: 600;
    font-size: 24px;

    @media (max-width: 880px) {
      font-size: 1.2rem;
      padding: 40px;
    }
  }

  .section-8-preguntas {
    background-color: ${theme => {
      return theme.theme === "dark" ? "#343434" : "#f5f5f5"
    }};
    border-radius: 16px;
    width: 100%;

    .preguntas {
      .item {
        min-height: 75px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: left;
        &:nth-child(2n) {
          background-color: ${theme => {
            return theme.theme === "dark" ? "#242424" : "#f5f5f5"
          }};
        }
        .title {
          cursor: pointer;
          transition: all 0.5s cubic-bezier(0, 1, 0, 1);
          padding: 10px;
          border-radius: 16 16 0 0px;
          @media (max-width: 420px) {
            padding: 0px;
          }

          h2 {
            margin-left: 15px;
            font-weight: 600;
            padding: 0px;
            font-size: 1.125rem;

            color: ${theme => {
              return theme.theme === "dark" ? "#fff" : "#4e4e4e"
            }};
            @media (max-width: 620px) {
            }
          }
        }

        .title.active {
          padding: 10px;
          h2 {
            color: white;
          }

          background-color: ${({ color }) => (color ? color : "#8039e5")};
        }

        .content {
          margin-left: 10px;
          opacity: 0;
          color: ${theme => {
            return theme.theme === "dark" ? "#fff" : "#838383"
          }};
          position: relative;
          max-height: 0;
          overflow: hidden;
          transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
          padding: 0px;
          max-height: 0px;
        }

        .content.show {
          margin-left: 10px;
          opacity: 1;
          font-weight: 400;
          font-size: 18px;
          line-height: 1.5rem;
          box-sizing: border-box;
          padding: 15px;
          max-height: 970px;
        }
      }
    }
  }
`

export default Preguntas
