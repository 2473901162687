import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Section, ColorBox, Footer } from "@woow/base-woow-react";
import { Cotizador } from "./components/Cotizador";
import { letrasChiquitas, preguntasFrecuentes } from "./constants/constants";
import { Proteccion } from "./components/landing/Proteccion";
import { Paquetes } from "./components/landing/Paquetes";
import gnp from "./assets/logos/gnp-xl.svg";
import TagManager from "react-gtm-module";
import { HeroSlider } from "./components/landing/HeroSlider";
import Navbar from "./components/landing/Navbar";
import { useMantineColorScheme } from "@mantine/core";
import LetrasChiquitas from "./components/landing/LetrasChiquitas";
import WOOWPassBox from "./components/landing/WoowPass";
import Preguntas from "./components/landing/Preguntas";

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_KEY,
};
TagManager.initialize(tagManagerArgs);

export const SeguroDeHogar = () => {
  const { colorScheme } = useMantineColorScheme();
  const [width, setWidth] = useState(window.innerWidth);
  const breakpoint = 1020;
  const isResponsive = width < breakpoint;

  useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleWindowResize);

    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  return (
    <Div id="cotizador" theme={colorScheme}>
      <Navbar color="#36405F" />
      <HeroSlider />
      <Cotizador />
      <Paquetes />
      <Proteccion />
      <WOOWPassBox
        svgColor="none"
        bgColor={colorScheme === "dark" ? "#242424" : "#F5F5F5"}
      />
      <Section
        pb="0px"
        bgColor={colorScheme === "dark" ? "#242424" : "#F5F5F5"}
      >
        <div className="respaldo">
          <br />
          <br />
          <span className="title-respaldo">Y con el respaldo de:</span>
          <img
            src={gnp}
            alt="logo GNP"
            style={{ filter: "brightness(0) invert(1)" }}
          />
        </div>
      </Section>
      <br />
      <div className="letras-chiquitas-div">
        <LetrasChiquitas
          content={letrasChiquitas}
          svgColor="hue-rotate(25deg) saturate(300%) brightness(100%) opacity(50%)"
          bgColor={colorScheme === "dark" ? "#1a1b1f" : "#F5F5F5"}
        />
      </div>
      <div className="preguntas-div">
        <Section bgColor="#242424">
          <Preguntas
            color="#536291"
            content={preguntasFrecuentes}
            svgColor="none"
            bgColor={colorScheme === "dark" ? "#242424" : "#F5F5F5"}
          />
        </Section>
      </div>
      <ColorBox bgColor="#36405F" />
      <Footer />
      {!isResponsive && (
        <div class="contenedor-div">
          <a
            href="https://wa.me/5215585327747"
            target="_blank"
            rel="noreferrer"
          >
            <img
              class="mi-imagen-abajo-derecha"
              src="https://21884627.fs1.hubspotusercontent-na1.net/hubfs/21884627/whatsapp_woow.svg"
              alt="Whatsapp"
              width="60px"
            />
          </a>
        </div>
      )}
    </Div>
  );
};

const Div = styled.div`
  .respaldo {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 76px;
  }
  .title-respaldo {
    font-weight: 600;
    font-size: 24px;
    line-height: 24px;
    color: ${(theme) => {
      return theme.theme === "dark" ? "#fff" : "#4e4e4e";
    }};
    margin-bottom: 40px;
  }

  .preguntas-div {
    background-color: ${(theme) => {
      return theme.theme === "dark" ? "#242424" : "#f5f5f5";
    }};
    padding: 100px 0;
  }

  .letras-chiquitas-div {
    .item {
      filter: hue-rotate(25deg) saturate(65%) brightness(69%) opacity(101%);
    }
  }

  .contenedor-div {
    position: absolute;
  }

  .mi-imagen-abajo-derecha {
    position: fixed;
    bottom: 15px;
    right: 90px;
    z-index: 1000 !important;
  }

  .widget-app-container,
  .Application__WidgetAppContainer-sc-1f2l0a1-0,
  .dvBAhE {
    z-index: 99px !important;
  }

  @media (max-width: 992px) {
    .mi-imagen-abajo-derecha {
      position: fixed;
      bottom: 9rem;
      right: 15px;
      z-index: 1000 !important;
    }
    div#hubspot-messages-iframe-container {
      bottom: 55px !important;
    }

    div#hubspot-messages-iframe-container {
      bottom: 55px !important;
      right: 20px !important;
    }
  }
`;
