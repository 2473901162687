import axios from "axios";

// obtener token flujo interno
export async function getTokenInterno(authorizationToken) {
  const res = await axios.post(
    `${process.env.REACT_APP_URL_HOGAR}${process.env.REACT_APP_OBTENER_TOKEN_INTERNO}`,
    {},
    {
      mode: "no-cors",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json; charset=utf-8",
        Authorization: authorizationToken,
      },
    }
  );

  return res;
}

// obtener cotizaciones pendientes flujo interno
export async function getCotizacionesPendientes(authorizationToken) {
  const res = await axios.get(
    `${process.env.REACT_APP_URL_HOGAR}${process.env.REACT_APP_OBTENER_COTIZACIONES_PENDIENTES}`,
    {
      mode: "no-cors",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: authorizationToken,
      },
    }
  );

  return res;
}

// obtener catálogos externos nuevo método
export async function getCatalogosHogar(catalogo) {
  const res = await axios.get(
    `${process.env.REACT_APP_URL_HOGAR}${process.env.REACT_APP_OBTENER_CATALOGOS_OUT}/${catalogo}`,
    {
      mode: "no-cors",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: process.env.REACT_APP_UUID_AUTH,
      },
    }
  );

  return res;
}

// obtener catálogos interno clase inmueble, perfil contratante, etc
export async function getCatalogosHogarInterno(catalogo, authorizationToken) {
  const res = await axios.get(
    `${process.env.REACT_APP_URL_HOGAR}${process.env.REACT_APP_OBTENER_CATALOGOS_INTERNO}/${catalogo}`,
    {
      mode: "no-cors",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: authorizationToken,
      },
    }
  );

  return res;
}

// realizar cotización externa
export async function cotizacionOut(dataAsegurado) {
  const res = await axios.post(
    `${process.env.REACT_APP_URL_HOGAR}${process.env.REACT_APP_COTIZAR_OUT}`,
    dataAsegurado,
    {
      mode: "no-cors",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: process.env.REACT_APP_UUID_AUTH,
      },
    }
  );

  return res;
}

// realizar cotización interna
export async function cotizacionInterna(dataAsegurado, authorizationToken) {
  const res = await axios.post(
    `${process.env.REACT_APP_URL_HOGAR}${process.env.REACT_APP_COTIZAR_INTERNO}`,
    dataAsegurado,
    {
      mode: "no-cors",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: authorizationToken,
      },
    }
  );

  return res;
}

// direcciones por código postal
export async function direccionCodigoPostal(dataCp, authorizationToken) {
  const res = await axios.post(
    `${process.env.REACT_APP_URL_HOGAR}${process.env.REACT_APP_OBTENER_DIRECCION_CP}`,
    dataCp,
    {
      mode: "no-cors",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: authorizationToken,
      },
    }
  );

  return res;
}

// recotizar suma asegurada
export async function recotizarSumaAsegurada(
  dataSumaAsegurada,
  authorizationToken
) {
  const res = await axios.post(
    `${process.env.REACT_APP_URL_HOGAR}${process.env.REACT_APP_RECOTIZAR_SUMA_ASEGURADA}`,
    dataSumaAsegurada,
    {
      mode: "no-cors",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: authorizationToken,
      },
    }
  );

  return res;
}

// recotizar coberturas
export async function recotizarCoberturas(dataCoberturas, authorizationToken) {
  const res = await axios.post(
    `${process.env.REACT_APP_URL_HOGAR}${process.env.REACT_APP_RECOTIZAR_COBERTURAS}`,
    dataCoberturas,
    {
      mode: "no-cors",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: authorizationToken,
      },
    }
  );

  return res;
}

// crear cuenta o loggear si ya existe correo
export async function loginCrearCuenta(dataUsuario) {
  const res = await axios.post(
    `${process.env.REACT_APP_URL_HOGAR}${process.env.REACT_APP_LOGIN_CREAR_CUENTA}`,
    dataUsuario,
    {
      mode: "no-cors",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: process.env.REACT_APP_UUID_AUTH,
      },
    }
  );

  return res;
}

// validar data emitir
export async function validarEmitir(dataValidarEmitir, authorizationToken) {
  const res = await axios.post(
    `${process.env.REACT_APP_URL_HOGAR}${process.env.REACT_APP_VALIDAR_EMITIR}`,
    dataValidarEmitir,
    {
      mode: "no-cors",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: authorizationToken,
      },
    }
  );

  return res;
}

// emitir
export async function emitirPoliza(dataEmision, authorizationToken) {
  const res = await axios.post(
    `${process.env.REACT_APP_URL_HOGAR}${process.env.REACT_APP_EMITIR_POLIZA}`,
    dataEmision,
    {
      mode: "no-cors",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: authorizationToken,
      },
    }
  );

  return res;
}

// regimen fiscal con persona física
export async function getRegimenFiscal(authorizationToken) {
  const res = await axios.get(
    `${process.env.REACT_APP_URL_HOGAR}informacionFiscal/regimen_tipo_persona?tipoPersona=F`,
    {
      mode: "no-cors",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: authorizationToken,
      },
    }
  );

  return res;
}

// obtener tarjetas de débito/crédito del usuario guardadas banwire
export async function getTarjetas(authorizationToken) {
  const res = await axios.get(
    `${process.env.REACT_APP_WOOW_SERVICIOS}${process.env.REACT_APP_OBTENER_TARJETAS}`,
    {
      mode: "no-cors",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: authorizationToken,
      },
    }
  );

  return res;
}

// agregar una tarjeta de débito/crédito nueva banwire
export async function agregarTarjetas(dataTarjeta, authorizationToken) {
  const res = await axios.post(
    `${process.env.REACT_APP_WOOW_SERVICIOS}${process.env.REACT_APP_AGREGAR_TARJETAS}`,
    dataTarjeta,
    {
      mode: "no-cors",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: authorizationToken,
      },
    }
  );

  return res;
}

// generador de referencia para pagos
export async function getReferencia(dataReferencia, authorizationToken) {
  const res = await axios.post(
    `${process.env.REACT_APP_WOOW_SERVICIOS}services/reference`,
    dataReferencia,
    {
      mode: "no-cors",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: authorizationToken,
      },
    }
  );

  return res;
}

// pago cuando es 1 vez al mes banwire
export async function monthCardPay(dataMonthCard, authorizationToken) {
  const res = await axios.post(
    `${process.env.REACT_APP_WOOW_SERVICIOS}payment/card_subscription`,
    dataMonthCard,
    {
      mode: "no-cors",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: authorizationToken,
      },
    }
  );

  return res;
}

// obtener tarjetas ACDC
export async function getTarjetasAcdc(authorizationToken, dataObtenerTarjetas) {
  const res = await axios.post(
    `${process.env.REACT_APP_WOOW_SERVICIOS}/acdc/get_cards`,
    dataObtenerTarjetas,
    {
      mode: "no-cors",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: authorizationToken,
      },
    }
  );

  return res;
}

// agregar tarjeta nueva ACDC
export async function agregarTarjetasAcdc(authorizationToken, dataTarjeta) {
  const res = await axios.post(
    `${process.env.REACT_APP_WOOW_SERVICIOS}/acdc/add_card`,
    dataTarjeta,
    {
      mode: "no-cors",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: authorizationToken,
      },
    }
  );

  return res;
}

// pago con tarjeta para one-shot acdc
export async function anualCardPAy(authorizationToken, dataAnualPay) {
  const res = await axios.post(
    `${process.env.REACT_APP_WOOW_SERVICIOS}/acdc/card_pay`,
    dataAnualPay,
    {
      mode: "no-cors",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: authorizationToken,
      },
    }
  );

  return res;
}

// configuración paypal
export async function getConfigPaypal(dataConfigPaypal, authorizationToken) {
  const res = await axios.post(
    `${process.env.REACT_APP_WOOW_SERVICIOS}${process.env.REACT_APP_GET_CONFIG_PAYPAL}`,
    dataConfigPaypal,
    {
      mode: "no-cors",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: authorizationToken,
      },
    }
  );

  return res;
}

// crean plan paypal
export async function createPlanPaypal(dataPlanPaypal, authorizationToken) {
  const res = await axios.post(
    `${process.env.REACT_APP_URL_HOGAR}${process.env.REACT_APP_CREATE_PLAN_PAYPAL}`,
    dataPlanPaypal,
    {
      mode: "no-cors",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: authorizationToken,
      },
    }
  );

  return res;
}

// guardar subscripción paypal
export async function saveSubscriptionPaypal(
  dataSubscriptionPaypal,
  authorizationToken
) {
  const res = await axios.post(
    `${process.env.REACT_APP_WOOW_SERVICIOS}${process.env.REACT_APP_SAVE_SUBSCRIPTION}`,
    dataSubscriptionPaypal,
    {
      mode: "no-cors",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: authorizationToken,
      },
    }
  );

  return res;
}

// generar orden
export async function generateOrder(dataGenerateOrder, authorizationToken) {
  const res = await axios.post(
    `${process.env.REACT_APP_URL_HOGAR}paypal/generate_order`,
    dataGenerateOrder,
    {
      mode: "no-cors",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: authorizationToken,
      },
    }
  );

  return res;
}

// capturar orden
export async function captureOrder(dataCaptureOrder, authorizationToken) {
  const res = await axios.post(
    `${process.env.REACT_APP_URL_HOGAR}paypal/capturar_orden`,
    dataCaptureOrder,
    {
      mode: "no-cors",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: authorizationToken,
      },
    }
  );

  return res;
}

// cancelar orden
export async function cancelOrder(dataCancelOrder, authorizationToken) {
  const res = await axios.post(
    `${process.env.REACT_APP_URL_HOGAR}paypal/cancelar_orden`,
    dataCancelOrder,
    {
      mode: "no-cors",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: authorizationToken,
      },
    }
  );

  return res;
}

// generar pago oxxo
export async function oxxoPayment(dataOxxo, authorizationToken) {
  const res = await axios.post(
    `${process.env.REACT_APP_URL_HOGAR}paypal/oxxo`,
    dataOxxo,
    {
      mode: "no-cors",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: authorizationToken,
      },
    }
  );

  return res;
}

// pagar con toku
export async function tokuPayment(dataToku, authorizationToken) {
  const res = await axios.post(
    `${process.env.REACT_APP_URL_HOGAR}Toku/pay_toku`,
    dataToku,
    {
      mode: "no-cors",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: authorizationToken,
      },
    }
  );

  return res;
}


// generar card de servicios contratados
export async function serviciosContratados(dataServicio, authorizationToken) {
  const res = await axios.post(
    `${process.env.REACT_APP_WOOW_SERVICIOS}${process.env.REACT_APP_SERVICIOS_CONTRATADOS}`,
    dataServicio,
    {
      mode: "no-cors",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: authorizationToken,
      },
    }
  );

  return res;
}

// create deal externo
export async function createDealExterno(dataCreateDeal) {
  const res = await axios.post(
    `${process.env.REACT_APP_WOOW_SERVICIOS}Hubspot/create_deal_external`,
    dataCreateDeal,
    {
      mode: "no-cors",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
    }
  );

  return res;
}

// create deal interno
export async function createDealInterno(authorizationToken) {
  const res = await axios.post(
    `${process.env.REACT_APP_WOOW_SERVICIOS}Hubspot/create_deal`,
    {
      mode: "no-cors",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: authorizationToken,
      },
    }
  );

  return res;
}

// asociar deal con usuario
export async function asociateDeal(authorizationToken, dataDeal) {
  const res = await axios.post(
    `${process.env.REACT_APP_WOOW_SERVICIOS}Hubspot/associate_deal_contact_woow_acount`,
    dataDeal,
    {
      mode: "no-cors",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: authorizationToken,
      },
    }
  );

  return res;
}

// Mover de stage al usuario externo_hogar
export async function updateDealExterno(dataUpdateDeal) {
  const res = await axios.post(
    `${process.env.REACT_APP_WOOW_SERVICIOS}Hubspot/update_deal`,
    dataUpdateDeal,
    {
      mode: "no-cors",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
    }
  );

  return res;
}

// Mover de stage al usuario interno
export async function updateDeal(authorizationToken, dataUpdateDeal) {
  const res = await axios.post(
    `${process.env.REACT_APP_WOOW_SERVICIOS}Hubspot/update_deal`,
    dataUpdateDeal,
    {
      mode: "no-cors",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: authorizationToken,
      },
    }
  );

  return res;
}

// actualizar contacto
export async function updateContact(authorizationToken, dataUpdateContact) {
  const res = await axios.post(
    `${process.env.REACT_APP_WOOW_SERVICIOS}Hubspot/update_contact`,
    dataUpdateContact,
    {
      mode: "no-cors",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: authorizationToken,
      },
    }
  );

  return res;
}

// Obtener folio seguro de hogar interno
export async function getFolioLeadInterno(authorizationToken, dataLead) {
  const res = await axios.post(
    `${process.env.REACT_APP_WOOW_SERVICIOS}lead/folio_asisteme`,
    dataLead,
    {
      mode: "no-cors",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: authorizationToken,
      },
    }
  );

  return res;
}

// enviar info leads
export async function sendInfoLeads(authorizationToken, dataLead) {
  const res = await axios.post(
    `${process.env.REACT_APP_WOOW_SERVICIOS}lead/seguro_hogar`,
    dataLead,
    {
      mode: "no-cors",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: authorizationToken,
      },
    }
  );

  return res;
}

// asisteme llamada
export async function asisteme(authorizationToken, dataAsisteme) {
  const res = await axios.post(
    `${process.env.REACT_APP_WOOW_SERVICIOS}lead/asisteme`,
    dataAsisteme,
    {
      mode: "no-cors",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: authorizationToken,
      },
    }
  );

  return res;
}

// generar pago rappi
export async function pagoRappiConekta(authorizationToken, dataPago) {
  const res = await axios.post(
    `${process.env.REACT_APP_PAGO_RAPPI_CONEKTA}`,
    dataPago,
    {
      mode: "no-cors",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: authorizationToken,
      },
    }
  );

  return res;
}

// guardar datos al pagar con RappiCard en BD
export async function saveDataPagoRappiConekta(
  dataRappiConekta,
  authorizationToken
) {
  const res = await axios.post(
    `${process.env.REACT_APP_URL_HOGAR}${process.env.REACT_APP_GUARDAR_DATA_RAPPI_CONEKTA}`,
    dataRappiConekta,
    {
      mode: "no-cors",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json; charset=utf-8",
        Authorization: authorizationToken,
      },
    }
  );

  return res;
}

// eliminar cotizaciones pendientes en flujo interno
export async function eliminarCotizacionesPendientes(
  dataCotizaciones,
  authorizationToken
) {
  const res = await axios.post(
    `${process.env.REACT_APP_URL_HOGAR}${process.env.REACT_APP_ELIMINAR_COTIZACIONES_PENDIENTES}`,
    dataCotizaciones,
    {
      mode: "no-cors",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json; charset=utf-8",
        Authorization: authorizationToken,
      },
    }
  );

  return res;
}

// obtener tema del usuario
export async function getThemePreferential(authorizationToken) {
  const res = await axios.get(
    `${process.env.REACT_APP_WOOW_SERVICIOS}profile/get_theme_user`,
    {
      mode: "no-cors",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authorizationToken}`,
      },
    }
  );

  return res;
}
