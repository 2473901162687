import lottieFlechas from "../assets/arrowDown.json";

export const descuento = false;

export const uuid = "68c2be08-9715-4b37-844e-dc88c15857bd";

export const verticalColor =
  "linear-gradient(312.79deg, #36405F 5.82%, #536291 89.32%)";

export const stagesHubspot = {
  interesado: process.env.REACT_APP_HUBSPOT_STAGE_INTERESADO,
  en_espera: process.env.REACT_APP_HUBSPOT_STAGE_EN_ESPERA,
  check_out_pendiente: process.env
    .REACT_APP_HUBSPOT_STAGE_CHECK_OUT_PENDIENTE,
  cierre_ganado: process.env.REACT_APP_HUBSPOT_STAGE_CIERRA_GANADO,
  cierre_perdido: process.env.REACT_APP_HUBSPOT_STAGE_CIERRA_PERDIDO,
  pipeline: process.env.REACT_APP_HUBSPOT_PIPELINE,
};

export function replaceSpaceWithPlus(string) {
  const newString = string.replace(/\s/g, "+");
  return newString;
}

export const getPerfilContratante = (id) => {
  switch (id) {
    case "1":
      return "Propietario";
    case "2":
      return "Arrendador";
    case "3":
      return "Arrendatario";
    default:
      return "Propietario";
  }
};

export const getTipoInmueble = (id) => {
  switch (id) {
    case "2":
      return "Casa";
    case "3":
      return "Casa dentro de fraccionamiento";
    case "4":
      return "Residencial";
    default:
      return "Sin valuación";
  }
};

export const getClaseInmueble = (id) => {
  switch (id) {
    case "3":
      return "Interés Social";
    case "4":
      return "Medio";
    case "5":
      return "Semilujo";
    case "6":
      return "Residencial";
    default:
      return "Interés Social";
  }
};

export const getPaqueteContratado = (nombre) => {
  switch (nombre) {
    case "Hogar Esencial":
      return "A";
    case "Hogar Vital":
      return "B";
    case "Hogar Plus":
      return "C";
    default:
      return "A";
  }
};

export const getMedidaSeguridad = (monto) => {
  switch (monto) {
    case "5.00":
      return "Protección perimetral";
    case "7.50":
      return "Alarma local";
    case "15.00":
      return "Alarma central";
    case "10.00":
      return "Vivienda en predios con acceso controlado y vigilancia 24 hrs";
    default:
      return "Alarma local";
  }
};

export const lottieFlechasData = {
  loop: true,
  autoplay: true,
  animationData: lottieFlechas,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

export const routerData = [
  { linkTo: "/", name: "Cotizador" },
  { linkTo: "/cotizar", name: "Paquetes" },
  { linkTo: "/coberturas", name: "A tu medida" },
  { linkTo: "/emision", name: "Detalles de contratación" },
  { linkTo: "/pago", name: "Pago" },
];

export const tipoPagoDoble = [
  { value: "Mensual", label: "Mensual" },
  { value: "Anual", label: "Anual" },
];

export const tipoPagoIndividual = [{ value: "Anual", label: "Anual" }];

export const letrasChiquitas = [
  {
    text: "La cobertura de Fenómenos Hidrometereológicos iniciará una vez transcurrido el periodo de espera de acuerdo con la zona que se indique en la póliza a partir de la fecha de contratación del seguro.",
  },
  {
    text: "Las coberturas de Riesgos Hidrometeorológicos: huracán e inundación, entrarán en vigor 10 días después de la fecha de inicio de vigencia de la póliza, para los bienes asegurables que estén en exposición directa a una o varias Tormenta(s) Nombrada(s).",
  },
  {
    text: "Para la cobertura de Contenidos la indemnización es a valor de reposición que tengan los contenidos afectados al momento del siniestro, teniendo como límite máximo la Suma Asegurada establecida en la Carátula de la Póliza.",
  },
  {
    text: "La cobertura de Robo será el Valor real o de reposición, según se indique en la Carátula de la Póliza, de los bienes al momento del siniestro con límite de la Suma Asegurada establecida en la Carátula de la Póliza.",
  },
  {
    text: "Para Joyería, Orfebrería, Bisutería, relojes el límite máximo de responsabilidad será de $14,000 pesos por artículo o juego, sin que el límite máximo de Indemnización por este concepto exceda el 10% de la Suma Asegurada establecida en la Carátula de la Póliza para esta Cobertura.",
  },
  {
    text: "Para dinero en efectivo el límite máximo de responsabilidad será el 1.5% de la Suma Asegurada de esta Cobertura, sin exceder de $9,000 pesos por vigencia de la Póliza.",
  },
];

export const preguntasFrecuentes = [
  {
    pregunta: "¿Qué incluye la cobertura de Edificio?",
    respuesta: (
      <>
        <ul>
          <li>
            Es la construcción material del edificio, construcciones anexas,
            dependencias, bardas, rejas, patios y pisos de áreas a la intemperie
          </li>
          <li>
            {" "}
            Instalaciones fijas, sobre el nivel del suelo, para los servicios de
            agua, aire acondicionado, drenaje, energía eléctrica, gas y cableado
            de conducción eléctrica o electrónica.
          </li>
          <li>Aditamentos fijos al edificio.</li>
          <li>
            {" "}
            Instalaciones fijas que por su naturaleza deban estar a la
            intemperie o en edificios abiertos.
          </li>
          <li>Albercas y sus instalaciones auxiliares.</li>
          <li>
            {" "}
            Edificios terminados, no obstante que algunas de sus partes estén en
            remodelación o mejoras.
          </li>
          <li>
            Cimientos, muros de contención e instalaciones fijas debajo del
            nivel del suelo.
          </li>
          <li>
            Bodega siendo parte del inmueble asegurado y de uso de menaje de
            casa habitación siempre y cuando los metros cuadrados y el valor
            estén considerados en póliza.
          </li>
          Quedan excluidos:
          <li>Suelos, terrenos y jardines.</li>
          <li>Edificios en construcción.</li>
          <li>Murales y frescos.</li>
          <li>
            Anuncios y otras estructuras similares por las cuales se obtenga un
            lucro.
          </li>
        </ul>
      </>
    ),
  },
  {
    pregunta: "¿Qué incluye la cobertura de Contenidos?",
    respuesta: (
      <>
        <ul>
          Los muebles, ropa y menaje y demás contenidos propios de una casa
          habitación así como:
          <li>
            Equipo electrodoméstico: refrigeradores, congeladores, planchas,
            lavadoras de ropa, secadoras de ropa, lavadoras para vajilla,
            equipos para aire acondicionado y calefacción, hornos de gas,
            eléctricos y electrónicos.
          </li>
          <li>
            Equipo electrónico: televisores, antenas, DVD’s, blu ray,
            computadoras personales, impresoras, cámaras de video, cámaras
            digitales, equipos de sonido y juegos de video sin incluir los
            cartuchos, cables y controles de juego.
          </li>
        </ul>
      </>
    ),
  },
  {
    pregunta: "¿Qué daños cubre las coberturas de Edificio y Contenidos?",
    respuesta: (
      <>
        <ul>
          Las pérdidas que sufra el inmueble y/o sus contenidos originadas por
          los siguientes riesgos:
          <li>Incendio, impacto de rayo y explosión.</li>
          <li>Impacto de naves aéreas y objetos caídos de ellas.</li>
          <li>
            Caída de árboles, antenas, anuncios y otras estructuras similares (a
            excepción de aquellos por los cuales obtenga un lucro).
          </li>
          <li>Humo o tizne producido fuera del predio Asegurado.</li>
          <li>
            Agua o vapor de agua provenientes de la rotura de tuberías o
            sistemas de abastecimiento, que se localicen por encima del nivel
            del suelo, dentro del predio Asegurado.
          </li>
          <li>
            Colisión de vehículos que no sean propiedad del Asegurado o de sus
            dependientes económicos.
          </li>
        </ul>
      </>
    ),
  },
  {
    pregunta: "¿Qué daños cubre la cobertura de Fenómenos Hidrometereológicos?",
    respuesta:
      "Las pérdidas o daños físicos al inmueble y/o sus contenidos, así como a sus cimentaciones e instalaciones fijas subterráneas, originados por Avalanchas de lodo, Granizo, Helada, Huracán, Inundación, Inundación por lluvia, Golpe de mar, Tsunami, Marejada, Nevada y Vientos tempestuosos.",
  },
  {
    pregunta:
      "¿Qué daños cubre la cobertura de Terremoto y/o Erupción Volcánica?",
    respuesta:
      "Cubre las pérdidas por daños materiales al inmueble asegurado, así como a sus cimentaciones e instalaciones fijas subterráneas, causados por Terremoto y/o Erupción Volcánica.",
  },
];
