import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {
  Modal,
  TextInput,
  NumberInput,
  Select,
  LoadingOverlay,
  useMantineColorScheme,
} from "@mantine/core";
import useStore from "../../useStore";
import chevronDown from "../../assets/chevron.svg";
import Lottie from "react-lottie";
import woowLoadingHogar from "../../assets/woow-loading-hogar.json";
import { uuid } from "../../constants/constants";
import editPencil from "../../assets/pencil.svg";
import { errores } from "../../constants/strings";
import {
  cotizacionInterna,
  cotizacionOut,
  direccionCodigoPostal,
} from "../../services/api";

const Valuador = ({
  openModal,
  onCloseModal,
  datosDelAseguradoHogar,
  tipoInmueble,
  claseInmueble,
}) => {
  const {
    setGlobalValuador,
    setglobalDatosAsegurado,
    setGlobalDatosPaquetes,
    setGlobalDatosRecotizacion,
    setGlobalIdCotizacion,
    setGlobalSumaAsegurada,
    setGlobalDatosValuacion,
    globalTipoCotizador,
    globalUsuarioInterno,
    globalTokenCotizacion,
    setGlobalPaquetesFactorLinea,
    setGlobalAlarmaLocal,
    setGlobalCpValuador,
  } = useStore();
  const { colorScheme } = useMantineColorScheme();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingCp, setIsLoadingCp] = useState(false);
  const [datosValuacion, setDatosValuacion] = useState(null);
  const [colonia, setColonia] = useState([]);
  const [estado, setEstado] = useState([]);
  const [municipio, setMunicipio] = useState([]);
  const [sumaAsegurada, setSumaAsegurada] = useState(null);
  const [showContinue, setShowContinue] = useState(false);
  const [coloniaError, setColoniaError] = useState(false);
  const [estadoError, setEstadoError] = useState(false);
  const [municipioError, setMunicipioError] = useState(false);
  const [codigoPostalError, setCodigoPostalError] = useState(false);
  const [codigoPostalInvalidoError, setCodigoPostalInvalidoError] =
    useState(false);
  const [calleError, setCalleError] = useState(false);
  const [numExteriorError, setNumExteriorError] = useState(false);
  const [tipoInmuebleError, setTipoInmuebleError] = useState(false);
  const [claseInmuebleError, setClaseInmuebleError] = useState(false);
  const [anioConstruccionError, setAnioConstruccionError] = useState(false);
  const [anioConstruccionErrorLength, setAnioConstruccionErrorLength] =
    useState(false);
  const [anioConstruccionErrorEdad, setAnioConstruccionErrorEdad] =
    useState(false);
  const [anioConstruccionActualError, setAnioConstruccionActualError] =
    useState(false);
  const [metrosConstruidosError, setMetrosConstruidosError] = useState(false);
  const [metrosConstruidosLengthError, setMetrosConstruidosLengthError] =
    useState(false);
  const [metrosCuadradosError, setMetrosCuadradosError] = useState(false);
  const [metrosCuadradosLengthError, setMetrosCuadradosLengthError] =
    useState(false);
  const currentYear = new Date().getFullYear();

  const [width, setWidth] = useState(window.innerWidth);
  const breakpoint = 1020;
  const isResponsive = width < breakpoint;

  useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleWindowResize);

    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: woowLoadingHogar,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  useEffect(() => {
    if (openModal) {
      setDatosValuacion({
        ...datosValuacion,
        codigoPostal: datosDelAseguradoHogar?.codigoPostal,
      });
    }
  }, [openModal]);

  useEffect(() => {
    if (datosValuacion?.codigoPostal?.length === 5) {
      handleCodigoPostal();
    }
  }, [datosValuacion?.codigoPostal]);

  useEffect(() => {
    if (datosValuacion?.codigoPostal || datosDelAseguradoHogar?.codigoPostal) {
      setCodigoPostalError(false);
    }
  }, [datosValuacion?.codigoPostal, datosDelAseguradoHogar?.codigoPostal]);

  useEffect(() => {
    if (datosValuacion?.calle) {
      setCalleError(false);
    }
  }, [datosValuacion?.calle]);

  useEffect(() => {
    if (datosValuacion?.numExterior) {
      setNumExteriorError(false);
    }
  }, [datosValuacion?.numExterior]);

  useEffect(() => {
    if (datosValuacion?.colonia) {
      setColoniaError(false);
    }
  }, [datosValuacion?.colonia]);

  useEffect(() => {
    if (datosValuacion?.municipio) {
      setMunicipioError(false);
    }
  }, [datosValuacion?.municipio]);

  useEffect(() => {
    if (datosValuacion?.estado) {
      setEstadoError(false);
    }
  }, [datosValuacion?.estado]);

  useEffect(() => {
    if (datosValuacion?.tipoInmueble) {
      setTipoInmuebleError(false);
    }
  }, [datosValuacion?.tipoInmueble]);

  useEffect(() => {
    if (datosValuacion?.claseInmueble) {
      setClaseInmuebleError(false);
    }
  }, [datosValuacion?.claseInmueble]);

  useEffect(() => {
    if (datosValuacion?.edad) {
      setAnioConstruccionError(false);
    }
  }, [datosValuacion?.edad]);

  useEffect(() => {
    if (datosValuacion?.edad?.toString()?.length === 4) {
      setAnioConstruccionErrorLength(false);
    }
  }, [datosValuacion?.edad?.length]);

  useEffect(() => {
    if (
      currentYear - datosValuacion?.edad === 50 ||
      currentYear - datosValuacion?.edad < 50
    ) {
      setAnioConstruccionErrorEdad(false);
    }
  }, [datosValuacion?.edad]);

  useEffect(() => {
    if (datosValuacion?.edad <= currentYear) {
      setAnioConstruccionActualError(false);
    }
  }, [datosValuacion?.edad]);

  useEffect(() => {
    if (datosValuacion?.metrosConstruidos) {
      setMetrosConstruidosError(false);
    }
  }, [datosValuacion?.metrosConstruidos]);

  useEffect(() => {
    if (datosValuacion?.metrosConstruidos?.length >= 2) {
      setMetrosConstruidosLengthError(false);
    }
  }, [datosValuacion?.metrosConstruidos]);

  useEffect(() => {
    if (datosValuacion?.metrosTerreno) {
      setMetrosCuadradosError(false);
    }
  }, [datosValuacion?.metrosTerreno]);

  useEffect(() => {
    if (datosValuacion?.metrosTerreno?.length >= 2) {
      setMetrosCuadradosLengthError(false);
    }
  }, [datosValuacion?.metrosTerreno]);

  const handleCodigoPostal = async () => {
    setIsLoadingCp(true);
    const dataCp = {
      cp: datosValuacion?.codigoPostal,
      origin: globalTipoCotizador === "interno" ? "APP" : "LP",
    };

    const token =
      globalTipoCotizador === "interno" ? globalTokenCotizacion : uuid;

    direccionCodigoPostal(dataCp, token)
      .then((response) => {
        if (response) {
          if (response?.data?.event?.codigoPostal === undefined) {
            setCodigoPostalInvalidoError(true);
            setIsLoadingCp(false);
          } else {
            setCodigoPostalInvalidoError(false);
            setIsLoadingCp(false);
            const colonias =
              response.data.event.codigoPostal.consultarAnidadosCRMResponse
                .catalogos[1].listaElementos;
            const coloniasLength = Array.isArray(colonias);
            if (coloniasLength === true) {
              const arrayColonias = colonias.map((item) => {
                return {
                  label: item.descripcionValor,
                  value: `${item.descripcionValor}/${item.idValor}`,
                };
              });
              setColonia(arrayColonias);
            } else {
              const arrayColonias = [colonias].map((item) => {
                return {
                  label: item.descripcionValor,
                  value: `${item.descripcionValor}/${item.idValor}`,
                };
              });
              setColonia(arrayColonias);
            }

            const municipios = [
              response.data.event.codigoPostal.consultarAnidadosCRMResponse
                .catalogos[2].listaElementos,
            ];
            const arrayMunicipios = municipios.map((item) => {
              return {
                label: item.descripcionValor,
                value: `${item.descripcionValor}/${item.idValor}`,
              };
            });
            setMunicipio(arrayMunicipios);

            const estados = [
              response.data.event.codigoPostal.consultarAnidadosCRMResponse
                .catalogos[3].listaElementos,
            ];
            const arrayEstados = estados.map((item) => {
              return {
                label: item.descripcionValor,
                value: `${item.descripcionValor}/${item.idValor}`,
              };
            });
            setEstado(arrayEstados);
          }
        }
      })
      .catch(function (error) {});
  };

  const cotizarOutSumaAsegurada = async () => {
    setIsLoading(true);

    const medidaSeguriad = datosDelAseguradoHogar?.medidasDeSeguridad / 100;

    const dataAsegurado = {
      montoDescMedidaSeg: medidaSeguriad || 0,
      perfilContratante: datosDelAseguradoHogar?.perfilContratante,
      codigoPostal: datosValuacion?.codigoPostal,
      esLineaPlaya: datosDelAseguradoHogar?.esPlaya,
      esLagoLaguna: datosDelAseguradoHogar?.esLaguna,
      valorInmueble:
        datosDelAseguradoHogar?.valorInmueble || sumaAsegurada || "",
      idPeriodicidadPago: 4,
      nombre:
        globalTipoCotizador === "interno"
          ? globalUsuarioInterno?.nombre
          : datosDelAseguradoHogar?.nombre,
      email:
        globalTipoCotizador === "interno"
          ? globalUsuarioInterno?.email
          : datosDelAseguradoHogar?.email,
      telefono:
        globalTipoCotizador === "interno"
          ? globalUsuarioInterno.celular
          : datosDelAseguradoHogar?.telefono,
      usarValuador: 1,
      valuador: {
        alarmaLocal:
          datosDelAseguradoHogar?.medidasDeSeguridad === "7.50" ? 1 : 0,
        calle: datosValuacion?.calle,
        numExterior: datosValuacion?.numExterior,
        numInterior: datosValuacion?.numInterior || "",
        colonia: datosValuacion?.colonia,
        municipio: datosValuacion?.municipio,
        estado: datosValuacion?.estado,
        cp: datosValuacion?.codigoPostal,
        claseInmueble: datosValuacion?.claseInmueble,
        metrosConstruidos: datosValuacion?.metrosConstruidos,
        metrosTerreno: datosValuacion?.metrosTerreno,
        tipoInmueble: datosValuacion?.tipoInmueble,
        edad: datosValuacion?.edad,
      },
    };

    setGlobalAlarmaLocal(
      datosDelAseguradoHogar?.medidasDeSeguridad === "7.50" ? 1 : 0
    );

    if (globalTipoCotizador === "interno") {
      if (
        datosValuacion?.codigoPostal &&
        datosValuacion?.calle &&
        datosValuacion?.numExterior &&
        datosValuacion?.colonia &&
        datosValuacion?.municipio &&
        datosValuacion?.estado &&
        datosValuacion?.tipoInmueble &&
        datosValuacion?.claseInmueble &&
        datosValuacion?.edad &&
        datosValuacion?.edad?.toString().length === 4 &&
        currentYear - datosValuacion?.edad <= 50 &&
        datosValuacion?.edad <= currentYear &&
        datosValuacion?.metrosConstruidos &&
        datosValuacion?.metrosConstruidos?.length >= 2 &&
        datosValuacion?.metrosTerreno &&
        datosValuacion?.metrosTerreno?.length >= 2
      ) {
        cotizacionInterna(dataAsegurado, globalTokenCotizacion)
          .then((response) => {
            if (response.data.success === 1) {
              const datosDelAsegurado = {
                esPlaya: datosDelAseguradoHogar?.esPlaya,
                esLaguna: datosDelAseguradoHogar?.esLaguna,
                nombre: datosDelAseguradoHogar?.nombre,
                email: datosDelAseguradoHogar?.email,
                telefono: datosDelAseguradoHogar?.telefono,
                perfilContratante: datosDelAseguradoHogar?.perfilContratante,
                codigoPostal: datosValuacion?.codigoPostal,
                medidasDeSeguridad: datosDelAseguradoHogar?.medidasDeSeguridad,
              };
              setglobalDatosAsegurado({ datosDelAsegurado });
              setGlobalDatosValuacion({ datosValuacion });
              const suma = response.data.event.valuador.valorDestructible;
              setSumaAsegurada(suma);

              setGlobalSumaAsegurada(suma);

              const paqueteEsencial = response.data.event.calculo.paquetes[0];
              const paqueteVital = response.data.event.calculo.paquetes[1];
              const paquetePlus = response.data.event.calculo.paquetes[2];
              setGlobalDatosPaquetes([
                paqueteEsencial,
                paqueteVital,
                paquetePlus,
              ]);
              setGlobalPaquetesFactorLinea(response.data.event.neo.paquetes);
              const idCotizacion = response.data.idCotizacion;
              setGlobalIdCotizacion({ idCotizacion });
              const datosConfiguracion =
                response.data.event.contratoCotizacion.datosConfiguracion;
              const paquetes = response.data.event.neo.paquetes[2].coberturas;

              const datosInmueble = {
                multiclausula:
                  response.data.event.contratoCotizacion.datosInmueble
                    .multiclausula,
                codigoPostalInmueble:
                  response.data.event.contratoCotizacion.datosInmueble
                    .codigoPostalInmueble,
                codigoPostalContratante:
                  response.data.event.contratoCotizacion.datosInmueble
                    .codigoPostalContratante,
                esConstruccionMaciza:
                  response.data.event.contratoCotizacion.datosInmueble
                    .esConstruccionMaciza,
                idTipoMoneda:
                  response.data.event.contratoCotizacion.datosInmueble
                    .idTipoMoneda,
                esLineaPlaya: datosDelAseguradoHogar?.esPlaya,
                esLagoLaguna: datosDelAseguradoHogar?.esLaguna,
                idPeriodicidadPago:
                  response.data.event.contratoCotizacion.datosInmueble
                    .idPeriodicidadPago,
                valorTipo:
                  response.data.event.contratoCotizacion.datosInmueble
                    .valorTipo,
              };

              setGlobalDatosRecotizacion([
                datosConfiguracion,
                datosInmueble,
                paquetes,
              ]);

              setIsLoading(false);
              setShowContinue(true);
            } else setIsLoading(false);
          })
          .catch(function (error) {
            setIsLoading(false);
          });
      } else {
        if (
          !datosDelAseguradoHogar?.codigoPostal ||
          !datosValuacion?.codigoPostal
        ) {
          setCodigoPostalError(true);
        }
        if (!datosValuacion?.calle) {
          setCalleError(true);
        }
        if (!datosValuacion?.numExterior) {
          setNumExteriorError(true);
        }
        if (!datosValuacion?.colonia) {
          setColoniaError(true);
        }
        if (!datosValuacion?.municipio) {
          setMunicipioError(true);
        }
        if (!datosValuacion?.estado) {
          setEstadoError(true);
        }
        if (!datosValuacion?.tipoInmueble) {
          setTipoInmuebleError(true);
        }
        if (!datosValuacion?.claseInmueble) {
          setClaseInmuebleError(true);
        }
        if (!datosValuacion?.edad) {
          setAnioConstruccionError(true);
        }
        if (
          datosValuacion?.edad &&
          datosValuacion?.edad?.toString().length < 4
        ) {
          setAnioConstruccionErrorLength(true);
        }
        if (datosValuacion?.edad && currentYear - datosValuacion?.edad > 50) {
          setAnioConstruccionErrorEdad(true);
        }
        if (datosValuacion?.edad > currentYear) {
          setAnioConstruccionActualError(true);
        }
        if (!datosValuacion?.metrosConstruidos) {
          setMetrosConstruidosError(true);
        }
        if (datosValuacion?.metrosConstruidos?.length < 2) {
          setMetrosConstruidosLengthError(true);
        }
        if (!datosValuacion?.metrosTerreno) {
          setMetrosCuadradosError(true);
        }
        if (datosValuacion?.metrosTerreno?.length < 2) {
          setMetrosCuadradosLengthError(true);
        }

        setIsLoading(false);
      }
    } else {
      if (
        datosValuacion?.codigoPostal &&
        datosValuacion?.calle &&
        datosValuacion?.numExterior &&
        datosValuacion?.colonia &&
        datosValuacion?.municipio &&
        datosValuacion?.estado &&
        datosValuacion?.tipoInmueble &&
        datosValuacion?.claseInmueble &&
        datosValuacion?.edad &&
        datosValuacion?.edad?.toString().length === 4 &&
        currentYear - datosValuacion?.edad <= 50 &&
        datosValuacion?.edad <= currentYear &&
        datosValuacion?.metrosConstruidos &&
        datosValuacion?.metrosConstruidos?.length >= 2 &&
        datosValuacion?.metrosTerreno &&
        datosValuacion?.metrosTerreno?.length >= 2
      ) {
        cotizacionOut(dataAsegurado)
          .then((response) => {
            if (response.data.success === 1) {
              const datosDelAsegurado = {
                esPlaya: datosDelAseguradoHogar?.esPlaya,
                esLaguna: datosDelAseguradoHogar?.esLaguna,
                nombre: datosDelAseguradoHogar?.nombre,
                email: datosDelAseguradoHogar?.email,
                telefono: datosDelAseguradoHogar?.telefono,
                perfilContratante: datosDelAseguradoHogar?.perfilContratante,
                codigoPostal: datosValuacion?.codigoPostal,
                medidasDeSeguridad: datosDelAseguradoHogar?.medidasDeSeguridad,
              };
              setglobalDatosAsegurado({ datosDelAsegurado });
              setGlobalDatosValuacion({ datosValuacion });

              const suma = response.data.event.valuador.valorDestructible;
              setSumaAsegurada(suma);

              setGlobalSumaAsegurada(suma);

              const paqueteEsencial = response.data.event.calculo.paquetes[0];
              const paqueteVital = response.data.event.calculo.paquetes[1];
              const paquetePlus = response.data.event.calculo.paquetes[2];
              setGlobalDatosPaquetes([
                paqueteEsencial,
                paqueteVital,
                paquetePlus,
              ]);
              setGlobalPaquetesFactorLinea(response.data.event.neo.paquetes);

              const idCotizacion = response.data.idCotizacion;
              setGlobalIdCotizacion({ idCotizacion });
              const datosConfiguracion =
                response.data.event.contratoCotizacion.datosConfiguracion;
              const paquetes = response.data.event.neo.paquetes[2].coberturas;

              const datosInmueble = {
                multiclausula:
                  response.data.event.contratoCotizacion.datosInmueble
                    .multiclausula,
                codigoPostalInmueble:
                  response.data.event.contratoCotizacion.datosInmueble
                    .codigoPostalInmueble,
                codigoPostalContratante:
                  response.data.event.contratoCotizacion.datosInmueble
                    .codigoPostalContratante,
                esConstruccionMaciza:
                  response.data.event.contratoCotizacion.datosInmueble
                    .esConstruccionMaciza,
                idTipoMoneda:
                  response.data.event.contratoCotizacion.datosInmueble
                    .idTipoMoneda,
                esLineaPlaya: 0,
                esLagoLaguna: 0,
                idPeriodicidadPago:
                  response.data.event.contratoCotizacion.datosInmueble
                    .idPeriodicidadPago,
                valorTipo:
                  response.data.event.contratoCotizacion.datosInmueble
                    .valorTipo,
              };

              setGlobalDatosRecotizacion([
                datosConfiguracion,
                datosInmueble,
                paquetes,
              ]);

              setIsLoading(false);
              setShowContinue(true);
            } else setIsLoading(false);
          })
          .catch(function (error) {
            setIsLoading(false);
          });
      } else {
        if (
          !datosDelAseguradoHogar?.codigoPostal ||
          !datosValuacion?.codigoPostal
        ) {
          setCodigoPostalError(true);
        }
        if (!datosValuacion?.calle) {
          setCalleError(true);
        }
        if (!datosValuacion?.numExterior) {
          setNumExteriorError(true);
        }
        if (!datosValuacion?.colonia) {
          setColoniaError(true);
        }
        if (!datosValuacion?.municipio) {
          setMunicipioError(true);
        }
        if (!datosValuacion?.estado) {
          setEstadoError(true);
        }
        if (!datosValuacion?.tipoInmueble) {
          setTipoInmuebleError(true);
        }
        if (!datosValuacion?.claseInmueble) {
          setClaseInmuebleError(true);
        }
        if (!datosValuacion?.edad) {
          setAnioConstruccionError(true);
        }
        if (
          datosValuacion?.edad &&
          datosValuacion?.edad?.toString().length < 4
        ) {
          setAnioConstruccionErrorLength(true);
        }
        if (datosValuacion?.edad && currentYear - datosValuacion?.edad > 50) {
          setAnioConstruccionErrorEdad(true);
        }
        if (datosValuacion?.edad > currentYear) {
          setAnioConstruccionActualError(true);
        }
        if (!datosValuacion?.metrosConstruidos) {
          setMetrosConstruidosError(true);
        }
        if (!datosValuacion?.metrosConstruidos?.length < 2) {
          setMetrosConstruidosLengthError(true);
        }
        if (!datosValuacion?.metrosTerreno) {
          setMetrosCuadradosError(true);
        }
        if (!datosValuacion?.metrosTerreno?.length < 2) {
          setMetrosCuadradosLengthError(true);
        }
        setIsLoading(false);
      }
    }
  };

  const handleCloseModal = () => {
    setDatosValuacion({
      ...datosValuacion,
      codigoPostal: "",
      calle: "",
      numExterior: "",
      numInterior: "",
      colonia: "",
      cveColonia: "",
      municipio: "",
      cveMunicipio: "",
      estado: "",
      cveEstado: "",
      tipoInmueble: "",
      claseInmueble: "",
      edad: "",
      metrosConstruidos: "",
      metrosTerreno: "",
    });
    setShowContinue(false);
    onCloseModal();
  };

  return (
    <Modal
      size={isResponsive ? "100%" : "60%"}
      withCloseButton
      opened={openModal}
      onClose={handleCloseModal}
    >
      <Div theme={colorScheme}>
        <LoadingOverlay
          visible={isLoadingCp}
          radius="35px"
          loader={
            <Lottie
              className="lottie"
              options={defaultOptions}
              height={50}
              width={50}
            />
          }
        />
        {!showContinue ? (
          <>
            <div className="title">¿No conoces el valor de tu inmueble?</div>
            <div className="subtitle">Ingresa los siguientes campos</div>
          </>
        ) : (
          <div className="title" style={{ paddingBottom: "40px" }}>
            Aquí esta tu avalúo
          </div>
        )}
        <div className="flex-inputs">
          <div className="container">
            <MantineTextInputStyled
              theme={colorScheme}
              label="Código Postal"
              minLength={1}
              maxLength={5}
              pattern="[0-9]+"
              value={datosValuacion?.codigoPostal || ""}
              required
              placeholder="Código Postal"
              variant="filled"
              hideControls
              onChange={(e) => {
                setDatosValuacion({
                  ...datosValuacion,
                  codigoPostal: e.currentTarget.value.replace(/[^0-9]/g, ""),
                });
              }}
            />
            {codigoPostalError && (
              <span className="error">{errores.campoVacio}</span>
            )}
            {codigoPostalInvalidoError && (
              <span className="error">{errores.cpValido}</span>
            )}
          </div>
          <div className="container">
            <MantineTextInputStyled
              theme={colorScheme}
              label="Calle"
              value={datosValuacion?.calle || ""}
              required
              placeholder="Calle"
              variant="filled"
              hideControls
              onChange={(e) => {
                setDatosValuacion({
                  ...datosValuacion,
                  calle: e.currentTarget.value,
                });
              }}
            />
            {calleError && <span className="error">{errores.campoVacio}</span>}
          </div>
          <div className="container">
            <MantineNumberInputStyled
              theme={colorScheme}
              label="Número exterior"
              value={datosValuacion?.numExterior || ""}
              required
              placeholder="Número exterior"
              variant="filled"
              hideControls
              onChange={(value) => {
                setDatosValuacion({
                  ...datosValuacion,
                  numExterior: value,
                });
              }}
            />
            {numExteriorError && (
              <span className="error">{errores.campoVacio}</span>
            )}
          </div>
        </div>
        <div className="flex-inputs">
          <div className="container">
            <MantineNumberInputStyled
              theme={colorScheme}
              label="Número interior"
              value={datosValuacion?.numInterior || ""}
              placeholder="Número interior"
              variant="filled"
              hideControls
              onChange={(value) => {
                setDatosValuacion({
                  ...datosValuacion,
                  numInterior: value,
                });
              }}
            />
          </div>
          <div className="container">
            <MantineSelectStyled
              theme={colorScheme}
              label="Colonia"
              rightSection={
                <img src={chevronDown} alt="flecha abajo" width="20" />
              }
              rightSectionWidth={50}
              styles={{ rightSection: { pointerEvents: "none" } }}
              data={colonia}
              required
              placeholder="Colonia"
              variant="filled"
              hideControls
              onChange={(value) => {
                setDatosValuacion({
                  ...datosValuacion,
                  colonia: value.split("/").slice(-2)[0],
                  cveColonia: value.split("/").slice(-1)[0],
                });
              }}
            />
            {coloniaError && (
              <span className="error">{errores.campoVacio}</span>
            )}
          </div>
          <div className="container">
            <MantineSelectStyled
              theme={colorScheme}
              label="Municipio"
              rightSection={
                <img src={chevronDown} alt="flecha abajo" width="20" />
              }
              rightSectionWidth={50}
              styles={{ rightSection: { pointerEvents: "none" } }}
              data={municipio}
              required
              placeholder="Municipio"
              variant="filled"
              hideControls
              onChange={(value) => {
                setDatosValuacion({
                  ...datosValuacion,
                  municipio: value.split("/").slice(-2)[0],
                  cveMunicipio: value.split("/").slice(-1)[0],
                });
              }}
            />
            {municipioError && (
              <span className="error">{errores.campoVacio}</span>
            )}
          </div>
        </div>
        <div className="flex-inputs">
          <div className="container">
            <MantineSelectStyled
              theme={colorScheme}
              label="Estado"
              rightSection={
                <img src={chevronDown} alt="flecha abajo" width="20" />
              }
              rightSectionWidth={50}
              styles={{ rightSection: { pointerEvents: "none" } }}
              data={estado}
              required
              placeholder="Estado"
              variant="filled"
              hideControls
              onChange={(value) => {
                setDatosValuacion({
                  ...datosValuacion,
                  estado: value.split("/").slice(-2)[0],
                  cveEstado: value.split("/").slice(-1)[0],
                });
              }}
            />
            {estadoError && <span className="error">{errores.campoVacio}</span>}
          </div>
          <div className="container">
            <MantineSelectStyled
              theme={colorScheme}
              label="Tipo de inmueble"
              rightSection={
                <img src={chevronDown} alt="flecha abajo" width="20" />
              }
              rightSectionWidth={50}
              styles={{ rightSection: { pointerEvents: "none" } }}
              required
              data={tipoInmueble}
              placeholder="Tipo de inmueble"
              variant="filled"
              onChange={(value) => {
                setDatosValuacion({
                  ...datosValuacion,
                  tipoInmueble: value,
                });
              }}
            />
            {tipoInmuebleError && (
              <span className="error">{errores.campoVacio}</span>
            )}
          </div>
          <div className="container">
            <MantineSelectStyled
              theme={colorScheme}
              label="Clase de inmueble"
              data={claseInmueble}
              required
              placeholder="Clase de inmueble"
              variant="filled"
              hideControls
              onChange={(value) => {
                setDatosValuacion({
                  ...datosValuacion,
                  claseInmueble: value,
                });
              }}
            />
            {claseInmuebleError && (
              <span className="error">{errores.campoVacio}</span>
            )}
          </div>
        </div>
        <div className="flex-inputs">
          <div className="container">
            <MantineTextInputStyled
              theme={colorScheme}
              label="Año de construcción"
              value={datosValuacion?.edad || ""}
              required
              max="4"
              minLength={1}
              maxLength={4}
              pattern="[0-9]+"
              placeholder="Ej. 1950"
              variant="filled"
              hideControls
              onChange={(e) => {
                setDatosValuacion({
                  ...datosValuacion,
                  edad: e.currentTarget.value?.replace(/[^0-9]/g, ""),
                });
              }}
            />
            {anioConstruccionError && (
              <span className="error">{errores.campoVacio}</span>
            )}
            <br />
            {datosValuacion?.edad && anioConstruccionErrorLength && (
              <span className="error">{errores.longitudAnioConstruccion}</span>
            )}
            <br />
            {anioConstruccionErrorEdad && (
              <span className="error">{errores.edadInmueble}</span>
            )}
            {datosValuacion?.edad?.length === 4 &&
              anioConstruccionActualError && (
                <span className="error">{errores.edadInmuebleActual}</span>
              )}
          </div>
          <div className="container">
            <MantineTextInputStyled
              theme={colorScheme}
              label="Metros construidos"
              value={datosValuacion?.metrosConstruidos || ""}
              required
              max="10"
              minLength={1}
              maxLength={10}
              pattern="[0-9]+"
              placeholder="Metros construidos"
              variant="filled"
              hideControls
              onChange={(e) => {
                setDatosValuacion({
                  ...datosValuacion,
                  metrosConstruidos: e.currentTarget.value?.replace(
                    /[^0-9]/g,
                    ""
                  ),
                });
              }}
            />
            {metrosConstruidosError && (
              <span className="error">{errores.campoVacio}</span>
            )}
            {datosValuacion?.metrosConstruidos?.length > 0 &&
              metrosConstruidosLengthError && (
                <span className="error">{errores.longitudMetros}</span>
              )}
          </div>
          <div className="container">
            <MantineTextInputStyled
              theme={colorScheme}
              label="Metros cuadrados del terreno"
              value={datosValuacion?.metrosTerreno || ""}
              required
              placeholder="Metros cuadrados del terreno"
              variant="filled"
              minLength={1}
              maxLength={10}
              pattern="[0-9]+"
              hideControls
              onChange={(e) => {
                setDatosValuacion({
                  ...datosValuacion,
                  metrosTerreno: e.currentTarget.value?.replace(/[^0-9]/g, ""),
                });
              }}
            />
            {metrosCuadradosError && (
              <span className="error">{errores.campoVacio}</span>
            )}
            {datosValuacion?.metrosTerreno?.length > 0 &&
              metrosCuadradosLengthError && (
                <span className="error">{errores.longitudMetros}</span>
              )}
          </div>
        </div>
        <LoadingDiv showContinue={showContinue}>
          <LoadingOverlay
            visible={isLoading}
            radius="35px"
            loader={
              <Lottie
                className="lottie"
                options={defaultOptions}
                height={50}
                width={50}
              />
            }
          />
          {!showContinue ? (
            <ButtonValuar onClick={() => cotizarOutSumaAsegurada()}>
              VALUAR
            </ButtonValuar>
          ) : (
            <>
              <ValuacionDiv theme={colorScheme}>
                <div className="first">
                  Valor estimado del inmueble
                  <span onClick={() => setShowContinue(false)}>
                    <img src={editPencil} alt="editar" />
                    EDITAR{" "}
                  </span>
                </div>
                <div className="second">
                  <span>MX</span>$
                  {sumaAsegurada.toLocaleString("en-US", {
                    maximumFractionDigits: 2,
                  })}
                </div>
              </ValuacionDiv>
              <br />
              <ButtonValuar
                onClick={() => {
                  handleCloseModal();
                  setGlobalValuador(1);
                  setGlobalCpValuador(datosValuacion?.codigoPostal);
                }}
              >
                USAR VALOR ESTIMADO DEL INMUEBLE
              </ButtonValuar>
              <ButtonRegresar
                theme={colorScheme}
                onClick={() => {
                  handleCloseModal();
                  setGlobalValuador(0);
                  setGlobalSumaAsegurada({});
                }}
              >
                REGRESAR A MI COTIZACIÓN SIN EL VALOR ESTIMADO
              </ButtonRegresar>
            </>
          )}
        </LoadingDiv>
      </Div>
    </Modal>
  );
};

const Div = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 10px 10px;
  .flex-inputs {
    width: 100%;
    display: flex;
    gap: 24px;
    margin-bottom: 24px;
    @media (max-width: 1020px) {
      flex-direction: column;
    }
  }
  .title {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 27px;
    color: ${(theme) => {
      return theme.theme === "dark" ? "#fff" : "#4e4e4e";
    }};
  }
  .subtitle {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 27px;
    color: ${(theme) => {
      return theme.theme === "dark" ? "#fff" : "#4e4e4e";
    }};
    padding-bottom: 48px;
  }
  .mantine-Checkbox-label {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #4e4e4e;
  }
  .mantine-Anchor-root {
    color: #4e4e4e;
    line-height: 24px;
  }

  .login {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-decoration-line: underline;
    color: #536291;
    &:hover {
      cursor: pointer;
    }
  }

  .container {
    width: 100%;
  }

  .error {
    color: red;
    font-size: 13px;
    margin-left: 5px;
  }

  & button {
    margin-top: 20px;
  }
`;

const LoadingDiv = styled.div`
  position: relative;
  width: 100%;
  height: ${(props) => (props.showContinue ? "220px" : "84px")};
  @media (max-width: 1020px) {
    height: ${(props) => (props.showContinue ? "320px" : "84px")};
  }
`;

const ButtonValuar = styled.div`
  border-radius: 16px;
  background: linear-gradient(312.79deg, #36405f 5.82%, #536291 89.32%);
  width: 100%;
  padding: 15px 0;
  margin-bottom: 10px;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: #ffffff;
  &:hover {
    cursor: pointer;
  }
`;

const ButtonRegresar = styled.div`
  border-radius: 16px;
  background: ${(theme) => {
    return theme.theme === "dark" ? "transparent" : "#f5f5f5";
  }};
  width: 100%;
  padding: 15px 0;
  font-weight: ${(theme) => {
    return theme.theme === "dark" ? 600 : 400;
  }};
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  border: ${(theme) => {
    return theme.theme === "dark" ? "2px solid#536291" : "transparent";
  }};
  color: ${(theme) => {
    return theme.theme === "dark" ? "#536291" : "#838383";
  }};
  &:hover {
    cursor: pointer;
  }
`;

const ValuacionDiv = styled.div`
  background: ${(theme) => {
    return theme.theme === "dark" ? "transparent" : "rgba(83, 98, 145, 0.1)";
  }};
  border-radius: 16px;
  display: flex;
  align-items: center;
  padding: 27px;
  @media (max-width: 1020px) {
    flex-direction: column;
  }
  .first {
    width: 100%;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    border-right: 1px solid #4e4e4e;
    text-align: center;
    color: ${(theme) => {
      return theme.theme === "dark" ? "#fff" : "#4e4e4e";
    }};
    display: flex;
    flex-direction: column;
    @media (max-width: 1020px) {
      border-right: none;
      border-bottom: 1px solid #4e4e4e;
      margin-bottom: 10px;
    }
    span {
      font-weight: 600;
      font-size: 12px;
      line-height: 17px;
      letter-spacing: 0.08em;
      color: #838383;
      margin-top: 5px;
      :hover {
        cursor: pointer;
      }
    }
  }
  .second {
    width: 100%;
    font-weight: 600;
    font-size: 24px;
    line-height: 17px;
    text-align: center;
    color: ${(theme) => {
      return theme.theme === "dark" ? "#fff" : "#4e4e4e";
    }};
    span {
      font-size: 10px;
    }
  }
`;

const MantineTextInputStyled = styled(TextInput)`
  width: 100%;
  .mantine-Input-input {
    background-color: ${(theme) => {
      return theme.theme === "dark" ? "#4a4a4a" : "#f5f5f5";
    }};
    &::placeholder {
      color: #808083;
    }
  }
  .mantine-TextInput-filledVariant {
    height: 48px;
    border-radius: 16px;
    background-color: #f5f5f5;
  }
  .mantine-TextInput-label {
    color: rgb(131, 131, 131);
    font-weight: 400;
  }
  label {
    padding-left: 12px;
  }
`;

const MantineNumberInputStyled = styled(NumberInput)`
  width: 100%;
  .mantine-Input-input {
    background-color: ${(theme) => {
      return theme.theme === "dark" ? "#4a4a4a" : "#f5f5f5";
    }};
    &::placeholder {
      color: #808083;
    }
  }
  .mantine-NumberInput-filledVariant {
    height: 50px;
    border-radius: 16px;
    background-color: #f5f5f5;
  }
  .mantine-NumberInput-label {
    color: rgb(131, 131, 131);
    font-weight: 400;
  }
  label {
    padding-left: 12px;
  }
`;

const MantineSelectStyled = styled(Select)`
  width: 100%;
  .mantine-Input-input {
    background-color: ${(theme) => {
      return theme.theme === "dark" ? "#4a4a4a" : "#f5f5f5";
    }};
    &::placeholder {
      color: #808083;
    }
  }
  .mantine-Select-filledVariant {
    height: 50px;
    border-radius: 16px;
    background-color: #f5f5f5;
  }
  .mantine-Select-label {
    color: rgb(131, 131, 131);
    font-weight: 400;
  }
  label {
    padding-left: 12px;
  }
`;

export default Valuador;
