import React from "react";
import styled from "styled-components/macro";
import logoWOOW from "../assets/logos/logoWOOW.svg";
import backImage from "../assets/back.svg";
import { useViewportSize } from "@mantine/hooks";
import { useNavigate } from "react-router-dom";
import useStore from "../useStore";

export const HeaderWOOW = ({
  subtitle,
  conRegresar,
  pagina,
  isFirstPageCotizadorInterno,
}) => {
  const { globalTipoCotizador } = useStore();
  const { width } = useViewportSize();
  let navigate = useNavigate();
  return (
    <HeaderContainer>
      {!conRegresar || width > 1020 ? (
        <>
          {globalTipoCotizador === "externo" ||
          (globalTipoCotizador === "interno" && width > 1020) ? (
            <a href={`${process.env.REACT_APP_LOGIN_WOOW}`}>
              <img src={logoWOOW} alt="logo woow" />
            </a>
          ) : null}
          <span className="title">Seguro de Hogar</span>
          <span className="subtitle">{subtitle}</span>
        </>
      ) : (
        <div className="back" onClick={() => navigate(`${pagina}`)}>
          <img src={backImage} alt="" className="flechaAtras" />
          <span className="backText">Regresar</span>
          {globalTipoCotizador === "externo" ? (
            <a href={`${process.env.REACT_APP_LOGIN_WOOW}`}>
              <img src={logoWOOW} alt="logo woow" />
            </a>
          ) : null}
        </div>
      )}
    </HeaderContainer>
  );
};

const HeaderContainer = styled.div`
  width: 100%;
  background: linear-gradient(312.79deg, #36405f 5.82%, #536291 89.32%);
  height: 60px;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  @media (max-width: 1020px) {
    position: absolute;
    top: 0;
    height: 80px;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }

  img {
    height: 32px;
    margin-left: 3vw;
    @media (max-width: 1020px) {
      margin-top: 30px;
      margin-left: 10vw;
      margin-bottom: 10px;
    }
  }

  .title {
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    color: #ffffff;
    margin-left: 10vw;
    display: none;
    @media (max-width: 1020px) {
      display: block;
      font-size: 18px;
      margin-left: 0;
    }
  }

  .subtitle {
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
    color: #ffffff;
    margin-left: 10vw;
    display: none;
    @media (max-width: 1020px) {
      display: block;
      font-size: 18px;
      margin-left: 0;
    }
  }

  .back {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    .backText {
      font-weight: 400;
      font-size: 20px;
      line-height: 28px;
      color: #ffffff;
      margin-left: 10vw;
      @media (max-width: 1020px) {
        font-size: 18px;
        margin-left: 8vw;
      }
    }

    .flechaAtras {
      @media (max-width: 1020px) {
        width: 20px;
      }
    }

    img {
      margin-bottom: 25px;
    }
  }
`;
