import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useViewportSize, useWindowScroll } from "@mantine/hooks";
import woowLogo from "../../assets/Navbar/woow_logo_verticales_a_hogar.png";
import { Menu, Drawer, useMantineColorScheme } from "@mantine/core";
import menuIcon from "../../assets/Navbar/menu.png";

const Navbar = ({ color }) => {
  const { colorScheme } = useMantineColorScheme();
  const [scroll] = useWindowScroll();
  const { width } = useViewportSize();
  const [isScrolled, setIsScrolled] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  useEffect(() => {
    if (scroll.y > 50) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  }, [scroll]);

  return (
    <NavbarContainer isScrolled={isScrolled} color={color} theme={colorScheme}>
      <div className="wrapper">
        <a href="https://www.woowtodobien.com/">
          <img src={woowLogo} alt="woow" />
        </a>
      </div>
      {width > 700 ? (
        <div className="wrapper" style={{ paddingRight: 67 }}>
          <Menu trigger="hover" radius={10}>
            <Menu.Target>
              <div className="white productos">
                <p>PRODUCTOS</p>
              </div>
            </Menu.Target>
            <Menu.Dropdown>
              <Menu.Item component="a" href="https://woowtodobien.com/woowpass">
                WOOW PASS
              </Menu.Item>
              <Menu.Divider />
              <div className="autos-menu">
                <Menu.Item
                  component="a"
                  href="https://seguroauto.woowtodobien.com/"
                >
                  SEGURO DE AUTOS
                  <div className="autos-submenu">
                    <ul>
                      <li>
                        <a href="https://seguroauto.woowtodobien.com/chubb">
                          CHUBB
                        </a>
                      </li>
                      <li>
                        <a href="https://seguroauto.woowtodobien.com/crabi">
                          CRABI
                        </a>
                      </li>
                      <li>
                        <a href="https://seguroauto.woowtodobien.com/pago-por-kilometro">
                          PAGO POR KILÓMETRO
                        </a>
                      </li>
                    </ul>
                  </div>
                </Menu.Item>
              </div>
              <Menu.Divider />
              <Menu.Item
                component="a"
                href="https://seguromoto.woowtodobien.com/"
              >
                SEGURO DE MOTO
              </Menu.Item>
              <Menu.Divider />
              <div className="mascotas-menu">
                <Menu.Item
                  component="a"
                  href="https://seguromascotas.woowtodobien.com/"
                >
                  SEGURO DE MASCOTAS
                  <div className="mascotas-submenu">
                    <ul>
                      <li>
                        <a href="https://seguromascotas.woowtodobien.com/gmx">
                          GMX
                        </a>
                      </li>
                      <li>
                        <a href="https://seguromascotas.woowtodobien.com/gnp">
                          GNP
                        </a>
                      </li>
                    </ul>
                  </div>
                </Menu.Item>
              </div>
              <Menu.Divider />
              <Menu.Item
                component="a"
                href="https://segurogadgets.woowtodobien.com/"
              >
                SEGURO DE GADGETS
              </Menu.Item>
              <Menu.Divider />
              <Menu.Item
                component="a"
                href="https://segurocelular.woowtodobien.com/"
              >
                SEGURO PROTECCIÓN CELULAR
              </Menu.Item>
              <Menu.Divider />
              <div className="autos-menu">
                <Menu.Item
                  component="a"
                  href="https://segurociclistas.woowtodobien.com/"
                >
                  SEGURO DE CICLISTAS
                  <div className="autos-submenu">
                    <ul>
                      <li>
                        <a href="https://segurociclistas.woowtodobien.com/clupp">
                          CLUPP
                        </a>
                      </li>
                      <li>
                        <a href="https://segurociclistas.woowtodobien.com/hir">
                          HIR
                        </a>
                      </li>
                      <li>
                        <a href="https://segurociclistas.woowtodobien.com/hdi">
                          HDI
                        </a>
                      </li>
                    </ul>
                  </div>
                </Menu.Item>
              </div>
              <Menu.Divider />
              <Menu.Item
                component="a"
                href="https://seguromedico.woowtodobien.com/"
              >
                SEGURO DE GASTOS MÉDICOS HOSPITALARIOS
              </Menu.Item>
              <Menu.Divider />
              <Menu.Item
                component="a"
                href="https://segurohogar.woowtodobien.com/"
              >
                SEGURO DE HOGAR
              </Menu.Item>
              <Menu.Divider />
              <Menu.Item
                component="a"
                href="https://segurovida.woowtodobien.com"
              >
                SEGURO DE VIDA
              </Menu.Item>
              <Menu.Divider />
              <Menu.Item
                component="a"
                href="https://seguroviajes.woowtodobien.com/"
              >
                VIAJA + SEGURO
              </Menu.Item>
              <Menu.Divider />
              <div className="autos-menu">
                <Menu.Item
                  component="a"
                  href="https://asistencia.woowtodobien.com/"
                >
                  ASISTENCIAS
                  <div className="autos-submenu">
                    <ul>
                      <li>
                        <a href="https://asistencia.woowtodobien.com/psicologia">
                          PSICOLOGÍA
                        </a>
                      </li>
                    </ul>
                  </div>
                </Menu.Item>
              </div>
              <Menu.Divider />
              <Menu.Item
                component="a"
                href="https://woowtodobien.com/tarjeta-de-regalo-woow"
              >
                TARJETA DE REGALO
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>

          <a href="https://blog.woowtodobien.com/">BLOG</a>
          <Menu trigger="hover" radius={10}>
            <Menu.Target>
              <div className="white productos">
                <p>DESCARGA LA APP</p>
              </div>
            </Menu.Target>
            <Menu.Dropdown>
              <Menu.Item
                component="a"
                href="https://apps.apple.com/mx/app/woow-seguros-y-servicios/id1539521309?l=es"
              >
                IOS
              </Menu.Item>
              <Menu.Divider />
              <Menu.Item
                component="a"
                href="https://play.google.com/store/apps/details?id=com.xs.protect.woow&pli=1"
              >
                ANDROID
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
          <a href={`${process.env.REACT_APP_LOGIN_WOOW}`}>
            <StyledButton isScrolled={isScrolled}>INGRESA</StyledButton>
          </a>
        </div>
      ) : (
        <MobileBurger>
          <img
            onClick={() => {
              setIsDrawerOpen(true);
            }}
            src={menuIcon}
            alt="menu"
            style={{ filter: "brightness(0%)" }}
          />
          <Drawer
            opened={isDrawerOpen}
            onClose={() => {
              setIsDrawerOpen(false);
            }}
            padding="xl"
            size="300px"
            position="right"
            style={{ overflow: "scroll" }}
          >
            <DrawerContainer style={{ overflowY: "auto" }}>
              <a href={`${process.env.REACT_APP_LOGIN_WOOW}`}>Inicio</a>
              <p>Productos</p>
              <ul>
                <li>
                  <a href="https://woowtodobien.com/woowpass">WOOW Pass</a>
                </li>
                <li>
                  <a href="https://seguroauto.woowtodobien.com/">
                    Seguro de Auto
                  </a>
                </li>
                <ul>
                  <li>
                    <a href="https://seguroauto.woowtodobien.com/chubb">
                      CHUBB
                    </a>
                  </li>
                  <li>
                    <a href="https://seguroauto.woowtodobien.com/crabi">
                      CRABI
                    </a>
                  </li>
                  <li>
                    <a href="https://seguroauto.woowtodobien.com/pago-por-kilometro">
                      PAGO POR KILÓMETRO
                    </a>
                  </li>
                </ul>
                <li>
                  <a href="https://seguromoto.woowtodobien.com/">
                    Seguro de Moto
                  </a>
                </li>
                <li>
                  <a href="https://seguromascotas.woowtodobien.com/">
                    Seguro de Mascotas
                  </a>
                </li>
                <ul>
                  <li>
                    <a href="https://seguromascotas.woowtodobien.com/gmx">
                      GMX
                    </a>
                  </li>
                  <li>
                    <a href="https://seguromascotas.woowtodobien.com/gnp">
                      GNP
                    </a>
                  </li>
                </ul>
                <li>
                  <a href="https://segurogadgets.woowtodobien.com/">
                    Seguro de Gadgets
                  </a>
                </li>
                <li>
                  <a href="https://segurocelular.woowtodobien.com/">
                    Seguro Protección Celular
                  </a>
                </li>
                <li>
                  <a href="https://segurociclistas.woowtodobien.com/">
                    Seguro de Ciclistas
                  </a>
                </li>
                <ul>
                  <li>
                    <a href="https://segurociclistas.woowtodobien.com/clupp">
                      CLUPP
                    </a>
                  </li>
                  <li>
                    <a href="https://segurociclistas.woowtodobien.com/hir">
                      HIR
                    </a>
                  </li>
                  <li>
                    <a href="https://segurociclistas.woowtodobien.com/hdi">
                      HDI
                    </a>
                  </li>
                </ul>
                <li>
                  <a href="https://seguromedico.woowtodobien.com/">
                    Seguro de Gastos Médicos Hospitalarios
                  </a>
                </li>
                <li>
                  <a href="https://segurohogar.woowtodobien.com/">
                    Seguro de Hogar
                  </a>
                </li>
                <li>
                  <a href="https://segurovida.woowtodobien.com">
                    Seguro de Vida
                  </a>
                </li>
                <li>
                  <a href="https://seguroviajes.woowtodobien.com/">
                    Viaja + Seguro
                  </a>
                </li>
                <li>
                  <a href="https://asistencia.woowtodobien.com/">Asistencias</a>
                </li>
                <ul>
                  <li>
                    <a href="https://asistencia.woowtodobien.com/psicologia">
                      Psicología
                    </a>
                  </li>
                </ul>
                <li>
                  <a href="https://woowtodobien.com/tarjeta-de-regalo-woow">
                    Tarjeta de Regalo
                  </a>
                </li>
              </ul>
              <a href="https://blog.woowtodobien.com">Blog</a>
              <p>Descarga la app</p>
              <ul>
                <li>
                  <a href="https://apps.apple.com/mx/app/woow-seguros-y-servicios/id1539521309?l=es">
                    IOS
                  </a>
                </li>
                <li>
                  <a href="https://play.google.com/store/apps/details?id=com.xs.protect.woow&pli=1">
                    ANDROID
                  </a>
                </li>
              </ul>
              <a href={`${process.env.REACT_APP_LOGIN_WOOW}`}>
                <button
                  style={{ backgroundColor: color, fontFamily: "Montserrat" }}
                >
                  INGRESA
                </button>{" "}
              </a>
            </DrawerContainer>
          </Drawer>
        </MobileBurger>
      )}
    </NavbarContainer>
  );
};

const NavbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 109px;
  top: 0;
  z-index: 100;
  position: sticky;
  transition: all 0.5s ease;
  background-color: ${(theme) => {
    return theme.theme === "dark" ? "#242424" : "#fff";
  }};
  @media (max-width: 1020px) {
    height: 86px;
  }
  a,
  p {
    color: ${(theme) => {
      return theme.theme === "dark" ? "#fff" : "#000";
    }};
    font-family: Montserrat !important;
    font-weight: 500;
    text-decoration: none;
    font-size: 14px;
    cursor: pointer;
  }

  .mantine-Menu-divider {
    margin-left: 5px;
    margin-right: 5px;
  }

  .mantine-Menu-itemLabel {
    color: ${(theme) => {
      return theme.theme === "dark" ? "#fff" : "#4e4e4e";
    }};
    letter-spacing: 0.125rem;
    font-weight: 600;
    font-size: 0.95rem;
    max-width: 350px;
    margin-top: -3px;
    margin-bottom: -3px;
  }

  .productos {
    display: flex;
    align-items: center;
  }
  .productos::after {
    display: inline-block;
    margin-left: 16px;
    content: "";
    color: ${(theme) => {
      return theme.theme === "dark" ? "#fff" : "#000";
    }};
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-left: 0.3em solid transparent;
  }

  .wrapper {
    padding: 0px 50px;
    padding: 0px 90px;
    display: flex;
    gap: 40px;
    justify-content: center;
    align-items: center;

    @media (max-width: 1020px) {
      padding: 0px 10px;
    }

    .autos-submenu {
      display: none;
      ul {
        list-style-type: none;
        padding: 5px 10px;
        margin: 5px 0;
        li {
          margin-bottom: 10px;
          a {
            color: ${(theme) => {
              return theme.theme === "dark" ? "#fff!important" : "#4e4e4e !important";
            }};
            font-size: 16px;
          }
        }
      }
    }

    .autos-menu {
      transition: all 0.25s ease-out;
      &:hover {
        .autos-submenu {
          display: block;
        }
      }
    }

    .mascotas-submenu {
      display: none;
      ul {
        list-style-type: none;
        padding: 5px 10px;
        margin: 5px 0;
        li {
          margin-bottom: 10px;
          a {
            color: ${(theme) => {
              return theme.theme === "dark" ? "#fff!important" : "#4e4e4e !important";
            }};
            font-size: 16px;
          }
        }
      }
    }

    .mascotas-menu {
      transition: all 0.25s ease-out;
      &:hover {
        .mascotas-submenu {
          display: block;
        }
      }
    }

    img {
      height: 58px;
      @media (max-width: 1020px) {
        height: 44px;
      }
    }
  }
`;

const StyledButton = styled.button`
  font-family: Montserrat;
  letter-spacing: 0.08em;
  padding: 10px 16px;
  background: #f0ce3f;
  background-blend-mode: color-burn, normal;
  border-radius: 30px;
  border: 1px transparent;
  color: #fff;
  font-weight: 600 !important;
  text-align: center;
  cursor: pointer;
`;

const MobileBurger = styled.div`
  .mantine-Drawer-drawer {
    overflow: scroll;
  }

  .mantine-Paper-root {
    overflow: scroll;
  }

  img {
    height: 40px;
    padding-right: 40px;
    cursor: pointer;
  }
`;

const DrawerContainer = styled.div`
  font-family: Montserrat;
  overflow: auto;
  max-height: 90vh;
  p {
    font-weight: 600;
    color: #4e4e4e;
    padding-bottom: 15px;
    padding-top: 15px;
  }

  a {
    font-size: 1.1rem;
    text-decoration: none;
    font-weight: 600;
    color: #4e4e4e;
  }

  ul {
    list-style-type: none;
    li {
      padding-bottom: 3px;
      a {
        letter-spacing: 1px;
        font-weight: initial;
      }
    }
  }

  button {
    cursor: pointer;
    display: block;
    margin-top: 15px;
    width: 100%;
    letter-spacing: 0.15rem;
    font-weight: 600;
    border-radius: 16px;
    border: none;
    color: white;
    padding: 16px 32px;
    margin-bottom: 20px;
    transition: all 0.1s ease-in;
    outline: none;
  }
`;

export default Navbar;
