import styled from "styled-components"
const Section = ({ children, bgColor, margin }) => {
  return (
    <SectionContainer bgColor={bgColor} margin={margin}>
      <div className="container">{children}</div>
    </SectionContainer>
  )
}

const SectionContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin: ${({ margin }) => margin || "0px"};
  background-color: ${({ bgColor }) => bgColor || "#FFF"};

  @media (max-width: 800px) {
    flex-direction: column;
  }

  .container {
    width: 100%;
    max-width: 1300px;
  }
`

export default Section;