import logo from "../../assets/landing/Sin_letras_chiquitas_dark.svg"
import dialogDots from "../../assets/landing/comment-icon-dark.svg"
import Section from "./Section"
import styled from "styled-components"
import { useMantineColorScheme } from "@mantine/core"

const LetrasChiquitas = ({
  content = [
    {
      text:
        "d the actual teachings of the great explorer of the truth, the master-builder of h"
    },
    {
      text:
        "lorem d the actual teachings of the great explorer of the truth, the master-builder of h lorem d the actual teachings of the great explorer of the truth, the master-builder of h"
    },
    {
      text:
        "lorem d the actual teachings of the great explorer of the truth, the master-builder of h"
    },
    {
      text:
        "lorem d the actual teachings of the great explorer of the truth, the master-builder of h"
    },
    {
      text:
        "lorem d the actual teachings of the great explorer of the truth, the master-builder of h"
    }
  ],
  svgColor,
  title = "Estos son los puntos más importantes:",
  bgColor = "#FFF"
}) => {
  const { colorScheme } = useMantineColorScheme()
  return (
    <Section bgColor={bgColor}>
      <LetrasChiquitasContainer theme={colorScheme} svgColor={svgColor}>
        <img className="logo" src={logo} alt="" />
        <p className="title">{title}</p>
        {content.map(item => {
          return (
            <div className="item">
              <img src={dialogDots} alt="" />
              <p>{item.text}</p>
            </div>
          )
        })}
      </LetrasChiquitasContainer>
    </Section>
  )
}

export const LetrasChiquitasContainer = styled.div`
  width: 100%;
  padding: 15px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 8px 32px rgb(0 0 0 / 8%);
  border-radius: 16px;
  margin-bottom: 30px;
  box-sizing: border-box;
  margin-top: 30px;
  font-family: Montserrat;

  @media (max-width: 400px) {
  }

  .logo {
    align-self: center;
    width: 400px;

    @media (max-width: 500px) {
      width: 60vw;
    }

    //filter: ${({ svgColor }) => svgColor && svgColor};
  }

  .title {
    margin-left: 15px;
    font-size: 18px;
    font-weight: 500;
  }

  .item {
    display: flex;
    padding: 0px 15px;

    img {
      align-self: flex-start;
      margin-top: 15px;
      padding-right: 8px;
      height: 25px;
      //filter: ${({ svgColor }) => svgColor && svgColor};
    }

    p {
      color: ${theme => {
        return theme.theme === "dark" ? "#fff" : "#4e4e4e"
      }};
      font-size: 16px;
      line-height: 21px;
    }
  }
`

export default LetrasChiquitas;