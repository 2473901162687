import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Modal } from "@mantine/core";

const AvisoSinCotizaciones = ({ openModal, onCloseModal }) => {
  const [width, setWidth] = useState(window.innerWidth);
  const breakpoint = 1020;
  const isResponsive = width < breakpoint;

  useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleWindowResize);

    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  return (
    <Container>
      <MantineStyledModal
        size={isResponsive ? "100%" : "30%"}
        withCloseButton={false}
        opened={openModal}
        onClose={onCloseModal}
      >
        <Div>
          <div className="subtitle">No tienes cotizaciones pendientes</div>
        </Div>
      </MantineStyledModal>
    </Container>
  );
};

const Container = styled.div``;

const MantineStyledModal = styled(Modal)`
  .mantine-Modal-modal {
    padding: 0;
    border-radius: 18px;
  }
  .mantine-Modal-header {
    margin-right: 0;
    border-radius: 16px 16px 0px 0px;
  }
`;

const Div = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  .subtitle {
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;
    text-align: center;
    color: #4e4e4e;
    padding: 20px;
  }
`;

export default AvisoSinCotizaciones;
