import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import {
  Checkbox,
  Select,
  NumberInput,
  Anchor,
  TextInput,
  LoadingOverlay,
  Tooltip,
  useMantineColorScheme,
} from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import chevronDown from "../assets/chevron.svg";
import useStore from "../useStore";
import Valuador from "../components/modals/Valuador";
import { useNavigate } from "react-router-dom";
import Lottie from "react-lottie";
import woowLoadingHogar from "../assets/woow-loading-hogar.json";
import { errores } from "../constants/strings";
import {
  createDealExterno,
  getCatalogosHogar,
  recotizarSumaAsegurada,
  updateDealExterno,
} from "../services/api";
import { stagesHubspot, uuid } from "../constants/constants";
import TagManager from "react-gtm-module";

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_KEY,
};
TagManager.initialize(tagManagerArgs);

export const Cotizador = () => {
  const { colorScheme } = useMantineColorScheme();
  let navigate = useNavigate();
  const {
    globalValuador,
    setglobalDatosAsegurado,
    setGlobalDatosPaquetes,
    setGlobalDatosRecotizacion,
    setGlobalIdCotizacion,
    setGlobalSumaAsegurada,
    globalSumaAsegurada,
    setGlobalCatalogos,
    setGlobalDealId,
    setGlobalContactId,
    setGlobalTipoCotizador,
    setGlobalPaquetesFactorLinea,
    setGlobalAlarmaLocal,
    globalTipoCotizador,
    globalCpValuador,
  } = useStore();
  const [datosDelAsegurado, setDatosDelAsegurado] = useState({
    esPlaya: "0",
    esLaguna: "0",
  });
  const [terminosYCondiciones, setTerminosYCondiciones] = useState(false);
  const [perfil, setPerfil] = useState([]);
  const [medidasSeguridad, setMedidasSeguridad] = useState([]);
  const [claseInmueble, setClaseInmueble] = useState([]);
  const [tipoInmueble, setTipoInmueble] = useState([]);
  const [checkLaguna, setCheckLaguna] = useState("0");
  const [checkPlaya, setCheckPlaya] = useState("0");
  const [valuadorModalOpen, setValuadorModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [nombreError, setNombreError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [emailInvalidoError, setEmailInvalidoError] = useState(false);
  const [telefonoError, setTelefonoError] = useState(false);
  const [telefonoErrorLenght, setTelefonoErrorLenght] = useState(false);
  const [perfilError, setPerfilError] = useState(false);
  const [codigoPostalError, setCodigoPostalError] = useState(false);
  const [codigoPostalErrorLenght, setCodigoPostalErrorLenght] = useState(false);
  const [medidasError, setMedidasError] = useState(false);
  const [valorInmuebleError, setValorInmuebleError] = useState(false);
  const [terminosError, setTerminosErrror] = useState(false);
  const data = sessionStorage.getItem("cotizacion");
  const loadedData = data !== "undefined" ? JSON.parse(data) : {};
  const [datosGuardados, setDatosGuardados] = useState(loadedData);

  const emailRegex = /\S+@\S+\.\S+/;

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: woowLoadingHogar,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  useEffect(() => {
    if (sessionStorage.getItem("paqueteElegido")) {
      sessionStorage.removeItem("paqueteElegido");
    }
    if (sessionStorage.getItem("datosCoberturas")) {
      sessionStorage.removeItem("datosCoberturas");
    }

    if (sessionStorage.getItem("coberturasExtra")) {
      sessionStorage.removeItem("coberturasExtra");
    }

    if (sessionStorage.getItem("globalDatosEmision")) {
      sessionStorage.removeItem("globalDatosEmision");
    }
  }, []);

  useEffect(() => {
    if (datosGuardados) {
      setDatosDelAsegurado({
        ...datosDelAsegurado,
        nombre:
          datosGuardados?.cotizacionPrincipal.nombre ||
          datosDelAsegurado?.nombre,
        email:
          datosGuardados?.cotizacionPrincipal.email || datosDelAsegurado?.email,
        telefono:
          datosGuardados?.cotizacionPrincipal.telefono ||
          datosDelAsegurado?.telefono,
        perfilContratante:
          datosGuardados?.cotizacionPrincipal.perfilContratante ||
          datosDelAsegurado?.perfilContratante,
        codigoPostal:
          datosGuardados?.cotizacionPrincipal.codigoPostal ||
          datosDelAsegurado?.codigoPostal,
        medidasDeSeguridad:
          datosGuardados?.cotizacionPrincipal.medidasDeSeguridad ||
          datosDelAsegurado?.medidasDeSeguridad,
        valorInmueble:
          datosGuardados?.cotizacionPrincipal.valorInmueble ||
          datosDelAsegurado?.valorInmueble,
        esLaguna:
          datosGuardados?.cotizacionPrincipal.esLaguna ||
          datosDelAsegurado?.esLaguna,
        esPlaya:
          datosGuardados?.cotizacionPrincipal.esPlaya ||
          datosDelAsegurado?.esPlaya,
      });
    }
  }, [datosGuardados]);

  useEffect(() => {
    setGlobalTipoCotizador("externo");
  }, []);

  useEffect(() => {
    getCatalogosCotizador();
  }, []);

  useEffect(() => {
    if (datosDelAsegurado.nombre) {
      setNombreError(false);
    }
  }, [datosDelAsegurado.nombre]);

  useEffect(() => {
    if (datosDelAsegurado.email) {
      setEmailError(false);
    }
  }, [datosDelAsegurado.email]);

  useEffect(() => {
    if (emailRegex.test(datosDelAsegurado.email)) setEmailInvalidoError(false);
  }, [datosDelAsegurado.email]);

  useEffect(() => {
    if (datosDelAsegurado.telefono) {
      setTelefonoError(false);
    }
  }, [datosDelAsegurado.telefono]);

  useEffect(() => {
    if (datosDelAsegurado.telefono?.length === 10) {
      setTelefonoErrorLenght(false);
    }
  }, [datosDelAsegurado.telefono]);

  useEffect(() => {
    if (datosDelAsegurado.perfilContratante) {
      setPerfilError(false);
    }
  }, [datosDelAsegurado.perfilContratante]);

  useEffect(() => {
    if (datosDelAsegurado.codigoPostal) {
      setCodigoPostalError(false);
    }
  }, [datosDelAsegurado.codigoPostal]);

  useEffect(() => {
    if (datosDelAsegurado.codigoPostal?.length === 5) {
      setCodigoPostalErrorLenght(false);
    }
  }, [datosDelAsegurado.codigoPostal]);

  useEffect(() => {
    if (datosDelAsegurado.medidasDeSeguridad) {
      setMedidasError(false);
    }
  }, [datosDelAsegurado.medidasDeSeguridad]);

  useEffect(() => {
    if (datosDelAsegurado.valorInmueble) {
      setValorInmuebleError(false);
    }
  }, [datosDelAsegurado.valorInmueble]);

  useEffect(() => {
    if (terminosYCondiciones) {
      setTerminosErrror(false);
    }
  }, [terminosYCondiciones]);

  useEffect(() => {
    if (Object.keys(globalCpValuador).length > 0) {
      setDatosDelAsegurado({
        ...datosDelAsegurado,
        codigoPostal: globalCpValuador,
      });
    }
  }, [globalCpValuador]);

  const getCatalogosCotizador = () => {
    setIsLoading(true);
    getCatalogosHogar("catClaseInmueble")
      .then((response) => {
        const clase = response.data.data.items;
        const arrayClases = clase.map((item) => {
          return { label: item.descripcion, value: item.codigo.toString() };
        });
        setClaseInmueble(arrayClases);
        setIsLoading(false);
      })
      .catch(function (error) {
        setIsLoading(false);
      });
    getCatalogosHogar("catTipoInmueble")
      .then((response) => {
        const tipo = response.data.data.items;
        const arrayTipos = tipo.map((item) => {
          return { label: item.descripcion, value: item.codigo.toString() };
        });
        setTipoInmueble(arrayTipos);
        setIsLoading(false);
      })
      .catch(function (error) {
        setIsLoading(false);
      });
    getCatalogosHogar("catMedidasSeguridad")
      .then((response) => {
        const medida = response.data.data.items;
        const arrayMedidas = medida.map((item) => {
          return { label: item.descripcion, value: item.monto.toString() };
        });
        setMedidasSeguridad(arrayMedidas);
        setIsLoading(false);
      })
      .catch(function (error) {
        setIsLoading(false);
      });
    getCatalogosHogar("catPerfilCliente")
      .then((response) => {
        const perfiles = response.data.data.items;
        const arrayPerfiles = perfiles.map((item) => {
          return { label: item.descripcion, value: item.codigo.toString() };
        });
        setPerfil(arrayPerfiles);
        setIsLoading(false);
      })
      .catch(function (error) {
        setIsLoading(false);
      });
    setIsLoading(false);
  };

  const validarCotizacionOut = async () => {
    setIsLoading(true);

    setGlobalAlarmaLocal(
      datosDelAsegurado?.medidasDeSeguridad === "7.50" ? 1 : 0
    );

    const medidaSeguridad = Number(datosDelAsegurado.medidasDeSeguridad) / 100;

    const dataSumaAsegurada = {
      origin: "LP",
      montoDescMedidaSeg: medidaSeguridad || 0,
      perfilContratante: datosDelAsegurado?.perfilContratante,
      codigoPostal: datosDelAsegurado?.codigoPostal,
      esLineaPlaya: datosDelAsegurado?.esPlaya,
      esLagoLaguna: datosDelAsegurado?.esLaguna,
      valorInmueble: datosDelAsegurado?.valorInmueble || "",
      idPeriodicidadPago: 4,
      nombre: datosDelAsegurado?.nombre,
      telefono: datosDelAsegurado?.telefono,
      email: datosDelAsegurado?.email,
    };
    if (
      datosDelAsegurado.nombre &&
      datosDelAsegurado.email &&
      emailRegex.test(datosDelAsegurado.email) &&
      datosDelAsegurado.telefono &&
      datosDelAsegurado.telefono?.length === 10 &&
      datosDelAsegurado.perfilContratante &&
      datosDelAsegurado.codigoPostal &&
      datosDelAsegurado.codigoPostal?.length === 5 &&
      datosDelAsegurado.medidasDeSeguridad &&
      datosDelAsegurado.valorInmueble &&
      terminosYCondiciones
    ) {
      recotizarSumaAsegurada(dataSumaAsegurada, uuid)
        .then((response) => {
          if (response.data?.success === 1) {
            setGlobalCatalogos([
              claseInmueble,
              tipoInmueble,
              medidasSeguridad,
              perfil,
            ]);
            sessionStorage.setItem(
              "globalCatalogos",
              JSON.stringify([
                claseInmueble,
                tipoInmueble,
                medidasSeguridad,
                perfil,
              ])
            );
            setglobalDatosAsegurado({ datosDelAsegurado });
            setGlobalSumaAsegurada(datosDelAsegurado.valorInmueble);

            const paqueteEsencial = response.data.event.calculo.paquetes[0];
            const paqueteVital = response.data.event.calculo.paquetes[1];
            const paquetePlus = response.data.event.calculo.paquetes[2];
            setGlobalDatosPaquetes([
              paqueteEsencial,
              paqueteVital,
              paquetePlus,
            ]);
            setGlobalPaquetesFactorLinea(response.data.event.neo.paquetes);

            const idCotizacion = response.data.idCotizacion;
            setGlobalIdCotizacion({ idCotizacion });
            const datosConfiguracion =
              response.data.event.contratoCotizacion.datosConfiguracion;
            const paquetes = response.data.event.neo.paquetes[2].coberturas;

            const datosInmueble = {
              multiclausula:
                response.data.event.contratoCotizacion.datosInmueble
                  .multiclausula,
              codigoPostalInmueble:
                response.data.event.contratoCotizacion.datosInmueble
                  .codigoPostalInmueble,
              codigoPostalContratante:
                response.data.event.contratoCotizacion.datosInmueble
                  .codigoPostalContratante,
              esConstruccionMaciza:
                response.data.event.contratoCotizacion.datosInmueble
                  .esConstruccionMaciza,
              idTipoMoneda:
                response.data.event.contratoCotizacion.datosInmueble
                  .idTipoMoneda,
              esLineaPlaya: datosDelAsegurado?.esPlaya,
              esLagoLaguna: datosDelAsegurado?.esLaguna,
              idPeriodicidadPago:
                response.data.event.contratoCotizacion.datosInmueble
                  .idPeriodicidadPago,
              valorTipo:
                response.data.event.contratoCotizacion.datosInmueble.valorTipo,
            };

            setGlobalDatosRecotizacion([
              datosConfiguracion,
              datosInmueble,
              paquetes,
            ]);
            const dataLocalStorage = {
              cotizacionPrincipal: datosDelAsegurado,
              sumaAsegurada: datosDelAsegurado.valorInmueble,
              datosRecotizacion: [datosConfiguracion, datosInmueble, paquetes],
              idCotizacion: idCotizacion,
              paquetesFactorLinea: response.data.event.neo.paquetes,
              datosPaquetes: [paqueteEsencial, paqueteVital, paquetePlus],
              tipoCotizador: globalTipoCotizador,
            };
            sessionStorage.setItem(
              "cotizacion",
              JSON.stringify(dataLocalStorage)
            );
            TagManager.dataLayer({
              dataLayer: {
                event: "hogar_seleccion_paquetes_externo",
                data: "",
              },
            });
            handleCreateDealExterno();
            setIsLoading(false);
            navigate("/cotizar");
          } else {
            showNotification({
              id: "errorCotizadorExternoResponse",
              title: "Error al cotizar",
              message:
                Object?.keys(response?.data?.errores)?.map(
                  (key) => response?.data?.errores[key]
                ) ||
                response?.data?.message ||
                "¡Ups! No podemos continuar en este momento. Por favor, revisa la información proporcionada, asegúrate de que todos los campos estén completos y que los datos sean correctos.",
              color: "red",
              autoClose: 5000,
            });
            setIsLoading(false);
          }
        })
        .catch(function (error) {
          showNotification({
            id: "errorCotizadorExterno",
            title: "Error al cotizar",
            message:
              "¡Ups! No podemos continuar en este momento. Por favor, revisa la información proporcionada, asegúrate de que todos los campos estén completos y que los datos sean correctos.",
            color: "red",
            autoClose: 5000,
          });
          setIsLoading(false);
        });
    } else {
      if (!datosDelAsegurado.nombre) {
        setNombreError(true);
      }
      if (!datosDelAsegurado.email) {
        setEmailError(true);
      }
      if (!emailRegex.test(datosDelAsegurado.email)) {
        setEmailInvalidoError(true);
      }
      if (!datosDelAsegurado.telefono) {
        setTelefonoError(true);
      }
      if (datosDelAsegurado.telefono?.length !== 10) {
        setTelefonoErrorLenght(true);
      }
      if (!datosDelAsegurado.perfilContratante) {
        setPerfilError(true);
      }
      if (!datosDelAsegurado.codigoPostal) {
        setCodigoPostalError(true);
      }
      if (datosDelAsegurado.codigoPostal?.length !== 5) {
        setCodigoPostalErrorLenght(true);
      }
      if (!datosDelAsegurado.medidasDeSeguridad) {
        setMedidasError(true);
      }
      if (!datosDelAsegurado.valorInmueble) {
        setValorInmuebleError(true);
      }
      if (!terminosYCondiciones) {
        setTerminosErrror(true);
      }
      setIsLoading(false);
    }
  };

  const handleOpenValuador = () => {
    if (
      datosDelAsegurado?.nombre &&
      datosDelAsegurado?.email &&
      datosDelAsegurado?.telefono &&
      datosDelAsegurado?.telefono.length === 10 &&
      datosDelAsegurado?.codigoPostal &&
      datosDelAsegurado?.codigoPostal.length === 5 &&
      datosDelAsegurado?.medidasDeSeguridad
    ) {
      setValuadorModalOpen(true);
    } else {
      setValuadorModalOpen(false);
      if (!datosDelAsegurado?.nombre) {
        setNombreError(true);
      }
      if (!datosDelAsegurado?.email) {
        setEmailError(true);
      }
      if (!datosDelAsegurado?.telefono) {
        setTelefonoError(true);
      }
      if (datosDelAsegurado?.telefono.length !== 10) {
        setTelefonoErrorLenght(true);
      }
      if (!datosDelAsegurado?.perfilContratante) {
        setPerfilError(true);
      }
      if (!datosDelAsegurado?.codigoPostal) {
        setCodigoPostalError(true);
      }
      if (datosDelAsegurado?.codigoPostal.length !== 5) {
        setCodigoPostalErrorLenght(true);
      }
      if (!datosDelAsegurado?.medidasDeSeguridad) {
        setMedidasError(true);
      }
    }
  };

  const handleUpdateDeal = (dealId) => {
    const dataUpdateDeal = {
      producto_interes: "Seguro de Hogar",
      externo_hogar: true,
      deal_id: dealId,
      dealstage: stagesHubspot?.interesado,
      pipeline: stagesHubspot?.pipeline,
      amount: 0,
    };
    updateDealExterno(dataUpdateDeal)
      .then((response) => {})
      .catch(function (error) {});
  };

  const handleCreateDealExterno = () => {
    const dataCreateDeal = {
      nombre: datosDelAsegurado?.nombre,
      email: datosDelAsegurado?.email,
      celular: datosDelAsegurado?.telefono,
    };
    createDealExterno(dataCreateDeal)
      .then((response) => {
        if (response) {
          setGlobalDealId(response.data?.deal_id);
          setGlobalContactId(response.data?.contact_id);
          handleUpdateDeal(response.data?.deal_id);
        }
      })
      .catch(function (error) {});
  };

  return (
    <CotizadorContainer theme={colorScheme}>
      <LoadingOverlay
        visible={isLoading}
        radius="35px"
        loader={
          <Lottie
            className="lottie"
            options={defaultOptions}
            height={200}
            width={200}
          />
        }
      />
      <Title theme={colorScheme}>
        Cotiza y contrata en línea tu Seguro de Hogar
      </Title>
      <PersonDetailsContainerCorreoLayout theme={colorScheme}>
        <div className="inputs-container">
          <div>
            <MantineTextInputStyled
              theme={colorScheme}
              value={datosDelAsegurado?.nombre || ""}
              label="Nombre"
              required
              placeholder="Nombre*"
              variant="filled"
              hideControls
              onChange={(e) => {
                setDatosDelAsegurado({
                  ...datosDelAsegurado,
                  nombre: e.currentTarget.value?.replace(
                    /[^a-zA-Z\u00C0-\u017F\s-]/g,
                    ""
                  ),
                });
              }}
            />
            {nombreError && <span className="error">{errores.campoVacio}</span>}
          </div>
          <div>
            <MantineTextInputStyled
              theme={colorScheme}
              value={datosDelAsegurado?.email || ""}
              label="Correo"
              required
              placeholder="Correo*"
              variant="filled"
              hideControls
              onChange={(e) => {
                setDatosDelAsegurado({
                  ...datosDelAsegurado,
                  email: e.currentTarget.value,
                });
              }}
            />
            {emailError && <span className="error">{errores.campoVacio}</span>}
            {datosDelAsegurado?.email && emailInvalidoError && (
              <span className="error">{errores.emailValido}</span>
            )}
          </div>
          <div>
            <MantineTextInputStyled
              theme={colorScheme}
              value={datosDelAsegurado?.telefono || ""}
              label="Teléfono"
              required
              placeholder="Teléfono*"
              variant="filled"
              max="10"
              maxLength={10}
              pattern="[0-9]+"
              hideControls
              onChange={(e) => {
                setDatosDelAsegurado({
                  ...datosDelAsegurado,
                  telefono: e.target.value.replace(/[^0-9]/g, ""),
                });
              }}
            />
            {telefonoError && (
              <span className="error">{errores.campoVacio}</span>
            )}
            <br />
            {datosDelAsegurado?.telefono && telefonoErrorLenght && (
              <span className="error">{errores.longitudTelefono}</span>
            )}
          </div>
          <div>
            <MantineSelectStyled
              theme={colorScheme}
              rightSection={
                <img src={chevronDown} alt="flecha abajo" width="20" />
              }
              rightSectionWidth={50}
              styles={{ rightSection: { pointerEvents: "none" } }}
              label="Perfil del contratante"
              required
              value={datosDelAsegurado?.perfilContratante || ""}
              clearable
              data={perfil}
              placeholder="Perfil del contratante*"
              variant="filled"
              onChange={(value) => {
                setDatosDelAsegurado({
                  ...datosDelAsegurado,
                  perfilContratante: value,
                });
              }}
            />
            {perfilError && <span className="error">{errores.campoVacio}</span>}
          </div>
          <div>
            <MantineTextInputStyled
              theme={colorScheme}
              value={datosDelAsegurado?.codigoPostal || ""}
              label="Código postal"
              required
              placeholder="Código postal*"
              minLength={1}
              maxLength={5}
              pattern="[0-9]+"
              variant="filled"
              hideControls
              onChange={(e) => {
                setDatosDelAsegurado({
                  ...datosDelAsegurado,
                  codigoPostal: e.currentTarget.value.replace(/[^0-9]/g, ""),
                });
              }}
            />
            {codigoPostalError && (
              <span className="error">{errores.campoVacio}</span>
            )}
            <br />
            {datosDelAsegurado?.codigoPostal && codigoPostalErrorLenght && (
              <span className="error">{errores.longitudCodigoPostal}</span>
            )}
          </div>
          <div>
            <MantineSelectStyled
              theme={colorScheme}
              rightSection={
                <img src={chevronDown} alt="flecha abajo" width="20" />
              }
              rightSectionWidth={50}
              styles={{ rightSection: { pointerEvents: "none" } }}
              label="Medidas de seguridad"
              value={datosDelAsegurado?.medidasDeSeguridad || ""}
              clearable
              data={medidasSeguridad}
              required
              placeholder="Medidas de seguridad"
              variant="filled"
              hideControls
              onChange={(value) => {
                setDatosDelAsegurado({
                  ...datosDelAsegurado,
                  medidasDeSeguridad: value,
                });
              }}
            />
            {medidasError && (
              <span className="error">{errores.campoVacio}</span>
            )}
          </div>
          <div className="inmueble">
            <MantineNumberInputStyled
              theme={colorScheme}
              hideControls
              label={
                <span className="info-inmueble">
                  Valor del inmueble / Contenidos{" "}
                  <span style={{ color: "red" }}>*</span>{" "}
                  <MantineStyledTooltip
                    withinPortal={false}
                    events={{ touch: true }}
                    label={
                      <>
                        Todo lo que este dentro de tu hogar como muebles,
                        refrigerador, televisión, ropa, etc.
                      </>
                    }
                  >
                    <div className="question-inmueble">?</div>
                  </MantineStyledTooltip>
                </span>
              }
              value={
                globalValuador === 1
                  ? globalSumaAsegurada || ""
                  : datosDelAsegurado?.valorInmueble || ""
              }
              onChange={(value) => {
                setDatosDelAsegurado({
                  ...datosDelAsegurado,
                  valorInmueble: value,
                });
              }}
              placeholder="Valor del inmueble: Ej. $500,000*"
              variant="filled"
              max={30000000}
              disabled={!datosDelAsegurado.perfilContratante}
              min={
                datosDelAsegurado?.perfilContratante !== "3" ? 500000 : 250000
              }
              parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
              formatter={(value) =>
                !Number.isNaN(parseFloat(value))
                  ? `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  : ""
              }
            />
            {valorInmuebleError && (
              <>
                <span className="error">{errores.campoVacio}</span>
                <br />
              </>
            )}
            <span className="label-valuador">
              ¿No conoces el valor?{" "}
              <u onClick={() => handleOpenValuador()}>Valúalo aquí</u>
            </span>
          </div>
          <div className="question">
            <span>
              ¿Tu inmueble está ubicado a menos de 250 metros de lago o laguna?
            </span>
            <div className="checkboxes">
              <Checkbox
                checked={checkLaguna === "1"}
                value={datosDelAsegurado?.esLaguna || "1"}
                onChange={() => {
                  setDatosDelAsegurado({
                    ...datosDelAsegurado,
                    esLaguna: "1",
                  });
                  setCheckLaguna("1");
                }}
                label="Sí"
              />
              <Checkbox
                checked={checkLaguna === "0"}
                value={datosDelAsegurado?.esLaguna || "0"}
                onChange={() => {
                  setDatosDelAsegurado({
                    ...datosDelAsegurado,
                    esLaguna: "0",
                  });
                  setCheckLaguna("0");
                }}
                label="No"
              />
            </div>
          </div>
          <div className="question">
            <span class>
              ¿Tu inmueble está ubicado a menos de 500 metros de linea de playa?
            </span>
            <div className="checkboxes">
              <Checkbox
                checked={checkPlaya === "1"}
                value={datosDelAsegurado?.esPlaya || "1"}
                onChange={() => {
                  setDatosDelAsegurado({
                    ...datosDelAsegurado,
                    esPlaya: "1",
                  });
                  setCheckPlaya("1");
                }}
                label="Sí"
              />
              <Checkbox
                checked={checkPlaya === "0"}
                value={datosDelAsegurado?.esPlaya || "0"}
                onChange={() => {
                  setDatosDelAsegurado({
                    ...datosDelAsegurado,
                    esPlaya: "0",
                  });
                  setCheckPlaya("0");
                }}
                label="No"
              />
            </div>
          </div>
        </div>
      </PersonDetailsContainerCorreoLayout>
      <CheckboxsContainer>
        <div
          className="checkBoxs"
          style={{ marginTop: terminosError ? 80 : 0 }}
        >
          <Checkbox
            required
            onChange={() => {
              setTerminosYCondiciones((value) => !value);
            }}
            label={
              <>
                Acepto{" "}
                <Anchor
                  underline
                  color={colorScheme === "dark" ? "dimmed" : "dark"}
                  weight="bold"
                  size="md"
                  href="https://woowtodobien.com/terminos-y-condiciones"
                  target="_blank"
                >
                  Términos y Condiciones
                </Anchor>{" "}
                y{" "}
                <Anchor
                  underline
                  color={colorScheme === "dark" ? "dimmed" : "dark"}
                  weight="bold"
                  size="md"
                  href="https://woowtodobien.com/aviso-de-privacidad"
                  target="_blank"
                >
                  Aviso de Privacidad
                </Anchor>
              </>
            }
          />
          {terminosError && (
            <>
              <span className="error">{errores.terminosCondiciones}</span>
              <br />
            </>
          )}
        </div>
      </CheckboxsContainer>
      <Valuador
        tipoInmueble={tipoInmueble}
        claseInmueble={claseInmueble}
        datosDelAseguradoHogar={datosDelAsegurado}
        openModal={valuadorModalOpen}
        onCloseModal={() => setValuadorModalOpen(false)}
      />
      {globalValuador === 1 ? (
        <CotizarButton
          onClick={() => {
            TagManager.dataLayer({
              dataLayer: {
                event: "hogar_seleccion_paquetes_externo",
                data: "",
              },
            });
            handleCreateDealExterno();
            navigate("/cotizar");
          }}
        >
          CONTINUAR
        </CotizarButton>
      ) : (
        <CotizarButton
          onClick={() => {
            validarCotizacionOut();
          }}
        >
          COTIZAR
        </CotizarButton>
      )}
    </CotizadorContainer>
  );
};

const CotizadorContainer = styled.div`
  margin-top: 52vw;
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  padding: 10px;
  box-sizing: border-box;
  max-width: 1100px;
  left: 9px;
  top: 0px;
  border-radius: 37px;
  box-shadow: 0px 20px 19px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  background-color: ${(theme) => {
    return theme.theme === "dark" ? "#343434" : "#fff";
  }};
  z-index: 98;
  .error {
    color: red;
    font-size: 13px;
    margin-left: 5px;
  }

  @media (max-width: 1020px) {
    margin-top: 145vw;
  }

  .mantine-Tooltip-tooltip {
    background: #d9d9d9;
    color: #4e4e4e;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 15px;
    .bold {
      font-weight: 600;
      font-size: 12px;
    }
    ul {
      padding-inline-start: 30px;
    }
    @media (max-width: 1020px) {
      width: 100%;
      white-space: normal;
      ul {
        padding-inline-start: 10px;
      }
    }
  }
`;

const MantineTextInputStyled = styled(TextInput)`
  .mantine-TextInput-input {
    background-color: ${(theme) => {
      return theme.theme === "dark" ? "#4a4a4a" : "#f5f5f5";
    }};
    &::placeholder {
      color: #808083;
    }
  }
  .mantine-TextInput-filledVariant {
    height: 50px;
    border-radius: 16px;
    background-color: #f5f5f5;
  }
  .mantine-TextInput-label {
    color: rgb(131, 131, 131);
    font-weight: 400;
  }
  width: ${(props) => props.width || "auto"};
`;

const MantineNumberInputStyled = styled(NumberInput)`
  .mantine-NumberInput-input {
    background-color: ${(theme) => {
      return theme.theme === "dark" ? "#4a4a4a" : "#f5f5f5";
    }};
    &::placeholder {
      color: #808083;
    }
  }
  .mantine-NumberInput-filledVariant {
    height: 50px;
    border-radius: 16px;
    background-color: #f5f5f5;
  }
  .mantine-NumberInput-label {
    color: rgb(131, 131, 131);
    font-weight: 400;
  }
  width: ${(props) => props.width || "auto"};
`;

const MantineSelectStyled = styled(Select)`
  .mantine-Select-input {
    background-color: ${(theme) => {
      return theme.theme === "dark" ? "#4a4a4a" : "#f5f5f5";
    }};
    &::placeholder {
      color: #808083;
    }
  }
  .mantine-Select-filledVariant {
    height: 50px;
    border-radius: 16px;
    background-color: #f5f5f5;
  }
  .mantine-Select-label {
    color: rgb(131, 131, 131);
    font-weight: 400;
  }
  width: ${(props) => props.width || "auto"};
`;

const Title = styled.h2`
  color: ${(theme) => {
    return theme.theme === "dark" ? "#fff" : "#4e4e4e";
  }};
  font-size: 24px;
  font-weight: 700;
  line-height: 27px;
  letter-spacing: 0em;
  @media (max-width: 1020px) {
    text-align: center;
  }
`;

const PersonDetailsContainerCorreoLayout = styled.div`
  width: 80%;
  height: 320px;
  display: flex;
  flex-direction: column;
  .inmueble {
    .info-inmueble {
      display: flex;
      align-items: center;
    }
    .question-inmueble {
      width: 13px;
      height: 13px;
      background: #c1c1c1;
      color: #fff;
      text-align: center;
      border-radius: 20px;
      font-weight: 600;
      font-size: 10px;
      margin-left: 5px;
      :hover {
        cursor: pointer;
      }
    }
    .label-valuador {
      font-size: 12px;
      line-height: 24px;
      color: ${(theme) => {
    return theme.theme === "dark" ? "#fff" : "#000";
  }};
      :hover {
        cursor: pointer;
      }
      u {
        color: #8e98b7;
      }
    }
  }

  .question {
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 24px;
    color: ${(theme) => {
      return theme.theme === "dark" ? "#fff" : "#4e4e4e";
    }};

    .checkboxes {
      display: flex;
      gap: 30px;
      padding-top: 14px;
      input:checked {
        background-color: #536291;
        border: 1px solid #536291;
      }
    }
  }

  @media (max-width: 1020px) {
    height: auto;
    margin-bottom: 20px;
  }

  p {
    display: block;
    font-weight: 600;
    padding-left: 15px;
    font-size: 1.2rem;
  }

  .inputs-container {
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;

    @media (max-width: 930px) {
      flex-wrap: nowrap;
      flex-direction: column;
      align-items: center;
    }

    & > * {
      width: 32%;
    }

    @media (max-width: 930px) {
      & > * {
        width: 100%;
      }
    }
  }
`;

const CheckboxsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: center;
  width: 80%;

  @media (max-width: 390px) {
    height: 200px;
  }

  .checkBoxs {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    gap: 10px;
    input:checked {
      background-color: #536291;
      border: 1px solid #536291;
    }

    .mantine-Checkbox-label {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
    }
  }
`;

const CotizarButton = styled.button`
  box-sizing: initial;
  border-radius: 16px;
  border: none;
  width: 30%;
  height: 15px;
  padding: 16px 32px;
  background: linear-gradient(312.79deg, #36405f 5.82%, #536291 89.32%);
  background-blend-mode: color-burn, normal;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: #ffffff;
  transition: all 0.15s ease-out;
  margin-bottom: 20px;

  :hover {
    filter: brightness(120%);
    cursor: pointer;
  }
`;

const MantineStyledTooltip = styled(Tooltip)`
  .mantine-Tooltip-body {
    color: #000;
    background-color: #f5f5f5;
    font-size: 10px;
    line-height: 14px;
    font-weight: 400;
    width: 250px;
    padding: 10px;
    .bold {
      font-weight: 600;
    }
  }
`;
