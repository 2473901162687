import { Link } from "react-router-dom";
import styled from "styled-components/macro";
import { routerData } from "../constants/constants";

const Breadcrumbs = ({ active }) => {
  return (
    <BreadCrumbsContainer>
      <div className="nav">
        {routerData.map((item, i) => {
          if (active > i) {
            return (
              <Link
                className={active === i + 1 ? "activo" : undefined}
                to={item.linkTo}
                key={item.name + " bc"}
              >
                {" "}
                {item.name} {active !== i + 1 ? ">" : undefined}
              </Link>
            );
          } else {
            return null;
          }
        })}
      </div>
    </BreadCrumbsContainer>
  );
};

const BreadCrumbsContainer = styled.div`
  padding: 5px;
  .nav {
    a {
      outline: none;
      text-decoration: none;
      color: black;
    }

    .activo {
      color: #536291;
      font-weight: 600;
    }
  }
`;

export default Breadcrumbs;
