import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components/macro";
import {
  LoadingOverlay,
  SegmentedControl,
  Select,
  useMantineColorScheme,
} from "@mantine/core";
import headerImage from "../assets/heros/woow_lp_hogar_coberturas-adicionales.webp";
import useStore from "../useStore";
import { useNavigate } from "react-router-dom";
import Lottie from "react-lottie";
import woowLoadingHogar from "../assets/woow-loading-hogar.json";
import { HeaderWOOW } from "../components/HeaderWOOW";
import chevronDown from "../assets/chevron.svg";
import {
  descuento,
  getPerfilContratante,
  getTipoInmueble,
  stagesHubspot,
  tipoPagoDoble,
  tipoPagoIndividual,
  uuid,
  verticalColor,
} from "../constants/constants";
import { HeroPages } from "../components/HeroPages";
import {
  asociateDeal,
  loginCrearCuenta,
  recotizarCoberturas,
  updateContact,
  updateDeal,
  updateDealExterno,
} from "../services/api";
import Steps from "../components/Steps";
import Breadcrumbs from "../components/Breadcrumbs";
import TagManager from "react-gtm-module";
import AvisoPago from "../components/modals/AvisoPago";
import { showNotification } from "@mantine/notifications";

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_KEY,
};
TagManager.initialize(tagManagerArgs);

export const Coberturas = () => {
  let navigate = useNavigate();
  const [cobertura, setCobertura] = useState("");
  const [avisoPagoOpen, setAvisoPagoOpen] = useState(false);
  const [isFirstViewAvisoPagoSet, setIsFirstViewAvisoPagoSet] = useState(false);
  const [dataCoberturas, setDataCoberturas] = useState(null);
  const {
    globalDatosAsegurado,
    globalDatosCoberturas,
    globalPaqueteElegido,
    globalDatosPaquetes,
    globalDatosRecotizacion,
    setGlobalDatosCoberturas,
    setGlobalTokenCotizacion,
    setGlobalCoberturasExtras,
    globalIdCotizacion,
    globalSumaAsegurada,
    setGlobalPaqueteElegido,
    globalTipoPago,
    globalTipoCotizador,
    globalTokenCotizacion,
    globalUsuarioInterno,
    setGlobalCoberturasPaqueteElegido,
    globalNuevoCosto,
    setGlobalNuevoCosto,
    setGlobalTipoPago,
    setGlobalSumaAsegurada,
    setglobalDatosAsegurado,
    setGlobalPaquetesFactorLinea,
    setGlobalDatosRecotizacion,
    setGlobalTipoCotizador,
    setGlobalDatosPaquetes,
    setGlobalIdCotizacion,
    globalPaquetesFactorLinea,
    globalContactId,
    globalDealId,
  } = useStore();
  const { colorScheme } = useMantineColorScheme();
  const dataPaquete = sessionStorage.getItem("paqueteElegido");
  const dataCotizacion = sessionStorage.getItem("cotizacion");
  const dataDatosCoberturas = sessionStorage.getItem("datosCoberturas");
  const dataCoberturasPaqueteElegido = sessionStorage.getItem(
    "coberturasPaqueteElegido"
  );
  const dataCoberturasExtra = sessionStorage.getItem("coberturasExtra");
  const loadedDataPaquete =
    dataPaquete !== "undefined" ? JSON.parse(dataPaquete) : {};
  const loadedDataCotizacion =
    dataCotizacion !== "undefined" ? JSON.parse(dataCotizacion) : {};
  const loadedDataCoberturas =
    dataDatosCoberturas !== "undefined" ? JSON.parse(dataDatosCoberturas) : {};
  const loadedDataCoberturasPaqueteElegido =
    dataCoberturasPaqueteElegido !== "undefined"
      ? JSON.parse(dataCoberturasPaqueteElegido)
      : {};
  const loadedDataCoberturasExtra =
    dataCoberturasExtra !== "undefined" ? JSON.parse(dataCoberturasExtra) : {};
  const [datosGuardadosPaquete, setDatosGuardadosPaquete] =
    useState(loadedDataPaquete);
  const [datosGuardadosCotizacion, setDatosGuardadosCotizacion] =
    useState(loadedDataCotizacion);
  const [datosGuardadosCoberturas, setDatosGuardadosCoberturas] =
    useState(loadedDataCoberturas);
  const [
    datosGuardadosCoberturasPaqueteElegido,
    setDatosGuardadosCoberturasPaqueteElegido,
  ] = useState(loadedDataCoberturasPaqueteElegido);
  const [datosGuardadosCoberturasExtra, setDatosGuardadosCoberturasExtra] =
    useState(loadedDataCoberturasExtra);

  // const periodicidad = globalTipoPago;
  const isFirstRender = useRef(true);
  const [periodicidadPago, setPeriodicidadPago] = useState(globalTipoPago);
  const [isDisabled, setIsDisabled] = useState(null);
  const [coberturasElegidas, setCoberturasElegidas] = useState([]);
  const [esencialElegidas, setEsencialElegidas] = useState(
    globalDatosPaquetes[0]?.coberturas
  );
  const [vitalElegidas, setVitalElegidas] = useState(
    globalDatosPaquetes[1]?.coberturas
  );
  const [plusElegidas, setPlusElegidas] = useState(
    globalDatosPaquetes[2]?.coberturas
  );
  const [isLoading, setIsLoading] = useState(false);

  const [width, setWidth] = useState(window.innerWidth);
  const breakpoint = 1020;
  const isResponsive = width < breakpoint;

  useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleWindowResize);

    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  useEffect(() => {
    if (isResponsive) {
      window.scrollTo(0, 0);
    }
  }, []);

  useEffect(() => {
    handleUpdateDeal();
  }, []);

  useEffect(() => {
    if (datosGuardadosCoberturasExtra) {
      setGlobalCoberturasExtras(datosGuardadosCoberturasExtra);
      setCoberturasElegidas(datosGuardadosCoberturasExtra);
    }
  }, [datosGuardadosCoberturasExtra]);

  useEffect(() => {
    if (datosGuardadosCoberturasPaqueteElegido) {
      setGlobalCoberturasPaqueteElegido(datosGuardadosCoberturasPaqueteElegido);
    }
  }, [datosGuardadosCoberturasPaqueteElegido]);

  useEffect(() => {
    if (datosGuardadosCoberturas) {
      setGlobalDatosCoberturas(datosGuardadosCoberturas?.datosCoberturas);
      setGlobalTipoPago(datosGuardadosCoberturas?.tipoPago);
    }
  }, [datosGuardadosCoberturas]);

  useEffect(() => {
    if (datosGuardadosPaquete) {
      setGlobalPaqueteElegido(datosGuardadosPaquete);
    }
  }, [datosGuardadosPaquete]);

  useEffect(() => {
    if (datosGuardadosCotizacion) {
      setGlobalSumaAsegurada(
        datosGuardadosCotizacion?.cotizacionPrincipal?.valorInmueble
      );
      setglobalDatosAsegurado({
        datosDelAsegurado: datosGuardadosCotizacion?.cotizacionPrincipal,
      });
      setGlobalDatosPaquetes(datosGuardadosCotizacion?.datosPaquetes);
      setEsencialElegidas(
        datosGuardadosCotizacion?.datosPaquetes[0]?.coberturas
      );
      setVitalElegidas(datosGuardadosCotizacion?.datosPaquetes[1]?.coberturas);
      setPlusElegidas(datosGuardadosCotizacion?.datosPaquetes[2]?.coberturas);
      setGlobalPaquetesFactorLinea(
        datosGuardadosCotizacion?.paquetesFactorLinea
      );
      setGlobalDatosRecotizacion(datosGuardadosCotizacion?.datosRecotizacion);
      setGlobalTipoCotizador(datosGuardadosCotizacion?.tipoCotizador);
      const idCotizacion = datosGuardadosCotizacion?.idCotizacion;
      setGlobalIdCotizacion({ idCotizacion });
    }
  }, [datosGuardadosCotizacion]);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: woowLoadingHogar,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  useEffect(() => {
    if (globalNuevoCosto?.pagoTotal > 4000) {
      setAvisoPagoOpen(true);
      setIsFirstViewAvisoPagoSet(true);
      if (isFirstViewAvisoPagoSet) {
        setAvisoPagoOpen(false);
      }
    } else {
      setIsFirstViewAvisoPagoSet(false);
    }
  }, [globalNuevoCosto]);

  useEffect(() => {
    const nombrePaquete = globalPaqueteElegido.nombre;
    if (nombrePaquete === "Hogar Esencial") {
      setCobertura("0");
    } else if (nombrePaquete === "Hogar Vital") {
      setCobertura("1");
    } else if (nombrePaquete === "Hogar Plus") {
      setCobertura("2");
    }
  }, []);

  useEffect(() => {
    if (!isFirstRender.current) {
      handleRecotizarCoberturasExtras();
    } else {
      isFirstRender.current = false;
    }
  }, [coberturasElegidas]);

  useEffect(() => {
    if (globalTipoPago && globalPaqueteElegido) {
      setGlobalNuevoCosto({
        pagoTotal:
          globalTipoPago === "Anual"
            ? globalPaqueteElegido.primaAnual
            : globalPaqueteElegido.primaTotal,
        primerRecibo: globalPaqueteElegido.primaPrimerRecibo,
        reciboSubsecuente: globalPaqueteElegido.primaReciboSubsecuente,
      });
    }
  }, [globalTipoPago, globalPaqueteElegido]);

  useEffect(() => {
    if (globalDatosCoberturas) {
      const nombrePaquete = globalPaqueteElegido?.nombre;
      if (nombrePaquete === "Hogar Plus") {
        setDataCoberturas(globalDatosCoberturas[0]?.coberturas);
      } else {
        setDataCoberturas(filteredCoberturasPaquetes);
      }
    }
  }, [globalDatosCoberturas]);

  const handleUpdateDeal = () => {
    const dataUpdateDeal = {
      amount:
        globalTipoPago === "Anual"
          ? globalPaqueteElegido.primaAnual
          : globalPaqueteElegido.primaTotal,
      dealstage: stagesHubspot?.en_espera,
      producto_interes: "Seguro de Hogar",
      pipeline: stagesHubspot?.pipeline,
      deal_id: globalDealId,
      paquete_interes: globalPaqueteElegido?.nombre,
      plan_de_pagos: globalTipoPago,
      ...(globalTipoCotizador === "externo" && { externo_hogar: true }),
    };
    if (globalTipoCotizador === "interno") {
      updateDeal(globalTokenCotizacion, dataUpdateDeal)
        .then((response) => {})
        .catch(function (error) {});
    } else {
      updateDealExterno(dataUpdateDeal)
        .then((response) => {})
        .catch(function (error) {});
    }
  };

  const handleUpdateContact = (token) => {
    const dataContact = {
      contador_de_actividad: 1,
      c_costo_total_hogar:
        globalTipoPago === "Anual"
          ? globalPaqueteElegido.primaAnual
          : globalPaqueteElegido.primaTotal,
      c_paquete_de_interes_hogar: globalPaqueteElegido?.nombre,
      c_plan_de_pagos_hogar: globalTipoPago,
    };
    const tokenContact =
      globalTipoCotizador === "interno" ? globalTokenCotizacion : token;
    updateContact(tokenContact, dataContact)
      .then((response) => {})
      .catch(function (error) {});
  };

  const totalPayment = globalNuevoCosto?.pagoTotal > 4000;

  const filteredCoberturasPaquetes =
    globalDatosCoberturas[0]?.coberturas?.filter(
      (item) => item.codigoInfo !== "0000001476"
    );

  const paquetesFactorLinea = Object?.keys(globalPaquetesFactorLinea)?.map(
    function (key) {
      return globalPaquetesFactorLinea[key];
    }
  );

  const getPaqueteFactorLinea = (nombre) => {
    switch (nombre) {
      case "Hogar Esencial":
        return paquetesFactorLinea[0]["coberturas"];
      case "Hogar Vital":
        return paquetesFactorLinea[1]["coberturas"];
      case "Hogar Plus":
        return paquetesFactorLinea[2]["coberturas"];
      default:
        return paquetesFactorLinea[0]["coberturas"];
    }
  };

  const handleCoberturasPaquetes = async (value) => {
    setIsLoading(true);
    // const coberturas = globalDatosRecotizacion[2];
    const coberturas = getPaqueteFactorLinea(globalPaqueteElegido?.nombre);
    const filtered = coberturas.filter(
      (item) =>
        item.idCobertura === "0000001325" ||
        item.idCobertura === "0000001324" ||
        item.idCobertura === "0000000866" ||
        item.idCobertura === "0000000869" ||
        item.idCobertura === "0000001476"
    );

    const body = filtered.map((item) => {
      return {
        [item.idCobertura]: {
          idCobertura: item?.idCobertura,
          sumaAsegurada: item?.sumaAsegurada,
          factorLinea: item?.factorLinea || 0,
          factorRenovacion: item?.factorRenovacion || 0,
        },
      };
    });

    const esencialFiltered = {
      "Hogar Esencial": {
        coberturas: Object.assign({}, ...body),
      },
    };

    const vitalFiltered = {
      "Hogar Vital": {
        coberturas: Object.assign({}, ...body),
      },
    };

    const plusFiltered = {
      "Hogar Plus": {
        coberturas: Object.assign({}, ...body),
      },
    };

    const dataCoberturas = {
      idCotizacion: globalIdCotizacion.idCotizacion,
      codigo: value,
      origin: globalTipoCotizador === "interno" ? "APP" : "LP",
      metodoPago: periodicidadPago === "Anual" ? 1 : 4,
      datosConfiguracion: globalDatosRecotizacion[0],
      datosInmueble: globalDatosRecotizacion[1],
      paquetes:
        (value === "0" && esencialFiltered) ||
        (value === "1" && vitalFiltered) ||
        (value === "2" && plusFiltered),
    };

    const token =
      globalTipoCotizador === "interno" ? globalTokenCotizacion : uuid;

    recotizarCoberturas(dataCoberturas, token)
      .then((response) => {
        const coberturas = response.data.event.paquetes[0];
        setGlobalDatosCoberturas([coberturas]);
        if (value === "0") {
          setGlobalPaqueteElegido(globalDatosPaquetes[0]);
        } else if (value === "1") {
          setGlobalPaqueteElegido(globalDatosPaquetes[1]);
        } else if (value === "2") {
          setGlobalPaqueteElegido(globalDatosPaquetes[2]);
        }

        setIsLoading(false);
        // navigate("/coberturas");
      })
      .catch(function (error) {
        setIsLoading(false);
      });
  };

  const handleRecotizarCoberturasExtras = async (value) => {
    const formaPago = value !== undefined ? value : periodicidadPago;
    if (value !== undefined) {
      setGlobalTipoPago(value);
    }
    setIsLoading(true);
    if (cobertura === "0") {
      setGlobalCoberturasPaqueteElegido(esencialElegidas);
    } else if (cobertura === "1") {
      setGlobalCoberturasPaqueteElegido(vitalElegidas);
    } else if (cobertura === "2") {
      setGlobalCoberturasPaqueteElegido(plusElegidas);
    }

    // sessionStorage.setItem(
    //   "coberturasPaqueteElegido",
    //   JSON.stringify(globalCoberturasPaqueteElegido)
    // );

    const bodyEsencial = esencialElegidas?.map((item) => {
      return {
        [item.codigoInfo]: {
          idCobertura: item?.codigoInfo,
          sumaAsegurada: item?.sumaAsegurada,
          factorLinea: item?.factorLinea || 0,
          factorRenovacion: item?.factorRenovacion || 0,
        },
      };
    });

    const paqueteEsencial = {
      "Hogar Esencial": {
        coberturas: Object.assign({}, ...bodyEsencial),
      },
    };

    const bodyVital = vitalElegidas?.map((item) => {
      return {
        [item.codigoInfo]: {
          idCobertura: item?.codigoInfo,
          sumaAsegurada: item?.sumaAsegurada,
          factorLinea: item?.factorLinea || 0,
          factorRenovacion: item?.factorRenovacion || 0,
        },
      };
    });

    const paqueteVital = {
      "Hogar Vital": {
        coberturas: Object.assign({}, ...bodyVital),
      },
    };

    const bodyPlus = plusElegidas?.map((item) => {
      return {
        [item.codigoInfo]: {
          idCobertura: item?.codigoInfo,
          sumaAsegurada: item?.sumaAsegurada,
          factorLinea: item?.factorLinea || 0,
          factorRenovacion: item?.factorRenovacion || 0,
        },
      };
    });

    const paquetePlus = {
      "Hogar Plus": {
        coberturas: Object.assign({}, ...bodyPlus),
      },
    };

    const datos = globalDatosRecotizacion[1];
    const datosInmuebles = [datos].map((item) => {
      return {
        codigoPostalContratante: item.codigoPostalContratante,
        codigoPostalInmueble: item?.codigoPostalInmueble,
        esConstruccionMaciza: item?.esConstruccionMaciza,
        esLagoLaguna: item?.esLagoLaguna,
        esLineaPlaya: item?.esLineaPlaya,
        idPeriodicidadPago: formaPago === "Anual" ? 1 : 4,
        idTipoMoneda: item?.idTipoMoneda,
        multiclausula: item?.multiclausula,
        valorTipo: item?.valorTipo,
      };
    });

    const output = Object.assign({}, ...datosInmuebles);

    const dataCoberturas = {
      idCotizacion: globalIdCotizacion.idCotizacion,
      codigo: cobertura,
      origin: globalTipoCotizador === "interno" ? "APP" : "LP",
      metodoPago: formaPago === "Anual" ? 1 : 4,
      datosConfiguracion: globalDatosRecotizacion[0],
      datosInmueble: output,
      paquetes:
        (cobertura === "0" && paqueteEsencial) ||
        (cobertura === "1" && paqueteVital) ||
        (cobertura === "2" && paquetePlus),
    };

    const token =
      globalTipoCotizador === "interno" ? globalTokenCotizacion : uuid;

    recotizarCoberturas(dataCoberturas, token)
      .then((response) => {
        const nuevaSumaAsegurada = response.data.event.paquetes[0].primaTotal;
        const nuevoPrimerRecibo =
          response.data.event.paquetes[0].primaPrimerRecibo;
        const nuevoReciboSubsecuente =
          response.data.event.paquetes[0].primaReciboSubsecuente;
        setGlobalNuevoCosto({
          pagoTotal: nuevaSumaAsegurada,
          primerRecibo: nuevoPrimerRecibo,
          reciboSubsecuente: nuevoReciboSubsecuente,
        });
        setIsLoading(false);
      })
      .catch(function (error) {
        setIsLoading(false);
      });
  };

  const handleEmision = () => {
    setIsLoading(true);

    if (globalTipoCotizador === "interno") {
      setTimeout(() => {
        setIsLoading(false);
        setGlobalCoberturasExtras(coberturasElegidas);
        sessionStorage.setItem(
          "coberturasExtra",
          JSON.stringify(coberturasElegidas)
        );
        handleUpdateContact();
        navigate("/emision");
      }, 3000);
    } else {
      const dataUsuario = {
        nombre: globalDatosAsegurado.datosDelAsegurado.nombre,
        celular: globalDatosAsegurado.datosDelAsegurado.telefono,
        email: globalDatosAsegurado.datosDelAsegurado.email,
      };

      loginCrearCuenta(dataUsuario)
        .then((response) => {
          setGlobalTokenCotizacion(response.data.token);
          setIsLoading(false);
          setGlobalCoberturasExtras(coberturasElegidas);
          sessionStorage.setItem(
            "coberturasExtra",
            JSON.stringify(coberturasElegidas)
          );
          handleUpdateContact(response.data.token);
          navigate("/emision");
        })
        .catch(function (error) {
          setIsLoading(false);
        });
    }
  };

  const handleAddItem = (item) => {
    const nombrePaquete = globalPaqueteElegido.nombre;

    setCoberturasElegidas([...coberturasElegidas, item]);

    // sessionStorage.setItem(
    //   "coberturasExtra",
    //   JSON.stringify([...coberturasElegidas, item])
    // );

    if (nombrePaquete === "Hogar Esencial") {
      setEsencialElegidas([...esencialElegidas, item]);
    }

    if (nombrePaquete === "Hogar Vital") {
      setVitalElegidas([...vitalElegidas, item]);
    }

    if (nombrePaquete === "Hogar Plus") {
      setPlusElegidas([...plusElegidas, item]);
    }
  };

  const handleDeleteItem = (codigo) => {
    const nombrePaquete = globalPaqueteElegido.nombre;
    setCoberturasElegidas((previousCoberturas) =>
      previousCoberturas.filter((item) => item.codigoInfo !== codigo)
    );

    if (nombrePaquete === "Hogar Esencial") {
      setEsencialElegidas((previousEsencial) =>
        previousEsencial.filter((item) => item.codigoInfo !== codigo)
      );
    }

    if (nombrePaquete === "Hogar Vital") {
      setVitalElegidas((previousVital) =>
        previousVital.filter((item) => item.codigoInfo !== codigo)
      );
    }

    if (nombrePaquete === "Hogar Plus") {
      setPlusElegidas((previousPlus) =>
        previousPlus.filter((item) => item.codigoInfo !== codigo)
      );
    }
  };

  return (
    <ComparadorContainer theme={colorScheme}>
      <AvisoPago
        openModal={avisoPagoOpen}
        onCloseModal={() => setAvisoPagoOpen(false)}
      />
      <HeaderWOOW subtitle="Personaliza tu cobertura" />
      <HeroPages img={headerImage} subtitle="¡Tenemos esto para ti" />
      <ComparadorBody>
        <ColumnaPrincipalContainer>
          {!isResponsive && globalTipoCotizador === "externo" && (
            <div className="breadcrumbs">
              <Breadcrumbs active={3} />
            </div>
          )}
          <SeccionTop theme={colorScheme}>
            <div className="big-title">Coberturas adicionales a elegir:</div>
            <div className="description">
              En esta sección podrás personalizar las coberturas y Sumas
              Aseguradas de acuerdo a tus necesidades.
            </div>
            <SegmentedControl
              orientation={isResponsive ? "vertical" : "horizontal"}
              value={cobertura}
              onChange={(value) => {
                setCobertura(value);
                handleCoberturasPaquetes(value);
              }}
              data={[
                { label: "Hogar Esencial", value: "0" },
                { label: "Hogar Vital", value: "1" },
                { label: "Hogar Plus", value: "2" },
              ]}
            />
          </SeccionTop>
          <SeccionTop theme={colorScheme}>
            {dataCoberturas &&
              dataCoberturas.map((item) => (
                <>
                  <div
                    className="cobertura"
                    style={{
                      display:
                        (globalDatosAsegurado?.datosDelAsegurado
                          ?.perfilContratante === "3" &&
                          item.nombre ===
                            "Riesgos Hidrometeorológicos Edificio") ||
                        (globalDatosAsegurado?.datosDelAsegurado
                          ?.perfilContratante === "3" &&
                          item.nombre ===
                            "Terremoto y/o Erupción Volcánica Edificio")
                          ? "none"
                          : "flex",
                    }}
                  >
                    <div className="first">
                      {item.nombre}{" "}
                      {item.nombre ===
                        "Riesgos Hidrometeorológicos Contenidos" &&
                        "(Inundación, granizo, huracán, golpe de mar)"}
                      {item.nombre === "Riesgos Hidrometeorológicos Edificio" &&
                        "(Inundación, granizo, huracán, golpe de mar)"}
                    </div>
                    <div className="second">
                      {descuento && (
                        <div className="descuento">10% DESC. INCLUIDO</div>
                      )}
                      <span className="costo">
                        <span className="mx">MX$</span>
                        {item?.prima.toLocaleString("en-US", {
                          maximumFractionDigits: 2,
                        })}
                      </span>{" "}
                      <span className="periodicidad">
                        Pago{" "}
                        {globalTipoPago === "Anual"
                          ? "anual"
                          : "en 12 mensualidades"}
                      </span>
                    </div>
                    <div className="third">
                      <ButtonAgregar
                        onClick={() => {
                          handleAddItem(item);
                          setIsDisabled(true);
                          showNotification({
                            id: "coberturaAgregada",
                            title: "Cobertura agregada",
                            message: "Se agregó cobertura a tu resumen",
                            color: "blue",
                            autoClose: 3000,
                            position: "top-right",
                          });
                        }}
                        disabled={coberturasElegidas.some(
                          (addedItem) =>
                            addedItem.codigoInfo === item.codigoInfo
                        )}
                        style={{
                          background:
                            isDisabled &&
                            coberturasElegidas.some(
                              (addedItem) =>
                                addedItem.codigoInfo === item.codigoInfo
                            )
                              ? "#F5F5F5"
                              : verticalColor,
                          color:
                            isDisabled &&
                            coberturasElegidas.some(
                              (addedItem) =>
                                addedItem.codigoInfo === item.codigoInfo
                            )
                              ? "#838383"
                              : "#fff",
                        }}
                      >
                        AGREGAR
                      </ButtonAgregar>
                    </div>
                  </div>
                </>
              ))}
          </SeccionTop>
        </ColumnaPrincipalContainer>
        <ColumnaSecundariaContainer>
          {!isResponsive && <Steps active={2} />}
          <div className="sticky">
            <SeccionTopLateral theme={colorScheme}>
              <LoadingOverlay
                visible={isLoading}
                radius="16px"
                loader={
                  <Lottie
                    className="lottie"
                    options={defaultOptions}
                    height={120}
                    width={120}
                  />
                }
              />
              <div className="bodyLateral">
                <p className="big-title">Resumen</p>
                <p className="subtitle" style={{ textAlign: "left" }}>
                  {globalPaqueteElegido?.nombre}
                </p>
                <div class="pago-total">
                  <>
                    <p className="subtitle">Tipo de inmueble</p>
                    <p className="subtitle2">
                      {getTipoInmueble(
                        globalDatosAsegurado.datosValuacion?.tipoInmueble
                      ) || "Sin valuación"}
                    </p>
                  </>
                </div>
                <div class="pago-total">
                  <p className="subtitle">Suma asegurada</p>
                  <p className="subtitle2">
                    $
                    {globalSumaAsegurada.toLocaleString("en-US", {
                      maximumFractionDigits: 2,
                    })}
                  </p>
                </div>
                <div class="pago-total">
                  <p className="subtitle">Perfil del contratante</p>
                  <p className="subtitle2">
                    {getPerfilContratante(
                      globalDatosAsegurado.datosDelAsegurado?.perfilContratante
                    )}
                  </p>
                </div>
                {coberturasElegidas &&
                  coberturasElegidas.map((item, index) => (
                    <>
                      <div class="pago-total">
                        <p className="resumen">{item.nombre}</p>
                        <p className="subtitle2">
                          MX$
                          {item.prima.toLocaleString("en-US", {
                            maximumFractionDigits: 2,
                          })}
                          <ButtonQuitar
                            onClick={() => {
                              handleDeleteItem(item.codigoInfo);
                            }}
                          >
                            QUITAR
                          </ButtonQuitar>
                        </p>
                      </div>
                    </>
                  ))}
                {descuento && (
                  <>
                    <div className="descuento">10% DESC. INCLUIDO</div>
                  </>
                )}
                <div class="pago-total">
                  <p className="subtitle">Pago total</p>
                  <p className="subtitle2">
                    MX$
                    {globalNuevoCosto?.pagoTotal?.toLocaleString("en-US", {
                      maximumFractionDigits: 2,
                    })}
                  </p>
                </div>
                {periodicidadPago === "Mensual" ||
                periodicidadPago === "Mensual" ? (
                  <>
                    <div class="pago-total">
                      <p className="subtitle">Primer pago</p>
                      <p className="subtitle2">
                        MX$
                        {globalNuevoCosto?.primerRecibo?.toLocaleString(
                          "en-US",
                          {
                            maximumFractionDigits: 2,
                          }
                        )}
                      </p>
                    </div>
                    <div class="pago-total">
                      <p className="subtitle">11 pagos subsecuentes de:</p>
                      <p className="subtitle2">
                        MX$
                        {globalNuevoCosto?.reciboSubsecuente?.toLocaleString(
                          "en-US",
                          {
                            maximumFractionDigits: 2,
                          }
                        )}
                      </p>
                    </div>
                  </>
                ) : (
                  <></>
                )}
                {totalPayment ? (
                  <MantineSelectStyled
                    rightSection={
                      <img src={chevronDown} alt="flecha abajo" width="20" />
                    }
                    rightSectionWidth={50}
                    styles={{ rightSection: { pointerEvents: "none" } }}
                    required
                    data={tipoPagoDoble}
                    placeholder="Tipo de pago"
                    variant="filled"
                    value={periodicidadPago}
                    onChange={(value) => {
                      setPeriodicidadPago(value);
                      handleRecotizarCoberturasExtras(value);
                    }}
                  />
                ) : (
                  <MantineSelectStyled
                    rightSection={
                      <img src={chevronDown} alt="flecha abajo" width="20" />
                    }
                    rightSectionWidth={50}
                    styles={{ rightSection: { pointerEvents: "none" } }}
                    required
                    data={tipoPagoIndividual}
                    placeholder="Tipo de pago"
                    variant="filled"
                    value={periodicidadPago}
                    onChange={(value) => {
                      setPeriodicidadPago(value);
                      handleRecotizarCoberturasExtras(value);
                    }}
                  />
                )}
                <br />
                <ButtonPrincipal
                  onClick={() => {
                    handleEmision();
                    TagManager.dataLayer({
                      dataLayer: {
                        event: "hogar_paquete_elegido",
                        data: {
                          celular:
                            globalDatosAsegurado.datosDelAsegurado?.telefono ||
                            globalUsuarioInterno?.celular,
                          nombre: globalPaqueteElegido.nombre,
                          costo: globalNuevoCosto?.pagoTotal,
                          tipo_pago: globalTipoPago,
                        },
                      },
                    });
                  }}
                >
                  ¡LO QUIERO!
                </ButtonPrincipal>
                <br />
                <ButtonSecundario
                  theme={colorScheme}
                  onClick={() => navigate("/cotizar")}
                >
                  REGRESAR
                </ButtonSecundario>
              </div>
            </SeccionTopLateral>
            <p className="texto-abajo">
              <a
                href="https://woowtodobien.com/terminos-y-condiciones"
                target="_blank"
                rel="noreferrer"
              >
                Términos y Condiciones
              </a>
              <span>y</span>
              <a
                href="https://woowtodobien.com/aviso-de-privacidad"
                target="_blank"
                rel="noreferrer"
              >
                Aviso de Privacidad
              </a>
            </p>
          </div>
        </ColumnaSecundariaContainer>
      </ComparadorBody>
      {!isResponsive && (
        <div class="contenedor-div">
          <a
            href="https://wa.me/5215585327747"
            target="_blank"
            rel="noreferrer"
          >
            <img
              class="mi-imagen-abajo-derecha"
              src="https://21884627.fs1.hubspotusercontent-na1.net/hubfs/21884627/whatsapp_woow.svg"
              alt="Whatsapp"
              width="60px"
            />
          </a>
        </div>
      )}
    </ComparadorContainer>
  );
};

const ComparadorContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: ${(theme) => {
    return theme.theme === "dark" ? "#1A1B1E" : "#f5f5f5";
  }};
  position: relative;
  height: auto;
  padding-bottom: 50px;
  @media (max-width: 1020px) {
    height: 100%;
  }

  .contenedor-div {
    position: absolute;
  }

  .mi-imagen-abajo-derecha {
    position: fixed;
    bottom: 15px;
    right: 90px;
    z-index: 1000 !important;
  }

  .widget-app-container,
  .Application__WidgetAppContainer-sc-1f2l0a1-0,
  .dvBAhE {
    z-index: 99px !important;
  }

  @media (max-width: 992px) {
    .mi-imagen-abajo-derecha {
      position: fixed;
      bottom: 9rem;
      right: 15px;
      z-index: 1000 !important;
    }
    div#hubspot-messages-iframe-container {
      bottom: 55px !important;
    }

    div#hubspot-messages-iframe-container {
      bottom: 55px !important;
      right: 20px !important;
    }
  }

  .header-image {
    width: 1400px;
  }

  .nav {
    width: 100%;
    max-width: 1150px;
    font-size: 0.9rem;

    .actual {
      color: #037cbf;
      font-weight: 700;
    }
  }
`;

const ComparadorBody = styled.div`
  display: flex;
  width: 100%;
  max-width: 1370px;
  @media (max-width: 1020px) {
    flex-direction: column;
    margin-top: 120px;
  }
`;

const ColumnaPrincipalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 70%;
  max-width: 1250px;
  @media (max-width: 1020px) {
    width: 100%;
    align-items: center;
  }

  .breadcrumbs {
    width: 100%;
    display: flex;
    align-self: flex-start;
    margin-bottom: 5px;
  }

  .otras {
    color: #4e4e4e;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 24px;
    padding: 40px 0px;
    margin: auto;
  }
`;

const ColumnaSecundariaContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 30%;
  @media (max-width: 1020px) {
    flex-wrap: wrap;
    width: 100%;
  }

  .descuento {
    color: #f86374;
    text-align: right;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px; /* 150% */
  }

  .sticky {
    position: sticky;
    top: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .texto-abajo {
    font-weight: 600;
    font-size: 12px;
    line-height: 21px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #4e4e4e;
    a {
      color: #4e4e4e;
      text-decoration-line: underline;
    }
    span {
      margin: 0 5px;
    }
  }
`;
const SeccionTopLateral = styled.div`
  position: relative;
  margin: 20px;
  background-color: ${(theme) => {
    return theme.theme === "dark" ? "#343434" : "#fff";
  }};
  border-radius: 16px;
  box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.1);
  padding: 27px 15px 15px;
  box-sizing: border-box;
  font-size: 1.1rem;
  text-align: center;
  width: 92%;
  text-align: left;

  @media (max-width: 1020px) {
    margin: 0;
  }

  .big-title {
    font-weight: 700;
    font-size: 18px;
    line-height: 27px;
    color: ${(theme) => {
      return theme.theme === "dark" ? "#fff" : "#4e4e4e";
    }};
  }

  .subtitle {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: ${(theme) => {
      return theme.theme === "dark" ? "#fff" : "#4e4e4e";
    }};
    text-align: right;
  }

  .subtitle2 {
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: ${(theme) => {
      return theme.theme === "dark" ? "#fff" : "#4e4e4e";
    }};
    text-align: right;
  }

  .resumen {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    text-align: right;
    color: ${(theme) => {
      return theme.theme === "dark" ? "#fff" : "#4e4e4e";
    }};
    text-align: left;
  }

  .pago-total {
    display: flex;
    justify-content: space-between;
  }
`;

const SeccionTop = styled.div`
  margin: 20px;
  background-color: ${(theme) => {
    return theme.theme === "dark" ? "#343434" : "#fff";
  }};
  border-radius: 30px;
  padding: 50px;
  box-sizing: border-box;
  font-size: 1.1rem;
  text-align: center;
  width: 92%;

  @media (max-width: 1020px) {
    padding: 40px 20px;
  }

  .big-title {
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: ${(theme) => {
      return theme.theme === "dark" ? "#fff" : "#4e4e4e";
    }};
    text-align: left;
  }

  .description {
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    color: ${(theme) => {
      return theme.theme === "dark" ? "#fff" : "#4e4e4e";
    }};
    text-align: left;
    padding: 14px 0 30px 0;
  }

  .cobertura {
    display: flex;
    gap: 35px;
    margin-bottom: 80px;

    @media (max-width: 400px) {
      flex-direction: column;
    }
    @media (max-width: 1020px) {
      flex-wrap: wrap;
    }
    & .first {
      width: 50%;
      text-align: left;
      font-weight: 600;
      font-size: 1.125rem;
      line-height: 27px;
      color: ${(theme) => {
        return theme.theme === "dark" ? "#fff" : "#4e4e4e";
      }};
      @media (max-width: 1020px) {
        flex: 1 0 33%;
        text-align: center;
      }

      @media (max-width: 400px) {
        width: 100%;
      }
    }

    & .second {
      width: 25%;
      display: flex;
      flex-direction: column;

      .descuento {
        color: #f86374;
        text-align: right;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px; /* 150% */
      }

      @media (max-width: 1020px) {
        flex: 1 0 33%;
      }

      @media (max-width: 400px) {
        width: 100%;
        text-align: left;
      }
      .costo {
        font-weight: 600;
        font-size: 1.5rem;
        line-height: 17px;
        letter-spacing: 0.08em;
        text-transform: uppercase;
        color: #536291;
        text-align: right;
        margin-right: 0;
        align-items: flex-end;

        @media (max-width: 1020px) {
          text-align: center;
        }

        .mx {
          font-size: 0.625rem;
          margin-right: 0;
        }
      }
      .periodicidad {
        font-weight: 400;
        font-size: 13px;
        line-height: 24px;
        color: ${(theme) => {
          return theme.theme === "dark" ? "#fff" : "#4e4e4e";
        }};
        margin: 0;
        text-align: right;

        @media (max-width: 1020px) {
          text-align: center;
        }
      }
    }

    & .third {
      width: 25%;
      @media (max-width: 1020px) {
        width: 100%;
      }
    }
  }

  .mantine-SegmentedControl-root {
    border-radius: 30px;
    gap: 10px;
    width: 100%;
    background-color: rgba(83, 98, 145, 0.2);
    height: 40px;

    @media (max-width: 1020px) {
      width: 100%;
      height: auto;
    }
  }

  .mantine-SegmentedControl-label {
    color: #536291;
    font-size: 1.1rem;
    font-family: "Montserrat";
    font-weight: 400;
    margin-top: 5px;

    @media (max-width: 1020px) {
      font-size: 0.688rem;
      margin-top: 0;
    }

    @media (max-width: 400px) {
      font-size: 0.788rem;
      padding-top: 8px;
      white-space: normal;
    }
  }

  .mantine-SegmentedControl-labelActive {
    color: white;
  }

  .mantine-SegmentedControl-active {
    border-radius: 30px;
    height: 40px;
    background-color: #536291 !important;
    color: white;
    @media (max-width: 1020px) {
      height: 25%;
    }
  }
`;

const MantineSelectStyled = styled(Select)`
  .mantine-Select-filledVariant {
    height: 50px;
    border-radius: 16px;
    background-color: #f5f5f5;
  }
  width: ${(props) => props.width || "auto"};

  .mantine-Select-root {
  }
`;

const ButtonAgregar = styled.button`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  width: 100%;
  border-color: transparent;
  padding: 15px 0;
  background: linear-gradient(312.79deg, #36405f 5.82%, #536291 89.32%);
  border-radius: 16px;
  &:hover {
    cursor: pointer;
  }
`;

const ButtonQuitar = styled.div`
  width: auto;
  background-color: transparent;
  color: #babcbe;
  padding: 5px;
  font-weight: 400;
  font-size: 12px;
  line-height: 21px;
  &:hover {
    cursor: pointer;
  }
`;

const ButtonPrincipal = styled.div`
  padding: 15px 0;
  border-radius: 16px;
  width: 100%;
  background: linear-gradient(312.79deg, #36405f 5.82%, #536291 89.32%);
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: #ffffff;
  &:hover {
    cursor: pointer;
  }
`;

const ButtonSecundario = styled.div`
  padding: 15px 0;
  border-radius: 16px;
  width: 100%;
  background: ${(theme) => {
    return theme.theme === "dark" ? "transparent" : "#e5e5e5";
  }};
  font-weight: ${(theme) => {
    return theme.theme === "dark" ? 600 : 400;
  }};
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  border: ${(theme) => {
    return theme.theme === "dark" ? "2px solid#536291" : "transparent";
  }};
  color: ${(theme) => {
    return theme.theme === "dark" ? "#536291" : "#838383";
  }};
  &:hover {
    cursor: pointer;
  }
`;
