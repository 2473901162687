import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Section } from "@woow/base-woow-react";
import section2 from "../../assets/sections/woow_lp_hogar_tiposdeperfilesalosqueprotegemos.png";
import tick from "../../assets/tick.svg";
import { verticalColor } from "../../constants/constants";
import { useMantineColorScheme } from "@mantine/core";

export const Proteccion = () => {
  const { colorScheme } = useMantineColorScheme();
  const [width, setWidth] = useState(window.innerWidth);
  const breakpoint = 1020;
  const isResponsive = width < breakpoint;

  useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleWindowResize);

    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  return (
    <Div theme={colorScheme}>
      {/* <Section
        margin={isResponsive ? "1100px 0 0" : "500px 0 20px"}
        flexDirection="column"
      >
        <Lottie options={lottieFlechasData} height={100} width={100} />
      </Section> */}
      {/* <SectionDiv>
        <div className="container-div">
          <Lottie options={lottieFlechasData} height={100} width={100} />
        </div>
      </SectionDiv>
      <Section padding={isResponsive ? "0 51px" : "0 160px"}>
        <FlexContainer>
          <Title textAlign="center" fontSize="24px">
            ¿Qué cubre mi Seguro de Hogar?
          </Title>
          <TextBox>
            <br />
            Con este seguro nada te quitará el sueño, pues están cubiertos
            muchos artículos de tu hogar, desde pantallas y refri, hasta tu ropa
            o microondas; esto en caso de que lleguen a sufrir algún daño por
            incendio, terremoto, inundación, corto circuito o robo. <br />
            <br />
            Ah, también incluye asistencias que salvarán tu día, ya que cuentas
            con servicio de plomeros, cerrajeros, consultas médicas y hasta
            veterinario para tu lomito.
          </TextBox>
        </FlexContainer>
      </Section>
      <Section
        flexDirection={isResponsive ? "column" : "row"}
        gapSubSection="50px"
      >
        <div className="responsive">
          <FlexBox>
            <div>
              <img src={edificio} alt="edificio" style={{ maxWidth: 50 }} />
              <br />
              <ColorTitle>Edificio y Contenidos:</ColorTitle> Nos encargamos de
              cuidar tu hogar, desde la raíz hasta la punta; asegurando desde
              los cimientos, ante los riesgos a los que está expuesto el
              inmueble y contenidos, incluyendo tus objetos de valor y tu ropa
              en caso de que ocurra algún incendio, si le cayó un rayo, si
              ocurre alguna explosión. 0% de deducible en esta cobertura.
            </div>
          </FlexBox>
          <FlexBox>
            <div>
              <img
                src={responsabilidad}
                alt="responsabilidad"
                style={{ maxWidth: 50 }}
              />
              <br />
              <ColorTitle>Responsabilidad Civil: </ColorTitle>
              Cubrimos los daños por actividades familiares o trabajos
              domésticos dentro y fuera de tu hogar, por ejemplo, si al realizar
              alguna actividad, dañan a un tercero.
            </div>
          </FlexBox>
          <FlexBox>
            <div>
              <img src={escombros} alt="escombros" style={{ maxWidth: 50 }} />
              <br />
              <ColorTitle>Remoción de escombros:</ColorTitle> Si el hogar sufrió
              un percance, te apoyamos durante el tiempo que sea necesario,
              desde desmontar alguna estructura hasta acarrear escombros.
            </div>
          </FlexBox>
        </div>
      </Section>
      <Section
        flexDirection={isResponsive ? "column" : "row"}
        gapSubSection="50px"
      >
        <div className="responsive">
          <FlexBox>
            <div>
              <img src={equipo} alt="equipo" style={{ maxWidth: 50 }} />
              <br />
              <ColorTitle>
                Equipo electrónico y electrodomésticos:
              </ColorTitle>{" "}
              Tus equipos están protegidos y serán reparados o reemplazados si
              ocurrió algún descuido o por descarga eléctrica.
            </div>
          </FlexBox>
          <FlexBox>
            <div>
              <img src={cristales} alt="cristales" style={{ maxWidth: 50 }} />
              <br />
              <ColorTitle>Cristales:</ColorTitle> En caso de rotura de los
              cristales del hogar, pagamos el reemplazo, incluyendo el costo de
              instalación. Esto aplica para cristales, vitrales, domos,
              canceles, espejos, etc.
            </div>
          </FlexBox>
          <FlexBox>
            <div>
              <img src={gastos} alt="gastos" style={{ maxWidth: 50 }} />
              <br />
              <ColorTitle>Gastos Extraordinarios:</ColorTitle> Si tu casa sufre
              un siniestro y queda inhabitable, se cubrirán los gastos generados
              por esta afectación hasta por 6 meses.
            </div>
          </FlexBox>
        </div>
      </Section>
      <Section margin={isResponsive ? "0px" : "50px 0 70px"}>
        <FlexDiv>
          <div className="title-gray">
            Protegemos el patrimonio y pertenencias desde
          </div>
          <div className="title-blue">
            <span>MX</span>$617 <span>al año</span>
          </div>
          <div className="title-description">
            Arrendatario, C.P. 03100, Valor del inmueble MX$500,000, Vigilancia
            24 horas. Incluye cobertura de terremoto, inundación y granizo.
          </div>
        </FlexDiv>
      </Section>
      <Section bgColor="#F5F5F5" pb="60px" margin="60px 0 0">
        <SectionImage>
          <div className="info">
            <div className="subtitle-chubb">
              Te acompañamos cuando más lo necesitas
            </div>
            {!isResponsive && (
              <>
                <div class="text-chubb">
                  Tu hogar y tus bienes se encuentran expuestos a sufrir
                  diversos daños causados{" "}
                  <b>
                    por granizo, huracanes, inundaciones, golpes de mar, vientos
                    tempestuosos (Riesgos Hidrometeorológicos), Terremotos o
                    erupciones volcánicas.
                  </b>
                  <br />
                  <br />
                  Personaliza tu seguro como tu lo necesites, estas coberturas
                  son opcionales tu decides si deseas contratarlas.
                </div>
                <Button
                  borderRadius="16px"
                  padding="16px 32px"
                  width="200px"
                  color="#fff"
                  bgColor={verticalColor}
                  onClick={() => {
                    document.getElementById("cotizador").scrollIntoView();
                  }}
                >
                  COTIZAR AHORA
                </Button>
              </>
            )}
          </div>
          <div className="imagen">
            <img src={section1} alt="" />
          </div>
          {isResponsive && (
            <>
              <div class="text-chubb">
                Este seguro es la mejor forma de evitar pérdidas financieras por
                cualquier tipo de daño que pueda ocurrirle a tu casa o en caso
                de que, a causa de algún accidente o siniestro, resulten
                afectadas terceras personas y se te responsabilice por los
                daños.
              </div>
              <Button
                borderRadius="16px"
                padding="16px 32px"
                width="200px"
                color="#fff"
                bgColor={verticalColor}
                onClick={() => {
                  document.getElementById("cotizador").scrollIntoView();
                }}
              >
                COTIZAR AHORA
              </Button>
            </>
          )}
        </SectionImage>
      </Section> */}
      <Section
        padding="60px 0"
        bgColor={colorScheme === "dark" ? "#343434" : "#fff"}
      >
        <SectionImage theme={colorScheme}>
          {isResponsive && (
            <div className="info2">
              <div class="subtitle-chubb-white">
                ¿Quién puede contratar el Seguro de Hogar?
              </div>
              <br />
            </div>
          )}
          <div className="imagen">
            <img src={section2} alt="sección chubb" />
          </div>
          <div className="info2">
            {!isResponsive && (
              <div class="subtitle-chubb-white">
                ¿Quién puede contratar el Seguro de Hogar?
              </div>
            )}
            <div class="text-chubb-white">
              <span>
                La coberturas de cada paquete cubren las necesidades de acuerdo
                al perfil del contratante, el Seguro de Hogar es para:
              </span>
              <span>
                <img src={tick} alt="tick" /> <b>El Propietario:</b> es el dueño
                del inmueble y sus contenidos.
              </span>
              <span>
                <img src={tick} alt="tick" /> <b>El Arrendador:</b> es el dueño
                de un inmueble que renta a un tercero.
              </span>
              <span>
                <img src={tick} alt="tick" /> <b>El Arrendatario:</b> es el
                Inquilino que renta una vivienda.
              </span>
            </div>
            <Button
              borderRadius="16px"
              padding="16px 32px"
              width="200px"
              color="#fff"
              bgColor={verticalColor}
              onClick={() => {
                document.getElementById("cotizador").scrollIntoView();
              }}
            >
              COTIZAR AHORA
            </Button>
          </div>
        </SectionImage>
      </Section>
    </Div>
  );
};

const Div = styled.div`
  .responsive {
    display: flex;
    gap: 50px;
    justify-content: center;
    align-items: center;
    margin: 30px 0;
    @media (max-width: 1020px) {
      flex-direction: column;
    }
  }
`;

const SectionImage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  width: 100%;
  gap: 100px;
  padding: 70px 0;

  @media (max-width: 1366px) {
    margin-left: 24px;
    margin-right: 24px;
    width: auto;
  }

  @media (max-width: 1020px) {
    flex-direction: column;
    gap: 12px;
    align-items: flex-start;
    margin-bottom: 50px;
  }

  .info {
    @media (max-width: 1020px) {
      /* width: 100%; */
      padding-left: 0px;
      padding-bottom: 40px;
    }
  }

  & .info2 {
    width: 78%;

    @media (max-width: 1020px) {
      /* width: 100%; */
      padding-left: 10px;
      width: 100%;
    }
  }

  & .imagen {
    img {
      object-fit: contain;
      width: 600px;
      padding: 10px 0;
      border-radius: 20px;
    }
    @media (max-width: 1020px) {
      img {
        width: 100%;
      }
    }
  }

  & .subtitle-chubb {
    font-weight: 600;
    font-size: 24px;
    line-height: 40px;
    color: #4e4e4e;
    width: 90%;
    padding: 20px;
    padding-left: 0;
    line-height: 24px;
  }

  & .text-chubb {
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    color: ${(theme) => {
      return theme.theme === "dark" ? "#fff" : "#4e4e4e";
    }};
    padding: 39px 0 25px;
    width: 88%;
  }

  & .subtitle-chubb-white {
    font-weight: 600;
    font-size: 24px;
    line-height: 24px;
    padding: 24px 0 0;
    color: ${(theme) => {
      return theme.theme === "dark" ? "#fff" : "#4e4e4e";
    }};
    width: 100%;
  }

  & .text-chubb-white {
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    padding: 39px 0 25px;
    width: 80%;
    color: ${(theme) => {
      return theme.theme === "dark" ? "#fff" : "#4e4e4e";
    }};
    display: flex;
    flex-direction: column;
    @media (max-width: 1020px) {
      width: 90%;
    }
  }

  & .title-black {
    font-weight: 600;
    font-size: 36px;
    line-height: 64px;
    color: #000000;
    text-align: left;
    width: 50%;

    @media (max-width: 1020px) {
      width: 100%;
    }
  }

  & .parrafo {
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    color: #4e4e4e;
    text-align: left;
    width: 50%;

    @media (max-width: 1020px) {
      width: 100%;
    }
  }

  & .border-card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    background: #ffffff;
    border: 1px solid #f1f1f1;
    border-radius: 10px;
    height: 269px;
    padding: 39px;
    width: 33.33%;
    transition: all 0.5s ease;

    @media (max-width: 1020px) {
      width: 80%;
    }

    &:nth-child(2) {
      margin: 0 20px;
    }
    &:hover {
      filter: drop-shadow(0px 8px 28px rgba(0, 0, 0, 0.15));
    }

    & .title {
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
      color: #4e4e4e;
      padding: 10px 0 18px;
    }

    & .text {
      font-weight: 400;
      font-size: 13px;
      line-height: 15px;
      letter-spacing: -0.02em;
      color: #838383;
    }
  }
`;

const Button = styled.div`
  letter-spacing: 0.08em;
  width: 240px;
  padding: 16px 32px;
  background: linear-gradient(
    312.79deg,
    rgb(54, 64, 95) 5.82%,
    rgb(83, 98, 145) 89.32%
  );
  background-blend-mode: color-burn, normal;
  border-radius: 16px;
  border: 1px transparent;
  color: rgb(255, 255, 255);
  font-weight: 400;
  font-size: 13px;
  line-height: 17px;
  text-align: center;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  &:hover {
    cursor: pointer;
  }
`;

const ColorTitle = styled.span`
  font-weight: 600;
  font-size: ${(props) => (props.fontSize ? props.fontSize : "16px")};
  line-height: 21px;
  color: #536291;
`;

const SectionDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 1280px 0 0;
  background-color: #fff;
  margin: 500px 0 20px;
  flex-direction: column;
  .container-div {
    width: 100%;
    max-width: 1300px;
  }
  @media (max-width: 920px) {
    margin: 900px 0 0;
  }

  @media (max-width: 700px) {
    margin: 950px 0 0;
  }

  @media (max-width: 399px) {
    margin: 1000px 0 0;
  }

  @media (max-width: 389px) {
    margin: 1100px 0 0;
  }

  @media (max-width: 360px) {
    margin: 1200px 0 0;
  }
`;

const Title = styled.span`
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  text-align: center;
  color: #4e4e4e;
`;

const TextBox = styled.span`
  width: 95%;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: #838383;
`;

const FlexContainer = styled.span`
  box-sizing: border-box;
  display: flex;
  width: 100%;
  max-width: 1500px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  gap: 0px;
  background-color: transparent;
`;

const FlexBox = styled.span`
  box-sizing: border-box;
  display: flex;
  width: 100%;
  max-width: 1500px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  gap: 0px;
  background-color: transparent;
  border: 1px solid #f1f1f1;
  border-radius: 10px;
  transition: all 0.25s ease-out;
  &:hover {
    background-color: #fff;
    -webkit-filter: drop-shadow(0px 8px 28px rgba(0, 0, 0, 0.15));
    filter: drop-shadow(0px 8px 28px rgba(0, 0, 0, 0.15));
  }
  & > div {
    padding: 38px;
    height: 240px;
    img {
      margin-bottom: 20px;
    }
    @media (max-width: 1020px) {
      padding: 24px;
      height: auto;
    }
  }
`;

const FlexDiv = styled.div`
  box-sizing: border-box;
  display: flex;
  width: 100%;
  max-width: 1500px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  gap: 0px;
  transition: all 0.25s ease-out;
  background-color: transparent;
  .title-gray {
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    color: #4e4e4e;
  }
  .title-blue {
    font-weight: 600;
    font-size: 36px;
    line-height: 30px;
    text-align: center;
    color: #536291;
    margin-top: 30px;
    span {
      font-size: 20px;
    }
  }
  .title-description {
    margin-top: 30px;
    font-size: 18px;
    text-align: center;
    color: #808083;
    width: 70%;
  }
`;
