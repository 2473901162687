import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Modal } from "@mantine/core";
import woowLogo from "../../assets/logos/woow_logo_azul.svg";

const AvisoPago = ({ openModal, onCloseModal }) => {
  const [width, setWidth] = useState(window.innerWidth);
  const breakpoint = 1020;
  const isResponsive = width < breakpoint;

  useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleWindowResize);

    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  return (
    <Container>
      <MantineStyledModal
        size={isResponsive ? "100%" : "45%"}
        withCloseButton
        opened={openModal}
        onClose={onCloseModal}
      >
        <Div>
          <br />
          <img src={woowLogo} alt="woow logo" />
          <div className="subtitle">
            No olvides elegir tipo de pago, puedes seleccionar anual o mensual
          </div>
          <Button onClick={() => onCloseModal()}>CONTINUAR</Button>
        </Div>
      </MantineStyledModal>
    </Container>
  );
};

const Container = styled.div``;

const Button = styled.div`
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 32px;
  background: linear-gradient(312.79deg, #36405f 5.82%, #536291 89.32%);
  border-radius: 16px;
  &:hover {
    cursor: pointer;
  }
`;

const MantineStyledModal = styled(Modal)`
  .mantine-Modal-modal {
    padding: 0;
    border-radius: 18px;
  }
  .mantine-Modal-header {
    margin-right: 0;
    padding: 16px 24px;
    border-radius: 16px 16px 0px 0px;
    background: linear-gradient(312.79deg, #36405f 5.82%, #536291 89.32%);
  }
  .mantine-Modal-close {
    svg {
      color: #fff !important;
      width: 20px;
      height: 20px;
      &:hover {
        background-color: none !important;
      }
    }
  }
`;

const Div = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 10px 30px;
  .flex-inputs {
    width: 100%;
    display: flex;
    gap: 24px;
    margin-bottom: 24px;
    @media (max-width: 1020px) {
      flex-direction: column;
    }
  }
  .title {
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;
    text-align: center;
    color: #4e4e4e;
    padding-bottom: 24px;
  }
  .subtitle {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #4e4e4e;
    padding-bottom: 48px;
    padding-top: 20px;
  }
  .mantine-Checkbox-label {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #4e4e4e;
  }
  .mantine-Anchor-root {
    color: #fff;
    line-height: 24px;
  }

  .login {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-decoration-line: underline;
    color: #536291;
    &:hover {
      cursor: pointer;
    }
  }

  .container {
    width: 100%;
  }

  .error {
    color: red;
    font-size: 14px;
    margin-left: 10px;
  }

  & button {
    margin-top: 20px;
  }
`;

const LoadingDiv = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 20px;
`;

const PendientesDiv = styled.div`
  width: 100%;
  .header {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 51.3px;
    background: linear-gradient(312.79deg, #36405f 5.82%, #536291 89.32%);
    border-radius: 16px 16px 0px 0px;
    color: #fff;
    font-size: 18px;
    font-weight: 600;
  }
  .body {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 100px;
    background: rgba(245, 245, 245, 0.5);
    .column {
      display: flex;
      flex-direction: column;
      .title-column {
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        color: #4e4e4e;
      }
      .subtitle-column {
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: #4e4e4e;
      }

      .amount {
        width: 100%;
        font-weight: 600;
        font-size: 24px;
        line-height: 17px;
        text-align: center;
        color: #536291;

        span {
          font-size: 10px;
        }
      }
    }
  }
`;

export default AvisoPago;
