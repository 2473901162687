import React from "react";
import styled from "styled-components/macro";

export const HeroPages = ({ subtitle, img, active }) => {
  return (
    <Div>
      <HeroPagesContainer>
        <img src={img} alt="" />
        <div className="content">
          <span className="title">Seguro de Hogar</span>
          <span className="subtitle">{subtitle}</span>
        </div>
      </HeroPagesContainer>
    </Div>
  );
};

const Div = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1350px;
  .breadcrumbs {
    width: 100%;
    display: flex;
    align-self: flex-start;
    margin-bottom: 29px;
  }
`;

const HeroPagesContainer = styled.div`
  width: 1370px;
  height: 237px;
  border-radius: 16px;
  display: flex;
  align-items: center;

  @media (max-width: 1020px) {
    position: absolute;
    top: 0;
    height: 150px;
    align-items: flex-start;
    flex-direction: column;
    display: none;
  }

  img {
    width: 100%;
    border-radius: 16px;
  }

  .content {
    position: absolute;
    margin-top: 5%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .title {
    font-weight: 600;
    font-size: 25px;
    line-height: 36px;
    color: #ffffff;
  }

  .subtitle {
    font-weight: 400;
    font-size: 25px;
    line-height: 36px;
    color: #ffffff;
  }
`;
