import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { HeaderWOOW } from "../components/HeaderWOOW";
import { HeroPages } from "../components/HeroPages";
import headerImage from "../assets/heros/woow_lp_hogar_coberturas-adicionales.webp";
import {
  Checkbox,
  Select,
  NumberInput,
  Anchor,
  TextInput,
  LoadingOverlay,
  Tooltip,
  useMantineColorScheme,
} from "@mantine/core";
import chevronDown from "../assets/chevron.svg";
import useStore from "../useStore";
import Valuador from "../components/modals/Valuador";
import { useNavigate, useSearchParams } from "react-router-dom";
import Lottie from "react-lottie";
import woowLoadingHogar from "../assets/woow-loading-hogar.json";
import { errores } from "../constants/strings";
import {
  createDealInterno,
  getCatalogosHogarInterno,
  getCotizacionesPendientes,
  getThemePreferential,
  getTokenInterno,
  recotizarSumaAsegurada,
} from "../services/api";
import { replaceSpaceWithPlus } from "../constants/constants";
import Steps from "../components/Steps";
import TagManager from "react-gtm-module";
import CotizacionPendiente from "../components/modals/CotizacionPendiente";
import AvisoSinCotizaciones from "../components/modals/AvisoSinCotizaciones";
import { showNotification } from "@mantine/notifications";

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_KEY,
};
TagManager.initialize(tagManagerArgs);

export const CotizadorInterno = () => {
  let navigate = useNavigate();
  const {
    globalValuador,
    setglobalDatosAsegurado,
    setGlobalDatosPaquetes,
    setGlobalDatosRecotizacion,
    setGlobalIdCotizacion,
    setGlobalSumaAsegurada,
    globalSumaAsegurada,
    setGlobalCatalogos,
    setGlobalUsuarioInterno,
    setGlobalTipoCotizador,
    setGlobalDealId,
    setGlobalTokenCotizacion,
    globalTokenCotizacion,
    setGlobalAlarmaLocal,
    globalCotizacionesPendientes,
    setGlobalCotizacionesPendientes,
    setGlobalPaquetesFactorLinea,
    globalTipoCotizador,
    setGlobalTokenParams,
    globalCpValuador,
    globalUsuarioInterno,
  } = useStore();
  const { colorScheme, toggleColorScheme } = useMantineColorScheme();
  const [searchParams] = useSearchParams();
  const [datosDelAsegurado, setDatosDelAsegurado] = useState({
    esPlaya: "0",
    esLaguna: "0",
  });
  const [terminosYCondiciones, setTerminosYCondiciones] = useState(false);
  const [perfil, setPerfil] = useState([]);
  const [medidasSeguridad, setMedidasSeguridad] = useState([]);
  const [claseInmueble, setClaseInmueble] = useState([]);
  const [tipoInmueble, setTipoInmueble] = useState([]);
  const [checkLaguna, setCheckLaguna] = useState("0");
  const [checkPlaya, setCheckPlaya] = useState("0");
  const [valuadorModalOpen, setValuadorModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [perfilError, setPerfilError] = useState(false);
  const [codigoPostalError, setCodigoPostalError] = useState(false);
  const [codigoPostalErrorLenght, setCodigoPostalErrorLenght] = useState(false);
  const [medidasError, setMedidasError] = useState(false);
  const [valorInmuebleError, setValorInmuebleError] = useState(false);
  const [terminosError, setTerminosErrror] = useState(false);
  const [cotizacionPendienteOpen, setCotizacionPendienteOpen] = useState(false);
  const [sinCotizacionesOpen, setSinCotizacionesOpen] = useState(false);
  const data = sessionStorage.getItem("cotizacion");
  const loadedData = data !== "undefined" ? JSON.parse(data) : {};
  const [datosGuardados, setDatosGuardados] = useState(loadedData);
  const [width, setWidth] = useState(window.innerWidth);
  const breakpoint = 1020;
  const isResponsive = width < breakpoint;

  useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleWindowResize);

    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  const tokenParams = searchParams.get("token");
  const tokenInterno = replaceSpaceWithPlus(tokenParams);
  const dealInterno = searchParams.get("deal_id");

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: woowLoadingHogar,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  useEffect(() => {
    if (sessionStorage.getItem("paqueteElegido")) {
      sessionStorage.removeItem("paqueteElegido");
    }
    if (sessionStorage.getItem("datosCoberturas")) {
      sessionStorage.removeItem("datosCoberturas");
    }

    if (sessionStorage.getItem("coberturasExtra")) {
      sessionStorage.removeItem("coberturasExtra");
    }

    if (sessionStorage.getItem("globalDatosEmision")) {
      sessionStorage.removeItem("globalDatosEmision");
    }
  }, []);

  useEffect(() => {
    if (datosGuardados) {
      setDatosDelAsegurado({
        ...datosDelAsegurado,
        nombre:
          datosGuardados?.cotizacionPrincipal.nombre ||
          datosDelAsegurado?.nombre,
        email:
          datosGuardados?.cotizacionPrincipal.email || datosDelAsegurado?.email,

        telefono:
          datosGuardados?.cotizacionPrincipal.telefono ||
          datosDelAsegurado?.telefono,
        perfilContratante:
          datosGuardados?.cotizacionPrincipal.perfilContratante ||
          datosDelAsegurado?.perfilContratante,
        codigoPostal:
          datosGuardados?.cotizacionPrincipal.codigoPostal ||
          datosDelAsegurado?.codigoPostal,
        medidasDeSeguridad:
          datosGuardados?.cotizacionPrincipal.medidasDeSeguridad ||
          datosDelAsegurado?.medidasDeSeguridad,
        valorInmueble:
          datosGuardados?.cotizacionPrincipal.valorInmueble ||
          datosDelAsegurado?.valorInmueble,
        esLaguna:
          datosGuardados?.cotizacionPrincipal.esLaguna ||
          datosDelAsegurado?.esLaguna,
        esPlaya:
          datosGuardados?.cotizacionPrincipal.esPlaya ||
          datosDelAsegurado?.esPlaya,
      });
    }
  }, [datosGuardados]);

  useEffect(() => {
    if (dealInterno) {
      setGlobalDealId(dealInterno);
    } else {
      handleCreateDealInterno();
    }
  }, [dealInterno]);

  useEffect(() => {
    setGlobalTipoCotizador("interno");
  }, []);

  useEffect(() => {
    if (tokenInterno) {
      setGlobalTokenParams(tokenParams);
      handleObtenerTokenInterno();
    }
  }, [tokenInterno]);

  useEffect(() => {
    getCatalogosCotizador();
  }, []);

  useEffect(() => {
    if (datosDelAsegurado.perfilContratante) {
      setPerfilError(false);
    }
  }, [datosDelAsegurado.perfilContratante]);

  useEffect(() => {
    if (datosDelAsegurado.codigoPostal) {
      setCodigoPostalError(false);
    }
  }, [datosDelAsegurado.codigoPostal]);

  useEffect(() => {
    if (datosDelAsegurado.codigoPostal?.length === 5) {
      setCodigoPostalErrorLenght(false);
    }
  }, [datosDelAsegurado.codigoPostal]);

  useEffect(() => {
    if (datosDelAsegurado.medidasDeSeguridad) {
      setMedidasError(false);
    }
  }, [datosDelAsegurado.medidasDeSeguridad]);

  useEffect(() => {
    if (datosDelAsegurado.valorInmueble) {
      setValorInmuebleError(false);
    }
  }, [datosDelAsegurado.valorInmueble]);

  useEffect(() => {
    if (terminosYCondiciones) {
      setTerminosErrror(false);
    }
  }, [terminosYCondiciones]);

  useEffect(() => {
    if (Object.keys(globalCpValuador).length > 0) {
      setDatosDelAsegurado({
        ...datosDelAsegurado,
        codigoPostal: globalCpValuador,
      });
    }
  }, [globalCpValuador]);

  const handleObtenerTokenInterno = () => {
    getTokenInterno(tokenInterno)
      .then((response) => {
        if (response) {
          const token = response.data.event?.token;
          setGlobalUsuarioInterno(response.data.event?.data_profile);
          setGlobalTokenCotizacion(token);
          handleCotizacionesPendientes(token);
          handleGetThemePreferential(response.data.event?.token);
        }
      })
      .catch(function (error) {});
  };

  const handleGetThemePreferential = (token) => {
    getThemePreferential(token)
      .then((response) => {
        if (response && response?.status === 200) {
          if (response?.data?.data?.tema === "oscuro") {
            toggleColorScheme("dark");
          } else toggleColorScheme("light");
          localStorage.setItem(
            "tema",
            JSON.stringify({
              tema: response?.data?.data?.tema,
            })
          );
        }
      })
      .catch(function (error) {
        console.log("error", error);
      });
  };

  const handleCreateDealInterno = () => {
    createDealInterno(globalTokenCotizacion)
      .then((response) => {
        if (response) {
          setGlobalDealId(response.data?.deal_id);
        }
      })
      .catch(function (error) {});
  };

  const handleCotizacionesPendientes = (token) => {
    getCotizacionesPendientes(token)
      .then((response) => {
        if (response) {
          setGlobalCotizacionesPendientes(response.data?.event);
          if (response.data?.event?.length > 0) {
            setCotizacionPendienteOpen(true);
          }
        }
      })
      .catch(function (error) {});
  };

  const getCatalogosCotizador = () => {
    getCatalogosHogarInterno("catClaseInmueble", globalTokenCotizacion)
      .then((response) => {
        const clase = response.data.data.items;
        const arrayClases = clase.map((item) => {
          return { label: item.descripcion, value: item.codigo.toString() };
        });
        setClaseInmueble(arrayClases);
      })
      .catch(function (error) {});
    getCatalogosHogarInterno("catTipoInmueble", globalTokenCotizacion)
      .then((response) => {
        const tipo = response.data.data.items;
        const arrayTipos = tipo.map((item) => {
          return { label: item.descripcion, value: item.codigo.toString() };
        });
        setTipoInmueble(arrayTipos);
      })
      .catch(function (error) {});
    getCatalogosHogarInterno("catMedidasSeguridad", globalTokenCotizacion)
      .then((response) => {
        const medida = response.data.data.items;
        const arrayMedidas = medida.map((item) => {
          return { label: item.descripcion, value: item.monto.toString() };
        });
        setMedidasSeguridad(arrayMedidas);
      })
      .catch(function (error) {});
    getCatalogosHogarInterno("catPerfilCliente", globalTokenCotizacion)
      .then((response) => {
        const perfiles = response.data.data.items;
        const arrayPerfiles = perfiles.map((item) => {
          return { label: item.descripcion, value: item.codigo.toString() };
        });
        setPerfil(arrayPerfiles);
      })
      .catch(function (error) {});
  };

  const validarCotizacionOut = async () => {
    setIsLoading(true);

    setGlobalAlarmaLocal(
      datosDelAsegurado?.medidasDeSeguridad === "7.50" ? 1 : 0
    );

    const medidaSeguridad = Number(datosDelAsegurado.medidasDeSeguridad) / 100;

    const dataSumaAsegurada = {
      origin: "APP",
      montoDescMedidaSeg: medidaSeguridad || 0,
      perfilContratante: datosDelAsegurado?.perfilContratante,
      codigoPostal: datosDelAsegurado?.codigoPostal,
      esLineaPlaya: datosDelAsegurado?.esPlaya,
      esLagoLaguna: datosDelAsegurado?.esLaguna,
      valorInmueble: datosDelAsegurado?.valorInmueble || "",
      idPeriodicidadPago: 4,
      nombre: globalUsuarioInterno?.nombre,
      telefono: globalUsuarioInterno?.celular,
      email: globalUsuarioInterno?.email,
    };
    if (
      datosDelAsegurado.perfilContratante &&
      datosDelAsegurado.codigoPostal &&
      datosDelAsegurado.codigoPostal?.length === 5 &&
      datosDelAsegurado.medidasDeSeguridad &&
      datosDelAsegurado.valorInmueble &&
      terminosYCondiciones
    ) {
      recotizarSumaAsegurada(dataSumaAsegurada, globalTokenCotizacion)
        .then((response) => {
          if (response?.data?.success === 1) {
            setGlobalCatalogos([
              claseInmueble,
              tipoInmueble,
              medidasSeguridad,
              perfil,
            ]);
            sessionStorage.setItem(
              "globalCatalogos",
              JSON.stringify([
                claseInmueble,
                tipoInmueble,
                medidasSeguridad,
                perfil,
              ])
            );
            setglobalDatosAsegurado({ datosDelAsegurado });
            setGlobalSumaAsegurada(datosDelAsegurado.valorInmueble);

            const paqueteEsencial = response.data.event.calculo.paquetes[0];
            const paqueteVital = response.data.event.calculo.paquetes[1];
            const paquetePlus = response.data.event.calculo.paquetes[2];
            setGlobalDatosPaquetes([
              paqueteEsencial,
              paqueteVital,
              paquetePlus,
            ]);
            setGlobalPaquetesFactorLinea(response.data.event.neo.paquetes);

            const idCotizacion = response.data.idCotizacion;
            setGlobalIdCotizacion({ idCotizacion });
            const datosConfiguracion =
              response.data.event.contratoCotizacion.datosConfiguracion;
            const paquetes = response.data.event.neo.paquetes[2].coberturas;

            const datosInmueble = {
              multiclausula:
                response.data.event.contratoCotizacion.datosInmueble
                  .multiclausula,
              codigoPostalInmueble:
                response.data.event.contratoCotizacion.datosInmueble
                  .codigoPostalInmueble,
              codigoPostalContratante:
                response.data.event.contratoCotizacion.datosInmueble
                  .codigoPostalContratante,
              esConstruccionMaciza:
                response.data.event.contratoCotizacion.datosInmueble
                  .esConstruccionMaciza,
              idTipoMoneda:
                response.data.event.contratoCotizacion.datosInmueble
                  .idTipoMoneda,
              esLineaPlaya: 0,
              esLagoLaguna: 0,
              idPeriodicidadPago:
                response.data.event.contratoCotizacion.datosInmueble
                  .idPeriodicidadPago,
              valorTipo:
                response.data.event.contratoCotizacion.datosInmueble.valorTipo,
            };

            setGlobalDatosRecotizacion([
              datosConfiguracion,
              datosInmueble,
              paquetes,
            ]);
            const dataLocalStorage = {
              cotizacionPrincipal: datosDelAsegurado,
              sumaAsegurada: datosDelAsegurado.valorInmueble,
              datosRecotizacion: [datosConfiguracion, datosInmueble, paquetes],
              idCotizacion: idCotizacion,
              paquetesFactorLinea: response.data.event.neo.paquetes,
              datosPaquetes: [paqueteEsencial, paqueteVital, paquetePlus],
              tipoCotizador: globalTipoCotizador,
            };
            sessionStorage.setItem(
              "cotizacion",
              JSON.stringify(dataLocalStorage)
            );
            setIsLoading(false);
            TagManager.dataLayer({
              dataLayer: {
                event: "hogar_seleccion_paquetes_interno",
                data: "",
              },
            });
            navigate("/cotizar");
          } else {
            showNotification({
              id: "errorCotizadorInternoResponse",
              title: "Error al cotizar",
              message:
                Object?.keys(response?.data?.errores)?.map(
                  (key) => response?.data?.errores[key]
                ) ||
                response?.data?.message ||
                "¡Ups! No podemos continuar en este momento. Por favor, revisa la información proporcionada, asegúrate de que todos los campos estén completos y que los datos sean correctos.",
              color: "red",
              autoClose: 5000,
            });
            setIsLoading(false);
          }
        })
        .catch(function (error) {
          showNotification({
            id: "errorCotizadorInterno",
            title: "Error al cotizar",
            message:
              "¡Ups! No podemos continuar en este momento. Por favor, revisa la información proporcionada, asegúrate de que todos los campos estén completos y que los datos sean correctos.",
            color: "red",
            autoClose: 5000,
          });
          setIsLoading(false);
        });
    } else {
      if (!datosDelAsegurado.perfilContratante) {
        setPerfilError(true);
      }
      if (!datosDelAsegurado.codigoPostal) {
        setCodigoPostalError(true);
      }
      if (datosDelAsegurado.codigoPostal?.length !== 5) {
        setCodigoPostalErrorLenght(true);
      }
      if (!datosDelAsegurado.medidasDeSeguridad) {
        setMedidasError(true);
      }
      if (!datosDelAsegurado.valorInmueble) {
        setValorInmuebleError(true);
      }
      if (!terminosYCondiciones) {
        setTerminosErrror(true);
      }
      showNotification({
        id: "errorCotizadorInterno",
        title: "Error al cotizar",
        message:
          "¡Ups! No podemos continuar en este momento. Por favor, revisa la información proporcionada, asegúrate de que todos los campos estén completos y que los datos sean correctos.",
        color: "red",
        autoClose: 5000,
      });
      setIsLoading(false);
    }
  };

  const handleOpenValuador = () => {
    if (
      datosDelAsegurado?.codigoPostal &&
      datosDelAsegurado?.codigoPostal.length === 5 &&
      datosDelAsegurado?.medidasDeSeguridad
    ) {
      setValuadorModalOpen(true);
    } else {
      setValuadorModalOpen(false);
      if (!datosDelAsegurado?.perfilContratante) {
        setPerfilError(true);
      }
      if (!datosDelAsegurado?.codigoPostal) {
        setCodigoPostalError(true);
      }
      if (datosDelAsegurado?.codigoPostal.length !== 5) {
        setCodigoPostalErrorLenght(true);
      }
      if (!datosDelAsegurado?.medidasDeSeguridad) {
        setMedidasError(true);
      }
    }
  };

  return (
    <ComparadorContainer theme={colorScheme}>
      <HeaderWOOW
        isFirstPageCotizadorInterno
        subtitle="¡Tenemos esto para ti!"
      />
      <HeroPages img={headerImage} subtitle="¡Tenemos esto para ti" />
      {!isResponsive && <Steps active={1} />}
      <ComparadorBody>
        <CotizadorContainer theme={colorScheme}>
          <LoadingOverlay
            visible={isLoading}
            radius="35px"
            loader={
              <Lottie
                className="lottie"
                options={defaultOptions}
                height={200}
                width={200}
              />
            }
          />
          <Title theme={colorScheme}>
            Cotiza y contrata en línea tu Seguro de Hogar
          </Title>
          <PersonDetailsContainerCorreoLayout theme={colorScheme}>
            <div className="inputs-container">
              <div>
                <MantineSelectStyled
                  theme={colorScheme}
                  rightSection={
                    <img src={chevronDown} alt="flecha abajo" width="20" />
                  }
                  rightSectionWidth={50}
                  styles={{ rightSection: { pointerEvents: "none" } }}
                  label="Perfil del contratante"
                  required
                  data={perfil}
                  placeholder="Perfil del contratante*"
                  variant="filled"
                  onChange={(value) => {
                    setDatosDelAsegurado({
                      ...datosDelAsegurado,
                      perfilContratante: value,
                    });
                  }}
                />
                {perfilError && (
                  <span className="error">{errores.campoVacio}</span>
                )}
              </div>
              <div>
                <MantineTextInputStyled
                  theme={colorScheme}
                  value={datosDelAsegurado?.codigoPostal || ""}
                  label="Código postal"
                  required
                  max="5"
                  minLength={1}
                  maxLength={5}
                  pattern="[0-9]+"
                  placeholder="Código postal*"
                  variant="filled"
                  hideControls
                  onChange={(e) => {
                    setDatosDelAsegurado({
                      ...datosDelAsegurado,
                      codigoPostal: e.currentTarget.value.replace(
                        /[^0-9]/g,
                        ""
                      ),
                    });
                  }}
                />
                {codigoPostalError && (
                  <span className="error">{errores.campoVacio}</span>
                )}
                <br />
                {datosDelAsegurado?.codigoPostal?.length > 0 &&
                  codigoPostalErrorLenght && (
                    <span className="error">
                      {errores.longitudCodigoPostal}
                    </span>
                  )}
              </div>
              <div>
                <MantineSelectStyled
                  theme={colorScheme}
                  rightSection={
                    <img src={chevronDown} alt="flecha abajo" width="20" />
                  }
                  rightSectionWidth={50}
                  styles={{ rightSection: { pointerEvents: "none" } }}
                  label="Medidas de seguridad"
                  data={medidasSeguridad}
                  required
                  placeholder="Medidas de seguridad"
                  variant="filled"
                  hideControls
                  onChange={(value) => {
                    setDatosDelAsegurado({
                      ...datosDelAsegurado,
                      medidasDeSeguridad: value,
                    });
                  }}
                />
                {medidasError && (
                  <span className="error">{errores.campoVacio}</span>
                )}
              </div>
              <div className="inmueble">
                <MantineNumberInputStyled
                  theme={colorScheme}
                  hideControls
                  label={
                    <span className="info-inmueble">
                      Valor del inmueble / Contenidos{" "}
                      <span style={{ color: "red" }}>*</span>{" "}
                      <MantineStyledTooltip
                        withinPortal={false}
                        events={{ touch: true }}
                        label={
                          <>
                            Todo lo que este dentro de tu hogar como muebles,
                            refrigerador, televisión, ropa, etc.
                          </>
                        }
                      >
                        <div className="question-inmueble">?</div>
                      </MantineStyledTooltip>
                    </span>
                  }
                  value={
                    globalValuador === 1
                      ? globalSumaAsegurada || ""
                      : datosDelAsegurado?.valorInmueble || ""
                  }
                  onChange={(value) => {
                    setDatosDelAsegurado({
                      ...datosDelAsegurado,
                      valorInmueble: value,
                    });
                  }}
                  placeholder="Valor del inmueble: Ej. $500,000*"
                  variant="filled"
                  max={30000000}
                  disabled={!datosDelAsegurado.perfilContratante}
                  min={
                    datosDelAsegurado.perfilContratante !== "3"
                      ? 500000
                      : 250000
                  }
                  parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                  formatter={(value) =>
                    !Number.isNaN(parseFloat(value))
                      ? `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      : ""
                  }
                />
                {valorInmuebleError && (
                  <>
                    <span className="error">{errores.campoVacio}</span>
                    <br />
                  </>
                )}
                <span className="label-valuador">
                  ¿No conoces el valor?{" "}
                  <u onClick={() => handleOpenValuador()}>Valúalo aquí</u>
                </span>
              </div>
              <div className="question">
                <span>
                  ¿Tu inmueble está ubicado a menos de 250 metros de lago o
                  laguna?
                </span>
                <div className="checkboxes">
                  <Checkbox
                    checked={checkLaguna === "1"}
                    value={"1"}
                    onChange={() => {
                      setDatosDelAsegurado({
                        ...datosDelAsegurado,
                        esLaguna: "1",
                      });
                      setCheckLaguna("1");
                    }}
                    label="Sí"
                  />
                  <Checkbox
                    checked={checkLaguna === "0"}
                    value={"0"}
                    onChange={() => {
                      setDatosDelAsegurado({
                        ...datosDelAsegurado,
                        esLaguna: "0",
                      });
                      setCheckLaguna("0");
                    }}
                    label="No"
                  />
                </div>
              </div>
              <div className="question">
                <span class>
                  ¿Tu inmueble está ubicado a menos de 500 metros de linea de
                  playa?
                </span>
                <div className="checkboxes">
                  <Checkbox
                    checked={checkPlaya === "1"}
                    value={datosDelAsegurado?.esPlaya || "1"}
                    onChange={() => {
                      setDatosDelAsegurado({
                        ...datosDelAsegurado,
                        esPlaya: "1",
                      });
                      setCheckPlaya("1");
                    }}
                    label="Sí"
                  />
                  <Checkbox
                    checked={checkPlaya === "0"}
                    value={datosDelAsegurado?.esPlaya || "0"}
                    onChange={() => {
                      setDatosDelAsegurado({
                        ...datosDelAsegurado,
                        esPlaya: "0",
                      });
                      setCheckPlaya("0");
                    }}
                    label="No"
                  />
                </div>
              </div>
            </div>
          </PersonDetailsContainerCorreoLayout>
          <CheckboxsContainer>
            <div className="checkBoxs">
              <Checkbox
                required
                onChange={() => {
                  setTerminosYCondiciones((value) => !value);
                }}
                label={
                  <>
                    Acepto{" "}
                    <Anchor
                      underline
                      color={colorScheme === "dark" ? "dimmed" : "dark"}
                      weight="bold"
                      size="md"
                      href="https://woowtodobien.com/terminos-y-condiciones"
                      target="_blank"
                    >
                      Términos y Condiciones
                    </Anchor>{" "}
                    y{" "}
                    <Anchor
                      underline
                      color={colorScheme === "dark" ? "dimmed" : "dark"}
                      weight="bold"
                      size="md"
                      href="https://woowtodobien.com/aviso-de-privacidad"
                      target="_blank"
                    >
                      Aviso de Privacidad
                    </Anchor>
                  </>
                }
              />
              {terminosError && (
                <>
                  <span className="error">{errores.terminosCondiciones}</span>
                  <br />
                </>
              )}
            </div>
          </CheckboxsContainer>
          <Valuador
            tipoInmueble={tipoInmueble}
            claseInmueble={claseInmueble}
            datosDelAseguradoHogar={datosDelAsegurado}
            openModal={valuadorModalOpen}
            onCloseModal={() => setValuadorModalOpen(false)}
          />
          <CotizacionPendiente
            catalogos={[claseInmueble, tipoInmueble, medidasSeguridad, perfil] || []}
            dataCotizacionesPendientes={globalCotizacionesPendientes}
            openModal={cotizacionPendienteOpen}
            onCloseModal={() => setCotizacionPendienteOpen(false)}
            sinCotizacionesPendientes={() => setSinCotizacionesOpen(true)}
          />
          <AvisoSinCotizaciones
            openModal={sinCotizacionesOpen}
            onCloseModal={() => setSinCotizacionesOpen(false)}
          />
          {globalValuador === 1 ? (
            <CotizarButton
              onClick={() => {
                TagManager.dataLayer({
                  dataLayer: {
                    event: "hogar_seleccion_paquetes_interno",
                    data: "",
                  },
                });
                navigate("/cotizar");
              }}
            >
              CONTINUAR
            </CotizarButton>
          ) : (
            <CotizarButton
              onClick={() => {
                validarCotizacionOut();
              }}
            >
              COTIZAR
            </CotizarButton>
          )}
        </CotizadorContainer>
      </ComparadorBody>
      {!isResponsive && (
        <div class="contenedor-div">
          <a
            href="https://wa.me/5215585327747"
            target="_blank"
            rel="noreferrer"
          >
            <img
              class="mi-imagen-abajo-derecha"
              src="https://21884627.fs1.hubspotusercontent-na1.net/hubfs/21884627/whatsapp_woow.svg"
              alt="Whatsapp"
              width="60px"
            />
          </a>
        </div>
      )}
    </ComparadorContainer>
  );
};

const ComparadorContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: ${(theme) => {
    return theme.theme === "dark" ? "#1A1B1E" : "#f5f5f5";
  }};

  position: relative;
  height: 1000px;

  @media (max-width: 1020px) {
    padding: 120px 24px 24px;
  }

  .contenedor-div {
    position: absolute;
  }

  .mi-imagen-abajo-derecha {
    position: fixed;
    bottom: 15px;
    right: 90px;
    z-index: 1000 !important;
  }

  .widget-app-container,
  .Application__WidgetAppContainer-sc-1f2l0a1-0,
  .dvBAhE {
    z-index: 99px !important;
  }

  @media (max-width: 992px) {
    .mi-imagen-abajo-derecha {
      position: fixed;
      bottom: 9rem;
      right: 15px;
      z-index: 1000 !important;
    }
    div#hubspot-messages-iframe-container {
      bottom: 55px !important;
    }

    div#hubspot-messages-iframe-container {
      bottom: 55px !important;
      right: 20px !important;
    }
  }

  .header-image {
    width: 1400px;
    @media (max-width: 1020px) {
      display: none;
    }
  }

  .nav {
    width: 100%;
    max-width: 1150px;
    font-size: 0.9rem;

    .actual {
      color: #037cbf;
      font-weight: 700;
    }
  }

  .error {
    color: red;
    font-size: 13px;
    margin-left: 10px;
  }
`;

const ComparadorBody = styled.div`
  display: flex;
  width: 100%;
  max-width: 1350px;
  @media (max-width: 1020px) {
    flex-direction: column;
  }
`;

const CotizadorContainer = styled.div`
  margin-top: 30px;
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  padding: 10px;
  box-sizing: border-box;
  max-width: 1100px;
  left: 9px;
  border-radius: 37px;
  box-shadow: 0px 20px 19px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  background-color: ${(theme) => {
    return theme.theme === "dark" ? "#343434" : "#fff";
  }};

  z-index: 99;
  @media (max-width: 1020px) {
    left: 0;
    box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.08);
    margin: 30px 10px;
  }
  .error {
    color: red;
    font-size: 13px;
    margin-left: 5px;
  }
`;

const MantineTextInputStyled = styled(TextInput)`
  .mantine-Input-input {
    background-color: ${(theme) => {
      return theme.theme === "dark" ? "#4a4a4a" : "#f5f5f5";
    }};
    &::placeholder {
      color: #808083;
    }
  }
  .mantine-TextInput-filledVariant {
    height: 50px;
    border-radius: 16px;
    background-color: #f5f5f5;
  }
  width: ${(props) => props.width || "auto"};
  .mantine-TextInput-label {
    color: rgb(131, 131, 131);
    font-weight: 400;
  }
`;

const MantineNumberInputStyled = styled(NumberInput)`
  .mantine-Input-input {
    background-color: ${(theme) => {
      return theme.theme === "dark" ? "#4a4a4a" : "#f5f5f5";
    }};
    &::placeholder {
      color: #808083;
    }
  }
  .mantine-NumberInput-filledVariant {
    height: 50px;
    border-radius: 16px;
    background-color: #f5f5f5;
  }
  .mantine-NumberInput-label {
    color: rgb(131, 131, 131);
    font-weight: 400;
  }

  width: ${(props) => props.width || "auto"};
`;

const MantineSelectStyled = styled(Select)`
  .mantine-Input-input {
    background-color: ${(theme) => {
      return theme.theme === "dark" ? "#4a4a4a" : "#f5f5f5";
    }};
    &::placeholder {
      color: #808083;
    }
  }
  .mantine-Select-filledVariant {
    height: 50px;
    border-radius: 16px;
    background-color: #f5f5f5;
  }
  .mantine-Select-label {
    color: rgb(131, 131, 131);
    font-weight: 400;
  }
  width: ${(props) => props.width || "auto"};
`;

const Title = styled.h2`
  color: ${(theme) => {
    return theme.theme === "dark" ? "#fff" : "#4e4e4e";
  }};
  font-size: 24px;
  font-weight: 700;
  line-height: 27px;
  letter-spacing: 0em;
  @media (max-width: 1020px) {
    text-align: center;
  }
`;

const PersonDetailsContainerCorreoLayout = styled.div`
  width: 80%;
  height: 220px;
  display: flex;
  flex-direction: column;
  .inmueble {
    .info-inmueble {
      display: flex;
      align-items: center;
    }
    .question-inmueble {
      width: 13px;
      height: 13px;
      background: #c1c1c1;
      color: #fff;
      text-align: center;
      border-radius: 20px;
      font-weight: 600;
      font-size: 10px;
      margin-left: 5px;
      :hover {
        cursor: pointer;
      }
    }
    .label-valuador {
      font-size: 12px;
      line-height: 24px;
      color: ${(theme) => {
        return theme.theme === "dark" ? "#fff" : "#000";
      }};
      :hover {
        cursor: pointer;
      }
      u {
        color: #8e98b7;
      }
    }
  }

  .question {
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 24px;
    color: ${(theme) => {
      return theme.theme === "dark" ? "#fff" : "#4e4e4e";
    }};
    .checkboxes {
      display: flex;
      gap: 30px;
      padding-top: 14px;
      input:checked {
        background-color: #536291;
        border: 1px solid #536291;
      }
    }
  }

  @media (max-width: 1020px) {
    margin-bottom: 50px;
    height: auto;
  }

  p {
    display: block;
    font-weight: 600;
    padding-left: 15px;
    font-size: 1.2rem;
  }

  .inputs-container {
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;

    @media (max-width: 930px) {
      flex-wrap: nowrap;
      flex-direction: column;
      align-items: center;
    }

    & > * {
      width: 32%;
    }

    @media (max-width: 930px) {
      & > * {
        width: 100%;
      }
    }
  }
`;

const CheckboxsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: center;
  width: 80%;
  height: 100px;

  .checkBoxs {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    gap: 10px;
    input:checked {
      background-color: #536291;
      border: 1px solid #536291;
    }

    .mantine-Checkbox-label {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
    }
  }
`;

const CotizarButton = styled.button`
  box-sizing: initial;
  border-radius: 16px;
  border: none;
  width: 30%;
  height: 15px;
  padding: 16px 32px;
  background: linear-gradient(312.79deg, #36405f 5.82%, #536291 89.32%);
  background-blend-mode: color-burn, normal;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: #ffffff;
  transition: all 0.15s ease-out;
  margin-bottom: 20px;

  :hover {
    filter: brightness(120%);
    cursor: pointer;
  }
`;

const MantineStyledTooltip = styled(Tooltip)`
  .mantine-Tooltip-body {
    color: #000;
    background-color: #f5f5f5;
    font-size: 10px;
    line-height: 14px;
    font-weight: 400;
    width: 250px;
    padding: 10px;
    .bold {
      font-weight: 600;
    }
  }
`;
