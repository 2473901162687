import { useMantineColorScheme } from "@mantine/core";
import styled from "styled-components/macro";

const Steps = ({ active }) => {
  const { colorScheme } = useMantineColorScheme();
  return (
    <StepsContainer theme={colorScheme}>
      <div className="nav">
        <div className={`step ${active === 1 ? "activo" : undefined}`}>1</div>
        <div className={`step ${active === 2 ? "activo" : undefined}`}>2</div>
        <div className={`step ${active === 3 ? "activo" : undefined}`}>3</div>
        <div className={`step ${active === 4 ? "activo" : undefined}`}>4</div>
      </div>
    </StepsContainer>
  );
};

const StepsContainer = styled.div`
  font-weight: 700;
  padding: 5px;
  .nav {
    display: flex;
    a {
      outline: none;
      text-decoration: none;
      color: black;
    }

    .step {
      box-sizing: border-box;
      color: #36405f;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20px;
      height: 20px;
      border-radius: 100%;
      background-color: transparent;
      border: 2px solid #36405f;
      margin-left: 10px;
    }

    .activo {
      width: 20px;
      background-color: #36405f;
      color: ${(theme) => {
        return theme.theme === "dark" ? "#1A1B1E" : "#fff";
      }};
    }
  }
`;

export default Steps;
