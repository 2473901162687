import "./App.css";
import { Cotizacion } from "./pages/Cotizacion";
import { SeguroDeHogar } from "./SeguroDeHogar";
import { HashRouter, Route, Routes } from "react-router-dom";
import { Coberturas } from "./pages/Coberturas";
import { Emisor } from "./pages/Emisor";
import { Pago } from "./pages/Pago";
import { ThankYouPage } from "./pages/ThankYouPage";
import { NotificationsProvider } from "@mantine/notifications";
import { CotizadorInterno } from "./pages/CotizadorInterno";
import { ThankYouPageGeneric } from "./pages/ThankYouPageGeneric";
import { FailedPayment } from "./pages/FailedPayment";
import {
  MantineProvider,
  ColorSchemeProvider,
} from "@mantine/core";
import { useState } from "react";

function App() {
  const [colorScheme, setColorScheme] = useState("dark");
  const toggleColorScheme = (value) =>
    setColorScheme(value || (colorScheme === "dark" ? "light" : "dark"));
  return (
    <NotificationsProvider position="top-right">
      <div
        className="App"
        style={{ fontFamily: "Montserrat", margin: 0, padding: 0 }}
      >
        <HashRouter>
          <ColorSchemeProvider
            colorScheme={colorScheme}
            toggleColorScheme={toggleColorScheme}
          >
            <MantineProvider
              theme={{ colorScheme }}
              withGlobalStyles
              withNormalizeCSS
            >
              <Routes>
                <Route path="/" element={<SeguroDeHogar />} />
                <Route path="/cotizar" element={<Cotizacion />} />
                <Route path="/coberturas" element={<Coberturas />} />
                <Route path="/emision" element={<Emisor />} />
                <Route path="/pago" element={<Pago />} />
                <Route path="/gracias" element={<ThankYouPage />} />
                <Route path="/gracias-pago" element={<ThankYouPageGeneric />} />
                <Route path="/error-pago" element={<FailedPayment />} />
                <Route path="/interno" element={<CotizadorInterno />} />
              </Routes>
            </MantineProvider>
          </ColorSchemeProvider>
        </HashRouter>
      </div>
    </NotificationsProvider>
  );
}

export default App;
