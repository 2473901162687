import perk1 from "../../assets/landing/msi-icon-dark.svg"
import perk2 from "../../assets/landing/card-icon-dark.svg"
import perk3 from "../../assets/landing/lock-icon-dark.svg"
import perk4 from "../../assets/landing/support-icon-dark.svg"
import perk5 from "../../assets/landing/phone-icon-dark.svg"
import perk6 from "../../assets/landing/comment-icon-dark.svg"
import woowPass from "../../assets/landing/Rectangle_6.png"
import Section from "./Section"
import styled from "styled-components"
import { useMantineColorScheme } from "@mantine/core"

const WOOWPassBox = ({ svgColor, bgColor }) => {
  const { colorScheme } = useMantineColorScheme()

  return (
    <Section bgColor={bgColor}>
      <WOOWPassBoxContainer
        theme={colorScheme}
        bgColor={bgColor}
        svgColor={svgColor}
      >
        <h2>¿Por qué contratar con WOOW?</h2>
        <div className="section-6-container">
          <div className="section-6-1">
            <img src={woowPass} className="woowImage" alt="woow pass" />

            <div className="text-container">
              <h3>Te obsequiamos MX$4,500 en servicios exclusivos</h3>
              <p>
                Puedes usarlos para proteger tu salud, tu hogar y tu auto,
                también tendrás cupones de descuento para tiendas de ropa,
                alimentos y bebidas, entretenimiento, viajes ¡y mucho más!
              </p>
              <span>¡Sin costo durante 3 meses!</span>
              <br />
              <br />

              <a href="https://woowtodobien.com/woowpass">Conoce más</a>
            </div>
          </div>
          <h3 style={{ paddingTop: "20px" }}>Además</h3>

          <div className="section-6-2">
            <div className="perks-body">
              <div className="row">
                <div className="perks-item">
                  <img src={perk1} alt="palomita" />
                  <span>Paga a precios accesibles y con facilidades</span>
                </div>
                <div className="perks-item">
                  <img src={perk2} alt="palomita" />
                  <span>Aceptamos tarjetas VISA, Mastercard y AMEX</span>
                </div>
                <div className="perks-item">
                  <img src={perk3} alt="palomita" />
                  <span>Toda tu información está segura y encriptada</span>
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="perks-item">
                  <img src={perk4} alt="palomita" />
                  <span>Recibe atención a través del chat de ayuda 24/7</span>
                </div>
                <div className="perks-item">
                  <img src={perk5} alt="palomita" />
                  <span>Todas tus protecciones en un mismo lugar</span>
                </div>
                <div className="perks-item">
                  <img src={perk6} alt="palomita" />
                  <span>Adquiere tu seguro sin letras chiquitas</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </WOOWPassBoxContainer>
    </Section>
  )
}

const WOOWPassBoxContainer = styled.div`
  max-width: 1500px;
  padding-top: 60px;

  & > * {
    font-family: Montserrat;
  }

  @media (max-width: 1080px) {
    padding: 0px;
  }

  h2 {
    text-align: center;
    color: ${theme => {
      return theme.theme === "dark" ? "#fff" : "#4e4e4e"
    }};
    font-weight: 600;
    font-size: 24px;
    padding-top: 10px;
  }

  .section-6-container {
    max-width: 1500px;
    display: flex;
    align-items: center;
    flex-direction: column;

    img {
      height: 120px;
      width: auto;
    }

    .section-6-1 {
      background-color: ${theme => {
        return theme.theme === "dark" ? "#242424" : "#fff"
      }};
      box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.08);
      border-radius: 16px;
      box-sizing: border-box;
      display: flex;
      padding: 15px;
      border-radius: 16px;
      padding-bottom: 10px;

      .woowImage {
        @media (max-width: 580px) {
          max-width: 150px;
        }
      }

      img {
        height: 100px;
      }

      @media (max-width: 580px) {
        flex-direction: column;
        padding: 20px;
        width: 80vw;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: 100%;
          height: auto;
        }
      }

      .text-container {
        h3 {
          color: ${theme => {
            return theme.theme === "dark" ? "#fff" : "#4e4e4e"
          }};
          font-size: 1.1rem;
        }

        p {
          color: ${theme => {
            return theme.theme === "dark" ? "#fff" : "#4e4e4e"
          }};
          font-size: 1rem;
          font-weight: 300;
        }

        span {
          color: #edc729;
          font-weight: 600;
          font-size: 18px;
        }

        a {
          color: ${theme => {
            return theme.theme === "dark" ? "#fff" : "#4e4e4e"
          }};
          font-weight: 800;
        }
      }
    }

    h3 {
      color: ${theme => {
        return theme.theme === "dark" ? "#fff" : "#4e4e4e"
      }};
      font-size: 24px;
      font-weight: 600;
    }

    .section-6-2 {
      @media (max-width: 580px) {
        img {
          height: 30px;
        }
      }

      .perks-body {
        display: flex;
        flex-direction: column;
        flex: 1;
        align-items: center;

        hr {
          margin-left: 0;
          margin-right: 0;
          border-top: 1px solid rgb(224, 224, 224);
          width: 95%;

          @media (max-width: 1000px) {
            display: none;
          }
        }

        .row {
          display: flex;
          flex-direction: row;
          flex: 1;

          @media (max-width: 1000px) {
            flex-direction: column;
          }

          .perks-item {
            display: flex;
            align-items: center;
            flex: 1;
            max-width: 400px;

            padding: 20px;
            font-weight: 600;
            color: ${theme => {
              return theme.theme === "dark" ? "#fff" : "#4e4e4e"
            }};
            letter-spacing: 0.5px;

            @media (max-width: 1000px) {
              left: 0;
              right: 0;
              margin-left: auto;
              margin-right: auto;
              position: relative;
              width: 60%;
              justify-content: flex-start;
              align-items: flex-start;
            }

            @media (max-width: 420px) {
              padding: 15px;
              width: 90%;
            }

            img {
              height: 50px;
              width: 50px;
              padding-right: 10px;
              filter: ${props => props.svgColor && props.svgColor};

              @media (max-width: 420px) {
                height: 50px;
                width: auto;
              }
            }
          }
        }
      }
    }
  }
`

export default WOOWPassBox
